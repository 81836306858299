import { productUnionCategoryTreeType } from "@swr/types/productTypes";
import { PRODUCT_CATEGORY_TREE_EXPOSURE_TYPE_LIST } from "@constants/service/product/product";

/**
 * 일반형, 강조형 카테고리 타입을 노출 타입의 값에 따라 합치는 함수
 * @param list 일반형 or 강조형 카테고리 트리 배열
 * @returns list 일반형과 강조형 카테고리 중 노출 타입이 공통인 카테고리를 합친 카테고리 트리 배열
 */
export const filterCommonExposureType = (list: productUnionCategoryTreeType[]) =>
  list.filter((category) => category.exposureType === PRODUCT_CATEGORY_TREE_EXPOSURE_TYPE_LIST.COMMON);
