import styled from "@styles/themedComponents";
import { PRODUCT_STATUS } from "@constants/enum/productEnum";
import LikeToggleButton from "@components/common/LikeToggleButton";

export const CardStyle = styled.article`
  display: flex;
  flex-direction: column;
`;
export const CardImageAreaStyle = styled.figure`
  display: block;
  margin: 0 0 8px;
  padding: 0;
  font-size: 0;

  img {
    border-radius: 2px;
  }
`;
export const CardInfoAreaStyle = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  font-family: ${({ theme }) => theme.fonts.notoSansCJKkr};
`;

interface ILikeFlagProps {
  isLike: boolean;
}
export const BrandNameStyle = styled.strong<ILikeFlagProps>`
  width: ${({ isLike }) => (isLike ? "calc(100% - 36px)" : "100%")};
  ${({ theme }) => theme.ellipsis};
  font-size: 12px;
  font-weight: bold;
  line-height: 19px;
  letter-spacing: -0.5px;
`;

export const ProductStyle = styled.p<ILikeFlagProps>`
  width: ${({ isLike }) => (isLike ? "calc(100% - 36px)" : "100%")};
  ${({ theme }) => theme.ellipsis};
  font-size: 12px;
  font-weight: normal;
  line-height: 19px;
  letter-spacing: -0.5px;
`;

export const PriceStyle = styled.p`
  flex: none;
  font-family: ${({ theme }) => theme.fonts.barlow};
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
  height: 17px;
`;
export const DiscountRateStyle = styled.span`
  color: ${({ theme }) => theme.color.amondz_purple};
  margin-right: 4px;

  &:after {
    content: "%";
  }
`;
interface ISalesPriceStyle {
  status?: PRODUCT_STATUS.NOW_ON_SALE | PRODUCT_STATUS.SOLD_OUT;
}
export const SalesPriceStyle = styled.span<ISalesPriceStyle>`
  color: ${({ status, theme }) =>
    status === PRODUCT_STATUS.SOLD_OUT ? theme.color.gray_01 : theme.color.amondz_black};

  & > span {
    ${({ status }) => status === PRODUCT_STATUS.SOLD_OUT && "text-decoration: line-through"};
  }

  &:after {
    content: "원";
    font-family: ${({ theme }) => theme.fonts.notoSansCJKkr};
    font-size: 11px;
    font-weight: normal;
    letter-spacing: -0.5px;
    text-decoration: none;
  }
`;

export const LikeCountStyle = styled.div`
  font-size: 10px;
  font-weight: bold;
  line-height: 15px;
  color: ${({ theme }) => theme.color.gray_01};

  &:before {
    content: "좋아요";
    margin-right: 4px;
  }
`;

export const LikeButtonStyle = styled(LikeToggleButton)`
  position: absolute;
  top: 0;
  right: 0;
`;

export const TodayBadgeStyle = styled.div`
  position: relative;
  flex-grow: 1;
  height: 12px;
  margin-left: 5px;
`;

export const GiftBadgeStyle = styled.div`
  padding: 2px 4px 3px;
  font-size: 10px;
  font-weight: 500;
  line-height: 15px;
  color: ${({ theme }) => theme.color.gray_01};
  background-color: #f5f5f5;
  border-radius: 2px;
`;
