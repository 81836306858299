import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";

export const GlobalStyle = createGlobalStyle`
	${normalize}

	@font-face {
		font-family: "Barlow";
		font-weight: bold;
		font-display: fallback;
		//@Todo public 폴더를 루트 경로(/)로 인식하게 하는 방법 찾은 후 개선
		//noinspection CssUnknownTarget
		src: url("/fonts/Barlow/Barlow-Bold.woff2") format("woff2"),
		url("/fonts/Barlow/Barlow-Bold.woff") format("woff")
	}

	@font-face {
		font-family: "Barlow";
		font-weight: 600;
		font-display: fallback;
		//noinspection CssUnknownTarget
		src: url("/fonts/Barlow/Barlow-SemiBold.woff2") format("woff2"),
		url("/fonts/Barlow/Barlow-SemiBold.woff") format("woff")
	}

	@font-face {
		font-family: "Barlow";
		font-weight: 500;
		font-display: fallback;
		//noinspection CssUnknownTarget
		src: url("/fonts/Barlow/Barlow-Medium.woff2") format("woff2"),
		url("/fonts/Barlow/Barlow-Medium.woff") format("woff")
	}

	@font-face {
		font-family: "Barlow";
		font-weight: normal;
		font-display: fallback;
		//noinspection CssUnknownTarget
		src: url("/fonts/Barlow/Barlow-Regular.woff2") format("woff2"),
		url("/fonts/Barlow/Barlow-Regular.woff") format("woff")
	}

	@font-face {
		font-family: "Barlow";
		font-weight: 300;
		font-display: fallback;
		//noinspection CssUnknownTarget
		src: url("/fonts/Barlow/Barlow-Light.woff2") format("woff2"),
		url("/fonts/Barlow/Barlow-Light.woff") format("woff")
	}

	@font-face {
		font-family: "NotoSansCJKkr";
		font-weight: bold;
		font-display: fallback;
		//noinspection CssUnknownTarget
		src: local("NotoSansCJKkr"), url("/fonts/NotoSansKr/NotoSansKR-Bold.woff2") format("woff2"),
		url("/fonts/NotoSansKr/NotoSansKR-Bold.woff") format("woff")
	}

	@font-face {
		font-family: "NotoSansCJKkr";
		font-weight: 500;
		font-display: fallback;
		//noinspection CssUnknownTarget
		src: local("NotoSansCJKkr"), url("/fonts/NotoSansKr/NotoSansKR-Medium.woff2") format("woff2"),
		url("/fonts/NotoSansKr/NotoSansKR-Medium.woff") format("woff")
	}

	@font-face {
		font-family: "NotoSansCJKkr";
		font-weight: normal;
		font-display: fallback;
		//noinspection CssUnknownTarget
		src: local("NotoSansCJKkr"), url("/fonts/NotoSansKr/NotoSansKR-Regular.woff2") format("woff2"),
		url("/fonts/NotoSansKr/NotoSansKR-Regular.woff") format("woff")
	}

	@font-face {
		font-family: "Pretendard";
		font-weight: bold;
		font-display: swap;
		//noinspection CssUnknownTarget
		src: url("/fonts/Pretendard/Pretendard-Bold.woff2") format("woff2"),
		url("/fonts/Pretendard/Pretendard-Bold.woff2") format("woff")
	}

	@font-face {
		font-family: "Pretendard";
		font-weight: normal;
		font-display: swap;
		//noinspection CssUnknownTarget
		src: url("/fonts/Pretendard/Pretendard-Regular.woff2") format("woff2"),
		url("/fonts/Pretendard/Pretendard-Regular.woff2") format("woff")
	}
	
	*,
	*:before,
	*:after {
		box-sizing: inherit;
	}

	a {
		text-decoration: none;
		color: inherit;
	}

	html,
	body {
		font-family: "NotoSansCJKkr", sans-serif;
		height: 100%;
		margin: 0;
		box-sizing: border-box;
		-webkit-tap-highlight-color: transparent;
	}

	ul {
		margin: 0;
		list-style: none;
	}

	ul, li, p, button {
		padding: 0;
	}

	p {
		margin: 0;
	}

	input {
		font-family: inherit;
		appearance: none;
		-webkit-appearance: none;
		-webkit-border-radius: 0;
	}

	input[type="number"]::-webkit-outer-spin-button,
	input[type="number"]::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	textarea {
		outline: none;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		-webkit-border-radius: 0;
	}
`;
