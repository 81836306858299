import useSWR, { Fetcher, SWRConfiguration, SWRResponse } from "swr";

import { setObjectToQuerystring } from "@lib/utility/common";
import { searchBeforeAutoCompleteListRequestType, searchBeforeAutoCompleteListType } from "@swr/types/searchTypes";
import { searchBeforeAutoCompleteListKey } from "@swr/keys/searchKeys";
import { searchBeforeAutoCompleteListFetcher } from "@swr/fetchers/search/searchFetchers";

function useSearchBeforeAutoCompleteList(
  payload: searchBeforeAutoCompleteListRequestType,
  option?: Partial<SWRConfiguration<searchBeforeAutoCompleteListType,Error,Fetcher<searchBeforeAutoCompleteListType,string>>>,
): SWRResponse<searchBeforeAutoCompleteListType, Error> {
  const key = setObjectToQuerystring(searchBeforeAutoCompleteListKey, payload);
  return useSWR(key, () => searchBeforeAutoCompleteListFetcher(payload.keyword), option);
}

export default useSearchBeforeAutoCompleteList;
