import {
  CouponListStateType,
  OrderItemResponseType,
  PhotographyGuidesResponse,
  ProductReviewBenefitResponse,
  RegisterReviewParameterType,
  ReviewCriteriaResponse,
} from "@amondz/types";
import superFetch from "@amondz/apis-v1";
import superFetchV2 from "@amondz/apis-v2";
import {
  MyPageMainStateType,
  CheckEmailMemberRequestType,
  MemberManageStateType,
  UpdatePasswordEmailMemberRequestType,
  UpdatePasswordEmailMemberSuccessType,
  UpdateMemberInfoSuccessType,
  RemoveMemberInfoSuccessType,
  GetSnsMemberRequestType,
  GetQnaProductListRequestType,
  DeleteQnaProductType,
  UpdateQnaProductType,
  GenerateCouponRequestType,
  GenerateCouponSuccessType,
  GetProductCouponRequestType,
  GetProductCouponSuccessType,
  GetPointListRequestType,
  PointListStateType,
  GetOrderDetailRequestType,
  GetOrderCancelRequestType,
  GetOrderItemShippingInfoRequestType,
  UpdateOrderItemShippingInfoRequestType,
  GetOrderDeliveryTrackingRequestType,
  ResultOrderCancelRequestType,
  WithdrawOrderCancelRequestType,
  GetOrderReturnRequestType,
  OrderReturnRequestType,
  UpdateOrderReturnVbankRequestType,
  OrderExchangeRequestType,
  ShippingDelayReasonRequestType,
  SubmitOrderCancelRequestType,
  ConfirmPurchaseRequestType,
  ReviewIdType,
  GetBrandCouponRequestType,
  GetBrandCouponSuccessType,
} from "@store/modules/mypage";
import { InsertProductQnaRequestType } from "@store/modules/product";

const mypageAPI = {
  // 마이페이지 메인 데이터 요청 API
  getMyPageMain: async () => {
    return superFetch.post<MyPageMainStateType>("/mypage/main/web/v1");
  },

  // 마이페이지 쿠폰 리스트 데이터 요청 API
  getCouponList: async () => {
    return superFetch.post<CouponListStateType>("/mypage/coupon/list/web/v1");
  },

  // 쿠폰 발급 요청 API
  generateCoupon: async (data: GenerateCouponRequestType) => {
    return superFetch.post<GenerateCouponSuccessType>("/coupon/generate/web/v1", data);
  },

  // 쿠폰 적용가능 상품 API
  getProductCouponList: async (data: GetProductCouponRequestType) => {
    return superFetch.post<GetProductCouponSuccessType>("/mypage/coupon/link/product/list/pagination/web/v2", data);
  },

  // 쿠폰 적용가능 브랜드 API
  getBrandCouponList: async (data: GetBrandCouponRequestType) => {
    return superFetch.post<GetBrandCouponSuccessType>("/mypage/coupon/link/brand/list/pagination/web/v1", data);
  },

  // 쿠폰 적용가능 상품 API
  getPointList: async (data: GetPointListRequestType) => {
    return superFetch.post<PointListStateType>("/mypage/point/list/pagination/web/v1", data);
  },

  // 이메일 비밀 번호 재확인 API
  checkEmailMember: async (data: CheckEmailMemberRequestType) => {
    return superFetch.post<MemberManageStateType>("/mypage/member/email/check/web/v1", data);
  },

  // sns 회원 정보 불러오기 API
  getSnsMember: async (data: GetSnsMemberRequestType) => {
    return superFetch.post<MemberManageStateType>("/mypage/member/sns/select/web/v1", data);
  },

  // 이메일 계정 비밀 번호 변경 API
  updatePasswordEmailMember: async (data: UpdatePasswordEmailMemberRequestType) => {
    return superFetch.post<UpdatePasswordEmailMemberSuccessType>("/mypage/member/email/password/update/web/v2", data);
  },

  // 회원 정보 수정 API
  updateMemberInfo: async (data: MemberManageStateType) => {
    return superFetchV2.patch<UpdateMemberInfoSuccessType>("/members", data);
  },

  // 회원 탈퇴 API
  removeMemberInfo: async () => {
    return superFetch.post<RemoveMemberInfoSuccessType>("/mypage/member/remove/web/v1");
  },

  // 상품 문의 리스트 요청 API
  getProductQnaList: async (data: GetQnaProductListRequestType) => {
    return superFetch.post<GetQnaProductListRequestType>("mypage/qna/list/pagination/web/v1", data);
  },

  // 상품 문의 수정 요청 API
  updateProductQna: async (data: UpdateQnaProductType) => {
    return superFetch.post<UpdateQnaProductType>("/mypage/qna/update/web/v1", data);
  },

  // 상품 문의 삭제 요청 API
  deleteProductQna: async (data: DeleteQnaProductType) => {
    return superFetch.post<DeleteQnaProductType>("/mypage/qna/delete/web/v1", data);
  },

  // 상품 문의 등록 요청 API
  insertProductQna: async (data: InsertProductQnaRequestType) => {
    return superFetch.post<InsertProductQnaRequestType>("/product/qna/insert/web/v2", data);
  },

  // 주문 정보 내역 요청 API
  getOrderList: async () => {
    return superFetch.post("/mypage/order/list/web/v2");
  },

  // 주문 상세 내역 요청 API
  getOrderDetail: async (data: GetOrderDetailRequestType) => {
    return superFetch.post("/mypage/order/detail/web/v3", data);
  },

  // 주문 취소 내역 요청 API
  getOrderCancel: async (data: GetOrderCancelRequestType) => {
    return superFetch.post("/mypage/order/cancel/select/web/v2", data);
  },

  // 주문 반품 내역 요청 API
  getOrderReturn: async (data: GetOrderReturnRequestType) => {
    return superFetch.post("/mypage/order/return/select/web/v2", data);
  },

  // 주문 교환 내역 요청 API
  getOrderExchange: async (data: GetOrderReturnRequestType) => {
    return superFetch.post("/mypage/order/exchange/select/web/v2", data);
  },

  // 주문 상품 배송지 내역 요청 API
  getOrderItemShipping: async (data: GetOrderItemShippingInfoRequestType) => {
    return superFetch.post("/mypage/order/delivery/select/web/v1", data);
  },

  // 주문 상품 배송지 수정 요청 API
  updateOrderItemShipping: async (data: UpdateOrderItemShippingInfoRequestType) => {
    return superFetch.post("/mypage/order/delivery/update/web/v1", data);
  },

  // 구매 확정 요청 API
  confirmPurchase: async (data: ConfirmPurchaseRequestType) => {
    return superFetch.post("/mypage/order/purchase/confirm/android/v3", data);
  },

  // 배송 추적 요청 API
  getOrderDeliveryTracking: async (data: GetOrderDeliveryTrackingRequestType) => {
    return superFetch.post("/mypage/order/delivery/tracking/web/v1", data);
  },

  // 배송 지연 사유 요청 API
  shippingDelay: async (data: ShippingDelayReasonRequestType) => {
    return superFetch.post("/mypage/order/delay/web/v2", data);
  },

  // 주문 취소 요청 API
  submitOrderCancel: async (data: SubmitOrderCancelRequestType) => {
    return superFetch.post("/mypage/order/cancel/submit/web/v2", data);
  },

  // 주문 취소 내역 요청 API
  resultOrderCancel: async (data: ResultOrderCancelRequestType) => {
    return superFetch.post("/mypage/order/cancel/result/web/v2", data);
  },

  // 주문 취소 철회 요청 API
  withdrawOrderCancel: async (data: WithdrawOrderCancelRequestType) => {
    return superFetch.post("/mypage/order/cancel/withdraw/web/v1", data);
  },

  // 주문 반품 요청 API
  submitOrderReturn: async (data: FormData) => {
    return superFetch.post("/mypage/order/return/submit/web/v2", data);
  },

  // 주문 반품 내역 요청 API
  resultOrderReturn: async (data: OrderReturnRequestType) => {
    return superFetch.post("/mypage/order/return/result/web/v2", data);
  },

  // 주문 반품 철회 요청 API
  withdrawOrderReturn: async (data: OrderReturnRequestType) => {
    return superFetch.post("/mypage/order/return/withdraw/web/v1", data);
  },

  // 주문 교환 요청 API
  submitOrderExchange: async (data: FormData) => {
    return superFetch.post("/mypage/order/exchange/submit/web/v2", data);
  },

  // 주문 교환 내역 요청 API
  resultOrderExchange: async (data: OrderExchangeRequestType) => {
    return superFetch.post("/mypage/order/exchange/result/web/v2", data);
  },

  // 주문 교환 철회 요청 API
  withdrawOrderExchange: async (data: OrderExchangeRequestType) => {
    return superFetch.post("/mypage/order/exchange/withdraw/web/v1", data);
  },

  // 가상계좌 환불 정보 조회 요청 API
  selectOrderReturnVbank: async (data: OrderReturnRequestType) => {
    return superFetch.post("/mypage/order/return/vbank/select/web/v1", data);
  },

  // 가상계좌 환불 정보 수정 요청 API
  updateOrderReturnVbank: async (data: UpdateOrderReturnVbankRequestType) => {
    return superFetch.post("/mypage/order/return/vbank/update/web/v1", data);
  },
  //후기 포인트 가이드 정보 조회 API
  getProductReviewBenefits: async (): Promise<ProductReviewBenefitResponse> => {
    return superFetchV2.get("/product-review-benefits");
  },
  // 후기 삭제 요청 API
  deleteReview: async (data: ReviewIdType) => {
    return superFetch.post("/mypage/review/delete/web/v1", data);
  },

  getOrderItem: async (orderItemId: number): Promise<OrderItemResponseType> => {
    return superFetchV2.get(`/order-items/${orderItemId}`);
  },
  registerReview: async (reviewData: RegisterReviewParameterType) => {
    return superFetchV2.post("/reviews", reviewData);
  },
  getReviewGuide: (categoryId: number): Promise<PhotographyGuidesResponse> => {
    return superFetchV2.get(`/product-categories/${categoryId}/photography-guides`);
  },
  getReviewCriteria: (categoryId: number): Promise<ReviewCriteriaResponse> => {
    return superFetchV2.get(`/product-categories/${categoryId}/review-criteria`);
  }
};

export default mypageAPI;
