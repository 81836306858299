import styled from "@styles/themedComponents";
import ButtonCover from "@components/common/cover/ButtonCover";

interface ISearchWordButtonListStyleProps {
  needContent: boolean;
}

export const SearchWordButtonListWrapperStyle = styled.ul`
  padding-top: 20px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.5px;
`;

export const SearchWordButtonListStyle = styled.li<ISearchWordButtonListStyleProps>`
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 10px;

  &:first-child:before {
    content: "${({ needContent }) => (needContent ? "연관검색어" : "")}";
    margin-right: ${({ needContent }) => (needContent ? "8px" : 0)};
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.5px;
  }
`;

export const SearchWordButtonStyle = styled(ButtonCover)`
  height: 42px;
  max-width: 345px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid ${({ theme }) => theme.color.gray_06};
  border-radius: 21px;
  background-color: ${({ theme }) => theme.color.amondz_white};
  padding: 11px 13px;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.6px;
  cursor: pointer;
`;
