// 상품 필터
export enum PRODUCT_FILTER_TYPE {
  /**
   * 브랜드
   * */
  STORE,
  /**
   * 1차 카테고리
   * */
  CATEGORY,
  /**
   * 2차 카테고리
   * */
  SUB_CATEGORY,
  /**
   * 베이스 소재
   * */
  BASE_MATERIAL,
  /**
   * 모양
   * */
  SHAPE,
  /**
   * 원석
   * */
  GEMSTONE,
  /**
   * 귀걸이 침소재
   * */
  POST_EARRING_MATERIAL,
  /**
   * 체인 모양
   * */
  CHAIN_SHAPE,
  /**
   * 컬러
   * */
  COLOR,
  /**
   * 가격
   * */
  PRICE,
  /**
   * 할인
   * */
  DISCOUNT,
}

// 상품 정럴 필터 리스트
export enum PRODUCT_SORT_TYPE {
  /**
   * 트렌드순
   * */
  RECOMMENDATION,
  /**
   * 인기순
   * */
  POPULAR,
  /**
   * 최신순
   * */
  RECENT,
  /**
   * 낮은가격순
   * */
  CHEAP,
  /**
   * 높은가격순
   * */
  EXPENSIVE,
}

// 상품 상태 리스트
export enum PRODUCT_STATUS {
  /**
   * 승인대기
   * */
  CONFIRM_READ,
  /**
   * 승인 거절
   */
  CONFIRM_REJECT,
  /**
   * 판매중
   * */
  NOW_ON_SALE,
  /**
   * 품절
   * */
  SOLD_OUT,
  /**
   * 판매종료
   * */
  SALES_CLOSE,
  /**
   * 판매금지
   * */
  SALES_PROHIBIT,
  /**
   * 판매대기
   * */
  SALES_READY,
  /**
   * 판매중지
   * */
  SALES_STOP,
  /**
   * 삭제
   * */
  DELETE,
}

// 상품 옵션 상태 리스트
export enum PRODUCT_OPTION_STATUS {
  /**
   * 비활성 (노출 안함)
   * */
  DEACTIVATE,
  /**
   * 활성
   * */
  ACTIVATE,
  /**
   *  품절 (노출 함)
   * */
  SOLD_OUT,
  /**
   * 삭제 (노출 안함)
   * */
  DELETE,
}

// 상품 상세에서 노출되는 배송안내 타입 리스트
export enum PRODUCT_DETAIL_DELIVERY_TYPE {
  /**
   * 일반
   * */
  NORMAL,
  /**
   * 주문 시 제작
   * */
  ORDER_MADE,
  /**
   * 바로 배송
   * */
  IMMEDIATELY,
  /**
   * 오늘출발
   * */
  TODAY_DELIVERY,
}

// 상품 이미지 타입 리스트
export enum PRODUCT_ATTACHMENT_TYPE {
  /**
   * 대표 이미지
   * */
  IMG_MAIN,
  /**
   * 미리보기 추가 이미지
   * */
  IMG_SUB,
  /**
   * 상세 컨텐츠 이미지
   * */
  IMG_DETAIL,
  /**
   * 상세 컨텐츠 동영상
   * */
  VIDEO_DETAIL = 100,
}

// 상품 모달명 리스트
export enum PRODUCT_MODAL_NAME_TYPE {
  /**
   * 상품 배송 안내 모달
   * */
  PRODUCT_DELIVERY_INFO_MODAL = "productDeliveryInfoModal",
  /**
   * 주문 반품, 교환 배송 안내 모달
   * */
  ORDER_RETURN_EXCHANGE_DELIVERY_INFO_MODAL = "orderReturnExchangeDeliveryInfoModal",
  /**
   * A/S 안내 모달
   * */
  AS_INFO_MODAL = "asInfoModal",
}

// 상품 구매 수량 버튼 타입 리스트
export enum PRODUCT_PURCHASE_COUNT_BTN_TYPE {
  /**
   * 감소
   * */
  DECREASE,
  /**
   * 증가
   * */
  INCREASE,
}

// 가격 할인 타입 리스트
export enum DISCOUNT_TYPE {
  /**
   * 금액할인
   * */
  AMOUNT,
  /**
   * 정률할인
   * */
  RATE,
}
