import { createReducer } from "typesafe-actions";
import { produce } from "immer";
import { BrandCouponStateType, CouponListStateType, ProductCouponStateType } from "@amondz/types";
import { asyncState } from "@lib/utility/reducerUtils";
import { resetOrderReason } from "@lib/utility/resetOrderReason";
import { ORDER_ITEM_REVIEW_STATUS } from "@constants/service/mypage/mypage";
import { LIKE_RESULT_CODE } from "@constants/service/common/common";
import { RECEIVE_AGREE_STATUS } from "@constants/service/auth/auth";
import { STATUS_CODE_COMMON } from "@constants/status/code/statusCodeCommon";
import { GENDER_TYPE } from "@constants/enum/authEnums";
import { ProductCardStateType } from "../common/types";
import {
  MyPageStateType,
  MyPageAction,
  MyPageMainStateType,
  LikeProductItemStateType,
  MemberManageStateType,
  QnaProductListStateType,
  QnaProductItemStateType,
  PointListStateType,
  OrderListStateType,
  OrderDetailStateType,
  OrderCancelStateType,
  OrderDetailItemType,
  OrderItemShippingStateType,
  OrderDeliveryTrackingStateType,
  SubmitOrderCancelStateType,
  ResultOrderCancelStateType,
  OrderWithdrawStateType,
  OrderReturnStateType,
  SubmitOrderReturnStateType,
  ResultOrderReturnStateType,
  OrderReturnVbankStateType,
  OrderReturnExchangeCommonType,
  SubmitOrderExchangeStateType,
  ResultOrderExchangeStateType,
  ShippingDelayReasonStateType,
  OrderInfoItemType,
} from "./types";
import {
  CHECK_EMAIL_MEMBER,
  GET_MY_PAGE_MAIN,
  UPDATE_PASSWORD_EMAIL_MEMBER,
  UPDATE_MEMBER_INFO,
  REMOVE_MEMBER_INFO,
  GET_SNS_MEMBER,
  REMOVE_MEMBER_MODAL_TOGGLE,
  GET_QNA_PRODUCT_LIST,
  UPDATE_QNA_PRODUCT,
  DELETE_QNA_PRODUCT,
  GET_COUPON_LIST,
  GENERATE_COUPON,
  GET_PRODUCT_COUPON,
  SET_LIKE_PRODUCT_COUPON,
  GET_POINT_LIST,
  GET_ORDER_LIST,
  GET_ORDER_DETAIL,
  GET_ORDER_CANCEL,
  GET_ORDER_ITEM_SHIPPING_INFO,
  UPDATE_ORDER_ITEM_SHIPPING_INFO,
  UNMOUNT_ORDER_MODAL,
  GET_ORDER_DELIVERY_TRACKING,
  SUBMIT_ORDER_CANCEL,
  RESULT_ORDER_CANCEL,
  WITHDRAW_ORDER_CANCEL,
  GET_ORDER_RETURN,
  SUBMIT_ORDER_RETURN,
  RESULT_ORDER_RETURN,
  INIT_MEMBER_MANAGE,
  WITHDRAW_ORDER_RETURN,
  SELECT_ORDER_RETURN_VBANK,
  UPDATE_ORDER_RETURN_VBANK,
  GET_ORDER_EXCHANGE,
  SUBMIT_ORDER_EXCHANGE,
  RESULT_ORDER_EXCHANGE,
  WITHDRAW_ORDER_EXCHANGE,
  SHIPPING_DELAY_REASON,
  CONFIRM_PURCHASE,
  INSERT_PRODUCT_QNA,
  AFTER_SUBMIT_ORDER_LIST_REVIEW,
  AFTER_SUBMIT_ORDER_DETAIL_REVIEW,
  GET_BRAND_COUPON,
  RESET_UPDATE_MEMBER_INFO_STATUS,
  MEMBERSHIP_ERROR,
  RESET_UPDATE_ORDER_RETURN_VBANK_STATUS,
} from "./actions";

export const initialMyPageState: MyPageStateType = {
  myPageMainState: asyncState.initial<MyPageMainStateType>({
    cartCount: 0,
    userName: "",
    point: 0,
    couponCount: 0,
    notificationList: [],
    reviewReadyCount: 0,
    orderList: [],
    likeProductList: [],
    likeBrandList: [],
    isMembershipError: false,
  }),
  couponListState: asyncState.initial<CouponListStateType>({
    allCount: 0,
    couponList: [],
  }),
  productCouponState: asyncState.initial<ProductCouponStateType>({
    coupon: {
      couponId: 0,
      availableType: 0,
      discountType: 0,
      discountAmount: 0,
      name: "",
      description: "",
      remainingPeriod: "",
      date: "",
    },
    allCount: 0,
    productList: [],
  }),
  brandCouponState: asyncState.initial<BrandCouponStateType>({
    coupon: {
      couponId: 0,
      availableType: 0,
      discountType: 0,
      discountAmount: 0,
      name: "",
      description: "",
      remainingPeriod: "",
      date: "",
    },
    allCount: 0,
    brandList: [],
  }),
  pointListState: asyncState.initial<PointListStateType>({
    credit: 0,
    usedCredit: 0,
    allCount: 0,
    pointLogList: [],
  }),
  qnaProductListState: asyncState.initial<QnaProductListStateType>({
    allCount: 0,
    qnaList: [],
  }),
  memberManageState: asyncState.initial<MemberManageStateType>({
    name: "",
    email: "",
    birthday: "",
    gender: GENDER_TYPE.WOMAN,
    cellPhone: "",
    smsReceive: RECEIVE_AGREE_STATUS.DISAGREE,
    emailReceive: RECEIVE_AGREE_STATUS.DISAGREE,
  }),
  memberManageStatusState: {
    updatePasswordStatus: 0,
    updateMemberInfoStatus: 0,
    removeMemberInfoStatus: 0,
    removeMemberModal: {
      visible: false,
    },
  },
  // 주문 정보
  orderListState: asyncState.initial<OrderListStateType[]>([]),
  orderDetailState: asyncState.initial<OrderDetailStateType>(),
  orderCancelState: asyncState.initial<OrderCancelStateType>(),
  orderReturnState: asyncState.initial<OrderReturnStateType>(),
  orderExchangeState: asyncState.initial<OrderReturnExchangeCommonType>(),
  orderItemShippingState: asyncState.initial<OrderItemShippingStateType>(),
  orderDeliveryTrackingState: asyncState.initial<OrderDeliveryTrackingStateType>(),
  orderWithdrawState: asyncState.initial<OrderWithdrawStateType>(),
  orderReasonState: {
    shippingDelayState: asyncState.initial<ShippingDelayReasonStateType>(),
    resultOrderCancelState: asyncState.initial<ResultOrderCancelStateType>(),
    resultOrderReturnState: asyncState.initial<ResultOrderReturnStateType>(),
    resultOrderExchangeState: asyncState.initial<ResultOrderExchangeStateType>(),
  },
  submitOrderCancelState: asyncState.initial<SubmitOrderCancelStateType>(),
  submitOrderReturnState: asyncState.initial<SubmitOrderReturnStateType>(),
  submitOrderExchangeState: asyncState.initial<SubmitOrderExchangeStateType>(),
  orderReturnVbankState: asyncState.initial<OrderReturnVbankStateType>({
    bankList: [],
    refundBank: null,
    updateStatus: null,
  }),
  confirmPurchaseState: asyncState.initial<null>(),
};

const mypageReducer = createReducer<MyPageStateType, MyPageAction>(initialMyPageState, {
  // 마이페이지 메인화면 요청
  [GET_MY_PAGE_MAIN.REQUEST]: produce((draft) => {
    draft.myPageMainState.loading = true;
    draft.myPageMainState.data.cartCount = 0;
    draft.myPageMainState.data.userName = "";
    draft.myPageMainState.data.point = 0;
    draft.myPageMainState.data.couponCount = 0;
    draft.myPageMainState.data.notificationList = [];
    draft.myPageMainState.data.reviewReadyCount = 0;
    draft.myPageMainState.data.orderList = [];
    draft.myPageMainState.data.likeProductList = [];
    draft.myPageMainState.data.likeBrandList = [];
  }),
  // 마이페이지 메인화면 요청 성공
  [GET_MY_PAGE_MAIN.SUCCESS]: produce((draft, action) => {
    draft.myPageMainState.loading = false;
    draft.myPageMainState.status = action.payload.status;
    draft.myPageMainState.data = action.payload.data;
  }),

  // 마이페이지 사용 가능한 쿠폰 리스트 요청
  [GET_COUPON_LIST.REQUEST]: produce((draft) => {
    draft.couponListState.loading = true;
  }),
  // 마이페이지 사용 가능한 쿠폰 리스트 요청 성공
  [GET_COUPON_LIST.SUCCESS]: produce((draft, action) => {
    draft.couponListState.loading = false;
    draft.couponListState.data = action.payload;
    // productCouponState, brandCouponState의 status 값을 초기화
    draft.productCouponState.status = null;
    draft.brandCouponState.status = null;
  }),

  // 쿠폰 발급 요청
  [GENERATE_COUPON.REQUEST]: produce((draft) => {
    draft.couponListState.loading = true;
    draft.couponListState.status = null;
  }),

  // 쿠폰 발급 요청 성공
  [GENERATE_COUPON.SUCCESS]: produce((draft, action) => {
    draft.couponListState.loading = false;
    draft.couponListState.status = action.payload.status;
  }),

  // 쿠폰 적용가능 상품 요청
  [GET_PRODUCT_COUPON.REQUEST]: produce((draft) => {
    draft.productCouponState.loading = true;
  }),

  // 쿠폰 적용가능 상품 요청 성공
  [GET_PRODUCT_COUPON.SUCCESS]: produce((draft, action) => {
    draft.productCouponState.loading = false;
    draft.productCouponState.status = action.payload.status;
    draft.productCouponState.data = action.payload.data;
  }),

  // 쿠폰 적용가능 브랜드 요청
  [GET_BRAND_COUPON.REQUEST]: produce((draft) => {
    draft.brandCouponState.loading = true;
  }),

  // 쿠폰 적용가능 브랜드 요청 성공
  [GET_BRAND_COUPON.SUCCESS]: produce((draft, action) => {
    draft.brandCouponState.loading = false;
    draft.brandCouponState.status = action.payload.status;
    draft.brandCouponState.data = action.payload.data;
  }),

  // 포인트 내역 요청
  [GET_POINT_LIST.REQUEST]: produce((draft) => {
    draft.pointListState.loading = true;
  }),

  // 포인트 내역 요청 성공
  [GET_POINT_LIST.SUCCESS]: produce((draft, action) => {
    draft.pointListState.loading = false;
    draft.pointListState.data = action.payload;
  }),

  // 주문 정보 내역 요청
  [GET_ORDER_LIST.REQUEST]: produce((draft) => {
    draft.orderListState.loading = true;
    draft.orderListState.data = [];
  }),
  // 주문 정보 내역 요청 성공
  [GET_ORDER_LIST.SUCCESS]: produce((draft, action) => {
    draft.orderListState.loading = false;
    draft.orderListState.data = action.payload;
  }),
  // 주문 상세 내역 요청
  [GET_ORDER_DETAIL.REQUEST]: produce((draft) => {
    draft.orderDetailState.loading = true;
  }),
  // 주문 상세 내역 요청 성공
  [GET_ORDER_DETAIL.SUCCESS]: produce((draft, action) => {
    draft.orderDetailState.loading = false;
    draft.orderDetailState.status = action.payload.status;
    draft.orderDetailState.data = action.payload.data;
    draft.orderDetailState.data.itemList.sort(
      (a: ProductCardStateType, b: ProductCardStateType) => a.storeId - b.storeId,
    );
  }),
  // 주문 취소 내역 요청
  [GET_ORDER_CANCEL.REQUEST]: produce((draft) => {
    draft.orderCancelState.loading = true;
    draft.orderCancelState.status = null;
    draft.orderCancelState.data = null;

    // 주문 취소 요청 완료시 상태값 초기화
    draft.submitOrderCancelState.status = null;
    draft.submitOrderCancelState.data = null;
  }),
  // 주문 취소 내역 요청 성공
  [GET_ORDER_CANCEL.SUCCESS]: produce((draft, action) => {
    draft.orderCancelState.loading = false;
    draft.orderCancelState.status = action.payload.status;
    draft.orderCancelState.data = action.payload.data;
  }),
  // 주문 반품 내역 요청
  [GET_ORDER_RETURN.REQUEST]: produce((draft) => {
    draft.orderReturnState.loading = true;
    draft.orderReturnState.status = null;
    draft.orderReturnState.data = null;

    // 주문 반품 요청 완료시 상태값 초기화
    draft.submitOrderReturnState.status = null;
    draft.submitOrderReturnState.data = null;
  }),
  // 주문 반품 내역 요청 성공
  [GET_ORDER_RETURN.SUCCESS]: produce((draft, action) => {
    draft.orderReturnState.loading = false;
    draft.orderReturnState.status = action.payload.status;
    draft.orderReturnState.data = action.payload.data;
  }),
  // 주문 교환 내역 요청
  [GET_ORDER_EXCHANGE.REQUEST]: produce((draft) => {
    draft.orderExchangeState.loading = true;
    draft.orderExchangeState.status = null;
    draft.orderExchangeState.data = null;

    // 주문 교환 요청 완료시 상태값 초기화
    draft.submitOrderExchangeState.status = null;
    draft.submitOrderExchangeState.data = null;
  }),
  // 주문 교환 내역 요청 성공
  [GET_ORDER_EXCHANGE.SUCCESS]: produce((draft, action) => {
    draft.orderExchangeState.loading = false;
    draft.orderExchangeState.status = action.payload.status;
    draft.orderExchangeState.data = action.payload.data;
  }),
  // 주문 상품 배송지 내역 요청
  [GET_ORDER_ITEM_SHIPPING_INFO.REQUEST]: produce((draft) => {
    draft.orderItemShippingState.loading = true;
    draft.orderItemShippingState.status = null;
    draft.orderItemShippingState.data = null;
  }),
  // 주문 상품 배송지 내역 요청 성공
  [GET_ORDER_ITEM_SHIPPING_INFO.SUCCESS]: produce((draft, action) => {
    draft.orderItemShippingState.loading = false;
    draft.orderItemShippingState.data = action.payload;
  }),
  // 주문 상품 배송지 수정 요청
  [UPDATE_ORDER_ITEM_SHIPPING_INFO.REQUEST]: produce((draft) => {
    draft.orderItemShippingState.status = null;
  }),
  // 주문 상품 배송지 수정 요청 성공
  [UPDATE_ORDER_ITEM_SHIPPING_INFO.SUCCESS]: produce((draft, action) => {
    draft.orderItemShippingState.status = action.payload;
  }),
  // 배송지 변경 모달 언마운트시 상태값 초기화
  [UNMOUNT_ORDER_MODAL]: produce((draft, action) => {
    draft[action.payload].status = null;
    draft[action.payload].data = null;
  }),
  [GET_ORDER_DELIVERY_TRACKING.REQUEST]: produce((draft) => {
    draft.orderDeliveryTrackingState.status = null;
    draft.orderDeliveryTrackingState.data = null;
  }),
  [GET_ORDER_DELIVERY_TRACKING.SUCCESS]: produce((draft, action) => {
    draft.orderDeliveryTrackingState.status = action.payload.status;
    draft.orderDeliveryTrackingState.data = action.payload.data;
  }),

  // 배송 지연 사유 요청
  [SHIPPING_DELAY_REASON.REQUEST]: produce((draft) => {
    draft.orderReasonState = resetOrderReason(draft.orderReasonState);
  }),
  // 배송 지연 사유 성공
  [SHIPPING_DELAY_REASON.SUCCESS]: produce((draft, action) => {
    draft.orderReasonState.shippingDelayState.loading = false;
    draft.orderReasonState.shippingDelayState.status = action.payload.status;
    draft.orderReasonState.shippingDelayState.data = action.payload.data;
  }),

  // 후기 작성 - 주문 내역
  [AFTER_SUBMIT_ORDER_LIST_REVIEW]: produce((draft, action) => {
    const order = draft.orderListState.data.find(
      (order: OrderListStateType) => order.orderId === action.payload.orderId,
    );
    if (order) {
      const orderItem = order.orderItemList.find(
        (orderItem: OrderInfoItemType) => orderItem.orderItemId === action.payload.orderItemId,
      );
      orderItem.review.type = ORDER_ITEM_REVIEW_STATUS.READ;
      orderItem.review.id = action.payload.reviewId;
    }
  }),

  // 후기 작성 - 주문 상세
  [AFTER_SUBMIT_ORDER_DETAIL_REVIEW]: produce((draft, action) => {
    const orderItem = draft.orderDetailState.data.itemList.find(
      (orderItem: OrderDetailItemType) => orderItem.orderItemId === action.payload.orderItemId,
    );
    orderItem.review.type = ORDER_ITEM_REVIEW_STATUS.READ;
    orderItem.review.id = action.payload.reviewId;
  }),

  // 주문 취소 요청
  [SUBMIT_ORDER_CANCEL.REQUEST]: produce((draft) => {
    draft.submitOrderCancelState.loading = true;
    draft.submitOrderCancelState.status = null;
    draft.submitOrderCancelState.data = null;
  }),
  // 주문 취소 요청 성공
  [SUBMIT_ORDER_CANCEL.SUCCESS]: produce((draft, action) => {
    draft.submitOrderCancelState.loading = false;
    draft.submitOrderCancelState.status = action.payload.status;
    draft.submitOrderCancelState.data = action.payload.data;
  }),

  // 주문 취소 내역 요청
  [RESULT_ORDER_CANCEL.REQUEST]: produce((draft) => {
    draft.orderReasonState = resetOrderReason(draft.orderReasonState);
  }),
  // 주문 취소 내역 요청 성공
  [RESULT_ORDER_CANCEL.SUCCESS]: produce((draft, action) => {
    draft.orderReasonState.resultOrderCancelState.loading = false;
    draft.orderReasonState.resultOrderCancelState.status = action.payload.status;
    draft.orderReasonState.resultOrderCancelState.data = action.payload.data;
  }),

  // 주문 취소 철회 요청
  [WITHDRAW_ORDER_CANCEL.REQUEST]: produce((draft) => {
    draft.orderWithdrawState.status = null;
    draft.orderWithdrawState.data = null;
  }),
  // 주문 취소 철회 요청 성공
  [WITHDRAW_ORDER_CANCEL.SUCCESS]: produce((draft, action) => {
    draft.orderWithdrawState.status = action.payload.status;
    draft.orderWithdrawState.data = action.payload.data;
  }),

  // 주문 반품 요청
  [SUBMIT_ORDER_RETURN.REQUEST]: produce((draft) => {
    draft.submitOrderReturnState.loading = true;
    draft.submitOrderReturnState.status = null;
    draft.submitOrderReturnState.data = null;
  }),
  // 주문 반품 요청 성공
  [SUBMIT_ORDER_RETURN.SUCCESS]: produce((draft, action) => {
    draft.submitOrderReturnState.loading = false;
    draft.submitOrderReturnState.status = action.payload.status;
    draft.submitOrderReturnState.data = action.payload.data;
  }),

  // 주문 반품 내역 요청
  [RESULT_ORDER_RETURN.REQUEST]: produce((draft) => {
    draft.orderReasonState = resetOrderReason(draft.orderReasonState);
  }),

  // 주문 반품 내역 요청 성공
  [RESULT_ORDER_RETURN.SUCCESS]: produce((draft, action) => {
    draft.orderReasonState.resultOrderReturnState.loading = false;
    draft.orderReasonState.resultOrderReturnState.status = action.payload.status;
    draft.orderReasonState.resultOrderReturnState.data = action.payload.data;
  }),

  // 주문 반품 철회 요청
  [WITHDRAW_ORDER_RETURN.REQUEST]: produce((draft) => {
    draft.orderWithdrawState.status = null;
    draft.orderWithdrawState.data = null;
  }),
  // 주문 반품 철회 요청 성공
  [WITHDRAW_ORDER_RETURN.SUCCESS]: produce((draft, action) => {
    draft.orderWithdrawState.status = action.payload.status;
    draft.orderWithdrawState.data = action.payload.data;
  }),

  // 주문 교환 요청
  [SUBMIT_ORDER_EXCHANGE.REQUEST]: produce((draft) => {
    draft.submitOrderExchangeState.loading = true;
    draft.submitOrderExchangeState.status = null;
    draft.submitOrderExchangeState.data = null;
  }),
  // 주문 교환 요청 성공
  [SUBMIT_ORDER_EXCHANGE.SUCCESS]: produce((draft, action) => {
    draft.submitOrderExchangeState.loading = false;
    draft.submitOrderExchangeState.status = action.payload.status;
    draft.submitOrderExchangeState.data = action.payload.data;
  }),
  // 주문 교환 철회 요청
  [WITHDRAW_ORDER_EXCHANGE.REQUEST]: produce((draft) => {
    draft.orderWithdrawState.status = null;
    draft.orderWithdrawState.data = null;
  }),
  // 주문 교환 철회 요청 성공
  [WITHDRAW_ORDER_EXCHANGE.SUCCESS]: produce((draft, action) => {
    draft.orderWithdrawState.status = action.payload.status;
    draft.orderWithdrawState.data = action.payload.data;
  }),

  // 주문 반품 내역 요청
  [RESULT_ORDER_EXCHANGE.REQUEST]: produce((draft) => {
    draft.orderReasonState = resetOrderReason(draft.orderReasonState);
  }),
  // 주문 반품 내역 요청 성공
  [RESULT_ORDER_EXCHANGE.SUCCESS]: produce((draft, action) => {
    draft.orderReasonState.resultOrderExchangeState.loading = false;
    draft.orderReasonState.resultOrderExchangeState.status = action.payload.status;
    draft.orderReasonState.resultOrderExchangeState.data = action.payload.data;
  }),

  // 가상계좌 환불 정보 조회 요청
  [SELECT_ORDER_RETURN_VBANK.REQUEST]: produce((draft) => {
    draft.orderReturnVbankState.status = null;
    draft.orderReturnVbankState.data.refundBank = null;
    draft.orderReturnVbankState.data.updateStatus = null;
  }),
  // 가상계좌 환불 정보 조회 요청 성공
  [SELECT_ORDER_RETURN_VBANK.SUCCESS]: produce((draft, action) => {
    draft.orderReturnVbankState.status = action.payload.status;
    draft.orderReturnVbankState.data.bankList = action.payload.data.bankList;
    draft.orderReturnVbankState.data.refundBank = {
      bankId: action.payload.data.bankId,
      bankNumber: action.payload.data.bankNumber,
      bankHolder: action.payload.data.bankHolder,
    };
  }),
  // 가상계좌 환불 정보 수정 요청
  [UPDATE_ORDER_RETURN_VBANK.REQUEST]: produce((draft) => {
    draft.orderReturnVbankState.data.updateStatus = null;
  }),
  // 가상계좌 환불 정보 조회 수정 성공
  [UPDATE_ORDER_RETURN_VBANK.SUCCESS]: produce((draft, action) => {
    draft.orderReturnVbankState.data.updateStatus = action.payload.status;
    draft.orderReasonState.resultOrderReturnState.data.refundInform.payInformList = action.payload.payInformList;
  }),
  // 가상계좌 환불 정보 조회 수정 실패
  [UPDATE_ORDER_RETURN_VBANK.FAILURE]: produce((draft, action) => {
    draft.orderReturnVbankState.data.updateStatus = action.payload.status;
  }),
  // 가상계좌 환불 완료 상태 초기화
  [RESET_UPDATE_ORDER_RETURN_VBANK_STATUS]: produce((draft) => {
    draft.orderReturnVbankState.data.updateStatus = null;
  }),
  // 구매 확정 요청
  [CONFIRM_PURCHASE.REQUEST]: produce((draft) => {
    draft.confirmPurchaseState.loading = true;
    draft.confirmPurchaseState.status = null;
  }),
  // 구매 확정 요청 성공
  [CONFIRM_PURCHASE.SUCCESS]: produce((draft, action) => {
    draft.confirmPurchaseState.loading = false;
    draft.confirmPurchaseState.status = action.payload;
  }),

  // 상품 좋아요 요청 성공
  [SET_LIKE_PRODUCT_COUPON.SUCCESS]: produce((draft, action) => {
    const target = draft.productCouponState.data.productList.find(
      (item: LikeProductItemStateType) => item.id === action.payload.data.productId,
    );
    target.likeFlag = action.payload.data.code === LIKE_RESULT_CODE.INSERT_SUCCESS;
  }),

  // 상품 문의 내역 요청
  [GET_QNA_PRODUCT_LIST.REQUEST]: produce((draft) => {
    draft.qnaProductListState.loading = true;
    draft.memberManageState.status = null;
    draft.memberManageState.error = null;
    draft.qnaProductListState.data = {
      allCount: 0,
      qnaList: [],
    };
  }),

  // 상품 문의 내역 요청 성공
  [GET_QNA_PRODUCT_LIST.SUCCESS]: produce((draft, action) => {
    draft.qnaProductListState.loading = false;
    draft.qnaProductListState.data = action.payload;
  }),

  // 상품 문의 내역 요청 실패
  [GET_QNA_PRODUCT_LIST.FAILURE]: produce((draft) => {
    draft.qnaProductListState.loading = false;
    draft.qnaProductListState.data = {
      allCount: 0,
      qnaList: [],
    };
  }),

  // 상품 문의 등록 요청
  [INSERT_PRODUCT_QNA.REQUEST]: produce((draft) => {
    draft.orderDetailState.data.orderItemInquiry = {
      loading: true,
      status: null,
    };
  }),

  // 상품 문의 등록 요청 성공
  [INSERT_PRODUCT_QNA.SUCCESS]: produce((draft, action) => {
    draft.orderDetailState.data.orderItemInquiry.loading = false;
    draft.orderDetailState.data.orderItemInquiry.status = action.payload.status;
  }),

  // 상품 문의 수정 요청
  [UPDATE_QNA_PRODUCT.REQUEST]: produce((draft) => {
    draft.qnaProductListState.loading = true;
    draft.qnaProductListState.status = null;
  }),

  // 상품 문의 수정 요청 성공
  [UPDATE_QNA_PRODUCT.SUCCESS]: produce((draft, action) => {
    draft.qnaProductListState.loading = false;
    const updatedQna = draft.qnaProductListState.data.qnaList.find(
      (qna: QnaProductItemStateType) => qna.id === action.payload.qnaId,
    );
    updatedQna.question = action.payload.question;
    draft.qnaProductListState.status = action.payload.status;
  }),

  // 상품 문의 삭제 요청
  [DELETE_QNA_PRODUCT.REQUEST]: produce((draft) => {
    draft.qnaProductListState.loading = true;
    draft.qnaProductListState.status = null;
  }),

  // 상품 문의 삭제 요청 성공
  [DELETE_QNA_PRODUCT.SUCCESS]: produce((draft, action) => {
    draft.qnaProductListState.loading = false;
    draft.qnaProductListState.data.qnaList = draft.qnaProductListState.data.qnaList.filter(
      (qna: QnaProductItemStateType) => qna.id !== action.payload.qnaId,
    );
    draft.qnaProductListState.status = action.payload.status;
  }),

  // 회원 정보 수정 상태 초기화
  [INIT_MEMBER_MANAGE]: produce((draft) => {
    draft.memberManageState.loading = false;
    draft.memberManageState.status = null;
    draft.memberManageState.error = null;
  }),

  // 이메일 비밀번호 재확인 요청
  [CHECK_EMAIL_MEMBER.REQUEST]: produce((draft) => {
    draft.memberManageState.loading = true;
    draft.memberManageState.status = null;
    draft.memberManageState.error = null;
    draft.memberManageState.data = {
      name: "",
      email: "",
      birthday: "",
      gender: GENDER_TYPE.WOMAN,
      cellPhone: "",
      smsReceive: RECEIVE_AGREE_STATUS.DISAGREE,
      emailReceive: RECEIVE_AGREE_STATUS.DISAGREE,
    };
  }),

  // 이메일 비밀번호 재확인 요청 성공
  [CHECK_EMAIL_MEMBER.SUCCESS]: produce((draft, action) => {
    draft.memberManageState = action.payload;
    draft.memberManageState.loading = false;
    draft.memberManageStatusState.updateMemberInfoStatus = 0;
    draft.memberManageStatusState.updatePasswordStatus = 0;
  }),

  // sns 회원 정보 불러오기 요청
  [GET_SNS_MEMBER.REQUEST]: produce((draft) => {
    draft.memberManageState.loading = true;
    draft.memberManageState.status = null;
    draft.memberManageState.error = null;
    draft.memberManageState.data = {
      name: "",
      email: "",
      birthday: "",
      gender: GENDER_TYPE.WOMAN,
      cellPhone: "",
      smsReceive: RECEIVE_AGREE_STATUS.DISAGREE,
      emailReceive: RECEIVE_AGREE_STATUS.DISAGREE,
    };
  }),

  // sns 회원 정보 불러오기 요청 성공
  [GET_SNS_MEMBER.SUCCESS]: produce((draft, action) => {
    draft.memberManageState = action.payload;
    draft.memberManageState.loading = false;
    draft.memberManageStatusState.updateMemberInfoStatus = 0;
    draft.memberManageStatusState.updatePasswordStatus = 0;
  }),

  // 이메일 비밀번호 수정 요청
  [UPDATE_PASSWORD_EMAIL_MEMBER.REQUEST]: produce((draft) => {
    draft.memberManageStatusState.updatePasswordStatus = 0;
    draft.memberManageState.loading = true;
  }),

  // 이메일 비밀번호 수정 요청 성공
  [UPDATE_PASSWORD_EMAIL_MEMBER.SUCCESS]: produce((draft, action) => {
    draft.memberManageStatusState.updatePasswordStatus = action.payload;
    draft.memberManageState.loading = false;
  }),

  // 회원 정보 수정 요청
  [UPDATE_MEMBER_INFO.REQUEST]: produce((draft) => {
    draft.memberManageStatusState.updateMemberInfoStatus = 0;
    draft.memberManageState.loading = true;
  }),

  // 회원 정보 수정 요청 실패
  [UPDATE_MEMBER_INFO.FAILURE]: produce((draft) => {
    // 정보 수정 로딩 상태 초기화
    draft.memberManageState.loading = false;
  }),

  // 회원 정보 수정 요청 성공
  [UPDATE_MEMBER_INFO.SUCCESS]: produce((draft, action) => {
    draft.memberManageStatusState.updateMemberInfoStatus = STATUS_CODE_COMMON.SUCCESS;
    draft.memberManageState.data = action.payload.data;
    draft.memberManageState.loading = false;
  }),

  // 회원 정보탈퇴 요청
  [REMOVE_MEMBER_INFO.REQUEST]: produce((draft) => {
    draft.memberManageState.status = 0;
  }),

  // 회원 정보탈퇴 요청 성공
  [REMOVE_MEMBER_INFO.SUCCESS]: produce((draft, action) => {
    draft.memberManageState.status = action.payload;
  }),

  [REMOVE_MEMBER_MODAL_TOGGLE]: produce((draft) => {
    draft.memberManageStatusState.removeMemberModal.visible = !draft.memberManageStatusState.removeMemberModal.visible;
  }),

  // 회원 정보 수정 상태 리셋
  [RESET_UPDATE_MEMBER_INFO_STATUS]: produce((draft) => {
    draft.memberManageStatusState.updateMemberInfoStatus = 0;
  }),

  //멤버십 에러 발생
  [MEMBERSHIP_ERROR]: produce((draft) => {
    draft.myPageMainState.isMembershipError = true;
  }),
});

export default mypageReducer;
