import { rest } from "msw";
import { productBenefitsMock } from "@services/msw/mocks/product";
import { mock_coupons } from "@test/mocks/product/coupon/mock_coupon";

export const productHandlers = [
  rest.get(process.env.NEXT_PUBLIC_API_URL_V2 + "/products/:productId/downloadable-coupons", (req, res, ctx) => {
    const { productId } = req.params;

    if (!productId) {
      return res(
        ctx.status(404),
        ctx.json({ error: 'Product not found' })
      );
    }
    return res(
      ctx.json({
        productDownloadableCoupons: mock_coupons,
      })
    );
  }),
  rest.get(process.env.NEXT_PUBLIC_API_URL_V2 + "/products/:productId/price-benefits", productBenefitsMock),
];
