import { mock } from "@services/msw/utils";

export const userSummaryMock = mock({
  userName: null,
  userGradeImgUrl: "https://cdntest.amondz.com/user/grade/FRIEND.png?v=1",
  userGradeName: "FRIEND",
  availablePurchaseConfirmCount: 0,
  availableCouponCount: 0,
  writableReviewCount: 0,
  availablePointAmount: 514200,
});

export const recentOrderMock = mock({
  orders: [
    {
      orderId: "21674799042235",
      orderTotalPaidPrice: 2000,
      orderDate: "2023-01-27T05:57:22.000Z",
      orderItemCount: 1,
      firstOrderItemName: "아몬즈금은방-제품번호O-사입-옵션X-일반배송",
      firstOrderItemImgUrl:
        "https://cdntest.amondz.com/product/77444/resize/mainImg/PSI_819524_50.jpeg?v=1672404284186",
    },
    {
      orderId: "21674797109632",
      orderTotalPaidPrice: 2000,
      orderDate: "2023-01-27T05:25:09.000Z",
      orderItemCount: 1,
      firstOrderItemName: "아몬즈금은방-제품번호O-위탁-옵션O-일반",
      firstOrderItemImgUrl:
        "https://cdntest.amondz.com/product/77445/resize/mainImg/PSI_819527_50.jpeg?v=1672404566396",
    },
    {
      orderId: "21674615805797",
      orderTotalPaidPrice: 2000,
      orderDate: "2023-01-25T03:03:25.000Z",
      orderItemCount: 1,
      firstOrderItemName: "아몬즈금은방-제품번호O-위탁-옵션O-일반",
      firstOrderItemImgUrl:
        "https://cdntest.amondz.com/product/77445/resize/mainImg/PSI_819527_50.jpeg?v=1672404566396",
    },
  ],
  totalCount: 3,
});

export const likeStoresMock = mock({
  totalCount: 10,
  likeStores: [
    {
      storeId: 591,
      storeName: "오델리프레",
      storeEngName: "odeliepre",
      imgUrl: "https://cdnqa.qa-amondz.com/store/591/original/titleImg/SAI_7852.jpeg?v=1653902460293",
      newCount: 3,
      products: [
        {
          id: 64001,
          imgUrl: "https://cdn.amondz.com/product/64330/resize/mainImg/PSI_667969_50.jpeg?v=1653124783992",
        },
        {
          id: 64001,
          imgUrl: "https://cdn.amondz.com/product/64330/resize/mainImg/PSI_667969_50.jpeg?v=1653124783992",
        },
        {
          id: 64001,
          imgUrl: "https://cdn.amondz.com/product/64330/resize/mainImg/PSI_667969_50.jpeg?v=1653124783992",
        },
        {
          id: 64001,
          imgUrl: "https://cdn.amondz.com/product/64330/resize/mainImg/PSI_667969_50.jpeg?v=1653124783992",
        },
      ],
    },
    {
      storeId: 592,
      storeName: "오델리프레",
      storeEngName: "odeliepre",
      imgUrl: "https://cdnqa.qa-amondz.com/store/591/original/titleImg/SAI_7852.jpeg?v=1653902460293",
      newCount: 3,
      products: [
        {
          id: 64001,
          imgUrl: "https://cdn.amondz.com/product/64330/resize/mainImg/PSI_667969_50.jpeg?v=1653124783992",
        },
        {
          id: 64001,
          imgUrl: "https://cdn.amondz.com/product/64330/resize/mainImg/PSI_667969_50.jpeg?v=1653124783992",
        },
        {
          id: 64001,
          imgUrl: "https://cdn.amondz.com/product/64330/resize/mainImg/PSI_667969_50.jpeg?v=1653124783992",
        },
        {
          id: 64001,
          imgUrl: "https://cdn.amondz.com/product/64330/resize/mainImg/PSI_667969_50.jpeg?v=1653124783992",
        },
      ],
    },
  ],
});

export const likeProductsMock = mock({
  totalCount: 2,
  likeProducts: [
    {
      id: 4, // 상품 식별 아이디
      name: "이쁜 귀걸이", // 상품 이름
      isTodayDelivery: true, // 상품 오늘 배송 여부
      isGift: true, // 상품 선물하기 가능 여부
      originPrice: 200000, // 상품 정가
      salesPrice: 200000, // 상품 판매가
      status: "nowOnSale", // 상품 상태 (nowOnSale: 판매중, soldOut: 품절)
      productMainAttachment: {
        id: 1, // 상품 대표 이미지 식별 id
        url: "https://devweb.dev-amondz.com/product/42831", // 상품 대표 이미지 url
        width: 800, // 상품 이미지 가로 크기(px)
        height: 800, // 상품 이미지 세로 크기(px)
      },
      store: {
        id: 753, // 브랜드 식별 아이디
        name: "위아몬즈", // 브랜드 이름
      },
      productCategories: [
        {
          id: 123, // 상품 카테고리 식별 아이디
          name: "귀걸이", // 상품 카테고리 명
          parentId: 3, // 부모 상품 카테고리 식별 아이디 (null 이면 부모 상품 카테고리)
        },
      ],
    },
    {
      id: 5, // 상품 식별 아이디
      name: "이쁜 귀걸이", // 상품 이름
      isTodayDelivery: true, // 상품 오늘 배송 여부
      isGift: true, // 상품 선물하기 가능 여부
      originPrice: 200000, // 상품 정가
      salesPrice: 50000, // 상품 판매가
      discountRate: 30, // 상품 할인률
      discountAmount: 150000, // 상품 할인 금액
      status: "nowOnSale", // 상품 상태 (nowOnSale: 판매중, soldOut: 품절)
      productMainAttachment: {
        id: 1, // 상품 대표 이미지 식별 id
        url: "https://devweb.dev-amondz.com/product/42831", // 상품 대표 이미지 url
        width: 800, // 상품 이미지 가로 크기(px)
        height: 800, // 상품 이미지 세로 크기(px)
      },
      store: {
        id: 753, // 브랜드 식별 아이디
        name: "위아몬즈", // 브랜드 이름
      },
      productCategories: [
        {
          id: 123, // 상품 카테고리 식별 아이디
          name: "귀걸이", // 상품 카테고리 명
          parentId: 3, // 부모 상품 카테고리 식별 아이디 (null 이면 부모 상품 카테고리)
        },
      ],
    },
  ],
});

export const imageReferralsMock = mock({
  imageReferrals: [
    {
      type: 0,
      linkType: 10,
      linkId: null,
      url: "https://cdntest.amondz.com/remoteImage/referral/3/myPage/RIRA_11.jpeg",
      imgType: 1,
      width: 1296,
      height: 495,
    },
  ],
});

export const orderListMock = mock({
  status: 1,
  data: {
    orderList: [
      {
        orderType: 0,
        orderDate: "2024-05-29",
        orderId: 21716951783783,
        totalPaidPrice: 2980,
        totalItemPrice: 11980,
        orderItemList: [
          {
            orderId: 21716951783783,
            totalItemPrice: 11980,
            totalPaidPrice: 2980,
            orderItemId: 11716951783951,
            storeId: 930,
            storeName: "위아몬즈1",
            storeStatus: 2,
            productStatus: 2,
            purchaseConfirmDate: null,
            productId: 75907,
            productName: "[한별] 테스트 상품",
            isTodayDelivery: 0,
            imgUrl: "https://cdnqa.qa-amondz.com/product/75907/resize/mainImg/PSI_801649_50.jpeg?v=1714354896793",
            status: 9,
            orderDate: "2024-05-29",
            orderCancel: {
              status: 3,
            },
          },
        ],
      },
      {
        orderType: 0,
        orderDate: "2024-04-23",
        orderId: 21713865609297,
        totalPaidPrice: 12374,
        totalItemPrice: 19040,
        orderItemList: [
          {
            orderId: 21713865609297,
            totalItemPrice: 19040,
            totalPaidPrice: 12374,
            orderItemId: 11713865609250,
            storeId: 539,
            storeName: "럽은",
            storeStatus: 2,
            productStatus: 2,
            purchaseConfirmDate: null,
            productId: 12197,
            productName: "실크 뱀줄 팔찌 (실버925)",
            isTodayDelivery: 0,
            imgUrl: "https://cdnqa.qa-amondz.com/product/12197/resize/mainImg/PSI_112052_50.jpeg?v=1571988761098",
            status: 3,
            orderDate: "2024-04-23",
          },
        ],
      },
      {
        orderType: 0,
        orderDate: "2024-04-01",
        orderId: 21711943663309,
        totalPaidPrice: 25020,
        totalItemPrice: 25020,
        orderItemList: [
          {
            orderId: 21711943663309,
            totalItemPrice: 25020,
            totalPaidPrice: 25020,
            orderItemId: 11711943663440,
            storeId: 930,
            storeName: "위아몬즈1",
            storeStatus: 2,
            productStatus: 2,
            purchaseConfirmDate: null,
            productId: 75885,
            productName: "[혜주]사방넷 테스트_옵션 O",
            deliveryId: 534470,
            isTodayDelivery: 0,
            imgUrl: "https://cdnqa.qa-amondz.com/product/75885/resize/mainImg/PSI_801555_50.jpeg?v=1708586134605",
            status: 10,
            orderDate: "2024-04-01",
            orderReturn: {
              status: 7,
            },
          },
        ],
      },
      {
        orderType: 0,
        orderDate: "2024-04-01",
        orderId: 21711938485862,
        totalPaidPrice: 9020,
        totalItemPrice: 14020,
        orderItemList: [
          {
            orderId: 21711938485862,
            totalItemPrice: 14020,
            totalPaidPrice: 9020,
            orderItemId: 11711938485964,
            storeId: 930,
            storeName: "위아몬즈1",
            storeStatus: 2,
            productStatus: 2,
            purchaseConfirmDate: "2024-04-01T02:33:28.000Z",
            productId: 75885,
            productName: "[혜주]사방넷 테스트_옵션 O",
            deliveryId: 534469,
            isTodayDelivery: 0,
            imgUrl: "https://cdnqa.qa-amondz.com/product/75885/resize/mainImg/PSI_801555_50.jpeg?v=1708586134605",
            status: 8,
            orderDate: "2024-04-01",
            review: {
              type: 0,
            },
          },
        ],
      },
      {
        orderType: 0,
        orderDate: "2024-04-01",
        orderId: 21711938396525,
        totalPaidPrice: 15020,
        totalItemPrice: 15020,
        orderItemList: [
          {
            orderId: 21711938396525,
            totalItemPrice: 15020,
            totalPaidPrice: 15020,
            orderItemId: 11711938396319,
            storeId: 930,
            storeName: "위아몬즈1",
            storeStatus: 2,
            productStatus: 2,
            purchaseConfirmDate: "2024-04-01T02:33:34.000Z",
            productId: 75885,
            productName: "[혜주]사방넷 테스트_옵션 O",
            deliveryId: 534468,
            isTodayDelivery: 1,
            imgUrl: "https://cdnqa.qa-amondz.com/product/75885/resize/mainImg/PSI_801555_50.jpeg?v=1708586134605",
            status: 8,
            orderDate: "2024-04-01",
            review: {
              type: 1,
              id: 178281,
            },
          },
        ],
      },
      {
        orderType: 0,
        orderDate: "2023-12-22",
        orderId: 21703231988297,
        totalPaidPrice: 2000,
        totalItemPrice: 9900,
        orderItemList: [
          {
            orderId: 21703231988297,
            totalItemPrice: 9900,
            totalPaidPrice: 2000,
            orderItemId: 11703231988708,
            storeId: 930,
            storeName: "위아몬즈1",
            storeStatus: 2,
            productStatus: 2,
            purchaseConfirmDate: null,
            productId: 75727,
            productName: "배송정보 일반상품 테스트3-3",
            isTodayDelivery: 0,
            imgUrl: "https://cdnqa.qa-amondz.com/product/75727/resize/mainImg/PSI_800884_50.jpeg?v=1677803204306",
            status: 4,
            orderDate: "2023-12-22",
          },
        ],
      },
      {
        orderType: 1,
        orderDate: "2023-10-12",
        orderId: 21697083147453,
        totalPaidPrice: 13000,
        totalItemPrice: 20000,
        orderItemList: [
          {
            orderId: 21697083147453,
            totalItemPrice: 20000,
            totalPaidPrice: 13000,
            orderItemId: 11697083147459,
            storeId: 753,
            storeName: "마마카사르",
            storeStatus: 2,
            productStatus: 2,
            purchaseConfirmDate: null,
            productId: 75781,
            productName: "[조작금지] (옵션O)쿠폰 다운로드 테스트 전용 상품",
            isTodayDelivery: 1,
            imgUrl: "https://cdnqa.qa-amondz.com/product/75781/resize/mainImg/PSI_801102_50.jpeg?v=1695354914997",
            status: 9,
            orderDate: "2023-10-12",
            orderCancel: {
              status: 0,
            },
          },
        ],
      },
    ],
  },
});

//주문 상세 내역 api mock
export const orderDetailMock = mock({
  status: 1,
  data: {
    orderType: 0,
    itemList: [
      {
        orderItemId: 11674797109873,
        storeId: 1150,
        storeName: "아몬즈금은방",
        storeStatus: 2,
        productStatus: 2,
        purchaseConfirmDate: null,
        productId: 77445,
        productName: "아몬즈금은방-제품번호O-위탁-옵션O-일반",
        productOptionText: "크기 : 1",
        usedCouponPrice: 0,
        couponName: null,
        couponDescription: null,
        couponDiscountRate: null,
        couponDate: null,
        usedPoint: 7900,
        quantity: 1,
        isTodayDelivery: 1,
        totalPrice: 2000,
        productPrice: 9900,
        orderMessage: null,
        status: 3,
        imgUrl: "https://cdntest.amondz.com/product/77445/resize/mainImg/PSI_819527_50.jpeg?v=1672404566396",
      },
    ],
    shipping: {
      recipient: "심승태",
      cellPhone: "01075332136",
      addressLine1: "경기 의정부시 용민로 64",
      addressLine2: "407호",
      postCode: "11785",
      message: "부재시 전화주시거나 문자 남겨주세요.",
    },
    payment: {
      amount: 2000,
      method: 6,
      payInform: "(카카오/네이버페이)",
      orderDate: "2023-01-27",
      paidDate: "2023-01-27",
    },
    price: {
      totalItemPrice: 9900,
      totalDeliveryPrice: 0,
      totalUsedPoint: 7900,
      totalUsedCouponPrice: 0,
      totalPaidPrice: 2000,
    },
  },
});

//결제 취소 정보 요청 api mock
export const orderCancelSelectMock = mock({
  status: 1,
  data: {
    orderItemList: [
      {
        storeId: 1150,
        storeName: "아몬즈금은방",
        productId: 77445,
        productName: "아몬즈금은방-제품번호O-위탁-옵션O-일반",
        productOptionText: "크기 : 1",
        totalPrice: 2000,
        quantity: 1,
        isTodayDelivery: 1,
        orderMessage: null,
        imgUrl: "https://cdntest.amondz.com/product/77445/resize/mainImg/PSI_819527_50.jpeg?v=1672404566396",
      },
    ],
    type: [
      {
        id: 0,
        text: "주문실수",
      },
      {
        id: 1,
        text: "단순변심",
      },
      {
        id: 2,
        text: "결제수단 변경",
      },
      {
        id: 3,
        text: "배송지연",
      },
    ],
    payMethod: 2,
    status: 4,
    refundExpectAmount: 2000,
  },
});

//결제 취소 요청 api mock
export const orderCancelSubmitMock = mock({
  status: 1,
  data: {
    cancelResultType: 2,
    cancelId: 79741,
    limitDate: "2023.02.17",
    store: {
      name: "판도라(트랜드메카)",
      phone: "0215993735",
      address: "(06107) 서울 강남구 논현로 626 7층 트랜드메카",
    },
    orderItem: {
      storeId: 884,
      storeName: "판도라(트랜드메카)",
      productId: 23477,
      productName: "모먼트 하트 클래습 실버 팔찌",
      productOptionText: "길이 : 590719-17",
      totalPrice: 83900,
      isTodayDelivery: 0,
      quantity: 1,
      orderMessage: null,
      imgUrl: "https://cdntest.amondz.com/product/23477/resize/mainImg/PSI_520825_50.jpeg?v=1636438834791",
    },
    cancelInform: {
      type: "단순 변심",
      reason: "asdsad",
    },
    refundInform: {
      payMethod: 0,
      payInformList: ["(신한카드/일시불)"],
      refundExpectAmount: 83900,
    },
  },
});

//취소 결과 내역 api mock
export const orderCancelResultMock = mock({
  status: 1,
  data: {
    orderCancelStatus: 4,
    cancelInform: {
      type: "단순 변심",
      reason: "ㅁㄴㅇㅁㄴㅇ",
      cancelRequestDate: "2023-01-25",
      cancelCompleteDate: "2023-01-25",
    },
    refundInform: {
      payMethod: 5,
      payInformList: ["(카카오/네이버페이)"],
      refundActualAmount: 2000,
    },
    isSellerCancel: 0,
  },
});

//반품 요청 api mock
export const orderReturnSubmitMock = mock({
  status: 1,
  data: {
    returnId: 10052,
    store: {
      name: "아몬즈",
      phone: "0216681753",
      address: "(06193) 서울 강남구 선릉로90길 38 3층 비주얼 글로벌 허브",
    },
    orderItem: {
      storeId: 1150,
      storeName: "아몬즈금은방",
      productId: 77444,
      productName: "아몬즈금은방-제품번호O-사입-옵션X-일반배송",
      productOptionText: null,
      totalPrice: 2000,
      isTodayDelivery: 1,
      quantity: 1,
      orderMessage: null,
      imgUrl: "https://cdntest.amondz.com/product/77444/resize/mainImg/PSI_819524_50.jpeg?v=1672404284186",
    },
    returnInform: {
      type: "주문실수",
      reason: "ㄴㅁㅇㄹㅁㅇㄴㄹ",
      imgUrlList: [],
    },
    refundInform: {
      payMethod: 7,
      payInformList: ["(카카오/네이버페이)"],
      paidAmount: 2000,
      returnDeliveryFee: 3000,
      refundExpectAmount: -1000,
      responsibility: 0,
    },
  },
});

//반품 결과 내역 api mock
export const orderReturnResultMock = mock({
  status: 1,
  data: {
    orderReturnStatus: 0,
    store: {
      name: "아몬즈",
      phone: "0216681753",
      address: "(06193) 서울 강남구 선릉로90길 38 3층 비주얼 글로벌 허브",
    },
    orderItem: {
      storeId: 1150,
      storeName: "아몬즈금은방",
      productId: 77444,
      productName: "아몬즈금은방-제품번호O-사입-옵션X-일반배송",
      productOptionText: null,
      totalPrice: 2000,
      quantity: 1,
      isTodayDelivery: 1,
      orderMessage: null,
      imgUrl: "https://cdntest.amondz.com/product/77444/resize/mainImg/PSI_819524_50.jpeg?v=1672404284186",
    },
    returnInform: {
      type: "주문실수",
      date: "2023-01-27",
      reason: "ㄴㅁㅇㄹㅁㅇㄴㄹ",
    },
    refundInform: {
      payMethod: 8,
      payInformList: ["(카카오/네이버페이)"],
      paidAmount: 2000,
      returnDeliveryFee: 3000,
      refundExpectAmount: -1000,
      responsibility: 0,
    },
  },
});
