import * as React from "react";
import { FC, memo, ReactNode, useEffect, useRef, useState } from "react";

import { ItemStyle, ListStyle, WrapperStyle } from "./style";

export interface IScrollXListProps {
  className?: string;
  margin?: number;
  itemPerView?: number;
  spaceBetween?: number;
  children: ReactNode[];
}

const ScrollXList: FC<IScrollXListProps> = (props) => {
  const { className, margin = 15, itemPerView = 2.5, spaceBetween = 15, children } = props;
  const wrapperEl = useRef<HTMLDivElement | null>(null);
  const [width, setWidth] = useState(0);

  const getWidth = () => {
    const wrapperWidth = wrapperEl.current?.offsetWidth || 0;
    const gapCount = Math.floor(itemPerView) === itemPerView ? itemPerView - 1 : Math.floor(itemPerView);
    const totalGapSize = gapCount * spaceBetween;
    const totalMarginSize = margin * 2;

    // wrapper 너비 - ( 아이템 사이 간격 + 양 옆 여백) / 화면에 노출할 아이템 갯수
    return (wrapperWidth - totalGapSize - totalMarginSize) / itemPerView;
  };

  useEffect(() => {
    const onResize = () => {
      setWidth(getWidth());
    };
    onResize();
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, [itemPerView, spaceBetween]);

  return (
    <WrapperStyle className={className} ref={wrapperEl} margin={margin}>
      <ListStyle gap={spaceBetween} margin={margin}>
        {children.map((slide, index) => (
          <ItemStyle key={index} width={width}>
            {slide}
          </ItemStyle>
        ))}
      </ListStyle>
    </WrapperStyle>
  );
};

export default memo(ScrollXList);
