import React, { FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Router, { useRouter } from "next/router";
import { isNil } from "lodash";

import { setLastSearchKeyword, setSearchKeyword } from "@store/modules/search";
import { RootStateType } from "@store/modules";
import { searchModalStateType, searchModalVisible, setRecentSearchKeywordList } from "@store/modules/search";
import { SEARCH_RESULT_PAGE_URL_PATH } from "@constants/url/internalUrlConstants";
import { HEADER_SEARCH_BLACK_BTN_IMAGE_PATH } from "@constants/static/images";
import { RECENT_SEARCH_WORD_MAX_LENGTH } from "@constants/service/search/search";
import { searchBeforeAutoCompleteListDataCommonType } from "@swr/types/searchTypes";
import { SearchIconStyle, WordWrapStyle, WordListStyle, WordNameStyle } from "./style";

interface IAutoCompletedSearchWordProps {
  wordList: searchBeforeAutoCompleteListDataCommonType[];
  brandListLength: number;
}

const AutoCompletedSearchWord: FC<IAutoCompletedSearchWordProps> = (props) => {
  const { wordList, brandListLength } = props;
  const { lastSearchKeyword, recentSearchKeywordList } = useSelector<RootStateType, searchModalStateType>(
    (state: RootStateType) => state.search.searchModalState,
  );

  const router = useRouter();
  const dispatch = useDispatch();

  Router.events.on("routeChangeStart", () => {
    dispatch(searchModalVisible({ isSearchModalVisible: false }));
  });

  const onClickWord = useCallback(
    (word: searchBeforeAutoCompleteListDataCommonType) => () => {
      // 서로 다른 검색어를 쳤을 경우에만 최근 검색어 로직 수행
      if (word.dataText !== lastSearchKeyword) {
        // 재정의한 최근 검색어 리스트
        let returnSearchKeywordList: string[] = [];
        // 중복 단어 인 경우 삭제하고 리스트 맨 앞에 삽입
        if (recentSearchKeywordList.includes(word.dataText)) {
          const filterList = recentSearchKeywordList.filter((keyword) => keyword !== word.dataText);
          returnSearchKeywordList = [word.dataText, ...filterList];
          dispatch(setRecentSearchKeywordList({ recentSearchKeywordList: [word.dataText, ...filterList] }));
        } else {
          // 최근 검색어의 길이가 최대 길이(10)을 초과할 경우 제일 오래된 기록을 검색 기록을 제거
          if (recentSearchKeywordList.length >= RECENT_SEARCH_WORD_MAX_LENGTH) {
            returnSearchKeywordList = [
              word.dataText,
              ...recentSearchKeywordList.slice(0, RECENT_SEARCH_WORD_MAX_LENGTH - 1),
            ];
            dispatch(
              setRecentSearchKeywordList({
                recentSearchKeywordList: [
                  word.dataText,
                  ...recentSearchKeywordList.slice(0, RECENT_SEARCH_WORD_MAX_LENGTH - 1),
                ],
              }),
            );
          } else {
            returnSearchKeywordList = [word.dataText, ...recentSearchKeywordList];
            dispatch(
              setRecentSearchKeywordList({
                recentSearchKeywordList: [word.dataText, ...recentSearchKeywordList],
              }),
            );
          }
        }

        if (!isNil(localStorage)) {
          localStorage.setItem("searchHistory", JSON.stringify(returnSearchKeywordList));
        }
      }

      // 검색 페이지 내부에서는 history stack 쌓지 않음
      if (router.pathname.includes(SEARCH_RESULT_PAGE_URL_PATH)) {
        Router.replace(`${SEARCH_RESULT_PAGE_URL_PATH}?keyword=${encodeURIComponent(word.dataText)}`);
      } else {
        Router.push(`${SEARCH_RESULT_PAGE_URL_PATH}?keyword=${encodeURIComponent(word.dataText)}`);
      }
      dispatch(setSearchKeyword({ searchKeyword: word.dataText }));
      dispatch(setLastSearchKeyword({ lastSearchKeyword: word.dataText }));
    },
    [recentSearchKeywordList],
  );

  return (
    <>
      {wordList.map((word, index) => {
        if (brandListLength + index + 1 <= 10) {
          return (
            <WordListStyle key={index} className="auto-completed-search-list">
              <WordWrapStyle onClick={onClickWord(word)}>
                <SearchIconStyle src={HEADER_SEARCH_BLACK_BTN_IMAGE_PATH} />
                <WordNameStyle dangerouslySetInnerHTML={{ __html: word.displayText }} />
              </WordWrapStyle>
            </WordListStyle>
          );
        }
        return null;
      })}
    </>
  );
};

export default AutoCompletedSearchWord;
