import { Cookies } from "react-cookie";
import { toNumber } from "lodash";

import superFetch from "@amondz/apis-v1";
import superFetchV2 from "@amondz/apis-v2";
import { COOKIE_EXPIRE_DAY_DEFAULT } from "@constants/service/common/common";
import { GIFT_DELIVERY_URL_PATH } from "@constants/url/internalUrlConstants";
import { replaceAll } from "./common";

/**
 * 쿠키 옵션 타입
 */
export interface ICookieOption {
  path: string;
  expires?: Date;
}

/**
 * cookie 초기화 하기
 *
 * next.js 서버에서 api 요청 시 사용되는 superFetch.header 의 cookie 값은
 * 모든 client 의 요청으로부터 동일하게 사용이 되므로
 * 요청마다 cookie 값을 초기화시키고 각 요청의 cookie 값으로 설정해야 함
 *
 * @param {any} req  getServerSideProps 의 context.req
 * @param {string} cookie  cookie
 *
 * @returns {void}
 */
export const initializeCookieV2 = (req: any, cookie: string): void => {
  // SSR 에서 사용중인 superFetch 의 cookie 값 초기화
  superFetchV2.defaults.headers.Cookie = "";

  // SSR 에서 사용중인 superFetch 의 uuid 값 초기화
  superFetchV2.defaults.headers.uuid = "";

  // SSR 에서 사용중인 superFetch 의 user-agent 값 초기화
  superFetchV2.defaults.headers.userAgent = req.headers["user-agent"];

  // SSR 에서 요청 중 cookie 가 존재한다면 해당 값으로 초기화 함
  if (req && cookie) {
    superFetchV2.defaults.headers.Cookie = cookie;
  }

  // 요청한 페이지를 header 에 저장
  const userWebHostUrl = process.env.NEXT_PUBLIC_USER_WEB_HOST_URL || "";
  superFetchV2.defaults.headers.prevUrlPath = replaceAll(req.headers.referer, userWebHostUrl, "");
};

const initializeCookieV1 = (req: any, cookie: string): void => {
  // SSR 에서 사용중인 superFetch 의 cookie 값 초기화
  superFetch.defaults.headers.Cookie = "";

  // SSR 에서 사용중인 superFetch 의 uuid 값 초기화
  superFetch.defaults.headers.uuid = "";

  // 선물하기 배송지 입력 페이지에 진입했을 경우, SSR 에서 사용중인 superFetch 의 giftToken 값 초기화
  if (req.url.includes(GIFT_DELIVERY_URL_PATH)) {
    superFetch.defaults.headers.giftToken = "";
  }

  // SSR 에서 사용중인 superFetch 의 user-agent 값 초기화
  superFetch.defaults.headers.userAgent = req.headers["user-agent"];

  // SSR 에서 요청 중 cookie 가 존재한다면 해당 값으로 초기화 함
  if (req && cookie) {
    superFetch.defaults.headers.Cookie = cookie;
  }

  // 요청한 페이지를 header 에 저장
  const userWebHostUrl = process.env.NEXT_PUBLIC_USER_WEB_HOST_URL || "";
  superFetch.defaults.headers.prevUrlPath = replaceAll(req.headers.referer, userWebHostUrl, "");
};

export const initializeCookie = (req: any): string => {
  const cookie = req ? req.headers.cookie : "";

  initializeCookieV1(req, cookie);
  initializeCookieV2(req, cookie);

  return cookie;
};

/**
 * cookie 에서 amondz token 꺼내오기
 *
 * cookie 에 amondz_login_ex key 속성으로 저장이 되어 있으므로
 * 해당 key 속성으로 api 에 유저 인증으로 사용될 token 값을 꺼내오도록 함
 *
 * @param {string | undefined} cookie browser 요청 시 받아온 Cookie
 *
 * @returns {string | undefined} token
 */
export const getAmondzLoginEx = (cookie: string | undefined): string | null | undefined => {
  if (!cookie) return;
  return cookie.replace(/(?:(?:^|.*;\s*)amondz_login_ex\s*=\s*([^;]*).*$)|^.*$/, "$1");
};

/**
 * cookie 에서 amondz uuid 꺼내오기
 *
 * cookie 에 amondz_uuid key 속성으로 저장이 되어 있으므로
 * 해당 key 속성으로 클라이언트의 uuid 를 꺼내오도록 함
 *
 * @param {string | undefined} cookie browser 요청 시 받아온 Cookie
 *
 * @returns {string | undefined} uuid
 */
export const getAmondzUuid = (cookie: string | undefined): string | undefined => {
  if (!cookie) return;
  return cookie.replace(/(?:(?:^|.*;\s*)amondz_uuid\s*=\s*([^;]*).*$)|^.*$/, "$1");
};

/**
 * cookie 에서 amondz device_type 꺼내오기
 *
 * cookie 에 amondz_device_type key 속성으로 저장이 되어 있으므로
 * 해당 key 속성으로 클라이언트의 device_type 를 꺼내오도록 함
 *
 * @param {string | undefined} cookie browser 요청 시 받아온 Cookie
 *
 * @returns {string | undefined} device_type
 */
export const getAmondzDeviceType = (cookie: string | undefined): string | undefined => {
  if (!cookie) return;
  return cookie.replace(/(?:(?:^|.*;\s*)amondz_device_type\s*=\s*([^;]*).*$)|^.*$/, "$1");
};

/**
 * 쿠키 만료기간 만들기
 *
 * @param {number} targetDay 변경 할 원본
 *
 * @returns {Date} expireDate 쿠키의 만료 기간
 */
export const getCookieExpireDate = (targetDay: number = COOKIE_EXPIRE_DAY_DEFAULT): Date => {
  // 쿠키 유효기간의 기본값은 10년
  const date = new Date();
  date.setTime(date.getTime() + targetDay * 365 * 24 * 60 * 60 * 1000);

  return date;
};

/**
 * cookie 에서 widgetId 꺼내오기
 *
 * 홈, 홈 상세에서 상품 클릭 시 widgetId 를 cookie 에 저장하도록 하고
 * widgetId 를 꺼내와서 상품 상세 API 요청 시 전달하도록 함
 *
 * @param {string | undefined} cookie browser 요청 시 받아온 Cookie
 *
 * @returns {string | undefined} widgetId
 */
export const getWidgetId = (cookie: string | undefined): number | undefined => {
  if (!cookie) return;
  const widgetId = cookie.replace(/(?:(?:^|.*;\s*)amondz_widget_id\s*=\s*([^;]*).*$)|^.*$/, "$1");
  if (widgetId !== "") {
    return toNumber(widgetId);
  }
  return;
};

export default new Cookies();
