import styled from "@styles/themedComponents";

interface IWrapperStyleProps {
  margin: number;
}
export const WrapperStyle = styled.div<IWrapperStyleProps>`
  display: flex;
  width: calc(100% + ${({ margin }) => margin * 2}px);
  margin: 0 ${({ margin }) => margin * -1}px;
  overflow-x: auto;
  overflow-y: hidden;

  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

interface IListStyleProps {
  gap: number;
  margin: number;
}
export const ListStyle = styled.ul<IListStyleProps>`
  display: flex;
  gap: ${({ gap }) => gap}px;
  padding: 0 ${({ margin }) => margin}px;
`;

interface IItemStyleProps {
  width: number;
}
export const ItemStyle = styled.li<IItemStyleProps>`
  width: ${({ width }) => width}px;
  min-width: 140px;
  flex-shrink: 0;
  flex-grow: 1;
`;
