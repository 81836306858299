import * as React from "react";
import { MouseEvent, memo, JSX } from "react";
import { ImageLoader } from "next/image";
import { ImageProps } from "next/legacy/image";
import qs from "query-string";

import { IMAGE_SIZE } from "@constants/service/common/imageSizeConstants";
import { ImageStyle, DefaultImageStyle } from "./style";

export type LayoutValue = "fill" | "fixed" | "intrinsic" | "responsive" | undefined;

interface ImgProps extends ImageProps {
  disabled?: boolean;
  format?: "jpg" | "jpeg" | "gif" | "svg" | "png" | "webp";
}

/**
 * next 에서 지원하는 이미지 최적화 기능:
 * https://nextjs.org/docs/basic-features/image-optimization
 *
 * next/Image component 문서
 * https://nextjs.org/docs/api-reference/next/image
 */
const Img = (props: ImgProps): JSX.Element => {
  const {
    src,
    width = IMAGE_SIZE.LARGE.WIDTH,
    height = IMAGE_SIZE.LARGE.HEIGHT,
    layout,
    disabled,
    onClick,
    format,
    quality = 90,
  } = props;
  const size = {
    width: layout === "fill" ? undefined : width,
    height: layout === "fill" ? undefined : height,
  };

  const loader: ImageLoader = ({ src, quality }) => {
    //src자체에 쿼리스트링이 붙어있는 url이 있어서 기존 쿼리스트링을 포함하여 src url 생성
    const { url, query } = qs.parseUrl(src);
    const imgQueryString = qs.stringify(
      { ...query, w: size.width, h: size.height, q: quality, f: format },
      { skipNull: true },
    );
    return `${url}?${imgQueryString}`;
  };

  const handleClick = (e: MouseEvent<HTMLImageElement, globalThis.MouseEvent>): void => {
    if (!onClick) return;
    if (!disabled) {
      onClick(e);
    }
  };

  if (!src) {
    return <DefaultImageStyle />;
  }

  return <ImageStyle {...props} {...size} src={src} loader={loader} quality={quality} onClick={handleClick} />;
};

export default memo(Img);
