import { SEARCH_TAB_QUERY_INDEX_SIGNATURE_TYPE } from "@store/modules/search/types";
import { PRODUCT_COMMON_FILTER_TAP, PRODUCT_COMMON_FILTER_TAP_TEXT } from "@constants/common/common";

// 검색 결과 탭 종류
export const SEARCH_TAB_QUERY: SEARCH_TAB_QUERY_INDEX_SIGNATURE_TYPE = {
  // 통합 검색
  INTEGRATION: 0,
  // 상품 검색
  PRODUCT: 1,
  // 브랜드 검색
  STORE: 2,
  // 후기 검색
  REVIEW: 3,
  // 기획전 검색
  EVENT: 4,
} as const;

// 검색 결과 탭 텍스트
export const SEARCH_TAB_TEXT = {
  [SEARCH_TAB_QUERY.INTEGRATION]: "통합검색",
  [SEARCH_TAB_QUERY.PRODUCT]: "상품",
  [SEARCH_TAB_QUERY.STORE]: "브랜드",
  [SEARCH_TAB_QUERY.REVIEW]: "상품후기",
  [SEARCH_TAB_QUERY.EVENT]: "기획전",
};

// 검색 결과 탭 쿼리 텍스트
export const SEARCH_TAB_QUERY_TEXT = {
  [SEARCH_TAB_QUERY.INTEGRATION]: "integration",
  [SEARCH_TAB_QUERY.PRODUCT]: "product",
  [SEARCH_TAB_QUERY.STORE]: "store",
  [SEARCH_TAB_QUERY.REVIEW]: "review",
  [SEARCH_TAB_QUERY.EVENT]: "event",
};

// 검색 결과 탭 리스트
export const SEARCH_TAB_LIST = [
  {
    id: SEARCH_TAB_QUERY.INTEGRATION,
    name: SEARCH_TAB_TEXT[SEARCH_TAB_QUERY.INTEGRATION],
    query: SEARCH_TAB_QUERY_TEXT[SEARCH_TAB_QUERY.INTEGRATION],
  },
  {
    id: SEARCH_TAB_QUERY.PRODUCT,
    name: SEARCH_TAB_TEXT[SEARCH_TAB_QUERY.PRODUCT],
    query: SEARCH_TAB_QUERY_TEXT[SEARCH_TAB_QUERY.PRODUCT],
  },
  {
    id: SEARCH_TAB_QUERY.STORE,
    name: SEARCH_TAB_TEXT[SEARCH_TAB_QUERY.STORE],
    query: SEARCH_TAB_QUERY_TEXT[SEARCH_TAB_QUERY.STORE],
  },
  {
    id: SEARCH_TAB_QUERY.REVIEW,
    name: SEARCH_TAB_TEXT[SEARCH_TAB_QUERY.REVIEW],
    query: SEARCH_TAB_QUERY_TEXT[SEARCH_TAB_QUERY.REVIEW],
  },
  {
    id: SEARCH_TAB_QUERY.EVENT,
    name: SEARCH_TAB_TEXT[SEARCH_TAB_QUERY.EVENT],
    query: SEARCH_TAB_QUERY_TEXT[SEARCH_TAB_QUERY.EVENT],
  },
];

// 검색 상품 정렬 타입
export const SEARCH_PRODUCT_SORT = {
  RELATION: 0, // 연관순
  RECENT: 2, // 최신순
  CHEAP: 3, // 낮은가격순
  EXPENSIVE: 4, // 높은가격순
} as const;

// 검색 상품 정렬 타입 텍스트
export const SEARCH_PRODUCT_SORT_TEXT = {
  [SEARCH_PRODUCT_SORT.RELATION]: "연관순",
  [SEARCH_PRODUCT_SORT.RECENT]: "신상품순",
  [SEARCH_PRODUCT_SORT.CHEAP]: "낮은가격순",
  [SEARCH_PRODUCT_SORT.EXPENSIVE]: "높은가격순",
} as const;

// 검색 상품 정렬 타입 리스트
export const SEARCH_PRODUCT_SORT_LIST = [
  {
    id: SEARCH_PRODUCT_SORT.RELATION,
    name: SEARCH_PRODUCT_SORT_TEXT[SEARCH_PRODUCT_SORT.RELATION],
  },
  {
    id: SEARCH_PRODUCT_SORT.RECENT,
    name: SEARCH_PRODUCT_SORT_TEXT[SEARCH_PRODUCT_SORT.RECENT],
  },
  {
    id: SEARCH_PRODUCT_SORT.CHEAP,
    name: SEARCH_PRODUCT_SORT_TEXT[SEARCH_PRODUCT_SORT.CHEAP],
  },
  {
    id: SEARCH_PRODUCT_SORT.EXPENSIVE,
    name: SEARCH_PRODUCT_SORT_TEXT[SEARCH_PRODUCT_SORT.EXPENSIVE],
  },
];

// 상품 후기, 기획전 검색결과 리스트 페이지 사이즈
export const SEARCH_LIST_PAGE_COUNT = 10;

// 통합 검색 종류 별 노출 갯수
export const SEARCH_INTEGRATION_RESULT_QUANTITY = {
  // 상품 검색
  PRODUCT: 6,
  // 브랜드 검색
  STORE: 3,
  // 후기 검색
  REVIEW: 3,
  // 기획전 검색
  EVENT: 1,
};

// 검색 결과 페이지 상품 필터 탭 리스트
export const SEARCH_PRODUCT_FILTER_TAB_LIST = [
  {
    id: PRODUCT_COMMON_FILTER_TAP.CATEGORY,
    name: PRODUCT_COMMON_FILTER_TAP_TEXT[PRODUCT_COMMON_FILTER_TAP.CATEGORY],
  },
  {
    id: PRODUCT_COMMON_FILTER_TAP.STORE,
    name: PRODUCT_COMMON_FILTER_TAP_TEXT[PRODUCT_COMMON_FILTER_TAP.STORE],
  },
  {
    id: PRODUCT_COMMON_FILTER_TAP.DETAIL_OPTION,
    name: PRODUCT_COMMON_FILTER_TAP_TEXT[PRODUCT_COMMON_FILTER_TAP.DETAIL_OPTION],
  },
  {
    id: PRODUCT_COMMON_FILTER_TAP.COLOR,
    name: PRODUCT_COMMON_FILTER_TAP_TEXT[PRODUCT_COMMON_FILTER_TAP.COLOR],
  },
  {
    id: PRODUCT_COMMON_FILTER_TAP.PRICE_DISCOUNT,
    name: PRODUCT_COMMON_FILTER_TAP_TEXT[PRODUCT_COMMON_FILTER_TAP.PRICE_DISCOUNT],
  },
];

// 검색 키워드 최대 글자수
export const MAXIMUM_NUMBER_OF_SEARCH_KEYWORD = 30;

// 최근 검색어 최대 저장 갯수
export const RECENT_SEARCH_WORD_MAX_LENGTH = 10;
