import { Review } from "@amondz/types";

export const mock_review: Review = {
  id: 222,
  rate: 1,
  content: "너무 마음에 들어요",
  likeCount: 0,
  createDate: "2022-07-26T08:39:02.274Z",
  updateDate: "2022-07-26T08:39:02.274Z",
  isMine: true,
  writer: {
    account: "abcd**",
    email: "beje**",
  },
  product: {
    id: 1,
    name: "아몬즈 귀걸이",
    storeName: "위아몬즈",
    option: {
      name: "길이",
      value: "44cm",
    },
    mainImageUrl: "https://cdntest.amondz.com/store/1605/original/titleImg/SAI_6521.png?v=1636098753004",
  },
  reviewCriteria: [
    {
      id: 1111,
      type: "size",
      typeLabel: "사이즈",
      criterion: "small",
      criterionLabel: "예상보다 작아요",
    },
  ],
  attachments: [
    {
      id: 1010,
      url: "https://image.dev-amondz.com/square-images/e905c24e-3ba4-4c7a-8cdf-f2a5fc847332.jpg?h=1080&q=90&w=1080",
    },
    {
      id: 1020,
      url: "https://image.dev-amondz.com/square-images/3fb0a3e4-cc9d-43e8-b67a-b7aa9b8d9a04.jpg?h=1080&q=90&w=1080",
    },
  ],
  tags: [
    {
      id: 1,
      name: "귀걸이",
    },
  ],
  likeFlag: true,
};
