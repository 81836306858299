import { useEffect, useRef, useState } from "react";

function useRefWidth<T extends HTMLElement>(defaultValue: number = 0) {
  const [width, setWidth] = useState(defaultValue);
  const ref = useRef<T>(null);

  useEffect(() => {
    const onResize = () => {
      const width = ref.current?.offsetWidth || defaultValue;
      setWidth(width);
    };

    setWidth(ref.current?.offsetWidth || defaultValue);
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  });

  return { ref, width };
}

export default useRefWidth;
