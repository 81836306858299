import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { isNil } from "lodash";

import { SelectedElType } from "@store/modules/search";
import useSearchBeforeAutoCompleteList from "@swr/hooks/search/useSearchBeforeAutoCompleteList";
import AutoCompletedSearchBrand from "@views/search/BeforeSearch/AutoCompletedSearchBrand";
import AutoCompletedSearchWord from "@views/search/BeforeSearch/AutoCompletedSearchWord";

interface IAutoCompletedSearchListProps {
  searchKeyword: string;
  inputEl?: React.RefObject<HTMLInputElement>;
  onChangeAutoCompleteDataExisted: (existed: boolean) => void;
}

const AutoCompletedSearchList: FC<IAutoCompletedSearchListProps> = (props) => {
  const { searchKeyword, inputEl, onChangeAutoCompleteDataExisted } = props;
  const { data: autoCompleteData } = useSearchBeforeAutoCompleteList({ keyword: searchKeyword });
  const SearchListEl = useRef<HTMLUListElement>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const SelectedEl = useRef<SelectedElType>({ element: null });

  // 방향 키로 추천 검색어를 선택 및 엔터를 눌렀을 때
  const onChangeKey = useCallback(
    (e: KeyboardEvent) => {
      // 한글 입력 시 입력한 문자가 조합문자인지 아닌지 판단(조합이 끝나면 실행)
      if (!e.isComposing) {
        if (e.key === "ArrowDown") {
          // 웹 페이지 스크롤 방지
          e.preventDefault();
          setIsHovered(false);
          setSelectedIndex((prev) => {
            if (isNil(prev)) {
              return 0;
            }
            return prev + 1;
          });
        } else if (e.key === "ArrowUp") {
          // 웹 페이지 스크롤 방지
          e.preventDefault();
          setIsHovered(false);
          setSelectedIndex((prev) => {
            if (isNil(prev)) {
              return -1;
            }
            return prev - 1;
          });
        } else if (e.key === "Enter" && SelectedEl.current?.element?.firstElementChild) {
          (SelectedEl.current.element.firstElementChild as HTMLAnchorElement).click();
        }
      }
    },
    [selectedIndex],
  );

  // 추천 검색어에 마우스를 hover했을 때
  const onMouseHover = useCallback((e: MouseEvent) => {
    setIsHovered(true);
    const target = e.target as HTMLElement;

    if (SearchListEl.current) {
      Array.from(SearchListEl.current.children).forEach((child: Element) => {
        child.classList.remove("selected");
      });
      let targetElement = target;
      while (
        targetElement &&
        targetElement !== SearchListEl.current &&
        !targetElement.classList.contains("auto-completed-search-list")
      ) {
        targetElement = targetElement.parentElement as HTMLElement;
      }
      if (targetElement && targetElement !== SearchListEl.current) {
        targetElement.classList.add("selected");
        setSelectedIndex(Array.from(SearchListEl.current.children).indexOf(targetElement));
      }
    }
  }, []);

  /**
   * 검색어를 바꾸면 SelectedIndex 및 selected element 초기화
   */
  useEffect(() => {
    if (SearchListEl.current) {
      // selected element 초기화
      Array.from(SearchListEl.current.children).map((list) => {
        if (list.className.includes("selected")) {
          list.classList.remove("selected");
        }
        return list;
      });
    }
    setSelectedIndex(null);
  }, [searchKeyword]);

  // isAutoCompleteData(자동검색어 여부) state 설정
  useEffect(() => {
    if (autoCompleteData?.data) {
      onChangeAutoCompleteDataExisted(
        autoCompleteData.data.keywordList?.length > 0 || autoCompleteData.data.brandList?.length > 0,
      );
    }
  }, [searchKeyword, autoCompleteData?.data]);

  /**
   * 추천 검색어 selected 지정 및 input value 설정
   */
  useEffect(() => {
    if (SearchListEl.current) {
      Array.from(SearchListEl.current.children).map((list, index) => {
        if (inputEl?.current && SearchListEl.current && !isNil(selectedIndex)) {
          if (selectedIndex >= Array.from(SearchListEl.current.children).length) {
            // 추천 검색어 맨 밑에서 아래 화살표 키를 눌렀을 때
            setSelectedIndex(0);
            list.classList.remove("selected");
          } else if (selectedIndex < 0) {
            // input 창에서 위 화살표 키를 눌렀을 때
            setSelectedIndex(Array.from(SearchListEl.current.children).length - 1);
          } else {
            if (selectedIndex === index) {
              // hover시 input value 불변
              if (!isHovered) {
                // 추천 검색어 종류가 브랜드일 경우 불필요한 텍스트 삭제
                if (list?.textContent?.includes("브랜드")) {
                  inputEl.current.value = list.textContent.replace("브랜드", "") as string;
                } else {
                  // 추천 검색어 종류가 브랜드가 아닐 경우
                  inputEl.current.value = list.textContent as string;
                }
              }
              list.classList.add("selected");
              SelectedEl.current.element = list;
            } else {
              list.classList.remove("selected");
            }
          }
        }
        return list;
      });
    }
  }, [selectedIndex]);

  useEffect(() => {
    window.addEventListener("keydown", onChangeKey);
    return () => window.removeEventListener("keydown", onChangeKey);
  }, []);

  useEffect(() => {
    if (SearchListEl.current) {
      SearchListEl.current.addEventListener("mouseover", onMouseHover);
      return () => SearchListEl?.current?.removeEventListener("mouseover", onMouseHover);
    }
  });

  if (!autoCompleteData) {
    return null;
  }

  return (
    <ul ref={SearchListEl}>
      {autoCompleteData.data?.brandList.length > 0 && (
        <AutoCompletedSearchBrand brandList={autoCompleteData.data?.brandList} />
      )}
      {autoCompleteData.data?.brandList.length < 10 && autoCompleteData.data?.keywordList.length > 0 && (
        <AutoCompletedSearchWord
          wordList={autoCompleteData.data?.keywordList}
          brandListLength={autoCompleteData.data?.brandList.length}
        />
      )}
    </ul>
  );
};

export default AutoCompletedSearchList;
