import Router from "next/router";
import { COMMON_ERROR_CODE } from "@amondz/types";
import { store } from "@store/index";
import { STATUS_CODE_COMMON } from "@constants/status/code/statusCodeCommon";
import { STATUS_CODE_API } from "@constants/status/code/statusCodeApi";
import { STATUS_COMMON_MESSAGE } from "@constants/status/msg/statusCommonMessage";
import cookies from "@lib/utility/cookies";
import { AMONDZ_LOGIN_TOKEN_KEY } from "@constants/service/auth/auth";
import { ErrorCodeType, notifyToastToggle, oneButtonModalToggle } from "@store/modules/base";
import { INVALID_USER_INFO_MODAL_CONTENTS } from "@constants/service/auth/modal";
import { COMMON_ERROR_MESSAGES } from "@constants/msg/errorResponse/common";
import { INTRO_PAGE_URL_PATH } from "@constants/url/internalUrlConstants";
import { forceLogout, LogOutAsync, memberErrorReset, memberErrorUpdate } from "@store/modules/auth";
import { MEMBER_ERROR_CODE, MEMBER_ERROR_FORM_NAME } from "@constants/msg/errorResponse/member";
import { NOTIFY_TOAST_TYPE } from "@constants/enum/baseEnums";
import { LUCKY_DRAW_ERROR_CODE, LUCKY_DRAW_ERROR_MESSAGES } from "@constants/msg/errorResponse/event";

export const errorHandlerV1 = (code: number) => {
  const dispatch = store.dispatch;
  // 공용 exception 상태 메시지
  let commonExceptionStatusMsg = null;
  // 토스트 메시지 필요 여부
  let isNeedNotifyToastMsg = false;

  switch (code) {
    case STATUS_CODE_COMMON.INVALID_MEMBER.CODE_200:
    case STATUS_CODE_COMMON.INVALID_MEMBER.CODE_201:
    case STATUS_CODE_COMMON.INVALID_MEMBER.CODE_202:
    case STATUS_CODE_COMMON.INVALID_MEMBER.CODE_203:
    case STATUS_CODE_COMMON.INVALID_MEMBER.CODE_204:
    case STATUS_CODE_API.USER_PROFILE.INVALID_USER_KEY:
      commonExceptionStatusMsg = STATUS_COMMON_MESSAGE[STATUS_CODE_COMMON.INVALID_MEMBER.CODE_200];

      console.log("회원정보 유용하지 않음 !");

      // 저장되어 있는 유저 인증 토큰 삭제
      cookies.remove(AMONDZ_LOGIN_TOKEN_KEY, { path: "/" });

      dispatch(
        oneButtonModalToggle({
          visible: true,
          isCloseBtn: false,
          msg: INVALID_USER_INFO_MODAL_CONTENTS.MSG,
          isLogOut: true,
        }),
      );

      return;
    case STATUS_CODE_COMMON.SERVER_ERROR:
      commonExceptionStatusMsg = STATUS_COMMON_MESSAGE[STATUS_CODE_COMMON.SERVER_ERROR];
      isNeedNotifyToastMsg = true;
      console.log("서버 Exception 발생 !");
      return;
    case STATUS_CODE_COMMON.INCOMPLETE_PARAM:
      commonExceptionStatusMsg = STATUS_COMMON_MESSAGE[STATUS_CODE_COMMON.INCOMPLETE_PARAM];
      isNeedNotifyToastMsg = true;
      console.log("매개변수가 제대로 전달되지 않음 !");

      // 홈으로 이동
      Router.push(INTRO_PAGE_URL_PATH);
  }

  if (commonExceptionStatusMsg) {
    if (isNeedNotifyToastMsg) {
      dispatch(
        notifyToastToggle({
          type: NOTIFY_TOAST_TYPE.ERROR,
          msg: commonExceptionStatusMsg,
          visible: true,
        }),
      );
    }
    console.error(commonExceptionStatusMsg);
  }
};

export const errorHandlerV2 = (error: ErrorCodeType) => {
  const dispatch = store?.dispatch;
  if (!dispatch) return;
  switch (error.code) {
    /**
     * 공통 에러 코드 처리
     */
    /**
     * 예기치 않은 서버 오류가 발생했을 경우
     */
    case COMMON_ERROR_CODE.COMMON_001:
      dispatch(
        oneButtonModalToggle({
          visible: true,
          isCloseBtn: false,
          msg: COMMON_ERROR_MESSAGES[COMMON_ERROR_CODE.COMMON_001],
          onClickBtn: () => {
            Router.push(INTRO_PAGE_URL_PATH);
            dispatch(memberErrorReset());
          },
          onCloseBtn: () => dispatch(memberErrorReset()),
        }),
      );
      break;

    /**
     * 유효하지 않은 토큰일 경우
     */
    case COMMON_ERROR_CODE.COMMON_002:
      dispatch(
        oneButtonModalToggle({
          visible: true,
          isCloseBtn: false,
          msg: COMMON_ERROR_MESSAGES[COMMON_ERROR_CODE.COMMON_002],
          btnText: "로그아웃하기",
          onClickBtn: () => {
            dispatch(LogOutAsync.request({}));
            dispatch(forceLogout({ isForceLogout: true }));
          },
          onCloseBtn: () => dispatch(memberErrorReset()),
        }),
      );
      break;

    /**
     * 유효하지 않은 앱 버전일 경우
     */
    case COMMON_ERROR_CODE.COMMON_003:
      dispatch(
        oneButtonModalToggle({
          visible: true,
          isCloseBtn: false,
          msg: COMMON_ERROR_MESSAGES[COMMON_ERROR_CODE.COMMON_003],
          btnText: "로그아웃하기",
          onClickBtn: () => {
            dispatch(LogOutAsync.request({}));
            dispatch(forceLogout({ isForceLogout: true }));
          },
          onCloseBtn: () => dispatch(memberErrorReset()),
        }),
      );
      break;

    /**
     * 헤더 필드가 서버에 바르게 전달되지 않았을 경우
     */
    case COMMON_ERROR_CODE.COMMON_004:
      dispatch(
        oneButtonModalToggle({
          visible: true,
          isCloseBtn: false,
          msg: COMMON_ERROR_MESSAGES[COMMON_ERROR_CODE.COMMON_004],
          onClickBtn: () => {
            Router.push(INTRO_PAGE_URL_PATH);
            dispatch(memberErrorReset());
          },
          onCloseBtn: () => dispatch(memberErrorReset()),
        }),
      );
      break;

    /**
     * 존재하지 않는 api path 일 경우
     */
    case COMMON_ERROR_CODE.COMMON_005:
      dispatch(
        oneButtonModalToggle({
          visible: true,
          isCloseBtn: false,
          msg: COMMON_ERROR_MESSAGES[COMMON_ERROR_CODE.COMMON_005],
          onClickBtn: () => {
            Router.push(INTRO_PAGE_URL_PATH);
            dispatch(memberErrorReset());
          },
          onCloseBtn: () => dispatch(memberErrorReset()),
        }),
      );
      break;

    /**
     * 분당 최대 요청수를 초과한 경우
     */
    case COMMON_ERROR_CODE.COMMON_006:
      dispatch(
        oneButtonModalToggle({
          visible: true,
          isCloseBtn: false,
          msg: COMMON_ERROR_MESSAGES[COMMON_ERROR_CODE.COMMON_006],
          onClickBtn: () => {
            dispatch(memberErrorReset());
          },
          onCloseBtn: () => dispatch(memberErrorReset()),
        }),
      );
      break;

    /**
     * JSON 문법 오류
     */
    case COMMON_ERROR_CODE.COMMON_009:
      dispatch(
        oneButtonModalToggle({
          visible: true,
          isCloseBtn: false,
          msg: COMMON_ERROR_MESSAGES[COMMON_ERROR_CODE.COMMON_009],
          onClickBtn: () => {
            dispatch(memberErrorReset());
          },
          onCloseBtn: () => dispatch(memberErrorReset()),
        }),
      );
      break;

    /**
     * 회원가입 인증 에러 코드 처리
     */
    /**
     * 에러 발생 시 에러 상태 업데이트
     */
    case MEMBER_ERROR_CODE.MEMBER_001:
    case MEMBER_ERROR_CODE.MEMBER_002:
    case MEMBER_ERROR_CODE.MEMBER_003:
    case MEMBER_ERROR_CODE.MEMBER_004:
    case MEMBER_ERROR_CODE.MEMBER_005:
    case MEMBER_ERROR_CODE.MEMBER_006:
    case MEMBER_ERROR_CODE.MEMBER_007:
    case MEMBER_ERROR_CODE.MEMBER_008:
    case MEMBER_ERROR_CODE.MEMBER_009:
    case MEMBER_ERROR_CODE.MEMBER_010:
    case MEMBER_ERROR_CODE.MEMBER_011:
    case MEMBER_ERROR_CODE.MEMBER_012:
    case MEMBER_ERROR_CODE.MEMBER_013:
    case MEMBER_ERROR_CODE.MEMBER_014:
    case MEMBER_ERROR_CODE.MEMBER_015:
    case MEMBER_ERROR_CODE.MEMBER_016:
    case MEMBER_ERROR_CODE.MEMBER_017:
    case MEMBER_ERROR_CODE.MEMBER_018:
    case MEMBER_ERROR_CODE.MEMBER_019:
    case MEMBER_ERROR_CODE.MEMBER_020:
    case MEMBER_ERROR_CODE.MEMBER_201:
    case MEMBER_ERROR_CODE.MEMBER_301:
    case MEMBER_ERROR_CODE.MEMBER_302:
    case MEMBER_ERROR_CODE.MEMBER_303:
    case MEMBER_ERROR_CODE.MEMBER_304:
    case MEMBER_ERROR_CODE.MEMBER_401:
      dispatch(
        memberErrorUpdate({
          code: error.code,
          message: error.message,
          name: MEMBER_ERROR_FORM_NAME[error.code as string],
        }),
      );
      break;
    case LUCKY_DRAW_ERROR_CODE.LUCKYDRAW_001:
    case LUCKY_DRAW_ERROR_CODE.LUCKYDRAW_002:
    case LUCKY_DRAW_ERROR_CODE.LUCKYDRAW_003:
      dispatch(
        oneButtonModalToggle({
          visible: true,
          isCloseBtn: true,
          msg: LUCKY_DRAW_ERROR_MESSAGES[error.code as string],
        }),
      );
      break;
    default:
      break;
  }
};
