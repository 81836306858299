import { mock } from "@services/msw/utils";

//결제 준비 api mock
export const orderPreparationMock = mock({
  status: 1,
  data: {
    point: 530000,
    buyer: {
      name: "심승태",
      cellPhone: "01075332136",
      email: "seungtae3@bejewel.kr",
    },
    mainAddress: {
      id: 285046,
      recipient: "심승태",
      cellPhone: "01075332136",
      addressLine1: "경기 의정부시 용민로 64",
      addressLine2: "407호",
      postCode: "11785",
    },
    extraFeeList: [],
    payMethodList: [
      {
        type: "card",
        name: "신용카드",
        eventName: "최대 5%할인",
        availabilityType: 0,
      },
      {
        type: "trans",
        name: "실시간 계좌이체",
        eventName: null,
        availabilityType: 0,
      },
      {
        type: "vbank",
        name: "가상계좌",
        eventName: "3000원 적립",
        availabilityType: 1,
      },
      {
        type: "kakaopay",
        name: "카카오페이",
        eventName: "최대 5%할인",
        availabilityType: 2,
      },
      {
        type: "naverpay",
        name: "네이버페이",
        eventName: null,
        availabilityType: 1,
      },
      {
        type: "payco",
        name: "페이코",
        eventName: "3000원 적립",
        availabilityType: 1,
      },
      {
        type: "tosspay",
        name: "토스페이",
        eventName: null,
        availabilityType: 1,
      },
    ],
    refund: {
      bankList: [
        {
          id: 1,
          name: "국민은행",
        },
        {
          id: 2,
          name: "기업은행",
        },
        {
          id: 3,
          name: "농협",
        },
        {
          id: 4,
          name: "신한은행",
        },
        {
          id: 5,
          name: "우리은행",
        },
        {
          id: 6,
          name: "외환은행",
        },
        {
          id: 7,
          name: "SC제일은행",
        },
        {
          id: 8,
          name: "하나은행",
        },
        {
          id: 9,
          name: "한국시티은행",
        },
        {
          id: 10,
          name: "광주은행",
        },
        {
          id: 11,
          name: "경남은행",
        },
        {
          id: 12,
          name: "대구은행",
        },
        {
          id: 13,
          name: "부산은행",
        },
        {
          id: 14,
          name: "전북은행",
        },
        {
          id: 16,
          name: "수협은행",
        },
        {
          id: 17,
          name: "우체국",
        },
        {
          id: 18,
          name: "산업은행",
        },
        {
          id: 19,
          name: "새마을금고",
        },
        {
          id: 20,
          name: "신협",
        },
        {
          id: 23,
          name: "케이뱅크",
        },
        {
          id: 24,
          name: "카카오뱅크",
        },
      ],
      bankId: 2,
      bankNumber: "01075332136",
      bankHolder: "심승태",
    },
  },
});

//결제 요청 api mock
export const orderFormInsertMock = mock({
  status: 1,
  data: {
    orderId: 21674797109632,
    orderName: "[AWS-TEST][심승태] 아몬즈금은방-제품번호O-위탁-옵션O-일반 외 0 건",
    amount: 2000,
    userEmail: "seungtae3@bejewel.kr",
    userName: "심승태",
    phone: "01075332136",
    pg: "naverpay.np_wsavd731127",
  },
});

//결제 완료 api mock
export const paymentCompleteMock = mock({
  status: 1,
  data: {
    orderId: 21674797109632,
    totalPrice: 2000,
    payMethod: 8,
    totalDeliveryExtraFee: 0,
    totalDiscountPrice: 7900,
    savingPoint: 20,
    orderItemList: [
      {
        storeId: 1150,
        storeName: "아몬즈금은방",
        productId: 77445,
        productName: "아몬즈금은방-제품번호O-위탁-옵션O-일반",
        productOptionId: 376335,
        productOptionText: "크기 : 1",
        quantity: 1,
        productPrice: 9900,
        discountPrice: 0,
        productOptionPrice: 0,
        usedCouponPrice: 0,
        usedPoint: 7900,
        totalPrice: 2000,
        isTodayDelivery: 1,
        couponId: null,
        couponName: null,
        productCategoryName: "목걸이",
        imgUrl: "https://cdntest.amondz.com/product/77445/resize/mainImg/PSI_819527_50.jpeg?v=1672404566396",
        categoryId: 2,
        categoryName: "목걸이",
        productCategoryList: [
          {
            id: 2,
            name: "목걸이",
          },
          {
            id: 7,
            name: "발찌",
          },
          {
            id: 174,
            name: "비즈발찌",
          },
          {
            id: 175,
            name: "패브릭발찌",
          },
        ],
      },
    ],
    recommendList: [
      {
        id: 64324,
        name: "TAPRE RING 03",
        storeId: 591,
        storeName: "오델리프레",
        salesPrice: 98000,
        status: 2,
        imgUrl: "https://cdntest.amondz.com/product/64324/resize/mainImg/PSI_667934_50.jpeg?v=1653123803925",
      },
      {
        id: 75883,
        name: "Navel Ring",
        storeId: 1159,
        storeName: "인스",
        salesPrice: 54000,
        status: 2,
        imgUrl: "https://cdntest.amondz.com/product/75883/resize/mainImg/PSI_803661_50.jpeg?v=1667299860728",
      },
      {
        id: 36400,
        name: "[래원,GOT7진영,성규 착용] S.S 써지컬스틸 체인팔찌 02 (2 TYPE)",
        storeId: 1207,
        storeName: "바이시클트로피",
        originPrice: 27000,
        discountRate: 10,
        salesPrice: 24300,
        status: 2,
        imgUrl: "https://cdntest.amondz.com/product/36400/resize/mainImg/PSI_366821_50.jpeg?v=1613725828085",
      },
      {
        id: 45799,
        name: "Half bullet ring",
        storeId: 1419,
        storeName: "오드콜렛",
        salesPrice: 187000,
        status: 2,
        imgUrl: "https://cdntest.amondz.com/product/45799/resize/mainImg/PSI_462429_50.jpeg?v=1626334979085",
      },
      {
        id: 35871,
        name: "[NCT 지성, 해찬, 여자아이들 소연 착용] FLEXIBLE RNG",
        storeId: 746,
        storeName: "논논",
        originPrice: 25000,
        discountRate: 10,
        salesPrice: 22500,
        status: 2,
        imgUrl: "https://cdntest.amondz.com/product/35871/resize/mainImg/PSI_571869_50.jpeg?v=1642480417116",
      },
      {
        id: 51981,
        name: "Love Lock Heart Necklace_VH2279NE101M",
        storeId: 1627,
        storeName: "빈티지헐리우드",
        salesPrice: 69000,
        status: 2,
        imgUrl: "https://cdntest.amondz.com/product/51981/resize/mainImg/PSI_528579_50.jpeg?v=1637429008749",
      },
      {
        id: 64182,
        name: "[공식수입] NEW LETTERS 컬렉션 이니셜 목걸이 A~Z (옐로우골드 컬러)",
        storeId: 1914,
        storeName: "피디파올라",
        salesPrice: 109000,
        status: 2,
        imgUrl: "https://cdntest.amondz.com/product/64182/resize/mainImg/PSI_737408_50.jpeg?v=1659435890366",
      },
      {
        id: 74020,
        name: "Cuddle pearl ring",
        storeId: 1994,
        storeName: "제이리사",
        originPrice: 90000,
        discountRate: 5,
        salesPrice: 85500,
        status: 2,
        imgUrl: "https://cdntest.amondz.com/product/74020/resize/mainImg/PSI_801599_50.jpeg?v=1666968170282",
      },
      {
        id: 63639,
        name: "COLORLESS, E20",
        storeId: 422,
        storeName: "꼼므드723",
        salesPrice: 64000,
        status: 2,
        imgUrl: "https://cdntest.amondz.com/product/63639/resize/mainImg/PSI_659479_50.jpeg?v=1652499421073",
      },
      {
        id: 40940,
        name: "maple ring (gold-plated)",
        storeId: 673,
        storeName: "테스",
        salesPrice: 79000,
        status: 2,
        imgUrl: "https://cdntest.amondz.com/product/40940/resize/mainImg/PSI_409771_50.jpeg?v=1619342062959",
      },
      {
        id: 44476,
        name: "Marriage D ring",
        storeId: 1419,
        storeName: "오드콜렛",
        salesPrice: 59000,
        status: 2,
        imgUrl: "https://cdntest.amondz.com/product/44476/resize/mainImg/PSI_448663_50.jpeg?v=1624614373878",
      },
      {
        id: 59684,
        name: "THE TWINS 19N",
        storeId: 1797,
        storeName: "1064스튜디오",
        salesPrice: 105000,
        status: 2,
        imgUrl: "https://cdntest.amondz.com/product/59684/resize/mainImg/PSI_615205_50.jpeg?v=1648132500937",
      },
      {
        id: 43220,
        name: "Pea body cuff 01",
        storeId: 591,
        storeName: "오델리프레",
        salesPrice: 160000,
        status: 2,
        imgUrl: "https://cdntest.amondz.com/product/43220/resize/mainImg/PSI_435174_50.jpeg?v=1622623651564",
      },
      {
        id: 40944,
        name: "rope necklace",
        storeId: 673,
        storeName: "테스",
        salesPrice: 86000,
        status: 2,
        imgUrl: "https://cdntest.amondz.com/product/40944/resize/mainImg/PSI_408755_50.jpeg?v=1619184380247",
      },
      {
        id: 71889,
        name: "Wave of consolation",
        storeId: 1769,
        storeName: "올로르",
        salesPrice: 77000,
        status: 2,
        imgUrl: "https://cdntest.amondz.com/product/71889/resize/mainImg/PSI_755919_50.jpeg?v=1661541399847",
      },
      {
        id: 16247,
        name: "#5120",
        storeId: 684,
        storeName: "쿼르코어",
        salesPrice: 21000,
        status: 2,
        imgUrl: "https://cdntest.amondz.com/product/16247/resize/mainImg/PSI_145347_50.jpeg?v=1582266625509",
      },
      {
        id: 15591,
        name: "memento 003 (실버925)",
        storeId: 672,
        storeName: "그레이노이즈",
        salesPrice: 38000,
        status: 2,
        imgUrl: "https://cdntest.amondz.com/product/15591/resize/mainImg/PSI_288327_50.jpeg?v=1602831848580",
      },
      {
        id: 15712,
        name: "Ball ear-cuff 볼 이어커프",
        storeId: 669,
        storeName: "브릴피스",
        salesPrice: 69000,
        status: 2,
        imgUrl: "https://cdntest.amondz.com/product/15712/resize/mainImg/PSI_141202_50.jpeg?v=1581562003570",
      },
      {
        id: 19317,
        name: "레코드 V2 블랙벨벳 밴드링",
        storeId: 753,
        storeName: "마마카사르",
        salesPrice: 113000,
        status: 2,
        imgUrl: "https://cdntest.amondz.com/product/19317/resize/mainImg/PSI_807464_50.jpeg?v=1667699097036",
      },
      {
        id: 16270,
        name: "나를 소중하게:쉐리-모아 엠보씽 실버 커플팔찌 남녀공용",
        storeId: 697,
        storeName: "통쉐",
        salesPrice: 62000,
        status: 2,
        imgUrl: "https://cdntest.amondz.com/product/16270/resize/mainImg/PSI_567877_50.jpeg?v=1641737909823",
      },
      {
        id: 75904,
        name: "Dig Necklace",
        storeId: 1159,
        storeName: "인스",
        salesPrice: 66000,
        status: 2,
        imgUrl: "https://cdntest.amondz.com/product/75904/resize/mainImg/PSI_803916_50.jpeg?v=1667310882260",
      },
      {
        id: 59679,
        name: "THE TWINS 24R",
        storeId: 1797,
        storeName: "1064스튜디오",
        salesPrice: 180000,
        status: 2,
        imgUrl: "https://cdntest.amondz.com/product/59679/resize/mainImg/PSI_615190_50.jpeg?v=1648131987934",
      },
      {
        id: 36241,
        name: "Diagonal texture layered ring",
        storeId: 280,
        storeName: "스칼렛또",
        originPrice: 24000,
        discountRate: 10,
        salesPrice: 21600,
        status: 2,
        imgUrl: "https://cdntest.amondz.com/product/36241/resize/mainImg/PSI_361645_50.jpeg?v=1612422177055",
      },
      {
        id: 61924,
        name: "Egg and Lines Ring",
        storeId: 1829,
        storeName: "하우스 오브 쉬폰",
        salesPrice: 106000,
        status: 2,
        imgUrl: "https://cdntest.amondz.com/product/61924/resize/mainImg/PSI_642767_50.jpeg?v=1650949918594",
      },
      {
        id: 38214,
        name: "[남우현 착용]S.S 써지컬스틸 체인목걸이 03 (2.5mm 라운드 사각체인)",
        storeId: 1207,
        storeName: "바이시클트로피",
        originPrice: 20000,
        discountRate: 10,
        salesPrice: 18000,
        status: 2,
        imgUrl: "https://cdntest.amondz.com/product/38214/resize/mainImg/PSI_382242_50.jpeg?v=1616002068682",
      },
      {
        id: 25251,
        name: "Silver Chain",
        storeId: 543,
        storeName: "킹크로치",
        salesPrice: 13000,
        status: 2,
        imgUrl: "https://cdntest.amondz.com/product/25251/resize/mainImg/PSI_236360_50.jpeg?v=1595484348294",
      },
      {
        id: 17875,
        name: "[펜타곤 신원 착용] N NEC",
        storeId: 746,
        storeName: "논논",
        originPrice: 25000,
        discountRate: 10,
        salesPrice: 22500,
        status: 2,
        imgUrl: "https://cdntest.amondz.com/product/17875/resize/mainImg/PSI_256490_50.jpeg?v=1598341898466",
      },
      {
        id: 44410,
        name: "이시스 회전 반지",
        storeId: 799,
        storeName: "세크메트",
        salesPrice: 110000,
        status: 2,
        imgUrl: "https://cdntest.amondz.com/product/44410/resize/mainImg/PSI_447914_50.jpeg?v=1624522181728",
      },
      {
        id: 70716,
        name: "버디 큐피드 타투 실버 목걸이-옐로우",
        storeId: 753,
        storeName: "마마카사르",
        salesPrice: 165000,
        status: 2,
        imgUrl: "https://cdntest.amondz.com/product/70716/resize/mainImg/PSI_793534_50.jpeg?v=1666232961754",
      },
      {
        id: 65714,
        name: "Cross pendent necklace",
        storeId: 1348,
        storeName: "이스트인디고",
        salesPrice: 69000,
        status: 2,
        imgUrl: "https://cdntest.amondz.com/product/65714/resize/mainImg/PSI_684455_50.jpeg?v=1654444314703",
      },
    ],
  },
});
