import { SubHomeListStateType } from "@amondz/types";

export const subHomeListMock: SubHomeListStateType = {
  title: "맨/젠더리스",
  widgetList: [
    {
      id: 6700,
      widgetType: 3,
      layoutType: 1,
      mainTitle: null,
      subTitle: null,
      itemList: [
        {
          id: 3360,
          linkType: 2,
          linkUrl: null,
          title: null,
          contents: null,
          mobileBannerImg: {
            width: 3750,
            height: 710,
            imgUrl: "https://cdn.amondz.com/widget/6700/item/banner/4141/resize/WIBAI_11689_50.jpeg?v=1710745076509",
          },
          pcBannerImg: {
            width: 2892,
            height: 366,
            imgUrl: "https://cdn.amondz.com/widget/6700/item/banner/4141/resize/WIBAI_11674_50.jpeg?v=1710742407062",
          },
        },
      ],
    },
    {
      id: 2485,
      widgetType: 3,
      layoutType: 3,
      mainTitle: "CATEGORY",
      subTitle: null,
      itemList: [
        {
          id: null,
          linkType: 1001,
          linkUrl: "https://www.amondz.com/product/list?category=270&subCategory=305",
          title: null,
          contents: null,
          mobileBannerImg: {
            width: 480,
            height: 534,
            imgUrl: "https://cdn.amondz.com/widget/2485/item/banner/2535/resize/WIBAI_11579_50.jpeg?v=1707360612282",
          },
          pcBannerImg: {
            width: 852,
            height: 852,
            imgUrl: "https://cdn.amondz.com/widget/2485/item/banner/2535/resize/WIBAI_11580_50.jpeg?v=1707360612585",
          },
        },
        {
          id: null,
          linkType: 1001,
          linkUrl: "https://www.amondz.com/product/list?category=270&subCategory=304",
          title: null,
          contents: null,
          mobileBannerImg: {
            width: 480,
            height: 534,
            imgUrl: "https://cdn.amondz.com/widget/2485/item/banner/2534/resize/WIBAI_11581_50.jpeg?v=1707360612957",
          },
          pcBannerImg: {
            width: 852,
            height: 852,
            imgUrl: "https://cdn.amondz.com/widget/2485/item/banner/2534/resize/WIBAI_11582_50.jpeg?v=1707360613360",
          },
        },
        {
          id: null,
          linkType: 1001,
          linkUrl: "https://www.amondz.com/product/list?category=270&subCategory=302",
          title: null,
          contents: null,
          mobileBannerImg: {
            width: 480,
            height: 534,
            imgUrl: "https://cdn.amondz.com/widget/2485/item/banner/2536/resize/WIBAI_11583_50.jpeg?v=1707360613697",
          },
          pcBannerImg: {
            width: 852,
            height: 852,
            imgUrl: "https://cdn.amondz.com/widget/2485/item/banner/2536/resize/WIBAI_11584_50.jpeg?v=1707360613983",
          },
        },
        {
          id: null,
          linkType: 1001,
          linkUrl: "https://www.amondz.com/product/list?category=270&subCategory=303,360",
          title: null,
          contents: null,
          mobileBannerImg: {
            width: 480,
            height: 534,
            imgUrl: "https://cdn.amondz.com/widget/2485/item/banner/2537/resize/WIBAI_11585_50.jpeg?v=1707360614278",
          },
          pcBannerImg: {
            width: 852,
            height: 852,
            imgUrl: "https://cdn.amondz.com/widget/2485/item/banner/2537/resize/WIBAI_11586_50.jpeg?v=1707360614631",
          },
        },
      ],
    },
    {
      id: 6907,
      widgetType: 0,
      eventId: null,
      layoutType: 300,
      isProductRecommend: false,
      mainTitle: "위클리 TOP 100",
      subTitle: "",
      buttonText: "VIEW MORE",
      exposureItemCount: 0,
      itemList: [
        {
          id: 92187,
          name: "백야 (白夜)",
          storeId: 2232,
          storeName: "슬윤",
          isTodayDelivery: 0,
          salesPrice: 68000,
          imgUrl: "https://cdn.amondz.com/product/92187/resize/mainImg/PSI_1026977_50.jpeg?v=1691667141097",
          likeFlag: 0,
          status: 2,
          categoryId: 3,
          categoryName: "반지",
          productCategoryList: [
            {
              id: 3,
              name: "반지",
            },
            {
              id: 104,
              name: "커플링",
            },
            {
              id: 106,
              name: "실반지",
            },
            {
              id: 110,
              name: "트위스트링",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 302,
              name: "반지",
            },
            {
              id: 397,
              name: "커플 주얼리",
            },
            {
              id: 399,
              name: "커플링",
            },
            {
              id: 267,
              name: "베스트",
            },
            {
              id: 284,
              name: "반지",
            },
          ],
        },
        {
          id: 75119,
          name: "scudo signature link bracelet [slim]",
          storeId: 1643,
          storeName: "스쿠도",
          isTodayDelivery: 0,
          salesPrice: 248000,
          imgUrl: "https://cdn.amondz.com/product/75119/resize/mainImg/PSI_794992_50.jpeg?v=1666329726542",
          likeFlag: 0,
          status: 2,
          categoryId: 4,
          categoryName: "팔찌",
          productCategoryList: [
            {
              id: 4,
              name: "팔찌",
            },
            {
              id: 131,
              name: "체인팔찌",
            },
            {
              id: 139,
              name: "레이어드팔찌",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 305,
              name: "팔찌",
            },
            {
              id: 267,
              name: "베스트",
            },
            {
              id: 650,
              name: "팔찌&발찌",
            },
          ],
        },
        {
          id: 107208,
          name: "blossom of snow flake stone necklace",
          storeId: 1643,
          storeName: "스쿠도",
          isTodayDelivery: 0,
          salesPrice: 228000,
          imgUrl: "https://cdn.amondz.com/product/107208/resize/mainImg/PSI_1211675_50.jpeg?v=1707184964089",
          likeFlag: 0,
          status: 2,
          categoryId: 2,
          categoryName: "목걸이",
          productCategoryList: [
            {
              id: 2,
              name: "목걸이",
            },
            {
              id: 56,
              name: "토글목걸이",
            },
            {
              id: 85,
              name: "펜던트목걸이",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 304,
              name: "목걸이",
            },
          ],
        },
        {
          id: 59209,
          name: "bay tree 2way necklace [bold]",
          storeId: 1643,
          storeName: "스쿠도",
          isTodayDelivery: 0,
          salesPrice: 428000,
          imgUrl: "https://cdn.amondz.com/product/59209/resize/mainImg/PSI_608711_50.jpeg?v=1647324650257",
          likeFlag: 0,
          status: 2,
          categoryId: 2,
          categoryName: "목걸이",
          productCategoryList: [
            {
              id: 2,
              name: "목걸이",
            },
            {
              id: 63,
              name: "롱목걸이",
            },
            {
              id: 56,
              name: "토글목걸이",
            },
            {
              id: 62,
              name: "체인목걸이",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 304,
              name: "목걸이",
            },
          ],
        },
        {
          id: 91720,
          name: "Lettering  Two Pendant Necklace",
          storeId: 2533,
          storeName: "이지띵즈",
          isTodayDelivery: 0,
          salesPrice: 39000,
          imgUrl: "https://cdn.amondz.com/product/91720/resize/mainImg/PSI_1004616_50.jpeg?v=1689485136736",
          likeFlag: 0,
          status: 2,
          categoryId: 2,
          categoryName: "목걸이",
          productCategoryList: [
            {
              id: 2,
              name: "목걸이",
            },
            {
              id: 85,
              name: "펜던트목걸이",
            },
            {
              id: 86,
              name: "서브펜던트",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 304,
              name: "목걸이",
            },
            {
              id: 267,
              name: "베스트",
            },
            {
              id: 648,
              name: "목걸이&펜던트",
            },
          ],
        },
        {
          id: 60405,
          name: "가든블랙 카렌실버",
          storeId: 1643,
          storeName: "스쿠도",
          isTodayDelivery: 0,
          salesPrice: 48000,
          imgUrl: "https://cdn.amondz.com/product/60405/resize/mainImg/PSI_623005_50.jpeg?v=1649066414546",
          likeFlag: 0,
          status: 2,
          categoryId: 4,
          categoryName: "팔찌",
          productCategoryList: [
            {
              id: 4,
              name: "팔찌",
            },
            {
              id: 130,
              name: "원석팔찌",
            },
            {
              id: 145,
              name: "커플팔찌",
            },
            {
              id: 151,
              name: "비즈팔찌",
            },
            {
              id: 319,
              name: "오늘 출발",
            },
            {
              id: 324,
              name: "팔찌",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 305,
              name: "팔찌",
            },
            {
              id: 267,
              name: "베스트",
            },
            {
              id: 288,
              name: "팔찌",
            },
            {
              id: 650,
              name: "팔찌&발찌",
            },
          ],
        },
        {
          id: 20239,
          name: "Twist Bangle - Simple",
          storeId: 766,
          storeName: "윌리앤더피",
          isTodayDelivery: 0,
          salesPrice: 89000,
          imgUrl: "https://cdn.amondz.com/product/20239/resize/mainImg/PSI_818949_50.jpeg?v=1668676702375",
          likeFlag: 0,
          status: 2,
          categoryId: 4,
          categoryName: "팔찌",
          productCategoryList: [
            {
              id: 4,
              name: "팔찌",
            },
            {
              id: 140,
              name: "뱅글",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 305,
              name: "팔찌",
            },
            {
              id: 397,
              name: "커플 주얼리",
            },
            {
              id: 400,
              name: "팔찌",
            },
          ],
        },
        {
          id: 36936,
          name: "smile cubic chain (925 실버)",
          storeId: 672,
          storeName: "그레이노이즈",
          isTodayDelivery: 0,
          salesPrice: 338000,
          imgUrl: "https://cdn.amondz.com/product/36936/resize/mainImg/PSI_368919_50.jpeg?v=1614067330663",
          likeFlag: 0,
          status: 2,
          categoryId: 4,
          categoryName: "팔찌",
          productCategoryList: [
            {
              id: 4,
              name: "팔찌",
            },
            {
              id: 131,
              name: "체인팔찌",
            },
            {
              id: 145,
              name: "커플팔찌",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 305,
              name: "팔찌",
            },
            {
              id: 397,
              name: "커플 주얼리",
            },
            {
              id: 400,
              name: "팔찌",
            },
          ],
        },
        {
          id: 41901,
          name: "[아몬즈 단독] Crater (14K 포인트 엥게이지 실버 금 반지)",
          storeId: 1285,
          storeName: "퍼스텐",
          isTodayDelivery: 0,
          salesPrice: 79000,
          imgUrl: "https://cdn.amondz.com/product/41901/resize/mainImg/PSI_420144_50.jpeg?v=1620732952067",
          likeFlag: 0,
          status: 2,
          categoryId: 3,
          categoryName: "반지",
          productCategoryList: [
            {
              id: 3,
              name: "반지",
            },
            {
              id: 104,
              name: "커플링",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 302,
              name: "반지",
            },
            {
              id: 266,
              name: "아몬즈 단독",
            },
            {
              id: 272,
              name: "반지",
            },
            {
              id: 124,
              name: "매트링",
            },
            {
              id: 267,
              name: "베스트",
            },
            {
              id: 284,
              name: "반지",
            },
            {
              id: 397,
              name: "커플 주얼리",
            },
            {
              id: 399,
              name: "커플링",
            },
          ],
        },
        {
          id: 69866,
          name: "[강다니엘 착용] SVR-S605 실버92.5 트위스트 실버링",
          storeId: 1986,
          storeName: "쇼브오프",
          isTodayDelivery: 0,
          originPrice: 29800,
          discountRate: 10,
          salesPrice: 26820,
          imgUrl: "https://cdn.amondz.com/product/69866/resize/mainImg/PSI_1208742_50.jpeg?v=1706679351998",
          likeFlag: 0,
          status: 2,
          categoryId: 3,
          categoryName: "반지",
          productCategoryList: [
            {
              id: 3,
              name: "반지",
            },
            {
              id: 104,
              name: "커플링",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 302,
              name: "반지",
            },
            {
              id: 110,
              name: "트위스트링",
            },
            {
              id: 397,
              name: "커플 주얼리",
            },
            {
              id: 399,
              name: "커플링",
            },
            {
              id: 319,
              name: "오늘 출발",
            },
            {
              id: 323,
              name: "반지",
            },
            {
              id: 267,
              name: "베스트",
            },
            {
              id: 284,
              name: "반지",
            },
          ],
        },
      ],
    },
    {
      id: 6908,
      widgetType: 0,
      eventId: null,
      isProductRecommend: false,
      layoutType: 300,
      mainTitle: "WEEKLY BEST ",
      subTitle: "이번주 판매랭킹",
      buttonText: "VIEW MORE",
      exposureItemCount: 0,
      itemList: [
        {
          id: 76454,
          name: "써지컬 블랙 로프 체인 팔찌",
          storeId: 2182,
          storeName: "마즈",
          isTodayDelivery: 1,
          salesPrice: 23000,
          imgUrl: "https://cdn.amondz.com/product/76454/resize/mainImg/PSI_848033_50.jpeg?v=1671170323732",
          likeFlag: 0,
          status: 2,
          categoryId: 4,
          categoryName: "팔찌",
          productCategoryList: [
            {
              id: 4,
              name: "팔찌",
            },
            {
              id: 131,
              name: "체인팔찌",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 305,
              name: "팔찌",
            },
            {
              id: 319,
              name: "오늘 출발",
            },
            {
              id: 324,
              name: "팔찌",
            },
            {
              id: 267,
              name: "베스트",
            },
            {
              id: 650,
              name: "팔찌&발찌",
            },
          ],
        },
        {
          id: 95411,
          name: "써지컬 커브 체인 두 줄 팔찌",
          storeId: 2182,
          storeName: "마즈",
          isTodayDelivery: 0,
          salesPrice: 23000,
          imgUrl: "https://cdn.amondz.com/product/95411/resize/mainImg/PSI_1075374_50.jpeg?v=1696476418641",
          likeFlag: 0,
          status: 2,
          categoryId: 4,
          categoryName: "팔찌",
          productCategoryList: [
            {
              id: 4,
              name: "팔찌",
            },
            {
              id: 131,
              name: "체인팔찌",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 305,
              name: "팔찌",
            },
            {
              id: 139,
              name: "레이어드팔찌",
            },
            {
              id: 145,
              name: "커플팔찌",
            },
            {
              id: 319,
              name: "오늘 출발",
            },
            {
              id: 324,
              name: "팔찌",
            },
            {
              id: 267,
              name: "베스트",
            },
            {
              id: 650,
              name: "팔찌&발찌",
            },
          ],
        },
        {
          id: 73068,
          name: "Bs-368 925실버 데일리 체인팔찌",
          storeId: 2097,
          storeName: "베벨로",
          isTodayDelivery: 1,
          salesPrice: 48000,
          imgUrl: "https://cdn.amondz.com/product/73068/resize/mainImg/PSI_804985_50.jpeg?v=1667370811111",
          likeFlag: 0,
          status: 2,
          categoryId: 4,
          categoryName: "팔찌",
          productCategoryList: [
            {
              id: 4,
              name: "팔찌",
            },
            {
              id: 131,
              name: "체인팔찌",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 305,
              name: "팔찌",
            },
            {
              id: 267,
              name: "베스트",
            },
            {
              id: 650,
              name: "팔찌&발찌",
            },
          ],
        },
        {
          id: 101608,
          name: "[아몬즈 단독] shell pearl blue karen beads",
          storeId: 1643,
          storeName: "스쿠도",
          isTodayDelivery: 0,
          salesPrice: 48000,
          imgUrl: "https://cdn.amondz.com/product/101608/resize/mainImg/PSI_1137235_50.jpeg?v=1700795060462",
          likeFlag: 0,
          status: 2,
          categoryId: 4,
          categoryName: "팔찌",
          productCategoryList: [
            {
              id: 4,
              name: "팔찌",
            },
            {
              id: 128,
              name: "진주팔찌",
            },
            {
              id: 130,
              name: "원석팔찌",
            },
            {
              id: 151,
              name: "비즈팔찌",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 305,
              name: "팔찌",
            },
            {
              id: 266,
              name: "아몬즈 단독",
            },
            {
              id: 276,
              name: "팔찌",
            },
            {
              id: 397,
              name: "커플 주얼리",
            },
            {
              id: 400,
              name: "팔찌",
            },
            {
              id: 616,
              name: "진주",
            },
            {
              id: 618,
              name: "#비정형",
            },
            {
              id: 622,
              name: "#플라워",
            },
            {
              id: 267,
              name: "베스트",
            },
            {
              id: 650,
              name: "팔찌&발찌",
            },
          ],
        },
        {
          id: 88327,
          name: "깊고, 푸른 바다",
          storeId: 2313,
          storeName: "마노서울",
          isTodayDelivery: 0,
          salesPrice: 140000,
          imgUrl: "https://cdn.amondz.com/product/88327/resize/mainImg/PSI_960795_50.jpeg?v=1685080156998",
          likeFlag: 0,
          status: 2,
          categoryId: 3,
          categoryName: "반지",
          productCategoryList: [
            {
              id: 3,
              name: "반지",
            },
            {
              id: 104,
              name: "커플링",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 302,
              name: "반지",
            },
            {
              id: 91,
              name: "원석반지",
            },
            {
              id: 108,
              name: "볼드링",
            },
            {
              id: 607,
              name: "다이아몬드",
            },
            {
              id: 609,
              name: "랩그로운",
            },
            {
              id: 267,
              name: "베스트",
            },
            {
              id: 284,
              name: "반지",
            },
          ],
        },
        {
          id: 38555,
          name: "[민주, 남윤수 착용]RE-자전거체인팔찌(발찌) 03",
          storeId: 1207,
          storeName: "바이시클트로피",
          isTodayDelivery: 0,
          originPrice: 20000,
          discountRate: 10,
          salesPrice: 18000,
          imgUrl: "https://cdn.amondz.com/product/38555/resize/mainImg/PSI_385017_50.jpeg?v=1616348417820",
          likeFlag: 0,
          status: 2,
          categoryId: 4,
          categoryName: "팔찌",
          productCategoryList: [
            {
              id: 4,
              name: "팔찌",
            },
            {
              id: 131,
              name: "체인팔찌",
            },
            {
              id: 145,
              name: "커플팔찌",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 305,
              name: "팔찌",
            },
            {
              id: 397,
              name: "커플 주얼리",
            },
            {
              id: 400,
              name: "팔찌",
            },
            {
              id: 7,
              name: "발찌",
            },
            {
              id: 167,
              name: "체인발찌",
            },
          ],
        },
        {
          id: 92186,
          name: "작은빛 [각인가능]",
          storeId: 2232,
          storeName: "슬윤",
          isTodayDelivery: 0,
          salesPrice: 58000,
          imgUrl: "https://cdn.amondz.com/product/92186/resize/mainImg/PSI_1049018_50.jpeg?v=1693470271494",
          likeFlag: 0,
          status: 2,
          categoryId: 3,
          categoryName: "반지",
          productCategoryList: [
            {
              id: 3,
              name: "반지",
            },
            {
              id: 104,
              name: "커플링",
            },
            {
              id: 106,
              name: "실반지",
            },
            {
              id: 98,
              name: "멜팅링",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 302,
              name: "반지",
            },
          ],
        },
        {
          id: 16270,
          name: "나를 소중하게:쉐리-모아 엠보씽 실버 커플팔찌 남녀공용",
          storeId: 697,
          storeName: "통쉐",
          isTodayDelivery: 0,
          salesPrice: 62000,
          imgUrl: "https://cdn.amondz.com/product/16270/resize/mainImg/PSI_567877_50.jpeg?v=1641737909823",
          likeFlag: 0,
          status: 2,
          categoryId: 4,
          categoryName: "팔찌",
          productCategoryList: [
            {
              id: 4,
              name: "팔찌",
            },
            {
              id: 145,
              name: "커플팔찌",
            },
            {
              id: 150,
              name: "펜던트팔찌",
            },
            {
              id: 397,
              name: "커플 주얼리",
            },
            {
              id: 400,
              name: "팔찌",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 305,
              name: "팔찌",
            },
          ],
        },
        {
          id: 99204,
          name: "섬운 반지",
          storeId: 2566,
          storeName: "조호",
          isTodayDelivery: 0,
          salesPrice: 31000,
          imgUrl: "https://cdn.amondz.com/product/99204/resize/mainImg/PSI_1104387_50.jpeg?v=1698754051542",
          likeFlag: 0,
          status: 2,
          categoryId: 3,
          categoryName: "반지",
          productCategoryList: [
            {
              id: 3,
              name: "반지",
            },
            {
              id: 100,
              name: "애끼반지",
            },
            {
              id: 98,
              name: "멜팅링",
            },
            {
              id: 106,
              name: "실반지",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 302,
              name: "반지",
            },
          ],
        },
        {
          id: 92779,
          name: "조영 (照映) [각인가능]",
          storeId: 2232,
          storeName: "슬윤",
          isTodayDelivery: 0,
          salesPrice: 68900,
          imgUrl: "https://cdn.amondz.com/product/92779/resize/mainImg/PSI_1175340_50.jpeg?v=1703821575705",
          likeFlag: 0,
          status: 2,
          categoryId: 3,
          categoryName: "반지",
          productCategoryList: [
            {
              id: 3,
              name: "반지",
            },
            {
              id: 108,
              name: "볼드링",
            },
            {
              id: 113,
              name: "각인반지",
            },
            {
              id: 104,
              name: "커플링",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 302,
              name: "반지",
            },
          ],
        },
      ],
    },
    {
      id: 6912,
      widgetType: 0,
      eventId: null,
      isProductRecommend: false,
      layoutType: 0,
      mainTitle: "HOT & NEW",
      subTitle: "반응이 뜨거운 신상품만 모았어요",
      buttonText: "VIEW MORE",
      exposureItemCount: 4,
      itemList: [
        {
          id: 106725,
          name: "[Silver925]어글리 크롬 오픈링",
          storeId: 2633,
          storeName: "고오리",
          isTodayDelivery: 0,
          originPrice: 29750,
          discountRate: 20,
          salesPrice: 23800,
          imgUrl: "https://cdn.amondz.com/product/106725/resize/mainImg/PSI_1209320_50.jpeg?v=1706752685213",
          likeFlag: 0,
          status: 2,
          categoryId: 3,
          categoryName: "반지",
          productCategoryList: [
            {
              id: 3,
              name: "반지",
            },
            {
              id: 98,
              name: "멜팅링",
            },
            {
              id: 101,
              name: "오픈링",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 302,
              name: "반지",
            },
          ],
        },
        {
          id: 108742,
          name: "HR 301N [ NCT WayV 쿤님 착용]",
          storeId: 2529,
          storeName: "하랑",
          isTodayDelivery: 0,
          salesPrice: 143000,
          imgUrl: "https://cdn.amondz.com/product/108742/resize/mainImg/PSI_1232609_50.jpeg?v=1709095347944",
          likeFlag: 0,
          status: 2,
          categoryId: 2,
          categoryName: "목걸이",
          productCategoryList: [
            {
              id: 2,
              name: "목걸이",
            },
            {
              id: 59,
              name: "원석목걸이",
            },
            {
              id: 62,
              name: "체인목걸이",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 304,
              name: "목걸이",
            },
          ],
        },
        {
          id: 106726,
          name: "[Silver925]후크 체인 행운 팔찌",
          storeId: 2633,
          storeName: "고오리",
          isTodayDelivery: 0,
          originPrice: 41000,
          discountRate: 20,
          salesPrice: 32800,
          imgUrl: "https://cdn.amondz.com/product/106726/resize/mainImg/PSI_1208315_50.jpeg?v=1706671734846",
          likeFlag: 0,
          status: 2,
          categoryId: 4,
          categoryName: "팔찌",
          productCategoryList: [
            {
              id: 4,
              name: "팔찌",
            },
            {
              id: 145,
              name: "커플팔찌",
            },
            {
              id: 147,
              name: "토글팔찌",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 305,
              name: "팔찌",
            },
          ],
        },
        {
          id: 111858,
          name: "Column Chain Necklace W",
          storeId: 1518,
          storeName: "어드레스.669",
          isTodayDelivery: 0,
          salesPrice: 104000,
          imgUrl: "https://cdn.amondz.com/product/111858/resize/mainImg/PSI_1272651_50.jpeg?v=1713364118064",
          likeFlag: 0,
          status: 2,
          categoryId: 2,
          categoryName: "목걸이",
          productCategoryList: [
            {
              id: 2,
              name: "목걸이",
            },
            {
              id: 63,
              name: "롱목걸이",
            },
            {
              id: 68,
              name: "레이어드목걸이",
            },
            {
              id: 85,
              name: "펜던트목걸이",
            },
          ],
        },
      ],
    },
    {
      id: 6909,
      widgetType: 0,
      eventId: null,
      layoutType: 300,
      isProductRecommend: false,
      mainTitle: "이번주 많은 사랑을 받았어요!",
      subTitle: "주간 좋아요 수 BEST",
      buttonText: "VIEW MORE",
      exposureItemCount: 0,
      itemList: [
        {
          id: 104674,
          name: "Leather key ring (red)",
          storeId: 1990,
          storeName: "어센틱모즈",
          isTodayDelivery: 0,
          originPrice: 35000,
          discountRate: 10,
          salesPrice: 31500,
          imgUrl: "https://cdn.amondz.com/product/104674/resize/mainImg/PSI_1175909_50.jpeg?v=1703856541227",
          likeFlag: 0,
          status: 2,
          categoryId: 10,
          categoryName: "기타소품",
          productCategoryList: [
            {
              id: 10,
              name: "기타소품",
            },
            {
              id: 217,
              name: "키링",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 267,
              name: "베스트",
            },
            {
              id: 651,
              name: "기타",
            },
          ],
        },
        {
          id: 109787,
          name: "Clover simple rail ring W (925 silver)",
          storeId: 672,
          storeName: "그레이노이즈",
          isTodayDelivery: 0,
          salesPrice: 68000,
          imgUrl: "https://cdn.amondz.com/product/109787/resize/mainImg/PSI_1246183_50.jpeg?v=1710760536549",
          likeFlag: 0,
          status: 2,
          categoryId: 3,
          categoryName: "반지",
          productCategoryList: [
            {
              id: 3,
              name: "반지",
            },
            {
              id: 124,
              name: "매트링",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 302,
              name: "반지",
            },
          ],
        },
        {
          id: 101608,
          name: "[아몬즈 단독] shell pearl blue karen beads",
          storeId: 1643,
          storeName: "스쿠도",
          isTodayDelivery: 0,
          salesPrice: 48000,
          imgUrl: "https://cdn.amondz.com/product/101608/resize/mainImg/PSI_1137235_50.jpeg?v=1700795060462",
          likeFlag: 0,
          status: 2,
          categoryId: 4,
          categoryName: "팔찌",
          productCategoryList: [
            {
              id: 4,
              name: "팔찌",
            },
            {
              id: 128,
              name: "진주팔찌",
            },
            {
              id: 130,
              name: "원석팔찌",
            },
            {
              id: 151,
              name: "비즈팔찌",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 305,
              name: "팔찌",
            },
            {
              id: 266,
              name: "아몬즈 단독",
            },
            {
              id: 276,
              name: "팔찌",
            },
            {
              id: 397,
              name: "커플 주얼리",
            },
            {
              id: 400,
              name: "팔찌",
            },
            {
              id: 616,
              name: "진주",
            },
            {
              id: 618,
              name: "#비정형",
            },
            {
              id: 622,
              name: "#플라워",
            },
            {
              id: 267,
              name: "베스트",
            },
            {
              id: 650,
              name: "팔찌&발찌",
            },
          ],
        },
        {
          id: 91630,
          name: "Dalmatian Gemstone Necklace",
          storeId: 2533,
          storeName: "이지띵즈",
          isTodayDelivery: 0,
          salesPrice: 27000,
          imgUrl: "https://cdn.amondz.com/product/91630/resize/mainImg/PSI_1003425_50.jpeg?v=1689238261448",
          likeFlag: 0,
          status: 2,
          categoryId: 2,
          categoryName: "목걸이",
          productCategoryList: [
            {
              id: 2,
              name: "목걸이",
            },
            {
              id: 55,
              name: "비즈목걸이",
            },
            {
              id: 59,
              name: "원석목걸이",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 304,
              name: "목걸이",
            },
            {
              id: 267,
              name: "베스트",
            },
            {
              id: 648,
              name: "목걸이&펜던트",
            },
          ],
        },
        {
          id: 88327,
          name: "깊고, 푸른 바다",
          storeId: 2313,
          storeName: "마노서울",
          isTodayDelivery: 0,
          salesPrice: 140000,
          imgUrl: "https://cdn.amondz.com/product/88327/resize/mainImg/PSI_960795_50.jpeg?v=1685080156998",
          likeFlag: 0,
          status: 2,
          categoryId: 3,
          categoryName: "반지",
          productCategoryList: [
            {
              id: 3,
              name: "반지",
            },
            {
              id: 104,
              name: "커플링",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 302,
              name: "반지",
            },
            {
              id: 91,
              name: "원석반지",
            },
            {
              id: 108,
              name: "볼드링",
            },
            {
              id: 607,
              name: "다이아몬드",
            },
            {
              id: 609,
              name: "랩그로운",
            },
            {
              id: 267,
              name: "베스트",
            },
            {
              id: 284,
              name: "반지",
            },
          ],
        },
        {
          id: 107217,
          name: "[아몬즈 단독] Ice Pearl green Beads Bracelet",
          storeId: 1643,
          storeName: "스쿠도",
          isTodayDelivery: 0,
          salesPrice: 48000,
          imgUrl: "https://cdn.amondz.com/product/107217/resize/mainImg/PSI_1211760_50.jpeg?v=1707187093789",
          likeFlag: 0,
          status: 2,
          categoryId: 4,
          categoryName: "팔찌",
          productCategoryList: [
            {
              id: 4,
              name: "팔찌",
            },
            {
              id: 130,
              name: "원석팔찌",
            },
            {
              id: 151,
              name: "비즈팔찌",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 305,
              name: "팔찌",
            },
            {
              id: 267,
              name: "베스트",
            },
            {
              id: 650,
              name: "팔찌&발찌",
            },
          ],
        },
        {
          id: 108941,
          name: "1mm Round Snake Bracelet 스네이크 체인 팔찌",
          storeId: 2780,
          storeName: "선데이피치파이",
          isTodayDelivery: 0,
          salesPrice: 24000,
          imgUrl: "https://cdn.amondz.com/product/108941/resize/mainImg/PSI_1234839_50.jpeg?v=1709371259996",
          likeFlag: 0,
          status: 2,
          categoryId: 4,
          categoryName: "팔찌",
          productCategoryList: [
            {
              id: 4,
              name: "팔찌",
            },
            {
              id: 131,
              name: "체인팔찌",
            },
            {
              id: 139,
              name: "레이어드팔찌",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 305,
              name: "팔찌",
            },
            {
              id: 267,
              name: "베스트",
            },
            {
              id: 650,
              name: "팔찌&발찌",
            },
          ],
        },
        {
          id: 73068,
          name: "Bs-368 925실버 데일리 체인팔찌",
          storeId: 2097,
          storeName: "베벨로",
          isTodayDelivery: 1,
          salesPrice: 48000,
          imgUrl: "https://cdn.amondz.com/product/73068/resize/mainImg/PSI_804985_50.jpeg?v=1667370811111",
          likeFlag: 0,
          status: 2,
          categoryId: 4,
          categoryName: "팔찌",
          productCategoryList: [
            {
              id: 4,
              name: "팔찌",
            },
            {
              id: 131,
              name: "체인팔찌",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 305,
              name: "팔찌",
            },
            {
              id: 267,
              name: "베스트",
            },
            {
              id: 650,
              name: "팔찌&발찌",
            },
          ],
        },
        {
          id: 104815,
          name: "스틸 라운드 베이직 실 반지 - 블랙",
          storeId: 2182,
          storeName: "마즈",
          isTodayDelivery: 1,
          salesPrice: 9900,
          imgUrl: "https://cdn.amondz.com/product/104815/resize/mainImg/PSI_1236798_50.jpeg?v=1709697626535",
          likeFlag: 0,
          status: 2,
          categoryId: 3,
          categoryName: "반지",
          productCategoryList: [
            {
              id: 3,
              name: "반지",
            },
            {
              id: 106,
              name: "실반지",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 302,
              name: "반지",
            },
            {
              id: 319,
              name: "오늘 출발",
            },
            {
              id: 323,
              name: "반지",
            },
          ],
        },
        {
          id: 92779,
          name: "조영 (照映) [각인가능]",
          storeId: 2232,
          storeName: "슬윤",
          isTodayDelivery: 0,
          salesPrice: 68900,
          imgUrl: "https://cdn.amondz.com/product/92779/resize/mainImg/PSI_1175340_50.jpeg?v=1703821575705",
          likeFlag: 0,
          status: 2,
          categoryId: 3,
          categoryName: "반지",
          productCategoryList: [
            {
              id: 3,
              name: "반지",
            },
            {
              id: 108,
              name: "볼드링",
            },
            {
              id: 113,
              name: "각인반지",
            },
            {
              id: 104,
              name: "커플링",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 302,
              name: "반지",
            },
          ],
        },
      ],
    },
    {
      id: 6911,
      widgetType: 0,
      eventId: null,
      layoutType: 0,
      isProductRecommend: false,
      mainTitle: "장바구니에 많이 담긴 아이템",
      subTitle: "",
      buttonText: "VIEW MORE",
      exposureItemCount: 0,
      itemList: [
        {
          id: 91603,
          name: "[아몬즈 단독] octagon Ring",
          storeId: 2533,
          storeName: "이지띵즈",
          isTodayDelivery: 0,
          salesPrice: 26000,
          imgUrl: "https://cdn.amondz.com/product/91603/resize/mainImg/PSI_1002857_50.jpeg?v=1689228086252",
          likeFlag: 0,
          status: 2,
          categoryId: 3,
          categoryName: "반지",
          productCategoryList: [
            {
              id: 3,
              name: "반지",
            },
            {
              id: 118,
              name: "비즈반지",
            },
            {
              id: 121,
              name: "더블링",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 302,
              name: "반지",
            },
            {
              id: 266,
              name: "아몬즈 단독",
            },
            {
              id: 272,
              name: "반지",
            },
            {
              id: 267,
              name: "베스트",
            },
            {
              id: 284,
              name: "반지",
            },
          ],
        },
        {
          id: 104674,
          name: "Leather key ring (red)",
          storeId: 1990,
          storeName: "어센틱모즈",
          isTodayDelivery: 0,
          originPrice: 35000,
          discountRate: 10,
          salesPrice: 31500,
          imgUrl: "https://cdn.amondz.com/product/104674/resize/mainImg/PSI_1175909_50.jpeg?v=1703856541227",
          likeFlag: 0,
          status: 2,
          categoryId: 10,
          categoryName: "기타소품",
          productCategoryList: [
            {
              id: 10,
              name: "기타소품",
            },
            {
              id: 217,
              name: "키링",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 267,
              name: "베스트",
            },
            {
              id: 651,
              name: "기타",
            },
          ],
        },
        {
          id: 104815,
          name: "스틸 라운드 베이직 실 반지 - 블랙",
          storeId: 2182,
          storeName: "마즈",
          isTodayDelivery: 1,
          salesPrice: 9900,
          imgUrl: "https://cdn.amondz.com/product/104815/resize/mainImg/PSI_1236798_50.jpeg?v=1709697626535",
          likeFlag: 0,
          status: 2,
          categoryId: 3,
          categoryName: "반지",
          productCategoryList: [
            {
              id: 3,
              name: "반지",
            },
            {
              id: 106,
              name: "실반지",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 302,
              name: "반지",
            },
            {
              id: 319,
              name: "오늘 출발",
            },
            {
              id: 323,
              name: "반지",
            },
          ],
        },
        {
          id: 69866,
          name: "[강다니엘 착용] SVR-S605 실버92.5 트위스트 실버링",
          storeId: 1986,
          storeName: "쇼브오프",
          isTodayDelivery: 0,
          originPrice: 29800,
          discountRate: 10,
          salesPrice: 26820,
          imgUrl: "https://cdn.amondz.com/product/69866/resize/mainImg/PSI_1208742_50.jpeg?v=1706679351998",
          likeFlag: 0,
          status: 2,
          categoryId: 3,
          categoryName: "반지",
          productCategoryList: [
            {
              id: 3,
              name: "반지",
            },
            {
              id: 104,
              name: "커플링",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 302,
              name: "반지",
            },
            {
              id: 110,
              name: "트위스트링",
            },
            {
              id: 397,
              name: "커플 주얼리",
            },
            {
              id: 399,
              name: "커플링",
            },
            {
              id: 319,
              name: "오늘 출발",
            },
            {
              id: 323,
              name: "반지",
            },
            {
              id: 267,
              name: "베스트",
            },
            {
              id: 284,
              name: "반지",
            },
          ],
        },
        {
          id: 91720,
          name: "Lettering  Two Pendant Necklace",
          storeId: 2533,
          storeName: "이지띵즈",
          isTodayDelivery: 0,
          salesPrice: 39000,
          imgUrl: "https://cdn.amondz.com/product/91720/resize/mainImg/PSI_1004616_50.jpeg?v=1689485136736",
          likeFlag: 0,
          status: 2,
          categoryId: 2,
          categoryName: "목걸이",
          productCategoryList: [
            {
              id: 2,
              name: "목걸이",
            },
            {
              id: 85,
              name: "펜던트목걸이",
            },
            {
              id: 86,
              name: "서브펜던트",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 304,
              name: "목걸이",
            },
            {
              id: 267,
              name: "베스트",
            },
            {
              id: 648,
              name: "목걸이&펜던트",
            },
          ],
        },
        {
          id: 92187,
          name: "백야 (白夜)",
          storeId: 2232,
          storeName: "슬윤",
          isTodayDelivery: 0,
          salesPrice: 68000,
          imgUrl: "https://cdn.amondz.com/product/92187/resize/mainImg/PSI_1026977_50.jpeg?v=1691667141097",
          likeFlag: 0,
          status: 2,
          categoryId: 3,
          categoryName: "반지",
          productCategoryList: [
            {
              id: 3,
              name: "반지",
            },
            {
              id: 104,
              name: "커플링",
            },
            {
              id: 106,
              name: "실반지",
            },
            {
              id: 110,
              name: "트위스트링",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 302,
              name: "반지",
            },
            {
              id: 397,
              name: "커플 주얼리",
            },
            {
              id: 399,
              name: "커플링",
            },
            {
              id: 267,
              name: "베스트",
            },
            {
              id: 284,
              name: "반지",
            },
          ],
        },
        {
          id: 91630,
          name: "Dalmatian Gemstone Necklace",
          storeId: 2533,
          storeName: "이지띵즈",
          isTodayDelivery: 0,
          salesPrice: 27000,
          imgUrl: "https://cdn.amondz.com/product/91630/resize/mainImg/PSI_1003425_50.jpeg?v=1689238261448",
          likeFlag: 0,
          status: 2,
          categoryId: 2,
          categoryName: "목걸이",
          productCategoryList: [
            {
              id: 2,
              name: "목걸이",
            },
            {
              id: 55,
              name: "비즈목걸이",
            },
            {
              id: 59,
              name: "원석목걸이",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 304,
              name: "목걸이",
            },
            {
              id: 267,
              name: "베스트",
            },
            {
              id: 648,
              name: "목걸이&펜던트",
            },
          ],
        },
        {
          id: 43003,
          name: "LUMIERE 2 RING GOLD / SILVER",
          storeId: 857,
          storeName: "레끌라",
          isTodayDelivery: 1,
          salesPrice: 150000,
          imgUrl: "https://cdn.amondz.com/product/43003/resize/mainImg/PSI_793758_50.jpeg?v=1666244710502",
          likeFlag: 0,
          status: 2,
          categoryId: 3,
          categoryName: "반지",
          productCategoryList: [
            {
              id: 3,
              name: "반지",
            },
            {
              id: 108,
              name: "볼드링",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 302,
              name: "반지",
            },
            {
              id: 319,
              name: "오늘 출발",
            },
            {
              id: 323,
              name: "반지",
            },
          ],
        },
        {
          id: 60405,
          name: "가든블랙 카렌실버",
          storeId: 1643,
          storeName: "스쿠도",
          isTodayDelivery: 0,
          salesPrice: 48000,
          imgUrl: "https://cdn.amondz.com/product/60405/resize/mainImg/PSI_623005_50.jpeg?v=1649066414546",
          likeFlag: 0,
          status: 2,
          categoryId: 4,
          categoryName: "팔찌",
          productCategoryList: [
            {
              id: 4,
              name: "팔찌",
            },
            {
              id: 130,
              name: "원석팔찌",
            },
            {
              id: 145,
              name: "커플팔찌",
            },
            {
              id: 151,
              name: "비즈팔찌",
            },
            {
              id: 319,
              name: "오늘 출발",
            },
            {
              id: 324,
              name: "팔찌",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 305,
              name: "팔찌",
            },
            {
              id: 267,
              name: "베스트",
            },
            {
              id: 288,
              name: "팔찌",
            },
            {
              id: 650,
              name: "팔찌&발찌",
            },
          ],
        },
        {
          id: 73068,
          name: "Bs-368 925실버 데일리 체인팔찌",
          storeId: 2097,
          storeName: "베벨로",
          isTodayDelivery: 1,
          salesPrice: 48000,
          imgUrl: "https://cdn.amondz.com/product/73068/resize/mainImg/PSI_804985_50.jpeg?v=1667370811111",
          likeFlag: 0,
          status: 2,
          categoryId: 4,
          categoryName: "팔찌",
          productCategoryList: [
            {
              id: 4,
              name: "팔찌",
            },
            {
              id: 131,
              name: "체인팔찌",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 305,
              name: "팔찌",
            },
            {
              id: 267,
              name: "베스트",
            },
            {
              id: 650,
              name: "팔찌&발찌",
            },
          ],
        },
        {
          id: 105150,
          name: "실버 큐빅 라인 오픈링",
          storeId: 2633,
          storeName: "고오리",
          isTodayDelivery: 0,
          originPrice: 41000,
          discountRate: 20,
          salesPrice: 32800,
          imgUrl: "https://cdn.amondz.com/product/105150/resize/mainImg/PSI_1184264_50.jpeg?v=1704548231054",
          likeFlag: 0,
          status: 2,
          categoryId: 3,
          categoryName: "반지",
          productCategoryList: [
            {
              id: 3,
              name: "반지",
            },
            {
              id: 104,
              name: "커플링",
            },
            {
              id: 108,
              name: "볼드링",
            },
            {
              id: 101,
              name: "오픈링",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 302,
              name: "반지",
            },
          ],
        },
        {
          id: 20239,
          name: "Twist Bangle - Simple",
          storeId: 766,
          storeName: "윌리앤더피",
          isTodayDelivery: 0,
          salesPrice: 89000,
          imgUrl: "https://cdn.amondz.com/product/20239/resize/mainImg/PSI_818949_50.jpeg?v=1668676702375",
          likeFlag: 0,
          status: 2,
          categoryId: 4,
          categoryName: "팔찌",
          productCategoryList: [
            {
              id: 4,
              name: "팔찌",
            },
            {
              id: 140,
              name: "뱅글",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 305,
              name: "팔찌",
            },
            {
              id: 397,
              name: "커플 주얼리",
            },
            {
              id: 400,
              name: "팔찌",
            },
          ],
        },
      ],
    },
    {
      id: 5252,
      widgetType: 0,
      eventId: null,
      layoutType: 0,
      isProductRecommend: false,
      mainTitle: "여기서 찾아보세요",
      subTitle: "아몬저가 가장 많이 물어본 제품",
      buttonText: "VIEW MORE",
      exposureItemCount: null,
      itemList: [
        {
          id: 91320,
          name: "[아몬즈 단독] Chip Stone Pearl Necklace",
          storeId: 2198,
          storeName: "카키 스튜디오",
          isTodayDelivery: 0,
          salesPrice: 48000,
          imgUrl: "https://cdn.amondz.com/product/91320/resize/mainImg/PSI_1036654_50.jpeg?v=1692695714698",
          likeFlag: 0,
          status: 2,
          categoryId: 2,
          categoryName: "목걸이",
          productCategoryList: [
            {
              id: 2,
              name: "목걸이",
            },
            {
              id: 57,
              name: "진주목걸이",
            },
            {
              id: 59,
              name: "원석목걸이",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 304,
              name: "목걸이",
            },
            {
              id: 266,
              name: "아몬즈 단독",
            },
            {
              id: 275,
              name: "목걸이",
            },
          ],
        },
        {
          id: 76443,
          name: "실버 라인 텍스쳐 링 목걸이",
          storeId: 2182,
          storeName: "마즈",
          isTodayDelivery: 1,
          salesPrice: 110000,
          imgUrl: "https://cdn.amondz.com/product/76443/resize/mainImg/PSI_988771_50.jpeg?v=1687859565535",
          likeFlag: 0,
          status: 2,
          categoryId: 2,
          categoryName: "목걸이",
          productCategoryList: [
            {
              id: 2,
              name: "목걸이",
            },
            {
              id: 62,
              name: "체인목걸이",
            },
            {
              id: 85,
              name: "펜던트목걸이",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 304,
              name: "목걸이",
            },
            {
              id: 319,
              name: "오늘 출발",
            },
            {
              id: 320,
              name: "목걸이",
            },
          ],
        },
        {
          id: 75570,
          name: "[몬스타엑스 아이엠] SVB-198 레더&오닉스 팔찌세트",
          storeId: 1986,
          storeName: "쇼브오프",
          isTodayDelivery: 0,
          originPrice: 26000,
          discountRate: 20,
          salesPrice: 20800,
          imgUrl: "https://cdn.amondz.com/product/75570/resize/mainImg/PSI_1230008_50.jpeg?v=1708854511031",
          likeFlag: 0,
          status: 2,
          categoryId: 4,
          categoryName: "팔찌",
          productCategoryList: [
            {
              id: 4,
              name: "팔찌",
            },
            {
              id: 130,
              name: "원석팔찌",
            },
            {
              id: 151,
              name: "비즈팔찌",
            },
            {
              id: 139,
              name: "레이어드팔찌",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 305,
              name: "팔찌",
            },
          ],
        },
        {
          id: 57321,
          name: "#5409",
          storeId: 684,
          storeName: "쿼르코어",
          isTodayDelivery: 0,
          originPrice: 43000,
          discountRate: 15,
          salesPrice: 36550,
          imgUrl: "https://cdn.amondz.com/product/57321/resize/mainImg/PSI_587676_50.jpeg?v=1644890885971",
          likeFlag: 0,
          status: 2,
          categoryId: 4,
          categoryName: "팔찌",
          productCategoryList: [
            {
              id: 4,
              name: "팔찌",
            },
            {
              id: 140,
              name: "뱅글",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 305,
              name: "팔찌",
            },
          ],
        },
        {
          id: 82726,
          name: "[아몬즈 단독] dolphin karen string bracelet [green]",
          storeId: 1643,
          storeName: "스쿠도",
          isTodayDelivery: 0,
          originPrice: 52000,
          discountRate: 5,
          salesPrice: 49400,
          imgUrl: "https://cdn.amondz.com/product/82726/resize/mainImg/PSI_888725_50.jpeg?v=1676604193746",
          likeFlag: 0,
          status: 2,
          categoryId: 4,
          categoryName: "팔찌",
          productCategoryList: [
            {
              id: 4,
              name: "팔찌",
            },
            {
              id: 152,
              name: "패브릭팔찌",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 305,
              name: "팔찌",
            },
            {
              id: 266,
              name: "아몬즈 단독",
            },
            {
              id: 276,
              name: "팔찌",
            },
          ],
        },
        {
          id: 73264,
          name: "핑퐁",
          storeId: 1854,
          storeName: "애끼",
          isTodayDelivery: 0,
          salesPrice: 65000,
          imgUrl: "https://cdn.amondz.com/product/73264/resize/mainImg/PSI_770685_50.jpeg?v=1663661477097",
          likeFlag: 0,
          status: 2,
          categoryId: 4,
          categoryName: "팔찌",
          productCategoryList: [
            {
              id: 4,
              name: "팔찌",
            },
            {
              id: 130,
              name: "원석팔찌",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 305,
              name: "팔찌",
            },
          ],
        },
        {
          id: 80406,
          name: "[NCT 지성 착용] WAVE NECKLACE",
          storeId: 2177,
          storeName: "이스트아르고",
          isTodayDelivery: 0,
          salesPrice: 57000,
          imgUrl: "https://cdn.amondz.com/product/80406/resize/mainImg/PSI_969010_50.jpeg?v=1686103650649",
          likeFlag: 0,
          status: 2,
          categoryId: 2,
          categoryName: "목걸이",
          productCategoryList: [
            {
              id: 2,
              name: "목걸이",
            },
            {
              id: 55,
              name: "비즈목걸이",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 304,
              name: "목걸이",
            },
          ],
        },
        {
          id: 73068,
          name: "Bs-368 925실버 데일리 체인팔찌",
          storeId: 2097,
          storeName: "베벨로",
          isTodayDelivery: 1,
          salesPrice: 48000,
          imgUrl: "https://cdn.amondz.com/product/73068/resize/mainImg/PSI_804985_50.jpeg?v=1667370811111",
          likeFlag: 0,
          status: 2,
          categoryId: 4,
          categoryName: "팔찌",
          productCategoryList: [
            {
              id: 4,
              name: "팔찌",
            },
            {
              id: 131,
              name: "체인팔찌",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 305,
              name: "팔찌",
            },
            {
              id: 267,
              name: "베스트",
            },
            {
              id: 650,
              name: "팔찌&발찌",
            },
          ],
        },
        {
          id: 76437,
          name: "마그넷 볼륨 체인 팔찌",
          storeId: 2182,
          storeName: "마즈",
          isTodayDelivery: 1,
          salesPrice: 120000,
          imgUrl: "https://cdn.amondz.com/product/76437/resize/mainImg/PSI_848178_50.jpeg?v=1671173181140",
          likeFlag: 0,
          status: 2,
          categoryId: 4,
          categoryName: "팔찌",
          productCategoryList: [
            {
              id: 4,
              name: "팔찌",
            },
            {
              id: 131,
              name: "체인팔찌",
            },
            {
              id: 145,
              name: "커플팔찌",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 305,
              name: "팔찌",
            },
            {
              id: 319,
              name: "오늘 출발",
            },
            {
              id: 324,
              name: "팔찌",
            },
          ],
        },
        {
          id: 54606,
          name: "Sole Pendant 001 [Jordan1](S)",
          storeId: 1646,
          storeName: "바운트",
          isTodayDelivery: 0,
          salesPrice: 36000,
          imgUrl: "https://cdn.amondz.com/product/54606/resize/mainImg/PSI_564418_50.jpeg?v=1641368023586",
          likeFlag: 0,
          status: 2,
          categoryId: 5,
          categoryName: "펜던트",
          productCategoryList: [
            {
              id: 5,
              name: "펜던트",
            },
            {
              id: 153,
              name: "고리형펜던트",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 2,
              name: "목걸이",
            },
            {
              id: 85,
              name: "펜던트목걸이",
            },
            {
              id: 304,
              name: "목걸이",
            },
          ],
        },
        {
          id: 51390,
          name: "925 실버 은 팔찌 SIB-FW2ST 꼬임 팔찌",
          storeId: 1019,
          storeName: "실버태그",
          isTodayDelivery: 0,
          originPrice: 32000,
          discountRate: 20,
          salesPrice: 25600,
          imgUrl: "https://cdn.amondz.com/product/51390/resize/mainImg/PSI_522973_50.jpeg?v=1636770471733",
          likeFlag: 0,
          status: 2,
          categoryId: 4,
          categoryName: "팔찌",
          productCategoryList: [
            {
              id: 4,
              name: "팔찌",
            },
            {
              id: 131,
              name: "체인팔찌",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 305,
              name: "팔찌",
            },
          ],
        },
      ],
    },
    {
      id: 2857,
      widgetType: 0,
      eventId: null,
      layoutType: 301,
      isProductRecommend: false,
      mainTitle: "당신이 본 그 상품",
      subTitle: "SNS에서 반응 좋은 아이템",
      buttonText: "VIEW MORE",
      exposureItemCount: 10,
      itemList: [
        {
          id: 68295,
          name: "#9307 silver92.5",
          storeId: 684,
          storeName: "쿼르코어",
          isTodayDelivery: 0,
          originPrice: 33000,
          discountRate: 10,
          salesPrice: 29700,
          imgUrl: "https://cdn.amondz.com/product/68295/resize/mainImg/PSI_711783_50.jpeg?v=1656471251538",
          likeFlag: 0,
          status: 2,
          categoryId: 4,
          categoryName: "팔찌",
          productCategoryList: [
            {
              id: 4,
              name: "팔찌",
            },
            {
              id: 131,
              name: "체인팔찌",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 305,
              name: "팔찌",
            },
          ],
        },
        {
          id: 81056,
          name: "[스트레이키즈 현진 착용] necklace MORSE (비즈 실목걸이)",
          storeId: 2258,
          storeName: "이더섬",
          isTodayDelivery: 0,
          salesPrice: 59000,
          imgUrl: "https://cdn.amondz.com/product/81056/resize/mainImg/PSI_950774_50.jpeg?v=1684127437048",
          likeFlag: 0,
          status: 2,
          categoryId: 2,
          categoryName: "목걸이",
          productCategoryList: [
            {
              id: 2,
              name: "목걸이",
            },
            {
              id: 55,
              name: "비즈목걸이",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 304,
              name: "목걸이",
            },
          ],
        },
        {
          id: 83249,
          name: "AnTi poP beads bracelet(blue)",
          storeId: 1990,
          storeName: "어센틱모즈",
          isTodayDelivery: 0,
          originPrice: 45000,
          discountRate: 10,
          salesPrice: 40500,
          imgUrl: "https://cdn.amondz.com/product/83249/resize/mainImg/PSI_898738_50.jpeg?v=1678184794843",
          likeFlag: 0,
          status: 2,
          categoryId: 4,
          categoryName: "팔찌",
          productCategoryList: [
            {
              id: 4,
              name: "팔찌",
            },
            {
              id: 150,
              name: "펜던트팔찌",
            },
            {
              id: 151,
              name: "비즈팔찌",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 305,
              name: "팔찌",
            },
            {
              id: 147,
              name: "토글팔찌",
            },
          ],
        },
        {
          id: 64801,
          name: "#3403",
          storeId: 684,
          storeName: "쿼르코어",
          isTodayDelivery: 0,
          originPrice: 41000,
          discountRate: 10,
          salesPrice: 36900,
          imgUrl: "https://cdn.amondz.com/product/64801/resize/mainImg/PSI_672658_50.jpeg?v=1653460702811",
          likeFlag: 0,
          status: 2,
          categoryId: 4,
          categoryName: "팔찌",
          productCategoryList: [
            {
              id: 4,
              name: "팔찌",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 305,
              name: "팔찌",
            },
            {
              id: 140,
              name: "뱅글",
            },
          ],
        },
        {
          id: 76419,
          name: "실버 레터링 밴드 반지",
          storeId: 2182,
          storeName: "마즈",
          isTodayDelivery: 1,
          salesPrice: 120000,
          imgUrl: "https://cdn.amondz.com/product/76419/resize/mainImg/PSI_848085_50.jpeg?v=1671171183247",
          likeFlag: 0,
          status: 2,
          categoryId: 3,
          categoryName: "반지",
          productCategoryList: [
            {
              id: 3,
              name: "반지",
            },
            {
              id: 98,
              name: "멜팅링",
            },
            {
              id: 108,
              name: "볼드링",
            },
            {
              id: 113,
              name: "각인반지",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 302,
              name: "반지",
            },
            {
              id: 319,
              name: "오늘 출발",
            },
            {
              id: 323,
              name: "반지",
            },
          ],
        },
        {
          id: 81104,
          name: "bracelet 141 (비즈 실팔찌)",
          storeId: 2258,
          storeName: "이더섬",
          isTodayDelivery: 0,
          salesPrice: 39000,
          imgUrl: "https://cdn.amondz.com/product/81104/resize/mainImg/PSI_866484_50.jpeg?v=1673340959088",
          likeFlag: 0,
          status: 2,
          categoryId: 4,
          categoryName: "팔찌",
          productCategoryList: [
            {
              id: 4,
              name: "팔찌",
            },
            {
              id: 151,
              name: "비즈팔찌",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 305,
              name: "팔찌",
            },
          ],
        },
        {
          id: 54590,
          name: "Dagger & Flower Oldschool Ring",
          storeId: 1646,
          storeName: "바운트",
          isTodayDelivery: 0,
          salesPrice: 75000,
          imgUrl: "https://cdn.amondz.com/product/54590/resize/mainImg/PSI_610251_50.jpeg?v=1647485491613",
          likeFlag: 0,
          status: 2,
          categoryId: 3,
          categoryName: "반지",
          productCategoryList: [
            {
              id: 3,
              name: "반지",
            },
            {
              id: 104,
              name: "커플링",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 302,
              name: "반지",
            },
            {
              id: 397,
              name: "커플 주얼리",
            },
            {
              id: 399,
              name: "커플링",
            },
          ],
        },
        {
          id: 58694,
          name: "[아몬즈 단독] HAIR LINE RING #1",
          storeId: 1518,
          storeName: "어드레스.669",
          isTodayDelivery: 1,
          salesPrice: 108000,
          imgUrl: "https://cdn.amondz.com/product/58694/resize/mainImg/PSI_602837_50.jpeg?v=1646734479462",
          likeFlag: 0,
          status: 2,
          categoryId: 3,
          categoryName: "반지",
          productCategoryList: [
            {
              id: 3,
              name: "반지",
            },
            {
              id: 104,
              name: "커플링",
            },
            {
              id: 108,
              name: "볼드링",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 302,
              name: "반지",
            },
            {
              id: 319,
              name: "오늘 출발",
            },
            {
              id: 323,
              name: "반지",
            },
            {
              id: 397,
              name: "커플 주얼리",
            },
            {
              id: 399,
              name: "커플링",
            },
            {
              id: 266,
              name: "아몬즈 단독",
            },
            {
              id: 272,
              name: "반지",
            },
          ],
        },
        {
          id: 83648,
          name: "미네랄 스트링 팔찌",
          storeId: 1854,
          storeName: "애끼",
          isTodayDelivery: 0,
          salesPrice: 78000,
          imgUrl: "https://cdn.amondz.com/product/83648/resize/mainImg/PSI_910338_50.jpeg?v=1679294799364",
          likeFlag: 0,
          status: 2,
          categoryId: 4,
          categoryName: "팔찌",
          productCategoryList: [
            {
              id: 4,
              name: "팔찌",
            },
            {
              id: 152,
              name: "패브릭팔찌",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 305,
              name: "팔찌",
            },
          ],
        },
        {
          id: 75165,
          name: "Rune Mark Ring (1EA)",
          storeId: 2114,
          storeName: "마르스마크",
          isTodayDelivery: 0,
          salesPrice: 95000,
          imgUrl: "https://cdn.amondz.com/product/75165/resize/mainImg/PSI_795335_50.jpeg?v=1666334426652",
          likeFlag: 0,
          status: 2,
          categoryId: 3,
          categoryName: "반지",
          productCategoryList: [
            {
              id: 3,
              name: "반지",
            },
            {
              id: 108,
              name: "볼드링",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 302,
              name: "반지",
            },
          ],
        },
      ],
      topImage: {
        width: 1125,
        height: 1260,
        url: "https://cdn.amondz.com/widget/2857/resize/top/WAI_2433_50.jpeg?v=1686556821098",
      },
    },
    {
      id: 3329,
      widgetType: 0,
      eventId: null,
      layoutType: 300,
      isProductRecommend: false,
      mainTitle: "AMONDZ ONLY",
      subTitle: "아몬즈 단독 상품",
      buttonText: "VIEW MORE",
      exposureItemCount: 10,
      itemList: [
        {
          id: 90062,
          name: "[아몬즈 단독] 1007 Be one bracelet",
          storeId: 1419,
          storeName: "오드콜렛",
          isTodayDelivery: 0,
          salesPrice: 149000,
          imgUrl: "https://cdn.amondz.com/product/90062/resize/mainImg/PSI_1003862_50.jpeg?v=1689300164941",
          likeFlag: 0,
          status: 2,
          categoryId: 4,
          categoryName: "팔찌",
          productCategoryList: [
            {
              id: 4,
              name: "팔찌",
            },
            {
              id: 140,
              name: "뱅글",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 305,
              name: "팔찌",
            },
            {
              id: 397,
              name: "커플 주얼리",
            },
            {
              id: 400,
              name: "팔찌",
            },
            {
              id: 266,
              name: "아몬즈 단독",
            },
            {
              id: 276,
              name: "팔찌",
            },
          ],
        },
        {
          id: 101607,
          name: "[아몬즈 단독] slim garden dust grey pearl necklace 6mm",
          storeId: 1643,
          storeName: "스쿠도",
          isTodayDelivery: 0,
          salesPrice: 148000,
          imgUrl: "https://cdn.amondz.com/product/101607/resize/mainImg/PSI_1137227_50.jpeg?v=1700794055263",
          likeFlag: 0,
          status: 2,
          categoryId: 2,
          categoryName: "목걸이",
          productCategoryList: [
            {
              id: 2,
              name: "목걸이",
            },
            {
              id: 57,
              name: "진주목걸이",
            },
            {
              id: 62,
              name: "체인목걸이",
            },
            {
              id: 63,
              name: "롱목걸이",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 304,
              name: "목걸이",
            },
            {
              id: 266,
              name: "아몬즈 단독",
            },
            {
              id: 275,
              name: "목걸이",
            },
            {
              id: 397,
              name: "커플 주얼리",
            },
            {
              id: 398,
              name: "목걸이",
            },
            {
              id: 616,
              name: "진주",
            },
            {
              id: 618,
              name: "#비정형",
            },
            {
              id: 619,
              name: "#그레이",
            },
            {
              id: 622,
              name: "#플라워",
            },
          ],
        },
        {
          id: 58694,
          name: "[아몬즈 단독] HAIR LINE RING #1",
          storeId: 1518,
          storeName: "어드레스.669",
          isTodayDelivery: 1,
          salesPrice: 108000,
          imgUrl: "https://cdn.amondz.com/product/58694/resize/mainImg/PSI_602837_50.jpeg?v=1646734479462",
          likeFlag: 0,
          status: 2,
          categoryId: 3,
          categoryName: "반지",
          productCategoryList: [
            {
              id: 3,
              name: "반지",
            },
            {
              id: 104,
              name: "커플링",
            },
            {
              id: 108,
              name: "볼드링",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 302,
              name: "반지",
            },
            {
              id: 319,
              name: "오늘 출발",
            },
            {
              id: 323,
              name: "반지",
            },
            {
              id: 397,
              name: "커플 주얼리",
            },
            {
              id: 399,
              name: "커플링",
            },
            {
              id: 266,
              name: "아몬즈 단독",
            },
            {
              id: 272,
              name: "반지",
            },
          ],
        },
        {
          id: 82736,
          name: "[아몬즈 단독] france karen string bracelet [navy]",
          storeId: 1643,
          storeName: "스쿠도",
          isTodayDelivery: 0,
          originPrice: 52000,
          discountRate: 5,
          salesPrice: 49400,
          imgUrl: "https://cdn.amondz.com/product/82736/resize/mainImg/PSI_888810_50.jpeg?v=1676609184873",
          likeFlag: 0,
          status: 2,
          categoryId: 4,
          categoryName: "팔찌",
          productCategoryList: [
            {
              id: 4,
              name: "팔찌",
            },
            {
              id: 152,
              name: "패브릭팔찌",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 305,
              name: "팔찌",
            },
            {
              id: 266,
              name: "아몬즈 단독",
            },
            {
              id: 276,
              name: "팔찌",
            },
          ],
        },
        {
          id: 83248,
          name: "[아몬즈 단독] 하트 반지 Fall in love",
          storeId: 1159,
          storeName: "인스 스튜디오",
          isTodayDelivery: 0,
          salesPrice: 68000,
          imgUrl: "https://cdn.amondz.com/product/83248/resize/mainImg/PSI_897156_50.jpeg?v=1677937882608",
          likeFlag: 0,
          status: 2,
          categoryId: 3,
          categoryName: "반지",
          productCategoryList: [
            {
              id: 3,
              name: "반지",
            },
            {
              id: 100,
              name: "애끼반지",
            },
            {
              id: 106,
              name: "실반지",
            },
            {
              id: 104,
              name: "커플링",
            },
            {
              id: 266,
              name: "아몬즈 단독",
            },
            {
              id: 272,
              name: "반지",
            },
          ],
        },
        {
          id: 82726,
          name: "[아몬즈 단독] dolphin karen string bracelet [green]",
          storeId: 1643,
          storeName: "스쿠도",
          isTodayDelivery: 0,
          originPrice: 52000,
          discountRate: 5,
          salesPrice: 49400,
          imgUrl: "https://cdn.amondz.com/product/82726/resize/mainImg/PSI_888725_50.jpeg?v=1676604193746",
          likeFlag: 0,
          status: 2,
          categoryId: 4,
          categoryName: "팔찌",
          productCategoryList: [
            {
              id: 4,
              name: "팔찌",
            },
            {
              id: 152,
              name: "패브릭팔찌",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 305,
              name: "팔찌",
            },
            {
              id: 266,
              name: "아몬즈 단독",
            },
            {
              id: 276,
              name: "팔찌",
            },
          ],
        },
        {
          id: 102484,
          name: "[아몬즈 단독] Snowball 이니셜 가죽 팔찌 레드",
          storeId: 1159,
          storeName: "인스 스튜디오",
          isTodayDelivery: 0,
          salesPrice: 51000,
          imgUrl: "https://cdn.amondz.com/product/102484/resize/mainImg/PSI_1146445_50.jpeg?v=1701622203799",
          likeFlag: 0,
          status: 2,
          categoryId: 4,
          categoryName: "팔찌",
          productCategoryList: [
            {
              id: 4,
              name: "팔찌",
            },
            {
              id: 145,
              name: "커플팔찌",
            },
            {
              id: 147,
              name: "토글팔찌",
            },
            {
              id: 150,
              name: "펜던트팔찌",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 305,
              name: "팔찌",
            },
            {
              id: 266,
              name: "아몬즈 단독",
            },
            {
              id: 276,
              name: "팔찌",
            },
          ],
        },
        {
          id: 89215,
          name: "[아몬즈 단독] STING DIAGONAL EAR CUFF",
          storeId: 2177,
          storeName: "이스트아르고",
          isTodayDelivery: 0,
          salesPrice: 50000,
          imgUrl: "https://cdn.amondz.com/product/89215/resize/mainImg/PSI_972959_50.jpeg?v=1686473889330",
          likeFlag: 0,
          status: 2,
          categoryId: 8,
          categoryName: "이어커프",
          productCategoryList: [
            {
              id: 8,
              name: "이어커프",
            },
            {
              id: 191,
              name: "투웨이이어커프",
            },
            {
              id: 266,
              name: "아몬즈 단독",
            },
            {
              id: 273,
              name: "이어커프",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 303,
              name: "이어커프",
            },
          ],
        },
        {
          id: 75084,
          name: "[아몬즈 단독] Fleurdelis Shield Bracelet",
          storeId: 2114,
          storeName: "마르스마크",
          isTodayDelivery: 0,
          salesPrice: 450000,
          imgUrl: "https://cdn.amondz.com/product/75084/resize/mainImg/PSI_794464_50.jpeg?v=1666319284975",
          likeFlag: 0,
          status: 2,
          categoryId: 4,
          categoryName: "팔찌",
          productCategoryList: [
            {
              id: 4,
              name: "팔찌",
            },
            {
              id: 131,
              name: "체인팔찌",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 305,
              name: "팔찌",
            },
            {
              id: 266,
              name: "아몬즈 단독",
            },
            {
              id: 276,
              name: "팔찌",
            },
          ],
        },
        {
          id: 58708,
          name: "[아몬즈 단독] HANDLE RING #1",
          storeId: 1518,
          storeName: "어드레스.669",
          isTodayDelivery: 0,
          salesPrice: 122000,
          imgUrl: "https://cdn.amondz.com/product/58708/resize/mainImg/PSI_602950_50.jpeg?v=1646735862187",
          likeFlag: 0,
          status: 2,
          categoryId: 3,
          categoryName: "반지",
          productCategoryList: [
            {
              id: 3,
              name: "반지",
            },
            {
              id: 108,
              name: "볼드링",
            },
            {
              id: 270,
              name: "맨/젠더리스",
            },
            {
              id: 302,
              name: "반지",
            },
            {
              id: 319,
              name: "오늘 출발",
            },
            {
              id: 323,
              name: "반지",
            },
            {
              id: 266,
              name: "아몬즈 단독",
            },
            {
              id: 272,
              name: "반지",
            },
          ],
        },
      ],
    },
    {
      id: 2326,
      widgetType: 3,
      layoutType: 3,
      mainTitle: "새로운 고객 후기",
      subTitle: "후기로 만나보는 젠더리스 주얼리",
      itemList: [
        {
          id: 92281,
          linkType: 16,
          linkUrl: null,
          title: null,
          contents: null,
          mobileBannerImg: {
            width: 480,
            height: 831,
            imgUrl: "https://cdn.amondz.com/widget/2326/item/banner/3861/resize/WIBAI_11045_50.jpeg?v=1694486711903",
          },
          pcBannerImg: {
            width: 852,
            height: 1197,
            imgUrl: "https://cdn.amondz.com/widget/2326/item/banner/3861/resize/WIBAI_11046_50.jpeg?v=1694486712323",
          },
        },
        {
          id: 24166,
          linkType: 16,
          linkUrl: null,
          title: null,
          contents: null,
          mobileBannerImg: {
            width: 480,
            height: 831,
            imgUrl: "https://cdn.amondz.com/widget/2326/item/banner/3862/resize/WIBAI_11047_50.jpeg?v=1694486712706",
          },
          pcBannerImg: {
            width: 852,
            height: 1197,
            imgUrl: "https://cdn.amondz.com/widget/2326/item/banner/3862/resize/WIBAI_11048_50.jpeg?v=1694486713168",
          },
        },
        {
          id: 16037,
          linkType: 16,
          linkUrl: null,
          title: null,
          contents: null,
          mobileBannerImg: {
            width: 480,
            height: 831,
            imgUrl: "https://cdn.amondz.com/widget/2326/item/banner/3863/resize/WIBAI_11049_50.jpeg?v=1694486713494",
          },
          pcBannerImg: {
            width: 852,
            height: 1197,
            imgUrl: "https://cdn.amondz.com/widget/2326/item/banner/3863/resize/WIBAI_11050_50.jpeg?v=1694486713848",
          },
        },
        {
          id: 73523,
          linkType: 16,
          linkUrl: null,
          title: null,
          contents: null,
          mobileBannerImg: {
            width: 480,
            height: 831,
            imgUrl: "https://cdn.amondz.com/widget/2326/item/banner/3864/resize/WIBAI_11051_50.jpeg?v=1694486714119",
          },
          pcBannerImg: {
            width: 852,
            height: 1197,
            imgUrl: "https://cdn.amondz.com/widget/2326/item/banner/3864/resize/WIBAI_11052_50.jpeg?v=1694486714480",
          },
        },
        {
          id: 75581,
          linkType: 16,
          linkUrl: null,
          title: null,
          contents: null,
          mobileBannerImg: {
            width: 480,
            height: 831,
            imgUrl: "https://cdn.amondz.com/widget/2326/item/banner/3865/resize/WIBAI_11053_50.jpeg?v=1694486714771",
          },
          pcBannerImg: {
            width: 852,
            height: 1197,
            imgUrl: "https://cdn.amondz.com/widget/2326/item/banner/3865/resize/WIBAI_11054_50.jpeg?v=1694486715159",
          },
        },
        {
          id: 75119,
          linkType: 16,
          linkUrl: null,
          title: null,
          contents: null,
          mobileBannerImg: {
            width: 480,
            height: 831,
            imgUrl: "https://cdn.amondz.com/widget/2326/item/banner/3722/resize/WIBAI_10713_50.jpeg?v=1692251793613",
          },
          pcBannerImg: {
            width: 852,
            height: 1197,
            imgUrl: "https://cdn.amondz.com/widget/2326/item/banner/3722/resize/WIBAI_10714_50.jpeg?v=1692251793932",
          },
        },
        {
          id: 64738,
          linkType: 16,
          linkUrl: null,
          title: null,
          contents: null,
          mobileBannerImg: {
            width: 480,
            height: 831,
            imgUrl: "https://cdn.amondz.com/widget/2326/item/banner/3866/resize/WIBAI_11055_50.jpeg?v=1694486715460",
          },
          pcBannerImg: {
            width: 852,
            height: 1197,
            imgUrl: "https://cdn.amondz.com/widget/2326/item/banner/3866/resize/WIBAI_11056_50.jpeg?v=1694486715836",
          },
        },
        {
          id: 83134,
          linkType: 16,
          linkUrl: null,
          title: null,
          contents: null,
          mobileBannerImg: {
            width: 480,
            height: 831,
            imgUrl: "https://cdn.amondz.com/widget/2326/item/banner/3724/resize/WIBAI_10717_50.jpeg?v=1692251794904",
          },
          pcBannerImg: {
            width: 852,
            height: 1197,
            imgUrl: "https://cdn.amondz.com/widget/2326/item/banner/3724/resize/WIBAI_10718_50.jpeg?v=1692251795257",
          },
        },
        {
          id: 63557,
          linkType: 16,
          linkUrl: null,
          title: null,
          contents: null,
          mobileBannerImg: {
            width: 480,
            height: 831,
            imgUrl: "https://cdn.amondz.com/widget/2326/item/banner/3725/resize/WIBAI_10719_50.jpeg?v=1692251795510",
          },
          pcBannerImg: {
            width: 852,
            height: 1197,
            imgUrl: "https://cdn.amondz.com/widget/2326/item/banner/3725/resize/WIBAI_10720_50.jpeg?v=1692251795850",
          },
        },
        {
          id: 91602,
          linkType: 16,
          linkUrl: null,
          title: null,
          contents: null,
          mobileBannerImg: {
            width: 480,
            height: 831,
            imgUrl: "https://cdn.amondz.com/widget/2326/item/banner/3867/resize/WIBAI_11057_50.jpeg?v=1694486716197",
          },
          pcBannerImg: {
            width: 852,
            height: 1197,
            imgUrl: "https://cdn.amondz.com/widget/2326/item/banner/3867/resize/WIBAI_11058_50.jpeg?v=1694486716469",
          },
        },
      ],
    },
    {
      id: 3563,
      widgetType: 3,
      layoutType: 200,
      mainTitle: "빠르게 만나는 브랜드 소식",
      subTitle: "여러분의 취향을 찾아보세요",
      itemList: [
        {
          id: 2030,
          linkType: 1,
          linkUrl: null,
          title: "센티멍",
          contents: "신규 컬렉션 출시",
          mobileBannerImg: {
            width: 1125,
            height: 600,
            imgUrl: "https://cdn.amondz.com/widget/3563/item/banner/4114/resize/WIBAI_11615_50.jpeg?v=1708331729620",
          },
          pcBannerImg: {
            width: 1422,
            height: 975,
            imgUrl: "https://cdn.amondz.com/widget/3563/item/banner/4114/resize/WIBAI_11616_50.jpeg?v=1708331730135",
          },
        },
        {
          id: 1019,
          linkType: 1,
          linkUrl: null,
          title: "실버태그",
          contents: "24SS 컬렉션 출시",
          mobileBannerImg: {
            width: 1125,
            height: 600,
            imgUrl: "https://cdn.amondz.com/widget/3563/item/banner/4115/resize/WIBAI_11617_50.jpeg?v=1708331730474",
          },
          pcBannerImg: {
            width: 1422,
            height: 975,
            imgUrl: "https://cdn.amondz.com/widget/3563/item/banner/4115/resize/WIBAI_11618_50.jpeg?v=1708331730878",
          },
        },
        {
          id: 1990,
          linkType: 1,
          linkUrl: null,
          title: "어센틱모즈",
          contents: "신규 컬렉션 출시",
          mobileBannerImg: {
            width: 1125,
            height: 600,
            imgUrl: "https://cdn.amondz.com/widget/3563/item/banner/4116/resize/WIBAI_11619_50.jpeg?v=1708331731294",
          },
          pcBannerImg: {
            width: 1422,
            height: 975,
            imgUrl: "https://cdn.amondz.com/widget/3563/item/banner/4116/resize/WIBAI_11620_50.jpeg?v=1708331731762",
          },
        },
        {
          id: 2710,
          linkType: 1,
          linkUrl: null,
          title: "루미소브",
          contents: "신규 입점",
          mobileBannerImg: {
            width: 1125,
            height: 600,
            imgUrl: "https://cdn.amondz.com/widget/3563/item/banner/4117/resize/WIBAI_11621_50.jpeg?v=1708331732146",
          },
          pcBannerImg: {
            width: 1422,
            height: 975,
            imgUrl: "https://cdn.amondz.com/widget/3563/item/banner/4117/resize/WIBAI_11622_50.jpeg?v=1708331732552",
          },
        },
        {
          id: 2182,
          linkType: 1,
          linkUrl: null,
          title: "마즈",
          contents: "23WT 컬렉션 출시",
          mobileBannerImg: {
            width: 1125,
            height: 600,
            imgUrl: "https://cdn.amondz.com/widget/3563/item/banner/3947/resize/WIBAI_11625_50.jpeg?v=1708331733664",
          },
          pcBannerImg: {
            width: 1422,
            height: 975,
            imgUrl: "https://cdn.amondz.com/widget/3563/item/banner/3947/resize/WIBAI_11626_50.jpeg?v=1708331734189",
          },
        },
        {
          id: 2654,
          linkType: 1,
          linkUrl: null,
          title: "에프에프티",
          contents: "신규 입점",
          mobileBannerImg: {
            width: 1125,
            height: 600,
            imgUrl: "https://cdn.amondz.com/widget/3563/item/banner/4118/resize/WIBAI_11623_50.jpeg?v=1708331732850",
          },
          pcBannerImg: {
            width: 1422,
            height: 975,
            imgUrl: "https://cdn.amondz.com/widget/3563/item/banner/4118/resize/WIBAI_11624_50.jpeg?v=1708331733208",
          },
        },
        {
          id: 2551,
          linkType: 1,
          linkUrl: null,
          title: "51퍼센트",
          contents: "신규 입점",
          mobileBannerImg: {
            width: 1125,
            height: 600,
            imgUrl: "https://cdn.amondz.com/widget/3563/item/banner/3952/resize/WIBAI_11240_50.jpeg?v=1697679916438",
          },
          pcBannerImg: {
            width: 1422,
            height: 975,
            imgUrl: "https://cdn.amondz.com/widget/3563/item/banner/3952/resize/WIBAI_11241_50.jpeg?v=1697679916830",
          },
        },
        {
          id: 2457,
          linkType: 1,
          linkUrl: null,
          title: "노틸러스",
          contents: "신규 입점",
          mobileBannerImg: {
            width: 1125,
            height: 600,
            imgUrl: "https://cdn.amondz.com/widget/3563/item/banner/3953/resize/WIBAI_11242_50.jpeg?v=1697679917201",
          },
          pcBannerImg: {
            width: 1422,
            height: 975,
            imgUrl: "https://cdn.amondz.com/widget/3563/item/banner/3953/resize/WIBAI_11243_50.jpeg?v=1697679917498",
          },
        },
        {
          id: 2636,
          linkType: 1,
          linkUrl: null,
          title: "킵번",
          contents: "신규 입점",
          mobileBannerImg: {
            width: 1125,
            height: 600,
            imgUrl: "https://cdn.amondz.com/widget/3563/item/banner/3954/resize/WIBAI_11244_50.jpeg?v=1697679917828",
          },
          pcBannerImg: {
            width: 1422,
            height: 975,
            imgUrl: "https://cdn.amondz.com/widget/3563/item/banner/3954/resize/WIBAI_11245_50.jpeg?v=1697679918204",
          },
        },
        {
          id: 2624,
          linkType: 1,
          linkUrl: null,
          title: "8케이엠",
          contents: "신규 입점",
          mobileBannerImg: {
            width: 1125,
            height: 600,
            imgUrl: "https://cdn.amondz.com/widget/3563/item/banner/4051/resize/WIBAI_11459_50.jpeg?v=1701403787900",
          },
          pcBannerImg: {
            width: 1422,
            height: 975,
            imgUrl: "https://cdn.amondz.com/widget/3563/item/banner/4051/resize/WIBAI_11460_50.jpeg?v=1701403788310",
          },
        },
      ],
    },
  ],
};
