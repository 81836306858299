import {
  BRAND_LIST_PAGE_URL_PATH,
  BRAND_PAGE_URL_PATH,
  EVENT_PAGE_URL_PATH,
  HOME_SUB_PAGE_URL_PATH,
  INTRO_PAGE_URL_PATH,
  MY_PAGE_COUPON_PRODUCT_URL_PATH,
  MY_PAGE_LIKE_BRAND_URL_PATH,
  MY_PAGE_LIKE_PRODUCT_URL_PATH,
  PAYMENT_CART_URL_PATH,
  PAYMENT_CHECKOUT_URL_PATH,
  PAYMENT_COMPLETION_URL_PATH,
  PRODUCT_DETAIL_PAGE_URL_PATH,
  PRODUCT_LIST_PAGE_URL_PATH,
  WIDGET_PAGE_URL_PATH,
  MY_PAGE_COUPON_BRAND_URL_PATH,
  SEARCH_RESULT_PAGE_URL_PATH,
  MY_PAGE_MEMBERSHIP_URL_PATH,
} from "../url/internalUrlConstants";
import { PAGE_VIEW_TYPE } from "../enum/baseEnums";

export const PAGE_URL_PAGE_VIEW_MAP: Record<string, PAGE_VIEW_TYPE> = {
  // 홈 페이지
  [INTRO_PAGE_URL_PATH]: PAGE_VIEW_TYPE.HOME_PAGE,
  // 상품 리스트 페이지
  [PRODUCT_LIST_PAGE_URL_PATH]: PAGE_VIEW_TYPE.PRODUCT_LIST_PAGE,
  // 상품 좋아요 페이지
  [MY_PAGE_LIKE_PRODUCT_URL_PATH]: PAGE_VIEW_TYPE.PRODUCT_LIKE_PAGE,
  // 브랜드 좋아요 페이지
  [MY_PAGE_LIKE_BRAND_URL_PATH]: PAGE_VIEW_TYPE.BRAND_LIKE_PAGE,
  // 위젯 상세 페이지
  [WIDGET_PAGE_URL_PATH]: PAGE_VIEW_TYPE.WIDGET_DETAIL_PAGE,
  // 상품 상세 페이지
  [PRODUCT_DETAIL_PAGE_URL_PATH]: PAGE_VIEW_TYPE.PRODUCT_DETAIL_PAGE,
  // 기획전 상세 페이지
  [EVENT_PAGE_URL_PATH]: PAGE_VIEW_TYPE.EVENT_DETAIL_PAGE,
  // 장바구니 페이지
  [PAYMENT_CART_URL_PATH]: PAGE_VIEW_TYPE.CART_PAGE,
  // 주문서 작성 페이지
  [PAYMENT_CHECKOUT_URL_PATH]: PAGE_VIEW_TYPE.PAYMENT_CHECKOUT_PAGE,
  // 주문완료 페이지
  [PAYMENT_COMPLETION_URL_PATH]: PAGE_VIEW_TYPE.PAYMENT_COMPLETE_PAGE,
  // 마이 > 쿠폰 > 적용 가능 상품 페이지
  [MY_PAGE_COUPON_PRODUCT_URL_PATH]: PAGE_VIEW_TYPE.MY_COUPON_APPLICABLE_PRODUCT_PAGE,
  // 마이 > 쿠폰 > 적용 가능 브랜드 페이지
  [MY_PAGE_COUPON_BRAND_URL_PATH]: PAGE_VIEW_TYPE.MY_COUPON_APPLICABLE_BRAND_PAGE,
  // 브랜드 목록 페이지
  [BRAND_LIST_PAGE_URL_PATH]: PAGE_VIEW_TYPE.BRAND_LIST_PAGE,
  // 브랜드 상세 페이지
  [BRAND_PAGE_URL_PATH]: PAGE_VIEW_TYPE.BRAND_DETAIL_PAGE,
  // 홈 서브 페이지
  [HOME_SUB_PAGE_URL_PATH]: PAGE_VIEW_TYPE.HOME_SUB_PAGE,
  // 검색 페이지(통합 검색 결과 페이지)
  [SEARCH_RESULT_PAGE_URL_PATH]: PAGE_VIEW_TYPE.TOTAL_SEARCH_RESULT_PAGE,
  //멤버십
  [MY_PAGE_MEMBERSHIP_URL_PATH]: PAGE_VIEW_TYPE.MEMBERSHIP_PAGE,
} as const;
