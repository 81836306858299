import styled from "@styles/themedComponents";

export const OneButtonModalLayoutStyle = styled.div`
  width: 320px;

  .modal-body {
    padding-top: 5px;
    margin: 30px 15px 22px;
    text-align: left;
    letter-spacing: -0.5px;

    .msg {
      padding-bottom: 8px;
      padding-right: 15px;
      line-height: 22px;
      font-size: 15px;
    }

    &.is-sub-msg {
      .msg {
        font-weight: 500;
      }

      .sub-msg {
        margin-top: 2px;
        padding-bottom: 8px;
        font-size: 13px;
        line-height: 19px;
        color: ${(props) => props.theme.color.gray_01};
      }
    }
  }

  .modal-footer {
    margin: 0 15px 30px;

    button {
      width: 100%;
      height: 48px;
      cursor: pointer;
    }
  }
`;
