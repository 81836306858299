import styled, { createGlobalStyle } from "@styles/themedComponents";

export const GlobalStyle = createGlobalStyle`
    main, footer, .hello-bar {
      display: none;
    }
`;

export const HeaderStyle = styled.header`
  position: sticky;
  top: 0;
  z-index: ${(props) => props.theme.zIndex.header};
  width: 100%;
  height: 56px;
  padding: 16px 15px;
  background-color: ${(props) => props.theme.color.amondz_white};

  .header-wrap {
    position: relative;
    height: 24px;
    display: flex;
    justify-content: center;

    .menu-btn {
      position: absolute;
      top: 0;
      left: 0;
      width: 24px;
      height: 24px;
      cursor: pointer;
    }

    .header-title {
      display: inline-block;
      max-width: 180px;
      margin: 0;
      ${(props) => props.theme.ellipsis};
      font-family: ${(props) => props.theme.fonts.notoSansCJKkr};
      font-size: 15px;
      font-weight: bold;
      line-height: 22px;
      letter-spacing: -0.5px;
    }

    .amondz-log {
      display: flex;
      align-items: center;

      img {
        height: 20px;
      }
    }

    .icon-menu {
      position: absolute;
      top: 0;
      right: 0;

      & > li {
        float: left;
        height: 24px;
        cursor: pointer;

        &:not(:last-child) {
          margin-right: 15px;
        }

        img {
          width: 24px;
          height: 24px;
          object-fit: contain;
        }

        .cart-btn {
          display: inline-block;
          position: relative;
          height: 100%;
        }

        .cart-count {
          position: absolute;
          top: 10px;
          right: -4px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 18px;
          height: 18px;
          color: ${(props) => props.theme.color.amondz_white};
          background: ${(props) => props.theme.color.amondz_black};
          font-family: ${(props) => props.theme.fonts.barlow};
          border-radius: 50%;
          text-align: center;

          span {
            font-size: 11px;
            font-weight: bold;
          }
        }
      }
    }
  }
`;
