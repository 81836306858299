import { RV_LOGIN_TYPE_TEXT } from "../../common/replaceVariables";

// 첫번째 회원가입 환영 모달
export const WELCOME_FIRST_MEMBER_JOIN_MODAL_CONTENTS = {
  MSG: "신규 고객을 위한 20% 할인 쿠폰이\n발급되었습니다.",
  BTN_TEXT: "확인",
};

// SMS 인증 번호 요청 횟수 초과 모달
export const EXCEEDED_REQUEST_SMS_MODAL_CONTENTS = {
  MSG: "하루 요청 가능 횟수를 초과했습니다.\n내일 다시 시도해주세요",
};

// 회원 가입 정보 모달
export const MEMBER_LOGIN_TYPE_INFO_MODAL_CONTENTS = {
  MSG: `이미 ${RV_LOGIN_TYPE_TEXT}로 가입하셨습니다.`,
  SUB_MSG: `${RV_LOGIN_TYPE_TEXT} 계정으로 시작할 수 있습니다. 로그인 문제가 해결되지 않는\n경우 아몬즈에 문의해주세요.`,
  RIGHT_BTN_TEXT: "로그인 하러가기",
  LEFT_BTN_TEXT: "문의하러 가기",
};

// 회원가입 시 유효하지 않은 휴대폰 번호 안내 모달
export const SIGN_UP_INVALID_CELL_PHONE_MODAL_CONTENTS = {
  MSG: "휴대폰 번호 인증 오류로 인해\n회원가입 초기화면으로 이동합니다.",
};

// 회원가입 시 유효하지 않은 이메일 안내 모달
export const SIGN_UP_INVALID_EMAIL_MODAL_CONTENTS = {
  MSG: "이메일 형식이 올바르지 않습니다.\n회원가입 초기화면으로 이동합니다.",
};

// 회원가입 시 유효하지 않은 비밀번호 안내 모달
export const SIGN_UP_INVALID_PASSWORD_MODAL_CONTENTS = {
  MSG: "비밀번호 형식이 올바르지 않습니다.\n회원가입 초기화면으로 이동합니다.",
};

// 회원가입 시 이미 가입된 이메일 안내 모달
export const SIGN_UP_ALREADY_JOIN_EMAIL_MODAL_CONTENTS = {
  MSG: "이미 사용중인 아이디입니다.\n회원가입 초기화면으로 이동합니다.",
};

// 회원가입 시 이미 가입된 이메일 안내 모달
export const SIGN_UP_ALREADY_JOIN_CELL_PHONE_MODAL_CONTENTS = {
  MSG: "이미 사용중인 핸드폰 번호입니다.\n회원가입 초기화면으로 이동합니다.",
};

// SMS 인증 번호 요청 - 유효하지 않은 휴대폰 번호
export const SMS_INVALID_CELL_PHONE_MODAL_CONTENTS = {
  MSG: "해당 번호로 가입한 정보가 없습니다.\n회원가입을 진행해주세요.",
  LEFT_BTN_TEXT: "회원 가입하기",
  RIGHT_BTN_TEXT: "다시 입력하기",
};

// 유효하지 않은 회원정보 안내 모달
export const INVALID_USER_INFO_MODAL_CONTENTS = {
  MSG: "회원정보가 유효하지 않습니다.\n 다시 로그인해주세요.",
};

// 이메일 찾기 안내 모달
export const FIND_EMAIL_INFO_MODAL_CONTENTS = {
  MSG: "가입하신 이메일 주소를 잊으셨나요?",
  SUB_MSG: "아이디 찾기를 통해 가입한 이메일 주소를\n확인 할 수 있습니다.",
  RIGHT_BTN_TEXT: "아이디 찾기",
};

// 비밀번호 변경 완료 안내 모달
export const COMPLETE_CHANGE_PASSWORD_INFO_MODAL_CONTENTS = {
  MSG: "비밀번호가 변경되었습니다.\n로그인 페이지로 이동합니다.",
};

// 비밀번호 변경 완료 안내 모달
export const EXPIRE_ENABLE_CHANGE_PASSWORD_INFO_MODAL_CONTENTS = {
  MSG: "비밀번호 재설정 가능 시간을 초과 했습니다.\n 다시 시도해주세요.",
  BTN_TEXT: "로그인 하러가기",
};

// 올바르지 않은 비밀번호 안내 모달
export const INVALID_PASSWORD_INFO_MODAL_CONTENTS = {
  MSG: "비밀번호가 올바르지 않습니다.",
};

// 로그아웃 안내 모달
export const LOG_OUT_INFO_MODAL_CONTENTS = {
  MSG: "로그아웃을 하시겠습니까?",
};

// 로그인 필요 모달
export const REQUIRED_LOG_IN_INFO_MODAL_CONTENTS = {
  MSG: "로그인이 필요한 서비스입니다.",
  SUB_MSG: "로그인 하시겠습니까?",
};
