import TagManager from "react-gtm-module";
import { productCategoryListType } from "@amondz/types";
import { LOGIN_TYPE } from "@constants/enum/authEnums";
import { LOGIN_TYPE_TEXT } from "@constants/service/auth/auth";
import { LIKE_STATUS, LIKE_STATUS_TYPE, TODAY_DELIVERY_FILTER_TYPE } from "@constants/service/common/common";

/**
 * subCategory name을 중복 없는 문자열배열로 변환
 * @param list productCategoryList
 */
export const subCategoryName = (list: productCategoryListType) =>
  list.reduce((a: string[], c) => {
    return a.find((v) => v === c.name) ? a : [...a, c.name];
  }, []);

////////// 공통 Type 선언 //////////
type CategoryInfoType = {
  categoryId: number;
  categoryName: string;
  subCategoryName: string | string[];
};

/**
 * gtm 기본 이벤트 정보
 * id, name 은 해당 이벤트의 주체가 되는 대상의 id, name
 * (ex. product - productId, productName / brand - brandId, brandName / event - eventId, eventName ...)
 */
type BaseEventInfoType = {
  id: number;
  name: string;
};

export type BaseProductInfoType = {
  brandId: number;
  brandName: string;
  productId: number;
  productName: string;
  imgUrl: string;
  price: number;
  deliveryType: TODAY_DELIVERY_FILTER_TYPE;
  isGift: boolean;
  discountRate?: number;
} & CategoryInfoType;

/**
 * 상품 구매 이벤트에서 공통적으로 사용하는 타입
 */
export type OrderInfoType = {
  brandId: number[];
  brandName: string[];
  productId: number[];
  productName: string[];
  imgUrl: string[];
  price: number[];
  deliveryType: TODAY_DELIVERY_FILTER_TYPE[];
  isGift: boolean[];
  quantity: number[];
  optionId: number[];
  option: string[];
  couponId: number[];
  coupon: string[];
  categoryId: number[];
  categoryName: string[];
  subCategoryName: string[];
  point: number;
  paymentMethod: string;
  totalPrice: number;
  totalCount: number;
};
////////// 공통 Type 선언 완료 //////////

////////// event 선언 //////////
/**
 * 네이밍 prefix 규칙
 * view~: 페이지 진입 시
 * begin~: 특정 동작 수행 전
 * complete~: 특정 동작 수행 완료
 * add~ : ~에 담기
 */

type PayloadType = { [key: string]: string | number | boolean | Date };
/**
 * gtm 이벤트를 관리하는 함수
 * @param event
 * @param payload
 */
const tagManager = (event: string, payload?: any) => {
  let payloadWithoutArr: PayloadType = {};
  if (payload) {
    for (const key in payload) {
      if (Array.isArray(payload[key])) {
        payloadWithoutArr[key] = payload[key].toString();
      } else {
        payloadWithoutArr[key] = payload[key];
      }
    }
  }

  setTimeout(() => {
    TagManager.dataLayer({
      dataLayer: {
        event,
        payload: payloadWithoutArr,
      },
    });
  }, 0);
};

/**
 * 로그인 / 회원가입 페이지 진입 확인
 */
export const beginAuth = () => {
  tagManager("beginAuth");
};

export type AuthPayload = { userId: number; loginType: LOGIN_TYPE };

/**
 * 회원가입 완료 확인
 */
export const completeSignUp = (payload: AuthPayload & { isSmsAgree: boolean }) => {
  tagManager("completeSignUp", {
    userId: payload.userId,
    type: payload.loginType,
    isSmsAgree: payload.isSmsAgree,
    method: LOGIN_TYPE_TEXT[payload.loginType],
  });
};

/**
 * 로그인 완료 확인
 */
export const completeSignIn = (payload: AuthPayload) => {
  tagManager("completeSignIn", {
    isSuccess: true,
    userId: payload.userId,
    type: payload.loginType,
    method: LOGIN_TYPE_TEXT[payload.loginType],
  });
};

export type BeginPaymentType = Omit<OrderInfoType, "paymentMethod" | "point" | "coupon" | "couponId">;
/**
 * 주문서 페이지 진입 확인
 */
export const beginPayment = (params: BeginPaymentType) => {
  tagManager("beginPayment", params);
};

/**
 * 결제하기 버튼 클릭 시(나이스페이 진입)
 */
export const submitPayment = (params: OrderInfoType) => {
  tagManager("submitPayment", params);
};

export type CompletePaymentType = Omit<BaseProductInfoType, "productId" | "discountRate"> & {
  // braze - purchase 에서 productId 는 string 으로 받음
  productId: string;
  quantity: number;
  optionId: number | null;
  option: string | null;
  couponId: number | null;
  coupon: string | null;
  point: number;
  paymentMethod: string;
  orderId: number;
  receiptId: string;
  revenue: number;
  userId: number;
};
/**
 * 결제 완료 확인
 */
export const completePayment = (params: CompletePaymentType) => {
  tagManager("completePayment", params);
};

/**
 * 홈 - 메인 페이지 진입 확인
 * @param isLoggedIn 로그인 여부
 */
/**
 * 홈 - 메인 페이지 진입 확인
 * @param isLoggedIn 로그인 여부
 * @param userId 유저 id
 */
export const viewMainHome = (isLoggedIn: boolean, userId?: number | null) => {
  tagManager("viewMainHome", { isLoggedIn, userId });
};

/**
 * 홈 - 서브 페이지 진입 확인
 * @param id 서브페이지 ID
 */
export const viewSubHome = (id: number) => {
  tagManager("viewSubHome", { id });
};

/**
 * 카테고리 페이지 진입 시
 * @param params 카테고리 id, name, 서브카테고리 name
 */
export const viewProductList = (params: CategoryInfoType) => {
  tagManager("viewProductList", { ...params, contentType: "product_group" });
};

/**
 * 상품 상세 페이지 진입 시
 * @param params 상품 관련 정보
 */
export const viewProductDetail = (params: Omit<BaseProductInfoType, "isGift">) => {
  tagManager("viewProductDetail", { ...params, contentType: "product" });
};

/**
 * 이벤트 상세 페이지 진입 시
 * @param params 이벤트 id, name
 */
export const viewEventDetail = (params: BaseEventInfoType) => {
  tagManager("viewEventDetail", { ...params, contentType: "event" });
};

/**
 * 위젯 상세 페이지 진입 시
 * @param params 위젯 id, name
 */
export const viewWidgetDetail = (params: BaseEventInfoType) => {
  tagManager("viewWidgetDetail", params);
};

/**
 * 장바구니 페이지 진입 시
 */
export const viewCart = () => {
  tagManager("viewCart");
};

/**
 * QnA 페이지 진입 시
 */
export const viewQna = () => {
  tagManager("viewQna");
};

export type OrderInfoForCartType = BaseProductInfoType & {
  quantity: number;
  optionId: number[];
  option: string[];
  totalPrice: number;
};
/**
 * 장바구니 담기 시
 * @param params 장바구니에 담을 상품 정보
 */
export const addToCart = (params: OrderInfoForCartType) => {
  tagManager("addToCart", { ...params, contentType: "product" });
};

/**
 * 상품 좋아요 시
 * @param params 좋아요한 상품 정보
 */
export const addToProductWishlist = (params: Omit<BaseProductInfoType, "imgUrl">) => {
  tagManager("addToProductWishlist", { ...params, contentType: "product" });
};

/**
 * 브랜드 좋아요 시
 * @param params 브랜드 id, name
 */
export const addToBrandWishlist = (params: BaseEventInfoType) => {
  tagManager("addToBrandWishlist", { ...params, contentType: "brand" });
};

/**
 * 쿠폰 발급 시
 * @param params 쿠폰 id, name
 */
export const receivedCoupon = (params: BaseEventInfoType) => {
  tagManager("receivedCoupon", params);
};

/**
 * 검색 완료 시
 * @param params 검색 성공 여부, 검색 단어
 */
export const submitSearch = (params: { isSuccess: boolean; searchWord: string }) => {
  tagManager("submitSearch", params);
};

/**
 * 리뷰 작성 시
 * @param params 상품 정보 와 리뷰 별점
 */
export const insertReview = (
  params: Pick<BaseProductInfoType, "brandId" | "brandName" | "productId" | "productName" | "imgUrl" | "price"> & {
    ratingValue: number;
  },
) => {
  tagManager("insertReview", params);
};

/**
 * 상품 공유하기 시
 * @param params
 */
export const shareProduct = (
  params: Omit<BaseProductInfoType, "deliveryType" | "isGift" | "discountRate"> & {
    description: string;
  },
) => {
  tagManager("shareProduct", { ...params, contentType: "product" });
};

/**
 * 기획전 공유하기 시
 * @param params
 */
export const shareEvent = (params: BaseEventInfoType & { description: string }) => {
  tagManager("shareEvent", { ...params, contentType: "event" });
};

/**
 * 레퍼럴 공유하기 시
 * @param params
 */
export const shareReferral = (params: BaseEventInfoType) => {
  tagManager("shareReferral", { ...params, contentType: "referral" });
};

/**
 * 브랜드 인스타 이동 시
 */
export const moveToBrandInsta = () => {
  tagManager("moveToBrandInsta");
};
////////// event 선언 완료 //////////

////////// 기존 event //////////
/**
 * 회원 가입 이벤트 설정
 */
export const setGTMMemberJoinEvent = (): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: "join_complete",
    },
  });
};

// 결제 완료 페이지 이벤트 타입
interface GTMPurchasePageEventType {
  // 결제 금액
  totalPrice: number;
}

/**
 * 결제 완료 이벤트 설정
 *
 * @param {GTMPurchasePageEventType} data 결제 완료 데이터
 */
export const setGTMPurchasePageEvent = (data: GTMPurchasePageEventType): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: "purchase-page-event",
      totalPrice: data.totalPrice,
    },
  });
};
////////// 기존 event 완료 //////////

export const setProductWishList = (status: LIKE_STATUS_TYPE, product: Omit<BaseProductInfoType, "imgUrl">) => {
  if (status === LIKE_STATUS.LIKE) {
    gtm.addToProductWishlist({ ...product });
  }
};

const gtm = {
  beginAuth,
  completeSignUp,
  completeSignIn,
  beginPayment,
  submitPayment,
  completePayment,
  viewMainHome,
  viewSubHome,
  viewProductList,
  viewProductDetail,
  viewEventDetail,
  viewWidgetDetail,
  viewCart,
  viewQna,
  addToCart,
  addToProductWishlist,
  addToBrandWishlist,
  receivedCoupon,
  submitSearch,
  insertReview,
  shareProduct,
  shareEvent,
  shareReferral,
  moveToBrandInsta,
};
export default gtm;
