import styled from "@styles/themedComponents";

export const TwoModalLayoutStyle = styled.div`
  width: 320px;

  .modal-body {
    margin: 30px 15px 22px;
    text-align: left;
    letter-spacing: -0.5px;

    .msg {
      padding-bottom: 8px;
      line-height: 22px;
      font-size: 16px;
    }

    &.is-sub-msg {
      .msg {
        font-weight: 500;
      }

      .sub-msg {
        margin-top: 2px;
        padding-bottom: 8px;
        font-size: 13px;
        line-height: 19px;
        color: ${(props) => props.theme.color.gray_01};
      }
    }
  }

  .modal-footer {
    margin: 0 15px 10px;
    transform: rotate(180deg);

    button {
      transform: rotate(-180deg);
      width: 100%;
      height: 48px;
      cursor: pointer;

      &:first-child {
        border: none;
        color: ${(props) => props.theme.color.temp_gray_02};
        margin-right: 12px;
        border-color: ${(props) => props.theme.color.amondz_black};
      }
    }
  }
`;
