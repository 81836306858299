export type ImageSizeType = "DEFAULT" | "LARGE" | "LARGEST";
export const IMAGE_SIZE = {
  /**
   * 기본 이미지 사이즈
   * width: 256
   * height: 256
   */
  DEFAULT: {
    WIDTH: 256,
    HEIGHT: 256,
  },
  /**
   * 큰 이미지 사이즈
   * width: 640
   * height: 640
   */
  LARGE: {
    WIDTH: 640,
    HEIGHT: 640,
  },
  /**
   * 가장 큰 이미지 사이즈
   * width: 1080
   * height: 1080
   */
  LARGEST: {
    WIDTH: 1080,
    HEIGHT: 1080,
  },
};
