import { createReducer } from "typesafe-actions";
import { produce } from "immer";

import { BaseStateType, BaseAction } from "./types";
import {
  BASE_MODAL_TOGGLE,
  NOTIFY_TOAST_TOGGLE,
  ONE_BUTTON_MODAL_TOGGLE,
  TWO_BUTTON_MODAL_TOGGLE,
  HELLOBAR_COOKIE,
} from "./actions";

export const initialBaseState: BaseStateType = {
  baseModalState: {
    headerMenuModal: {
      visible: false,
    },
    logOutModal: {
      visible: false,
    },
    requiredAuthInfoModal: {
      visible: false,
    },
    oneButtonModal: {
      visible: false,
    },
    twoButtonModal: {
      visible: false,
    },
  },

  coreState: {
    notifyToast: {
      visible: true,
    },
  },
  HellobarState: {
    isHellobarCookieEnrolled: false,
  },
};

const baseReducer = createReducer<BaseStateType, BaseAction>(initialBaseState, {
  // 모달 토글
  [BASE_MODAL_TOGGLE]: produce((draft, action) => {
    draft.baseModalState[action.payload.targetModal].visible =
      !draft.baseModalState[action.payload.targetModal].visible;
    if (action.payload.productId) {
      draft.baseModalState[action.payload.targetModal].productId = action.payload.productId;
    } else {
      draft.baseModalState[action.payload.targetModal].productId = null;
    }
  }),

  // 토스트 메시지 토글
  [NOTIFY_TOAST_TOGGLE]: produce((draft, action) => {
    draft.coreState.notifyToast = action.payload;
  }),

  // 원 버튼 토글
  [ONE_BUTTON_MODAL_TOGGLE]: produce((draft, action) => {
    draft.baseModalState.oneButtonModal = action.payload;
  }),

  // 투 버튼 토글
  [TWO_BUTTON_MODAL_TOGGLE]: produce((draft, action) => {
    draft.baseModalState.twoButtonModal = action.payload;
  }),

  // 헬로바 쿠키
  [HELLOBAR_COOKIE]: produce((draft, action) => {
    draft.HellobarState = action.payload;
  }),
});

export default baseReducer;
