import { paymentHadlers } from "@services/msw/handlers/paymentHadlers";
import { mypageHandlers } from "@services/msw/handlers/mypageHandlers";
import { productHandlers } from "@services/msw/handlers/productHandlers";
import { couponHandlers } from "@services/msw/handlers/couponHandlers";
import { reviewHandlers } from "@services/msw/handlers/reviewHandlers";
import { homeHandlers } from "@services/msw/handlers/homeHadlers";

export const handlers = [
  ...homeHandlers,
  ...mypageHandlers,
  ...paymentHadlers,
  ...productHandlers,
  ...couponHandlers,
  ...reviewHandlers,
];
