/* eslint-disable import/no-unresolved */
import * as React from "react";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NextComponentType } from "next";
import { AppContext, AppInitialProps, AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import { initializeApp } from "firebase/app";
import { DehydratedState, Hydrate } from "@tanstack/react-query";
import { isNil, throttle } from "lodash";
import GTM, { GtmProvider } from "@amondz/gtm";
import smoothscroll from "smoothscroll-polyfill";
import { AdsProvider } from "@amondz/design-system";
import { OverlayProvider } from "@amondz/use-overlay";
import { FirebaseProvider } from "@amondz/firebase";
//axios 인터셉터 설정용
import "@services/superFetch";
import "@services/superFetchV2";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/grid";
import "swiper/css/thumbs";
import "swiper/css/effect-fade";
import "swiper/css/free-mode";
import "react-datepicker/dist/react-datepicker.css";

import { ThemeProvider } from "@styles/themedComponents";
import { GlobalStyle } from "@styles/globalStyles";
import theme from "@styles/theme";
import wrapper from "@store/index";
import {
  searchModalStateType,
  searchModalVisible,
  setLastSearchKeyword,
  setRecentSearchKeywordList,
  setSearchKeyword,
} from "@store/modules/search";
import { RootStateType } from "@store/modules";
import { hellobarCookie } from "@store/modules/base";
import { initChannelTalk } from "@lib/utility/channelTalk";
import { getCookie } from "@lib/utility/settingCookies";
import { setUuidOption } from "@lib/utility/common";
import cookies from "@lib/utility/cookies";
import Core from "@components/base/Core";
import { SEARCH_RESULT_PAGE_URL_PATH } from "@constants/url/internalUrlConstants";
import { AMONDZ_UUID_KEY, AMONDZ_WIDGET_ID_KEY } from "@constants/service/auth/auth";
import { COOKIE_NAME } from "@constants/service/common/modal";
import HeaderMenuModal from "@views/base/common/modal/HeaderMenuModal";
import OneButtonModal from "@views/base/common/modal/OneButtonModal";
import TwoButtonModal from "@views/base/common/modal/TwoButtonModal";
import SearchModal from "@views/search/modal/SearchModal";
import { AuthStateType } from "@store/modules/auth";
import CustomSWRConfig from "@swr/CustomSWRConfig";

if (process.env.NEXT_PUBLIC_API_MOCKING === "enabled") {
  require("../services/msw/index");
}

const config = {
  debug: process.env.NEXT_PUBLIC_ENVIRONMENT === "development",
  auto_track_page_view: true,
};

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
};

const App: NextComponentType<
  AppContext,
  AppInitialProps,
  AppProps<{ dehydratedState: DehydratedState; fallback: any }>
> = ({ Component, pageProps }) => {
  const { userAuthState } = useSelector<RootStateType, AuthStateType>((state: RootStateType) => state.auth);
  const { isSearchModalVisible } = useSelector<RootStateType, searchModalStateType>(
    (state: RootStateType) => state.search.searchModalState,
  );

  const currentUrlPath = useRef<string | null>(null);
  const router = useRouter();
  const { keyword } = router.query;

  const dispatch = useDispatch();

  useEffect(() => {
    initChannelTalk();
  }, []);

  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  // 새로고침 했을 때 검색어가 있으면 input section에 적용
  useEffect(() => {
    if (keyword && (keyword as string).trim()) {
      dispatch(setSearchKeyword({ searchKeyword: (keyword as string).trim() }));
      dispatch(setLastSearchKeyword({ lastSearchKeyword: (keyword as string).trim() }));
    }
  }, []);

  // 검색 모달 최근 검색어 localStorage와 동기화
  useEffect(() => {
    if (
      !isNil(localStorage) &&
      localStorage.getItem("searchHistory") &&
      JSON.parse(localStorage.getItem("searchHistory")!).length
    ) {
      dispatch(
        setRecentSearchKeywordList({ recentSearchKeywordList: JSON.parse(localStorage.getItem("searchHistory")!) }),
      );
    }
  }, []);

  useEffect(() => {
    if (router.pathname !== currentUrlPath.current) {
      // 이전 페이지 경로를 저장
      sessionStorage.setItem("prevUrlPath", currentUrlPath.current ? currentUrlPath.current : router.pathname);
      currentUrlPath.current = router.pathname;
    }
  }, [router.pathname]);

  useEffect(() => {
    GTM.initialize(userAuthState.data?.userId ?? null, userAuthState.data?.userName ?? null);
  }, []);

  useEffect(() => {
    if (!userAuthState.data) return;

    const { userId, userName } = userAuthState.data;
    GTM.changeUser({ userId, userName });
  }, [userAuthState.data?.userId, userAuthState.data?.userName]);

  useEffect(() => {
    let uuid = cookies.get(AMONDZ_UUID_KEY);

    if (!uuid) {
      // browser 에 uuid 가 없을 경우 uuid 를 새롭게 만들어서 쿠키에 저장함
      const uuidOption = setUuidOption();
      cookies.set(AMONDZ_UUID_KEY, uuidOption.uuid, {
        path: "/",
        expires: uuidOption.date,
      });
    }

    // 헬로바 노출 여부
    dispatch(hellobarCookie({ isHellobarCookieEnrolled: !!getCookie(COOKIE_NAME.HELLO_BAR) }));

    // 스크롤 복구 로직 시작
    window.history.scrollRestoration = "manual";

    const scrollRestoration = sessionStorage.getItem("scrollRestoration");
    let cacheScrollRestoration = scrollRestoration ? JSON.parse(scrollRestoration) : {};
    let shouldScrollRestore: { y: number } | null;

    // 페이지 이동 전, 검색 input 기록 설정 or 초기화(검색 결과 창 제외), 검색 모달 비노출 및 캐시된 위치 정보 sessionStorage 에 저장
    router.events.on("routeChangeStart", (url) => {
      if (!url.includes(SEARCH_RESULT_PAGE_URL_PATH)) {
        dispatch(setSearchKeyword({ searchKeyword: "" }));
        dispatch(setLastSearchKeyword({ lastSearchKeyword: "" }));
      }
      dispatch(searchModalVisible({ isSearchModalVisible: false }));
      sessionStorage.setItem("scrollRestoration", JSON.stringify(cacheScrollRestoration));
    });

    // 페이지 이동 후, 스크롤 위치 보정
    router.events.on("routeChangeComplete", () => {
      // beforePopState 이벤트가 발생 할 경우에만 스크롤 복구 로직 태움
      if (shouldScrollRestore) {
        const { y } = shouldScrollRestore;
        window.scrollTo(0, y);
        shouldScrollRestore = null;
      }
      // 위젯 id 키 제거
      cookies.remove(AMONDZ_WIDGET_ID_KEY, { path: "/" });
    });

    // 뒤로가기 또는 앞으로가기 시 검색 input 기록 설정(검색 페이지 내) 및 스크롤 위치 받아옴
    router.beforePopState(({ as }) => {
      const parseUrl = new URL(location.href);
      const beforeSearchKeyword = parseUrl.searchParams.get("keyword");

      // 모달이 켜져 있으면 끄기
      if (isSearchModalVisible) {
        dispatch(searchModalVisible({ isSearchModalVisible: false }));
      }

      if (as.includes(SEARCH_RESULT_PAGE_URL_PATH) && beforeSearchKeyword && beforeSearchKeyword.trim()) {
        dispatch(setSearchKeyword({ searchKeyword: beforeSearchKeyword.trim() }));
        dispatch(setLastSearchKeyword({ lastSearchKeyword: beforeSearchKeyword.trim() }));
      }
      const scrollPosition = cacheScrollRestoration[as];

      if (scrollPosition) {
        shouldScrollRestore = {
          y: scrollPosition,
        };
      }
      window.history.scrollRestoration = "manual";
      return true;
    });

    // 스크롤 변경시 스크롤 위치 캐싱
    const onScroll = throttle(() => {
      cacheScrollRestoration =
        window.history.state !== null
          ? {
              ...cacheScrollRestoration,
              [window.history.state.as]: window.scrollY,
            }
          : {
              ...cacheScrollRestoration,
            };
    }, 500);
    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <>
      <GlobalStyle />

      <AdsProvider deviceType="mo">
        <ThemeProvider theme={(adsTheme) => ({ ...adsTheme, ...theme })}>
          <OverlayProvider>
            <CustomSWRConfig fallback={pageProps.fallback}>
              <Hydrate state={pageProps.dehydratedState}>
                <FirebaseProvider firebaseApp={initializeApp(firebaseConfig)}>
                  <GtmProvider>
                    <Head>
                      <meta
                        name="viewport"
                        content="width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,viewport-fit=cover"
                      />
                      <link rel="icon" href="/static/favicon48.ico" />
                    </Head>

                    <Component {...pageProps} />

                    <SearchModal />
                    <HeaderMenuModal />
                    <OneButtonModal />
                    <TwoButtonModal />
                    <Core />
                  </GtmProvider>
                </FirebaseProvider>
              </Hydrate>
            </CustomSWRConfig>
          </OverlayProvider>
        </ThemeProvider>
      </AdsProvider>
    </>
  );
};

export default wrapper.withRedux(App);
