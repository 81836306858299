import { DISCOUNT_TYPE, PRODUCT_DETAIL_DELIVERY_TYPE } from "@constants/enum/productEnum";
import { omit } from "lodash";
import { setDate, setMonth, subYears } from "date-fns";

// 상품 전체 필터
export const PRODUCT_COMMON_FILTER = {
  BASE_MATERIAL: 0, // 베이스 소재
  SHAPE: 1, // 모양
  GEMSTONE: 2, // 원석
  POST_EARRING_MATERIAL: 3, // 귀걸이 침소
  CHAIN_SHAPE: 4, // 체인 모양
  COLOR: 5, // 컬러
  PRICE: 6, // 가격
  DISCOUNT: 7, // 할인
  STORE: 8, // 브랜드
  CATEGORY: 9, // 1차 카테고리
  SUB_CATEGORY: 10, // 2차 카테고리
} as const;

// 상품 공통 필터
export const PRODUCT_BASE_FILTER = omit(PRODUCT_COMMON_FILTER, ["STORE", "CATEGORY", "SUB_CATEGORY"]);

// 상품 정렬 공통 타입
export const PRODUCT_COMMON_SORT = {
  RECOMMENDATION: 0, // 추천순
  POPULAR: 1, // 인기순
  RECENT: 2, // 최신순
  CHEAP: 3, // 낮은가격순
  EXPENSIVE: 4, // 높은가격순
} as const;

// 상품 정렬 타입 공통 텍스트
export const PRODUCT_COMMON_SORT_TEXT = {
  [PRODUCT_COMMON_SORT.RECOMMENDATION]: "트렌드순",
  [PRODUCT_COMMON_SORT.POPULAR]: "인기순",
  [PRODUCT_COMMON_SORT.RECENT]: "신상품순",
  [PRODUCT_COMMON_SORT.CHEAP]: "낮은가격순",
  [PRODUCT_COMMON_SORT.EXPENSIVE]: "높은가격순",
} as const;

// 상품 정렬 타입 공통 리스트
export const PRODUCT_COMMON_SORT_LIST = [
  {
    id: PRODUCT_COMMON_SORT.RECOMMENDATION,
    name: PRODUCT_COMMON_SORT_TEXT[PRODUCT_COMMON_SORT.RECOMMENDATION],
  },
  {
    id: PRODUCT_COMMON_SORT.POPULAR,
    name: PRODUCT_COMMON_SORT_TEXT[PRODUCT_COMMON_SORT.POPULAR],
  },
  {
    id: PRODUCT_COMMON_SORT.RECENT,
    name: PRODUCT_COMMON_SORT_TEXT[PRODUCT_COMMON_SORT.RECENT],
  },
  {
    id: PRODUCT_COMMON_SORT.CHEAP,
    name: PRODUCT_COMMON_SORT_TEXT[PRODUCT_COMMON_SORT.CHEAP],
  },
  {
    id: PRODUCT_COMMON_SORT.EXPENSIVE,
    name: PRODUCT_COMMON_SORT_TEXT[PRODUCT_COMMON_SORT.EXPENSIVE],
  },
];

// 상품 필터 공통 탭
export const PRODUCT_COMMON_FILTER_TAP = {
  DETAIL_OPTION: 0, // 상세옵션
  COLOR: 1, // 컬러
  PRICE_DISCOUNT: 2, // 가격/할인율
  STORE: 8, // 브랜드
  CATEGORY: 9, // 카테고리
} as const;

// 상품 리스트 탭 타입 공통 텍스트
export const PRODUCT_COMMON_FILTER_TAP_TEXT = {
  [PRODUCT_COMMON_FILTER_TAP.DETAIL_OPTION]: "상세옵션",
  [PRODUCT_COMMON_FILTER_TAP.COLOR]: "컬러",
  [PRODUCT_COMMON_FILTER_TAP.PRICE_DISCOUNT]: "가격/할인율",
  [PRODUCT_COMMON_FILTER_TAP.STORE]: "브랜드",
  [PRODUCT_COMMON_FILTER_TAP.CATEGORY]: "카테고리",
} as const;

// 상품 필터 공통 타입 쿼리스트링 키
export const PRODUCT_COMMON_FILTER_TYPE_TO_QUERY_STRING_KEY = {
  [PRODUCT_COMMON_FILTER.BASE_MATERIAL]: "base",
  [PRODUCT_COMMON_FILTER.SHAPE]: "shape",
  [PRODUCT_COMMON_FILTER.GEMSTONE]: "gemstone",
  [PRODUCT_COMMON_FILTER.POST_EARRING_MATERIAL]: "postEarring",
  [PRODUCT_COMMON_FILTER.CHAIN_SHAPE]: "chainShape",
  [PRODUCT_COMMON_FILTER.COLOR]: "color",
  [PRODUCT_COMMON_FILTER.PRICE]: "price",
  [PRODUCT_COMMON_FILTER.DISCOUNT]: "discount",
  [PRODUCT_COMMON_FILTER.STORE]: "store",
  [PRODUCT_COMMON_FILTER.CATEGORY]: "category",
  [PRODUCT_COMMON_FILTER.SUB_CATEGORY]: "subCategory",
} as const;

// 상품 가격 필터 최소 값
export const MINIMUM_VALUE_OF_PRICE_FILTER = 2000;

// 상품 가격 필터 최대 값
export const MAXIMUM_VALUE_OF_PRICE_FILTER = 9999999;

// 상품 상세에서 노출되는 배송안내 타입 텍스트
export const PRODUCT_DETAIL_DELIVERY_TYPE_TEXT = {
  [PRODUCT_DETAIL_DELIVERY_TYPE.NORMAL]: "일반 배송 상품",
  [PRODUCT_DETAIL_DELIVERY_TYPE.ORDER_MADE]: "주문 제작 상품",
  [PRODUCT_DETAIL_DELIVERY_TYPE.IMMEDIATELY]: "바로 배송 상품",
  [PRODUCT_DETAIL_DELIVERY_TYPE.TODAY_DELIVERY]: "오늘 출발 상품",
} as const;

// 리스트 페이지 사이즈
export const LIST_PAGE_COUNT = 60;

// 상품 브랜드 필터 index 노출 할 수 있는 최소 브라우저 높이
export const BRAND_FILTER_MINIMUM_WINDOW_HEIGHT = 642;

// 상품 구매 수량 최소 값
export const MINIMUM_VALUE_OF_PRODUCT_PURCHASE_COUNT = 1;

// 상품 구매 수량 최대 값
export const MAXIMUM_VALUE_OF_PRODUCT_PURCHASE_COUNT = 99;

// 가격 할인 타입 단위 텍스트
export const DISCOUNT_TYPE_UNIT_TEXT = {
  [DISCOUNT_TYPE.AMOUNT]: "원",
  [DISCOUNT_TYPE.RATE]: "%",
};

// 브랜드 필터 검색 키워드 최대 글자수
export const MAXIMUM_NUMBER_OF_BRAND_FILTER_SEARCH_KEYWORD = 50;

// 상품 카테고리 식별 id
export const PRODUCT_CATEGORY_ID = {
  EARRING: 1, // 귀걸이
  NECKLACE: 2, // 목걸이
  BRACELET: 4, // 팔찌
  ANKLET: 7, // 발찌
};

// 체인 모양 필터 선택 가능한 카테고리 식별 id 리스트
export const ENABLE_CHAIN_SHAPE_FILTER_PRODUCT_CATEGORY_ID_LIST = [
  PRODUCT_CATEGORY_ID.NECKLACE,
  PRODUCT_CATEGORY_ID.BRACELET,
  PRODUCT_CATEGORY_ID.ANKLET,
];

// 상품 필터 옵션 타입
export const PRODUCT_FILTER_OPTION_ID = {
  SILVER_NEEDLE: 216, // 은침
  GOLD_NEEDLE: 217, // 골드침
  TITANIUM_NEEDLE: 221, // 티타늄침
  NICKEL_FREE_NEEDLE: 218, // 무니켈침
  SURGICAL_NEEDLE: 220, // 써지컬침
  GOLD_PLATED_NEEDLE: 447, // 골드도금침
  SNAKE_CHAIN: 385, // 스네이크체인
  HIGH_CHAIN: 378, // 고방체인
  ROPE_CHAIN: 382, // 로프체인
  CURVE_CHAIN: 379, // 커브체인
  FIGARO_CHAIN: 383, // 피가로체인
  CLIP_CHAIN: 456, // 클립체인
  MIX_CHAIN: 514, // 믹스체인
  BOX_CHAIN: 377, // 박스체인
  BALL_CHAIN: 376, // 볼체인
  GOLD_LIST: 226, // 10K골드/14K골드/18K골드
  SILVER_925: 235, // 실버925
  BRACE: 236, // 브라스
  SURGE_CURL_STEEL: 238, // 써지컬스틸
  EXTRA_MATERIALS: 240, // 기타소재
  HEART: 357, // 하트
  STAR_PIECE: 500, // 별조각
  COIN: 362, // 코인
  KEY_AND_LOCK: 513, // key_and_lock
  BALL: 366, // 볼
  OBJET: 361, // 오브제
  SQUARE: 354, // 스퀘어
  TWIST: 369, // 꼬임
  METING: 458, // 멜팅
  BAR: 388, // 바
  ENGRAVE: 368, // 각인
  INITIAL: 372, // 이니셜
  FISH_HOOK: 501, // 피시후크
  BOLD: 365, // 볼드
  TOGGLE: 386, // 토글
  CUBIC_ZIRCONIA: 286, // 큐빅 지르코니아
  PEARL: 281, // 진주
  DIAMOND: 285, // 다이아몬드
  ONYX: 305, // 오닉스
  NATURAL_GEMSTONE: 280, // 천연원석
  MOONSTONE: 296, // 문스톤
  MOTHER_PEARL: 307, // 자개
  TOPAZ: 311, // 토파즈
  GARNETT: 289, // 가넷
  SILVER: 317, // 실버
  RED: 323, // 레드
  GOLD: 316, // 골드
  YELLOW: 327, // 옐로우
  WHITE_GOLD: 223, // 화이트골드
  GREEN: 330, // 그린
  ROSE_GOLD: 224, // 로즈골드
  BLUE: 334, // 블루
  COMBI: 340, // 콤비
  PINK: 337, // 핑크
  BLACK: 318, // 블랙
  PURPLE: 339, // 퍼플
  WHITE: 320, // 화이트
  TRANSPARENT: 341, // 투명
  MULTI: 352, // 멀티
};

// 상품 베이스 소재 - 추가 골드 리스트 (14K/18K)
export const PRODUCT_BASE_MATERIAL_EXTRA_GOLD_ID_LIST = [227, 228];

// 상품 옵션 타입 텍스트
export const PRODUCT_FILTER_OPTION_TEXT = {
  [PRODUCT_FILTER_OPTION_ID.SILVER_NEEDLE]: "은침",
  [PRODUCT_FILTER_OPTION_ID.GOLD_NEEDLE]: "골드침",
  [PRODUCT_FILTER_OPTION_ID.TITANIUM_NEEDLE]: "티타늄침",
  [PRODUCT_FILTER_OPTION_ID.NICKEL_FREE_NEEDLE]: "무니켈침",
  [PRODUCT_FILTER_OPTION_ID.SURGICAL_NEEDLE]: "써지컬침",
  [PRODUCT_FILTER_OPTION_ID.GOLD_PLATED_NEEDLE]: "골드도금침",
  [PRODUCT_FILTER_OPTION_ID.SNAKE_CHAIN]: "스네이크체인",
  [PRODUCT_FILTER_OPTION_ID.HIGH_CHAIN]: "고방체인",
  [PRODUCT_FILTER_OPTION_ID.ROPE_CHAIN]: "로프체인",
  [PRODUCT_FILTER_OPTION_ID.CURVE_CHAIN]: "커브체인",
  [PRODUCT_FILTER_OPTION_ID.FIGARO_CHAIN]: "피가로체인",
  [PRODUCT_FILTER_OPTION_ID.CLIP_CHAIN]: "클립체인",
  [PRODUCT_FILTER_OPTION_ID.MIX_CHAIN]: "믹스체인",
  [PRODUCT_FILTER_OPTION_ID.BOX_CHAIN]: "박스체인",
  [PRODUCT_FILTER_OPTION_ID.BALL_CHAIN]: "볼체인",
  [PRODUCT_FILTER_OPTION_ID.GOLD_LIST]: "10K/14K/18K골드",
  [PRODUCT_FILTER_OPTION_ID.SILVER_925]: "실버925",
  [PRODUCT_FILTER_OPTION_ID.BRACE]: "브라스",
  [PRODUCT_FILTER_OPTION_ID.SURGE_CURL_STEEL]: "써지컬스틸",
  [PRODUCT_FILTER_OPTION_ID.EXTRA_MATERIALS]: "기타소재",
  [PRODUCT_FILTER_OPTION_ID.HEART]: "하트",
  [PRODUCT_FILTER_OPTION_ID.STAR_PIECE]: "별조각",
  [PRODUCT_FILTER_OPTION_ID.COIN]: "코인",
  [PRODUCT_FILTER_OPTION_ID.KEY_AND_LOCK]: "열쇠/자물쇠",
  [PRODUCT_FILTER_OPTION_ID.BALL]: "볼",
  [PRODUCT_FILTER_OPTION_ID.OBJET]: "오브제",
  [PRODUCT_FILTER_OPTION_ID.SQUARE]: "스퀘어",
  [PRODUCT_FILTER_OPTION_ID.TWIST]: "꼬임",
  [PRODUCT_FILTER_OPTION_ID.METING]: "멜팅",
  [PRODUCT_FILTER_OPTION_ID.BAR]: "바",
  [PRODUCT_FILTER_OPTION_ID.ENGRAVE]: "각인",
  [PRODUCT_FILTER_OPTION_ID.INITIAL]: "이니셜",
  [PRODUCT_FILTER_OPTION_ID.FISH_HOOK]: "피시후크",
  [PRODUCT_FILTER_OPTION_ID.BOLD]: "볼드",
  [PRODUCT_FILTER_OPTION_ID.TOGGLE]: "토글",
  [PRODUCT_FILTER_OPTION_ID.CUBIC_ZIRCONIA]: "큐빅 지르코니아",
  [PRODUCT_FILTER_OPTION_ID.PEARL]: "진주",
  [PRODUCT_FILTER_OPTION_ID.DIAMOND]: "다이아몬드",
  [PRODUCT_FILTER_OPTION_ID.ONYX]: "오닉스",
  [PRODUCT_FILTER_OPTION_ID.NATURAL_GEMSTONE]: "천연원석",
  [PRODUCT_FILTER_OPTION_ID.MOONSTONE]: "문스톤",
  [PRODUCT_FILTER_OPTION_ID.MOTHER_PEARL]: "자개",
  [PRODUCT_FILTER_OPTION_ID.TOPAZ]: "토파즈",
  [PRODUCT_FILTER_OPTION_ID.GARNETT]: "가넷",
  [PRODUCT_FILTER_OPTION_ID.SILVER]: "실버",
  [PRODUCT_FILTER_OPTION_ID.RED]: "레드",
  [PRODUCT_FILTER_OPTION_ID.GOLD]: "골드",
  [PRODUCT_FILTER_OPTION_ID.YELLOW]: "옐로우",
  [PRODUCT_FILTER_OPTION_ID.WHITE_GOLD]: "화이트골드",
  [PRODUCT_FILTER_OPTION_ID.GREEN]: "그린",
  [PRODUCT_FILTER_OPTION_ID.ROSE_GOLD]: "로즈골드",
  [PRODUCT_FILTER_OPTION_ID.BLUE]: "블루",
  [PRODUCT_FILTER_OPTION_ID.COMBI]: "콤비",
  [PRODUCT_FILTER_OPTION_ID.PINK]: "핑크",
  [PRODUCT_FILTER_OPTION_ID.BLACK]: "블랙",
  [PRODUCT_FILTER_OPTION_ID.PURPLE]: "퍼플",
  [PRODUCT_FILTER_OPTION_ID.WHITE]: "화이트",
  [PRODUCT_FILTER_OPTION_ID.TRANSPARENT]: "투명",
  [PRODUCT_FILTER_OPTION_ID.MULTI]: "멀티",
};

// 상품 귀걸이침 필터 옵션 id 리스트
export const PRODUCT_POST_EARRING_FILTER_OPTION_ID_LIST = [
  PRODUCT_FILTER_OPTION_ID.SILVER_NEEDLE,
  PRODUCT_FILTER_OPTION_ID.GOLD_NEEDLE,
  PRODUCT_FILTER_OPTION_ID.TITANIUM_NEEDLE,
  PRODUCT_FILTER_OPTION_ID.NICKEL_FREE_NEEDLE,
  PRODUCT_FILTER_OPTION_ID.SURGICAL_NEEDLE,
  PRODUCT_FILTER_OPTION_ID.GOLD_PLATED_NEEDLE,
];

// 상품 귀걸이침 필터 옵션 리스트
export const PRODUCT_POST_EARRING_FILTER_OPTION_LIST = [
  {
    id: PRODUCT_FILTER_OPTION_ID.SILVER_NEEDLE,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.SILVER_NEEDLE],
  },
  {
    id: PRODUCT_FILTER_OPTION_ID.GOLD_NEEDLE,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.GOLD_NEEDLE],
  },
  {
    id: PRODUCT_FILTER_OPTION_ID.TITANIUM_NEEDLE,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.TITANIUM_NEEDLE],
  },
  {
    id: PRODUCT_FILTER_OPTION_ID.NICKEL_FREE_NEEDLE,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.NICKEL_FREE_NEEDLE],
  },
  {
    id: PRODUCT_FILTER_OPTION_ID.SURGICAL_NEEDLE,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.SURGICAL_NEEDLE],
  },
  {
    id: PRODUCT_FILTER_OPTION_ID.GOLD_PLATED_NEEDLE,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.GOLD_PLATED_NEEDLE],
  },
];

// 상품 체인 모양 필터 옵션 id 리스트
export const PRODUCT_CHAIN_SHAPE_FILTER_OPTION_ID_LIST = [
  PRODUCT_FILTER_OPTION_ID.SNAKE_CHAIN,
  PRODUCT_FILTER_OPTION_ID.HIGH_CHAIN,
  PRODUCT_FILTER_OPTION_ID.ROPE_CHAIN,
  PRODUCT_FILTER_OPTION_ID.CURVE_CHAIN,
  PRODUCT_FILTER_OPTION_ID.FIGARO_CHAIN,
  PRODUCT_FILTER_OPTION_ID.CLIP_CHAIN,
  PRODUCT_FILTER_OPTION_ID.MIX_CHAIN,
  PRODUCT_FILTER_OPTION_ID.BOX_CHAIN,
  PRODUCT_FILTER_OPTION_ID.BALL_CHAIN,
];

// 상품 체인 모양 필터 옵션 리스트
export const PRODUCT_CHAIN_SHAPE_FILTER_OPTION_LIST = [
  {
    id: PRODUCT_FILTER_OPTION_ID.SNAKE_CHAIN,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.SNAKE_CHAIN],
  },
  {
    id: PRODUCT_FILTER_OPTION_ID.HIGH_CHAIN,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.HIGH_CHAIN],
  },
  {
    id: PRODUCT_FILTER_OPTION_ID.ROPE_CHAIN,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.ROPE_CHAIN],
  },
  {
    id: PRODUCT_FILTER_OPTION_ID.CURVE_CHAIN,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.CURVE_CHAIN],
  },
  {
    id: PRODUCT_FILTER_OPTION_ID.FIGARO_CHAIN,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.FIGARO_CHAIN],
  },
  {
    id: PRODUCT_FILTER_OPTION_ID.CLIP_CHAIN,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.CLIP_CHAIN],
  },
  {
    id: PRODUCT_FILTER_OPTION_ID.MIX_CHAIN,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.MIX_CHAIN],
  },
  {
    id: PRODUCT_FILTER_OPTION_ID.BOX_CHAIN,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.BOX_CHAIN],
  },
  {
    id: PRODUCT_FILTER_OPTION_ID.BALL_CHAIN,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.BALL_CHAIN],
  },
];

// 상품 베이스 소재 필터 옵션 타입 리스트
export const PRODUCT_BASE_MATERIAL_FILTER_OPTION_LIST = [
  {
    id: PRODUCT_FILTER_OPTION_ID.GOLD_LIST,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.GOLD_LIST],
  },
  {
    id: PRODUCT_FILTER_OPTION_ID.SILVER_925,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.SILVER_925],
  },
  {
    id: PRODUCT_FILTER_OPTION_ID.BRACE,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.BRACE],
  },
  {
    id: PRODUCT_FILTER_OPTION_ID.SURGE_CURL_STEEL,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.SURGE_CURL_STEEL],
  },
  {
    id: PRODUCT_FILTER_OPTION_ID.EXTRA_MATERIALS,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.EXTRA_MATERIALS],
  },
];

// 상품 모양 필터 옵션 타입 리스트
export const PRODUCT_SHAPE_FILTER_OPTION_LIST = [
  {
    id: PRODUCT_FILTER_OPTION_ID.HEART,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.HEART],
  },
  {
    id: PRODUCT_FILTER_OPTION_ID.STAR_PIECE,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.STAR_PIECE],
  },
  {
    id: PRODUCT_FILTER_OPTION_ID.COIN,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.COIN],
  },
  {
    id: PRODUCT_FILTER_OPTION_ID.KEY_AND_LOCK,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.KEY_AND_LOCK],
  },
  {
    id: PRODUCT_FILTER_OPTION_ID.BALL,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.BALL],
  },
  {
    id: PRODUCT_FILTER_OPTION_ID.OBJET,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.OBJET],
  },
  {
    id: PRODUCT_FILTER_OPTION_ID.SQUARE,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.SQUARE],
  },
  {
    id: PRODUCT_FILTER_OPTION_ID.TWIST,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.TWIST],
  },
  {
    id: PRODUCT_FILTER_OPTION_ID.METING,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.METING],
  },
  {
    id: PRODUCT_FILTER_OPTION_ID.BAR,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.BAR],
  },
  {
    id: PRODUCT_FILTER_OPTION_ID.ENGRAVE,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.ENGRAVE],
  },
  {
    id: PRODUCT_FILTER_OPTION_ID.INITIAL,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.INITIAL],
  },
  {
    id: PRODUCT_FILTER_OPTION_ID.FISH_HOOK,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.FISH_HOOK],
  },
  {
    id: PRODUCT_FILTER_OPTION_ID.BOLD,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.BOLD],
  },
  {
    id: PRODUCT_FILTER_OPTION_ID.TOGGLE,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.TOGGLE],
  },
];

// 상품 원석 필터 옵션 리스트
export const PRODUCT_GEMSTONE_FILTER_OPTION_LIST = [
  {
    id: PRODUCT_FILTER_OPTION_ID.CUBIC_ZIRCONIA,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.CUBIC_ZIRCONIA],
  },
  {
    id: PRODUCT_FILTER_OPTION_ID.PEARL,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.PEARL],
  },
  {
    id: PRODUCT_FILTER_OPTION_ID.DIAMOND,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.DIAMOND],
  },
  {
    id: PRODUCT_FILTER_OPTION_ID.ONYX,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.ONYX],
  },
  {
    id: PRODUCT_FILTER_OPTION_ID.NATURAL_GEMSTONE,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.NATURAL_GEMSTONE],
  },
  {
    id: PRODUCT_FILTER_OPTION_ID.MOONSTONE,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.MOONSTONE],
  },
  {
    id: PRODUCT_FILTER_OPTION_ID.MOTHER_PEARL,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.MOTHER_PEARL],
  },
  {
    id: PRODUCT_FILTER_OPTION_ID.TOPAZ,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.TOPAZ],
  },
  {
    id: PRODUCT_FILTER_OPTION_ID.GARNETT,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.GARNETT],
  },
];

// 상품 컬러 필터 옵션 타입 리스트
export const PRODUCT_COLOR_FILTER_OPTION_LIST = [
  {
    id: PRODUCT_FILTER_OPTION_ID.SILVER,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.SILVER],
  },
  {
    id: PRODUCT_FILTER_OPTION_ID.RED,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.RED],
  },
  {
    id: PRODUCT_FILTER_OPTION_ID.GOLD,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.GOLD],
  },
  {
    id: PRODUCT_FILTER_OPTION_ID.YELLOW,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.YELLOW],
  },
  {
    id: PRODUCT_FILTER_OPTION_ID.WHITE_GOLD,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.WHITE_GOLD],
  },
  {
    id: PRODUCT_FILTER_OPTION_ID.GREEN,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.GREEN],
  },
  {
    id: PRODUCT_FILTER_OPTION_ID.ROSE_GOLD,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.ROSE_GOLD],
  },
  {
    id: PRODUCT_FILTER_OPTION_ID.BLUE,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.BLUE],
  },
  {
    id: PRODUCT_FILTER_OPTION_ID.COMBI,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.COMBI],
  },
  {
    id: PRODUCT_FILTER_OPTION_ID.PINK,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.PINK],
  },
  {
    id: PRODUCT_FILTER_OPTION_ID.BLACK,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.BLACK],
  },
  {
    id: PRODUCT_FILTER_OPTION_ID.PURPLE,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.PURPLE],
  },
  {
    id: PRODUCT_FILTER_OPTION_ID.WHITE,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.WHITE],
  },
  {
    id: PRODUCT_FILTER_OPTION_ID.TRANSPARENT,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.TRANSPARENT],
  },
  {
    id: PRODUCT_FILTER_OPTION_ID.MULTI,
    name: PRODUCT_FILTER_OPTION_TEXT[PRODUCT_FILTER_OPTION_ID.MULTI],
  },
];

// 상품 컬러 필터 옵션 색상 CLASSNAME
export const PRODUCT_COLOR_FILTER_OPTION_CLASS_NAME = {
  [PRODUCT_FILTER_OPTION_ID.SILVER]: "silver",
  [PRODUCT_FILTER_OPTION_ID.RED]: "red",
  [PRODUCT_FILTER_OPTION_ID.GOLD]: "gold",
  [PRODUCT_FILTER_OPTION_ID.YELLOW]: "yellow",
  [PRODUCT_FILTER_OPTION_ID.WHITE_GOLD]: "white-gold",
  [PRODUCT_FILTER_OPTION_ID.GREEN]: "green",
  [PRODUCT_FILTER_OPTION_ID.ROSE_GOLD]: "rose-gold",
  [PRODUCT_FILTER_OPTION_ID.BLUE]: "blue",
  [PRODUCT_FILTER_OPTION_ID.COMBI]: "combi",
  [PRODUCT_FILTER_OPTION_ID.PINK]: "pink",
  [PRODUCT_FILTER_OPTION_ID.BLACK]: "black",
  [PRODUCT_FILTER_OPTION_ID.PURPLE]: "purple",
  [PRODUCT_FILTER_OPTION_ID.WHITE]: "white",
  [PRODUCT_FILTER_OPTION_ID.TRANSPARENT]: "transparent",
  [PRODUCT_FILTER_OPTION_ID.MULTI]: "multi",
};

// 상품 가격 필터 옵션 타입
export const PRODUCT_PRICE_FILTER_OPTION_ID = {
  // 1만원 ~ 3만원
  ONE_TO_THREE: "10000|30000",
  // 3만원 ~ 5만원
  THREE_TO_FIVE: "30000|50000",
  // 5만원 ~ 10만원
  FIVE_TO_TEN: "50000|100000",
  // 10만원이상
  OVER_TEN: "100000|9999999",
};

// 상품 가격 필터 옵션 타입 텍스트
export const PRODUCT_PRICE_FILTER_OPTION_TEXT = {
  [PRODUCT_PRICE_FILTER_OPTION_ID.ONE_TO_THREE]: "1만원 ~ 3만원",
  [PRODUCT_PRICE_FILTER_OPTION_ID.THREE_TO_FIVE]: "3만원 ~ 5만원",
  [PRODUCT_PRICE_FILTER_OPTION_ID.FIVE_TO_TEN]: "5만원 ~ 10만원",
  [PRODUCT_PRICE_FILTER_OPTION_ID.OVER_TEN]: "10만원 이상",
};

// 상품 가격 필터 옵션 타입 리스트
export const PRODUCT_PRICE_FILTER_OPTION_LIST = [
  {
    id: PRODUCT_PRICE_FILTER_OPTION_ID.ONE_TO_THREE,
    name: PRODUCT_PRICE_FILTER_OPTION_TEXT[PRODUCT_PRICE_FILTER_OPTION_ID.ONE_TO_THREE],
  },
  {
    id: PRODUCT_PRICE_FILTER_OPTION_ID.THREE_TO_FIVE,
    name: PRODUCT_PRICE_FILTER_OPTION_TEXT[PRODUCT_PRICE_FILTER_OPTION_ID.THREE_TO_FIVE],
  },
  {
    id: PRODUCT_PRICE_FILTER_OPTION_ID.FIVE_TO_TEN,
    name: PRODUCT_PRICE_FILTER_OPTION_TEXT[PRODUCT_PRICE_FILTER_OPTION_ID.FIVE_TO_TEN],
  },
  {
    id: PRODUCT_PRICE_FILTER_OPTION_ID.OVER_TEN,
    name: PRODUCT_PRICE_FILTER_OPTION_TEXT[PRODUCT_PRICE_FILTER_OPTION_ID.OVER_TEN],
  },
];

// 상품 할인 필터 옵션 타입
export const PRODUCT_DISCOUNT_FILTER_OPTION_ID = {
  // 10% 이상 할인
  OVER_TEN: 10,
  // 20% 이상 할인
  OVER_TWENTY: 20,
  // 30% 이상 할인
  OVER_THIRTY: 30,
  // 40% 이상 할인
  OVER_FORTY: 40,
  // 50% 이상 할인
  OVER_FIFTY: 50,
};

// 상품 할인 필터 옵션 타입 텍스트
export const PRODUCT_DISCOUNT_FILTER_OPTION_TEXT = {
  [PRODUCT_DISCOUNT_FILTER_OPTION_ID.OVER_TEN]: "10% 이상 할인",
  [PRODUCT_DISCOUNT_FILTER_OPTION_ID.OVER_TWENTY]: "20% 이상 할인",
  [PRODUCT_DISCOUNT_FILTER_OPTION_ID.OVER_THIRTY]: "30% 이상 할인",
  [PRODUCT_DISCOUNT_FILTER_OPTION_ID.OVER_FORTY]: "40% 이상 할인",
  [PRODUCT_DISCOUNT_FILTER_OPTION_ID.OVER_FIFTY]: "50% 이상 할인",
};

// 상품 할인 필터 옵션 타입 리스트
export const PRODUCT_DISCOUNT_FILTER_OPTION_LIST = [
  {
    id: PRODUCT_DISCOUNT_FILTER_OPTION_ID.OVER_TEN,
    name: PRODUCT_DISCOUNT_FILTER_OPTION_TEXT[PRODUCT_DISCOUNT_FILTER_OPTION_ID.OVER_TEN],
  },
  {
    id: PRODUCT_DISCOUNT_FILTER_OPTION_ID.OVER_TWENTY,
    name: PRODUCT_DISCOUNT_FILTER_OPTION_TEXT[PRODUCT_DISCOUNT_FILTER_OPTION_ID.OVER_TWENTY],
  },
  {
    id: PRODUCT_DISCOUNT_FILTER_OPTION_ID.OVER_THIRTY,
    name: PRODUCT_DISCOUNT_FILTER_OPTION_TEXT[PRODUCT_DISCOUNT_FILTER_OPTION_ID.OVER_THIRTY],
  },
  {
    id: PRODUCT_DISCOUNT_FILTER_OPTION_ID.OVER_FORTY,
    name: PRODUCT_DISCOUNT_FILTER_OPTION_TEXT[PRODUCT_DISCOUNT_FILTER_OPTION_ID.OVER_FORTY],
  },
  {
    id: PRODUCT_DISCOUNT_FILTER_OPTION_ID.OVER_FIFTY,
    name: PRODUCT_DISCOUNT_FILTER_OPTION_TEXT[PRODUCT_DISCOUNT_FILTER_OPTION_ID.OVER_FIFTY],
  },
];

// 상품 상세 필터 옵션 타입 리스트
export const PRODUCT_FILTER_DETAIL_TAB_OPTION_ID_LIST = [
  PRODUCT_COMMON_FILTER.BASE_MATERIAL,
  PRODUCT_COMMON_FILTER.SHAPE,
  PRODUCT_COMMON_FILTER.GEMSTONE,
  PRODUCT_COMMON_FILTER.POST_EARRING_MATERIAL,
  PRODUCT_COMMON_FILTER.CHAIN_SHAPE,
];

// 상품 컬러 필터 옵션 타입
export const PRODUCT_FILTER_COLOR_TAB_OPTION_ID = PRODUCT_COMMON_FILTER.COLOR;

// 상품 가격/할인율 필터 옵션 타입 리스트
export const PRODUCT_FILTER_PRICE_DISCOUNT_TAB_OPTION_ID_LIST = [
  PRODUCT_COMMON_FILTER.PRICE,
  PRODUCT_COMMON_FILTER.DISCOUNT,
];

// 카테고리 필터에서 상품 2차 카테고리를 초기화 하기 위한 번호
export const PRODUCT_CATEGORY_FILTER_INIT_NUMBER = 0;

// 상품 필터에서 선택한 필터를 모두 리셋시키기 위한 번호
export const PRODUCT_FILTER_RESET_NUMBER = -1;

// 페이지를 클릭한 걸 알려주는 Flag 번호(페이지를 클릭했을 때는 스크롤이 상단으로 올라가야 하기 때문에 구분이 필요)
export const SCROLL_TOP_FLAG_NUMBER = -2;

// 라우터 쿼리에 페이지네이션만 사용할 경우
export const ONLY_USE_PAGINATION_QUERY_NUMBER = -3;

export const BIRTHDAY_OPEN_DATE = new Date(1990, 0, 1); // 월은 0부터 시작
export const BIRTHDAY_START_DATE = new Date(1920, 0, 1); // 월은 0부터 시작
export const BIRTHDAY_END_DATE = setDate(setMonth(subYears(new Date(), 8), 11), 31);
