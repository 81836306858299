import Modal from "@components/common/Modal";
import styled from "@styles/themedComponents";
import { DOWNLOAD_ARROW_IMAGE_PATH } from "@constants/static/images";

interface ICategoryListStyleProps {
  visible: boolean;
}

export const HeaderMenuModalStyle = styled(Modal)`
  .modal-box {
    .modal-full-screen-header {
      .modal-close-btn-section {
        padding: 16px 15px;
        height: 56px;
      }
    }

    .modal-body {
      padding: 10px 25px 0;
      height: 100%;

      .modal-contents {
        height: 100%;

        .modal-header-section {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 56px;

          img {
            width: 85px;
            height: 18px;
            cursor: pointer;
          }

          .auth-menu {
            display: flex;
            padding-right: 5px;

            & > li {
              &:not(:last-child) {
                margin-right: 30px;
              }
            }

            a {
              display: inline-block;
              height: 100%;
              font-family: ${(props) => props.theme.fonts.notoSansCJKkr};
              font-size: 15px;
              font-weight: 500;
              letter-spacing: -0.58px;
              color: ${(props) => props.theme.color.gray_01};
              cursor: pointer;
            }
          }
        }

        .modal-content-section {
          height: calc(100% - 112px);
          padding-bottom: 48px;
          overflow: auto;
          ${(props) => props.theme.invisibleScroll};

          .product-category-list-section {
            padding-bottom: 20px;

            .product-category-list-section-title {
              margin: 20px 0 10px;
              padding: 21px 0 20px;
              font-size: 18px;
              font-weight: bold;
              letter-spacing: -0.5px;
              border-bottom: 1px solid ${(props) => props.theme.color.gray_07};
            }

            .product-category-list {
              padding-left: 16px;

              li {
                display: inline-block;
                width: 50%;
                line-height: 42px;
                height: 42px;
                font-size: 15px;
                letter-spacing: -0.5px;

                .category-button {
                  width: 100%;
                  height: 100%;
                }
              }

              .show-more-button {
                a {
                  cursor: pointer;
                  font-weight: 500;
                  font-size: 13px;
                  color: ${({ theme }) => theme.color.gray_01};
                  &:after {
                    content: " +";
                  }
                }
              }
            }
          }

          .brand-menu-section {
            cursor: pointer;
            margin-bottom: 25px;
            border-bottom: 1px solid ${(props) => props.theme.color.gray_07};

            .brand-list-page-btn {
              display: block;
              width: 100%;
              height: 100%;
              padding: 21px 0 20px;
              font-size: 18px;
              font-weight: bold;
              letter-spacing: -0.5px;
            }
          }

          .app-download-banner-section {
            height: 95px;
            border-radius: 13px;
            background-color: ${({ theme }) => theme.color.gray_08};

            a {
              display: inline-block;
              padding: 15px;
              width: 100%;
              height: 100%;

              .app-download-banner {
                position: relative;
                width: 100%;

                .banner-logo {
                  display: inline-block;

                  img {
                    width: 67px;
                    height: 14px;
                  }
                }

                .banner-title {
                  font-size: 13px;
                  letter-spacing: -0.5px;
                  margin: 12px 0 3px;
                  line-height: 1.54;
                  font-weight: 500;
                }

                .banner-sub-title {
                  font-size: 13px;
                  letter-spacing: -0.5px;
                }

                & > span {
                  position: absolute;
                  top: 0;
                  right: 0;
                  font-size: 12px;
                  letter-spacing: -0.3px;
                  font-weight: 500;
                  color: ${({ theme }) => theme.color.gray_01};

                  &:after {
                    content: " ";
                    display: inline-block;
                    margin-left: 4px;
                    width: 16px;
                    height: 16px;
                    vertical-align: text-bottom;
                    background-image: url(${DOWNLOAD_ARROW_IMAGE_PATH});
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const CategoryListStyle = styled.li<ICategoryListStyleProps>`
  display: ${({ visible }) => (visible ? "inline-block" : "none")} !important;
`;
