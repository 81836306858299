import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import Router, { useRouter } from "next/router";
import { isNil } from "lodash";
import { REMOTE_CONFIG_KEY, useFirebaseRemoteConfig } from "@amondz/firebase";
import { REX_EMOJI_PATTERN } from "@amondz/constants-reg";

import { RootStateType } from "@store/modules";
import {
  eventSearchCount,
  integrationSearchCount,
  productSearchCount,
  reviewSearchCount,
  searchInputType,
  searchModalStateType,
  searchModalVisible,
  setLastSearchKeyword,
  setRecentSearchKeywordList,
  setSearchKeyword,
  storeSearchCount,
} from "@store/modules/search";
import Input from "@components/common/Input";
import { GlobalStyle } from "@components/base/header/Header/style";
import useSearchBeforeIntegrationList from "@swr/hooks/search/useSearchBeforeIntegrationList";
import { SEARCH_RESULT_PAGE_URL_PATH } from "@constants/url/internalUrlConstants";
import { CLEAR_INPUT_BTN_IMAGE_PATH, SEARCH_GRAY_BTN_24_IMAGE_PATH } from "@constants/static/images";
import { MAXIMUM_NUMBER_OF_SEARCH_KEYWORD, RECENT_SEARCH_WORD_MAX_LENGTH } from "@constants/service/search/search";
import SearchRecommendedProductList from "@views/search/AfterSearch/screen/empty/SearchRecommendedProductList";
import SearchWordButtonList from "@views/search/common/SearchWordButtonList";
import RecentSearchWordList from "@views/search/BeforeSearch/RecentSearchWordList";
import SearchModalInfoBaseSection from "@views/search/BeforeSearch/SearchModalInfoBaseSection";
import AutoCompletedSearchList from "@views/search/BeforeSearch/AutoCompletedSearchList";
import {
  HiddenInputStyle,
  RecentSearchListClearButton,
  SearchFormStyle,
  SearchIconStyle,
  SearchInputClearButtonStyle,
  SearchInputSectionStyle,
  SearchInputSubmitButton,
  SearchModalCloseButtonStyle,
  SearchModalStyle,
} from "./style";

const SearchModal = () => {
  const { searchKeyword, lastSearchKeyword, recentSearchKeywordList, isSearchModalVisible } = useSelector<
    RootStateType,
    searchModalStateType
  >((state: RootStateType) => state.search.searchModalState);

  const dispatch = useDispatch();
  const router = useRouter();
  const [isAutoCompleteDataExisted, setIsAutoCompleteDataExisted] = useState<boolean>(false);
  const containerEl = useRef<HTMLDivElement>(null);
  const formMethods = useForm<searchInputType>({
    mode: "onChange",
    criteriaMode: "firstError",
  });
  const inputEl = useRef<HTMLInputElement>(null);
  const hiddenInputRef = useRef<HTMLInputElement>(null);
  const { getString } = useFirebaseRemoteConfig();

  const searchPlaceHolder = getString(REMOTE_CONFIG_KEY.SEARCH_PLACEHOLDER);

  // 검색 키워드 설정(디바운싱 적용)
  const onChangeSearchKeyword = (e: KeyboardEvent) => {
    // 추천 검색어 키보드로 고를 경우 API 요청하지 않음
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      return;
    }
    if (inputEl.current) {
      dispatch(setSearchKeyword({ searchKeyword: inputEl.current.value.trim() }));
    }
  };

  // 검색 중 이벤트 설정
  useEffect(() => {
    window.addEventListener("keyup", onChangeSearchKeyword);
    return () => {
      window.removeEventListener("keyup", onChangeSearchKeyword);
    };
  }, []);

  // 검색 모달 영역 외 클릭 이벤트 리스너 등록 / 해지
  useEffect(() => {
    window.addEventListener("click", onClickOutside);
    return () => window.removeEventListener("click", onClickOutside);
  }, [isSearchModalVisible]);

  const { data: searchBeforeIntegrationListData } = useSearchBeforeIntegrationList();

  // 자동 검색어가 존재하는지 state change 함수
  const onChangeAutoCompleteDataExisted = useCallback((existed: boolean) => {
    setIsAutoCompleteDataExisted(existed);
  }, []);

  // 검색어 입력창에 이모지를 사용하면 빈스트링으로 치환하여 입력 방지
  const onChangeSearchKeywordInput = (value: string): void => {
    if (REX_EMOJI_PATTERN.test(value)) {
      const replacedSearchKeyword = value.replace(REX_EMOJI_PATTERN, "");
      formMethods.setValue("keyword", replacedSearchKeyword);
    }
  };

  // 검색 모달 영역 외 클릭시 모달 비활성화
  const onClickOutside = useCallback(
    (e: MouseEvent) => {
      const target = e.target as HTMLElement;

      if (!containerEl.current || (containerEl.current! as any).contains(e.target)) return;
      if (target?.tagName === "svg") return;
      if (
        target?.className &&
        (target.className.includes("search-keyword-text") ||
          target.className.includes("mobile-search-keyword-text") ||
          target.className.includes("search-keyword-box") ||
          target.className.includes("search-keyword-section") ||
          target.className.includes("clear-search-input-btn") ||
          target.className.includes("search-btn"))
      )
        return;
      dispatch(setSearchKeyword({ searchKeyword: lastSearchKeyword.trim() }));
      dispatch(searchModalVisible({ isSearchModalVisible: false }));
    },
    [containerEl, isSearchModalVisible],
  );

  const onHandleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      // 검색어가 빈 스트링이 아닐경우 로직 수행
      if (inputEl.current?.value && inputEl.current?.value.trim() !== "") {
        // 같은 검색어 입력할 경우 모달 닫기
        if (inputEl.current.value.trim() === lastSearchKeyword) {
          dispatch(searchModalVisible({ isSearchModalVisible: false }));
        } else {
          // 다른 검색어를 쳤을 경우 검색 결과 수 초기화 및 최근 검색어 로직 수행
          dispatch(integrationSearchCount({ count: 0 }));
          dispatch(productSearchCount({ count: 0 }));
          dispatch(storeSearchCount({ count: 0 }));
          dispatch(reviewSearchCount({ count: 0 }));
          dispatch(eventSearchCount({ count: 0 }));

          // 재정의한 최근 검색어 리스트
          let returnSearchKeywordList: string[] = [];
          // 중복 단어 인 경우 삭제하고 리스트 맨 앞에 삽입
          if (recentSearchKeywordList.includes(inputEl.current.value.trim())) {
            const filterList = recentSearchKeywordList.filter((keyword) => keyword !== inputEl.current?.value.trim());
            returnSearchKeywordList = [inputEl.current.value.trim(), ...filterList];
            dispatch(
              setRecentSearchKeywordList({ recentSearchKeywordList: [inputEl.current.value.trim(), ...filterList] }),
            );
          } else {
            // 최근 검색어의 길이가 최대 길이(10)을 초과할 경우 제일 오래된 기록을 검색 기록을 제거
            if (recentSearchKeywordList.length >= RECENT_SEARCH_WORD_MAX_LENGTH) {
              returnSearchKeywordList = [
                inputEl.current.value.trim(),
                ...recentSearchKeywordList.slice(0, RECENT_SEARCH_WORD_MAX_LENGTH - 1),
              ];
              dispatch(
                setRecentSearchKeywordList({
                  recentSearchKeywordList: [
                    inputEl.current.value.trim(),
                    ...recentSearchKeywordList.slice(0, RECENT_SEARCH_WORD_MAX_LENGTH - 1),
                  ],
                }),
              );
            } else {
              returnSearchKeywordList = [inputEl.current.value.trim(), ...recentSearchKeywordList];
              dispatch(
                setRecentSearchKeywordList({
                  recentSearchKeywordList: [inputEl.current.value.trim(), ...recentSearchKeywordList],
                }),
              );
            }
          }

          if (!isNil(localStorage)) {
            localStorage.setItem("searchHistory", JSON.stringify(returnSearchKeywordList));
          }

          const keyword = inputEl.current.value.trim();
          if (router.pathname.includes(SEARCH_RESULT_PAGE_URL_PATH)) {
            Router.replace(`${SEARCH_RESULT_PAGE_URL_PATH}?keyword=${encodeURIComponent(keyword)}`);
          } else {
            Router.push(`${SEARCH_RESULT_PAGE_URL_PATH}?keyword=${encodeURIComponent(keyword)}`);
          }
          dispatch(setSearchKeyword({ searchKeyword: keyword }));
          dispatch(setLastSearchKeyword({ lastSearchKeyword: keyword }));

          dispatch(searchModalVisible({ isSearchModalVisible: false }));
        }
      }
    },
    [inputEl.current?.value],
  );

  if (!isSearchModalVisible || !searchBeforeIntegrationListData) {
    return null;
  }

  return (
    <>
      <SearchModalStyle
        ref={containerEl}
        isSearchKeywordExisted={!!searchKeyword}
        isRecentSearchKeywordListExisted={recentSearchKeywordList.length > 0}
        isAutoCompleteDataExisted={isAutoCompleteDataExisted}
      >
        <>
          <SearchInputSectionStyle isSearchKeywordExisted={!!searchKeyword}>
            <FormProvider {...formMethods}>
              <SearchFormStyle onSubmit={onHandleSubmit} noValidate autoComplete="off">
                {/* 아이폰에서 한글을 치고 input clear 초기화 시, 한글 자동완성 되는 동작을 막기 위해 만든 hidden input */}
                <HiddenInputStyle ref={hiddenInputRef} />
                <Input
                  className="search-keyword"
                  name="keyword"
                  type="text"
                  innerRef={inputEl}
                  isShowClearBtn={false}
                  onKeyPress={() => {}}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeSearchKeywordInput(e.target.value)}
                  defaultValue={searchKeyword}
                  maxLength={MAXIMUM_NUMBER_OF_SEARCH_KEYWORD}
                  placeholder={searchPlaceHolder}
                  autoFocus
                />
                <SearchInputSubmitButton onClick={() => {}} type="submit">
                  <SearchIconStyle src={SEARCH_GRAY_BTN_24_IMAGE_PATH} />
                </SearchInputSubmitButton>
              </SearchFormStyle>
            </FormProvider>
            <SearchInputClearButtonStyle
              isSearchKeywordExisted={!!searchKeyword}
              src={CLEAR_INPUT_BTN_IMAGE_PATH}
              onClick={() => {
                dispatch(setSearchKeyword({ searchKeyword: "" }));
                if (inputEl.current && hiddenInputRef.current) {
                  hiddenInputRef.current.focus();
                  formMethods.setValue("keyword", "");
                  inputEl.current.focus();
                }
              }}
            />
            <SearchModalCloseButtonStyle
              onClick={() => {
                dispatch(searchModalVisible({ isSearchModalVisible: false }));
                dispatch(setSearchKeyword({ searchKeyword: lastSearchKeyword.trim() }));
              }}
            >
              취소
            </SearchModalCloseButtonStyle>
          </SearchInputSectionStyle>
          {searchKeyword ? (
            <AutoCompletedSearchList
              searchKeyword={searchKeyword}
              inputEl={inputEl}
              onChangeAutoCompleteDataExisted={onChangeAutoCompleteDataExisted}
            />
          ) : (
            <>
              {searchBeforeIntegrationListData.data.recommendSearchKeywordList.length ? (
                <SearchModalInfoBaseSection
                  className="recommended-search-list-wrapper"
                  title={"추천 검색어"}
                  sectionBorderBottom
                >
                  <SearchWordButtonList
                    className="recommended-search-list"
                    wordList={searchBeforeIntegrationListData.data.recommendSearchKeywordList}
                  />
                </SearchModalInfoBaseSection>
              ) : null}
              {recentSearchKeywordList.length ? (
                <SearchModalInfoBaseSection
                  className="recent-search-word-list-wrapper"
                  title={"최근 검색어"}
                  sectionBorderBottom
                >
                  <RecentSearchWordList wordList={recentSearchKeywordList} />
                  <RecentSearchListClearButton
                    onClick={(e) => {
                      e.stopPropagation();
                      dispatch(setRecentSearchKeywordList({ recentSearchKeywordList: [] }));

                      if (!isNil(localStorage)) {
                        localStorage.removeItem("searchHistory");
                      }
                    }}
                  >
                    모두 지우기
                  </RecentSearchListClearButton>
                </SearchModalInfoBaseSection>
              ) : null}
              <SearchModalInfoBaseSection className="recommended-product-list-wrapper" title={"추천 상품"}>
                <SearchRecommendedProductList
                  recommendProductList={searchBeforeIntegrationListData.data.recommendProductList}
                />
              </SearchModalInfoBaseSection>
            </>
          )}
        </>
      </SearchModalStyle>
      <GlobalStyle />
    </>
  );
};

export default SearchModal;
