import * as React from "react";
import { FC, ReactNode, useCallback, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { isNull } from "lodash";

import { MODAL_CLOSE_BTN_IMAGE_PATH } from "@constants/static/images";
import { DEVICE_OS_TYPE } from "@constants/enum/baseEnums";
import { detectDeviceOs } from "@lib/utility/deviceDetect";
import Icon from "../Icon";
import { ModalStyle } from "./style";
import { useSSRStatus } from "@lib/hooks/useSSRStatus";
import Backdrop from "@components/common/Backdrop";

interface IModalProps {
  className?: string;
  visible: boolean;
  toggleModal: () => void;
  isMobileFullScreen?: boolean;
  header?: ReactNode | string;
  children: ReactNode;
}

const Modal: FC<IModalProps> = (props: IModalProps) => {
  const { className, visible, toggleModal, isMobileFullScreen = false, header, children } = props;
  const [currentPageYOffset, setCurrentPageYOffset] = useState<null | number>(null);
  const { isSSR } = useSSRStatus();

  useEffect(() => {
    const deviceOsType: DEVICE_OS_TYPE = detectDeviceOs();
    if (visible) {
      // 모달이 열릴 때, 뒷 배경 스크롤 하지 못하도록 하기
      if (deviceOsType === DEVICE_OS_TYPE.IOS) {
        // IOS 에서는 가상키보드가 열릴 때, 본 페이지의 scroll 이 활성화가 되기때문에 body 가 스크롤이 되지 않도록
        // position 을 fixed 로 해놓고, scroll 위치를 저장한 뒤 모달이 닫힐 때 사용자 이전에 보고 있던 스크롤 위치로 이동 시킴
        if (isNull(currentPageYOffset)) {
          setCurrentPageYOffset(window.pageYOffset);
        }
      }
    }

    // 모달을 닫힐 때, 뒷 배경이 스크롤이 되도록 하기
    return () => {
      // if (!document.querySelector(".modal-box")) {
      //   document.body.style.cssText = "overflow: auto;";
      // }
      if (currentPageYOffset) {
        window.scrollTo(0, currentPageYOffset);
        setCurrentPageYOffset(null);
      }
    };
  }, [visible, currentPageYOffset]);

  const onClickBackdrop = useCallback(() => toggleModal(), []);

  if (isSSR) {
    return null;
  }

  if (visible) {
    return createPortal(
      <ModalStyle className={`${className} ${isMobileFullScreen ? "mobile-full-screen" : ""}`}>
        <Backdrop onClick={onClickBackdrop} visible={!isMobileFullScreen} />
        <div className="modal-box">
          {isMobileFullScreen && (
            <div className="modal-full-screen-header">
              <div className="modal-close-btn-section">
                <Icon className="modal-close-btn" src={MODAL_CLOSE_BTN_IMAGE_PATH} onClick={toggleModal} />
              </div>
              {header && <div className="modal-header-section">{header}</div>}
            </div>
          )}

          {children}
        </div>
      </ModalStyle>,
      document.body,
    );
  }

  return null;
};

export default Modal;
