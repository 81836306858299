import { mock } from "@services/msw/utils";
import { mock_coupons } from "@test/mocks/product/coupon/mock_coupon";
import { maxDiscountAmountTypeCouponBenefit } from "@test/mocks/benefits/mockData";

export const couponListMock = mock({
  productDownloadableCoupons: mock_coupons
});

// 상품 최저가 혜택 api mock
export const productBenefitsMock = mock({
  ...maxDiscountAmountTypeCouponBenefit,
});
