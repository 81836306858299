import { omit } from "lodash";

import {
  PRODUCT_COMMON_FILTER,
  PRODUCT_COMMON_FILTER_TAP,
  PRODUCT_COMMON_FILTER_TAP_TEXT,
  PRODUCT_COMMON_FILTER_TYPE_TO_QUERY_STRING_KEY,
} from "@constants/common/common";

// 카테고리 페이지 상품 필터
export const PRODUCT_CATEGORY_FILTER = omit(PRODUCT_COMMON_FILTER, ["CATEGORY", "SUB_CATEGORY"]);

// 카테고리 페이지 상품 필터 타입 쿼리스트링 키
export const PRODUCT_CATEGORY_FILTER_TYPE_TO_QUERY_STRING_KEY = omit(PRODUCT_COMMON_FILTER_TYPE_TO_QUERY_STRING_KEY, [
  PRODUCT_COMMON_FILTER.CATEGORY,
  PRODUCT_COMMON_FILTER.SUB_CATEGORY,
]);

// 카테고리 페이지 상품 필터 탭
export const PRODUCT_CATEGORY_FILTER_TAP = omit(PRODUCT_COMMON_FILTER_TAP, "CATEGORY");

// 카테고리 페이지 상품 필터 탭 리스트
export const PRODUCT_CATEGORY_FILTER_TAP_LIST = [
  {
    id: PRODUCT_COMMON_FILTER_TAP.STORE,
    name: PRODUCT_COMMON_FILTER_TAP_TEXT[PRODUCT_COMMON_FILTER_TAP.STORE],
  },
  {
    id: PRODUCT_COMMON_FILTER_TAP.DETAIL_OPTION,
    name: PRODUCT_COMMON_FILTER_TAP_TEXT[PRODUCT_COMMON_FILTER_TAP.DETAIL_OPTION],
  },
  {
    id: PRODUCT_COMMON_FILTER_TAP.COLOR,
    name: PRODUCT_COMMON_FILTER_TAP_TEXT[PRODUCT_COMMON_FILTER_TAP.COLOR],
  },
  {
    id: PRODUCT_COMMON_FILTER_TAP.PRICE_DISCOUNT,
    name: PRODUCT_COMMON_FILTER_TAP_TEXT[PRODUCT_COMMON_FILTER_TAP.PRICE_DISCOUNT],
  },
];

// 카테고리 트리 노출 타입 리스트
export const PRODUCT_CATEGORY_TREE_EXPOSURE_TYPE_LIST = {
  // 공통
  COMMON: 0,
  // 카테고리 페이지에서만 노출
  CATEGORY_PAGE: 1,
};

export const RECOMMEND_PRODUCT_LIST_SIZE = {
  PAGE: 1,
  PAGE_SIZE: 15,
};

export const PRODUCT_QNAS_PREVIEW_SIZE = 3;
