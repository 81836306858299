export const homeTabListMock = {
  homeTabs: [
    {
      id: 1,
      linkType: "main",
      linkId: null,
      no: 1,
      title: "메인홈",
    },
    {
      id: 2,
      linkType: "subPage",
      linkId: 2,
      no: 2,
      title: "무기한노출서브페이지",
    },
    {
      id: 3,
      linkType: "subPage",
      linkId: 3,
      no: 3,
      title: "유기한노출서브페이지",
    },
  ],
};
