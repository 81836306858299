import { LOGIN_TYPE, GENDER_TYPE, BUYER_CELL_PHONE_SELECT_TYPE } from "../../enum/authEnums";

// 로그인 타입 텍스트
export const LOGIN_TYPE_TEXT = {
  [LOGIN_TYPE.EMAIL]: "이메일",
  [LOGIN_TYPE.KAKAO]: "카카오",
  [LOGIN_TYPE.NAVER]: "네이버",
  [LOGIN_TYPE.FACEBOOK]: "페이스북",
  [LOGIN_TYPE.APPLE]: "애플",
};

// 성별 텍스트
export const GENDER_TYPE_TEXT = {
  [GENDER_TYPE.WOMAN]: "여자",
  [GENDER_TYPE.MAN]: "남자",
};

// 성별 타입 리스트
export const GENDER_TYPE_SELECT_LIST = [
  {
    id: GENDER_TYPE.WOMAN,
    name: GENDER_TYPE_TEXT[GENDER_TYPE.WOMAN],
  },
  {
    id: GENDER_TYPE.MAN,
    name: GENDER_TYPE_TEXT[GENDER_TYPE.MAN],
  },
];

// 유저 인증 토큰 키
export const AMONDZ_LOGIN_TOKEN_KEY = "amondz_login_ex";

// 유저 UUID 키
export const AMONDZ_UUID_KEY = "amondz_uuid";

// 유저 device-type 키
export const AMONDZ_DEVICE_TYPE_KEY = "amondz_device_type";

// widgetId 키
export const AMONDZ_WIDGET_ID_KEY = "amondz_widget_id";

// 이메일 최소 글자수
export const MINIMUM_NUMBER_OF_EMAIL = 6;

// 이메일 최대 글자수
export const MAXIMUM_NUMBER_OF_EMAIL = 80;

// SMS 인증 코드 최대 글자수
export const MAXIMUM_NUMBER_OF_SMS_AUTH_CODE = 6;

// 주문자 정보 연락처 선택 타입 텍스트
export const BUYER_CELL_PHONE_SELECT_TYPE_TEXT = {
  [BUYER_CELL_PHONE_SELECT_TYPE.OLD]: "기존 연락처 사용",
  [BUYER_CELL_PHONE_SELECT_TYPE.NEW]: "새로운 연락처 등록",
};

// 주문자 정보 연락처 선택 타입 리스트
export const BUYER_CELL_PHONE_SELECT_TYPE_SELECT_LIST = [
  {
    id: BUYER_CELL_PHONE_SELECT_TYPE.OLD,
    name: BUYER_CELL_PHONE_SELECT_TYPE_TEXT[BUYER_CELL_PHONE_SELECT_TYPE.OLD],
  },
  {
    id: BUYER_CELL_PHONE_SELECT_TYPE.NEW,
    name: BUYER_CELL_PHONE_SELECT_TYPE_TEXT[BUYER_CELL_PHONE_SELECT_TYPE.NEW],
  },
];

// 전화번호 최소 글자수
export const MINIMUM_NUMBER_OF_PHONE_NUMBER = 8;

// 전화번호 최대 글자수
export const MAXIMUM_NUMBER_OF_PHONE_NUMBER = 11;

// 이름 최대 글자수
export const MAXIMUM_NUMBER_OF_NAME = 20;

// 비밀번호 최대 글자수
export const MAXIMUM_NUMBER_OF_PASSWORD = 15;

// 회원 가입 페이지 단계
export const SIGN_UP_PAGE_STEP = {
  // 본인 인증 폼
  AUTH_FORM: 1,
  // 약관동의
  AGREEMENT: 2,
  // 비밀번호 폼
  PASSWORD_FORM: 3,
  // 추가 정보 폼
  EXTRA_FROM: 4,
};

// 아이디 찾기 페이지 단계
export const FIND_ID_PAGE_STEP = {
  // 본인 인증 폼
  AUTH_FORM: 0,
  // 아이디 정보 폼
  INFO_FROM: 1,
};

// 비밀번호 찾기 페이지 단계
export const FIND_PASSWORD_PAGE_STEP = {
  // 본인 인증 폼
  AUTH_FORM: 0,
  // 비밀번호 폼
  PASSWORD_FORM: 1,
};

/**
 * 이메일 or SMS 수신동의 상태
 */
export const RECEIVE_AGREE_STATUS = {
  DISAGREE: 0,
  AGREE: 1,
} as const;

export const EXTRA_INFO_TITLE = "추가 정보";
export const EXTRA_INFO_SUB_TEXT = "생일에 20% 할인 혜택을 드립니다. (연 1회)";
