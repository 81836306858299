import { createAction } from "typesafe-actions";

import {
  relatedSearchWordListStateType,
  searchModalStateType,
  searchResultCountPayloadType,
} from "@store/modules/search/types";

// 통합 검색 결과 수
export const INTEGRATION_SEARCH_COUNT = "search/INTEGRATION_SEARCH_COUNT";
export const integrationSearchCount = createAction(INTEGRATION_SEARCH_COUNT)<searchResultCountPayloadType>();

// 상품 검색 결과 수
export const PRODUCT_SEARCH_COUNT = "search/PRODUCT_SEARCH_COUNT";
export const productSearchCount = createAction(PRODUCT_SEARCH_COUNT)<searchResultCountPayloadType>();

// 브랜드 검색 결과 수
export const STORE_SEARCH_COUNT = "search/STORE_SEARCH_COUNT";
export const storeSearchCount = createAction(STORE_SEARCH_COUNT)<searchResultCountPayloadType>();

// 리뷰 검색 결과 수
export const REVIEW_SEARCH_COUNT = "search/REVIEW_SEARCH_COUNT";
export const reviewSearchCount = createAction(REVIEW_SEARCH_COUNT)<searchResultCountPayloadType>();

// 기획전 검색 결과 수
export const EVENT_SEARCH_COUNT = "search/EVENT_SEARCH_COUNT";
export const eventSearchCount = createAction(EVENT_SEARCH_COUNT)<searchResultCountPayloadType>();

// 연관 검색어
export const RELATED_SEARCH_WORD_LIST = "search/RELATED_SEARCH_WORD_LIST";
export const relatedSearchWordList = createAction(RELATED_SEARCH_WORD_LIST)<relatedSearchWordListStateType>();

// 검색한 키워드
export const SET_SEARCH_KEYWORD = "search/SET_SEARCH_KEYWORD";
export const setSearchKeyword = createAction(SET_SEARCH_KEYWORD)<Pick<searchModalStateType, "searchKeyword">>();

// 마지막으로 검색한 키워드
export const SET_LAST_SEARCH_KEYWORD = "search/SET_LAST_SEARCH_KEYWORD";
export const setLastSearchKeyword = createAction(SET_LAST_SEARCH_KEYWORD)<
  Pick<searchModalStateType, "lastSearchKeyword">
>();

// 검색한 키워드 리스트
export const SET_RECENT_SEARCH_KEYWORD_LIST = "search/SET_RECENT_SEARCH_KEYWORD_LIST";
export const setRecentSearchKeywordList = createAction(SET_RECENT_SEARCH_KEYWORD_LIST)<
  Pick<searchModalStateType, "recentSearchKeywordList">
>();

// 검색 모달 노출 여부
export const SEARCH_MODAL_VISIBLE = "search/SEARCH_MODAL_VISIBLE";
export const searchModalVisible = createAction(SEARCH_MODAL_VISIBLE)<
  Pick<searchModalStateType, "isSearchModalVisible">
>();
