import * as React from "react";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Link from "next/link";
import { useRouter } from "next/router";
import { REMOTE_CONFIG_KEY, useFirebaseRemoteConfig } from "@amondz/firebase";

import AmondzLogo from "@components/icon/AmondzLogo/AmondzLogo";
import { RootStateType } from "@store/modules";
import { baseModalToggle, BaseModalStateType, twoButtonModalToggle } from "@store/modules/base";
import { AuthStateType, LogOutAsync } from "@store/modules/auth";
import { getProductCategoryAsync, ProductListStateType } from "@store/modules/product";
import { GlobalStyle } from "@components/base/header/Header/style";
import { BASE_MODAL_NAME_TYPE } from "@constants/enum/baseEnums";
import {
  MY_PAGE_URL_PATH,
  PRODUCT_LIST_PAGE_URL_PATH,
  SIGN_IN_PAGE_URL_PATH,
  INTRO_PAGE_URL_PATH,
  BRAND_LIST_PAGE_URL_PATH,
} from "@constants/url/internalUrlConstants";
import { LOG_OUT_INFO_MODAL_CONTENTS } from "@constants/service/auth/modal";
import { AMONDZ_APP_DOWNLOAD_DEEP_LINK_URL } from "@constants/url/externalUrlConstants";
import { HeaderMenuModalStyle, CategoryListStyle } from "./style";

const HeaderMenuModal = (): React.JSX.Element => {
  const { headerMenuModal } = useSelector<RootStateType, BaseModalStateType>(
    (state: RootStateType) => state.base.baseModalState,
  );
  const { productCategoryTree } = useSelector<RootStateType, ProductListStateType>(
    (state: RootStateType) => state.product.productListState.data,
  );
  const { isLoggedIn } = useSelector<RootStateType, AuthStateType>((state: RootStateType) => state.auth);
  const [showMoreCategory, setShowMoreCategory] = useState<boolean>(false);

  const dispatch = useDispatch();
  const router = useRouter();
  const rtnUrl = useMemo(() => {
    return typeof router.query.rtnUrl === "string" ? router.query.rtnUrl : router.asPath;
  }, [router]);

  const { getJson } = useFirebaseRemoteConfig();

  useEffect(() => {
    if (headerMenuModal.visible) {
      dispatch(getProductCategoryAsync.request());
    }
    setShowMoreCategory(false);
  }, [headerMenuModal.visible]);

  // 헤더 메뉴 모달 토글
  const toggleModal = useCallback(
    () =>
      dispatch(
        baseModalToggle({
          targetModal: BASE_MODAL_NAME_TYPE.HEADER_MENU_MODAL,
        }),
      ),
    [],
  );

  useEffect(() => {
    if (headerMenuModal.visible) {
      // 페이지 이동 시 모달 닫기
      toggleModal();
    }
  }, [router.asPath]);

  // 로그아웃 버튼 클릭
  const onClickLogOutBtn = useCallback(
    () =>
      dispatch(
        twoButtonModalToggle({
          visible: true,
          msg: LOG_OUT_INFO_MODAL_CONTENTS.MSG,
          onClickRightBtn: () => dispatch(LogOutAsync.request({ isApiRequest: true })),
        }),
      ),
    [],
  );

  // 더보기 버튼 클릭
  const onClickShowMoreButton = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setShowMoreCategory(true);
  }, []);

  const serviceInfo = getJson(REMOTE_CONFIG_KEY.SERVICE_INFO).serviceSlogan;
  const benefitMessage = getJson(REMOTE_CONFIG_KEY.BENEFIT).signupBenefit;

  return (
    <HeaderMenuModalStyle visible={headerMenuModal.visible} toggleModal={toggleModal} isMobileFullScreen>
      <div className="modal-body">
        <div className="modal-contents">
          <div className="modal-header-section">
            <div className="amondz-logo">
              <Link href={INTRO_PAGE_URL_PATH}>
                <AmondzLogo width={85} height={18} />
              </Link>
            </div>

            <ul className="auth-menu">
              {!isLoggedIn ? (
                <>
                  <li className="login">
                    <Link href={`${SIGN_IN_PAGE_URL_PATH}?rtnUrl=${encodeURIComponent(rtnUrl)}`}>로그인</Link>
                  </li>
                </>
              ) : (
                <li>
                  <a className="logout" onClick={onClickLogOutBtn}>
                    로그아웃
                  </a>
                </li>
              )}
              <li className="mypage">
                <a href={MY_PAGE_URL_PATH}>마이</a>
              </li>
            </ul>
          </div>
          <div className="modal-content-section">
            <div className="product-category-list-section">
              <div className="product-category-list-section-title">카테고리</div>

              <ul className="product-category-list">
                {productCategoryTree && (
                  <>
                    {productCategoryTree.normal?.map((category, index) => (
                      <CategoryListStyle key={category.id} visible={index < 8 || showMoreCategory}>
                        <Link href={`${PRODUCT_LIST_PAGE_URL_PATH}?category=${category.id}`}>
                          <div className="category-button">
                            <span>{category.name}</span>
                          </div>
                        </Link>
                      </CategoryListStyle>
                    ))}
                    {!showMoreCategory && (
                      <li className="show-more-button">
                        <a onClick={onClickShowMoreButton}>더보기</a>
                      </li>
                    )}
                    {showMoreCategory &&
                      productCategoryTree.emphasis?.map((category) => (
                        <li key={category.id}>
                          <Link href={`${PRODUCT_LIST_PAGE_URL_PATH}?category=${category.id}`}>
                            <div className="category-button">
                              <span className="emphasis">{category.name}</span>
                            </div>
                          </Link>
                        </li>
                      ))}
                  </>
                )}
              </ul>
            </div>
            <div className="brand-menu-section">
              <Link href={BRAND_LIST_PAGE_URL_PATH} className="brand-list-page-btn">
                브랜드
              </Link>
            </div>
            <div className="app-download-banner-section">
              <Link href={AMONDZ_APP_DOWNLOAD_DEEP_LINK_URL}>
                <div className="app-download-banner">
                  <div className="banner-logo">
                    <AmondzLogo width={67} height={14} />
                  </div>
                  <div className="banner-title">
                    <span>{serviceInfo}</span>
                  </div>
                  <div className="banner-sub-title">{benefitMessage}</div>
                  <span>앱 다운로드</span>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <GlobalStyle />
    </HeaderMenuModalStyle>
  );
};

export default memo(HeaderMenuModal);
