import styled from "@styles/themedComponents";

export const NotifyToastMessageStyle = styled.div`
  width: 345px;
  padding: 13px 16px;
  border-radius: 2px;
  display: inline-block;
  box-shadow: 0 8px 15px 3px rgba(125, 125, 125, 0.3);

  .msg {
    font-family: ${(props) => props.theme.fonts.notoSansCJKkr};
    font-size: 13px;
    letter-spacing: -0.5px;
    line-height: 22px;
    color: ${(props) => props.theme.color.amondz_white};
    word-break: keep-all;
  }

  &.success {
    background: ${(props) => props.theme.color.amondz_white};

    .msg {
      color: ${(props) => props.theme.color.amondz_black};
    }
  }

  &.error {
    background: ${(props) => props.theme.color.amondz_orange};
  }

  ${(props) => props.theme.media.phone`
		width: 345px;
	`}
`;
