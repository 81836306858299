import Img from "@components/common/Img";
import styled from "@styles/themedComponents";

export const BrandListStyle = styled.li`
  width: calc(100% + 30px);
  margin-left: -15px;
  height: 44px;
  cursor: pointer;

  &:last-child {
    border-radius: 0;
  }

  &.selected {
    background-color: ${({ theme }) => theme.color.gray_08};
  }
`;

export const BrandWrapStyle = styled.a`
  display: flex;
  align-items: center;
  line-height: 1.71;
  width: 100%;
  height: 100%;
  padding: 10px 15px;
`;

export const BrandImageStyle = styled(Img)`
  border-radius: 20px;
`;

export const BrandNameStyle = styled.span`
  margin-left: 10px;
  margin-right: 8px;
  padding: 10px 0;
  font-size: 14px;
`;

export const HorizonBorder = styled.div`
  width: 1px;
  height: 12px;
  background-color: ${({ theme }) => theme.color.temp_gray_02};
`;

export const BrandCategoryStyle = styled.span`
  font-size: 13px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  margin-left: 8px;
  color: ${({ theme }) => theme.color.temp_gray_02};
`;
