import axios from "axios";
import { takeEvery, put, throttle, select } from "redux-saga/effects";

import marketingService, { eventType } from "@amondz/marketing-service";
import { ApiHelperResponseType } from "@store/modules/common/types";
import asyncApiHelper from "@lib/utility/apiHelper";
import gtm, { setProductWishList } from "@lib/utility/gtm";
import brazeAttribute from "@lib/utility/braze";
import mypageAPI from "@services/apis/mypageAPI";
import baseAPI from "@services/apis/baseAPI";
import { RootStateType } from "@store/modules";
import { LIKE_STATUS } from "@constants/service/common/common";
import { STATUS_CODE_COMMON } from "@amondz/constants";
import { AuthStateType, LogOutAsync, memberErrorReset } from "../auth";
import {
  CHECK_EMAIL_MEMBER,
  checkEmailMemberAsync,
  CONFIRM_PURCHASE,
  confirmPurchaseAsync,
  DELETE_QNA_PRODUCT,
  deleteQnaProductAsync,
  GENERATE_COUPON,
  generateCouponAsync,
  GET_COUPON_LIST,
  GET_MY_PAGE_MAIN,
  GET_ORDER_CANCEL,
  GET_ORDER_DELIVERY_TRACKING,
  GET_ORDER_DETAIL,
  GET_ORDER_EXCHANGE,
  GET_ORDER_ITEM_SHIPPING_INFO,
  GET_ORDER_LIST,
  GET_ORDER_RETURN,
  GET_POINT_LIST,
  GET_PRODUCT_COUPON,
  GET_QNA_PRODUCT_LIST,
  GET_SNS_MEMBER,
  getCouponListAsync,
  getMyPageMainAsync,
  getOrderCancelAsync,
  getOrderDeliveryTrackingAsync,
  getOrderDetailAsync,
  getOrderExchangeAsync,
  getOrderItemShippingInfoAsync,
  getOrderListAsync,
  getOrderReturnAsync,
  getPointListAsync,
  getProductCouponAsync,
  getQnaProductListAsync,
  getSnsMemberAsync,
  REMOVE_MEMBER_INFO,
  removeMemberInfoAsync,
  RESULT_ORDER_CANCEL,
  RESULT_ORDER_EXCHANGE,
  RESULT_ORDER_RETURN,
  resultOrderCancelAsync,
  resultOrderExchangeAsync,
  resultOrderReturnAsync,
  SELECT_ORDER_RETURN_VBANK,
  selectOrderReturnVbankAsync,
  SET_LIKE_PRODUCT_COUPON,
  setLikeProductCouponAsync,
  SHIPPING_DELAY_REASON,
  shippingDelayReasonAsync,
  SUBMIT_ORDER_CANCEL,
  SUBMIT_ORDER_EXCHANGE,
  SUBMIT_ORDER_RETURN,
  submitOrderCancelAsync,
  submitOrderExchangeAsync,
  submitOrderReturnAsync,
  UPDATE_MEMBER_INFO,
  UPDATE_ORDER_ITEM_SHIPPING_INFO,
  UPDATE_ORDER_RETURN_VBANK,
  UPDATE_PASSWORD_EMAIL_MEMBER,
  UPDATE_QNA_PRODUCT,
  updateMemberInfoAsync,
  updateOrderItemShippingInfoAsync,
  updateOrderReturnVbankAsync,
  updatePasswordEmailMemberAsync,
  updateQnaProductAsync,
  WITHDRAW_ORDER_CANCEL,
  WITHDRAW_ORDER_EXCHANGE,
  WITHDRAW_ORDER_RETURN,
  withdrawOrderCancelAsync,
  withdrawOrderExchangeAsync,
  withdrawOrderReturnAsync,
  INSERT_PRODUCT_QNA,
  insertProductQna,
  GET_BRAND_COUPON,
  getBrandCouponAsync,
} from "./actions";

/**
 * 마이페이지 메인 SAGA
 */
function* getMyPageMainSaga() {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(mypageAPI.getMyPageMain);

    yield put(getMyPageMainAsync.success(result));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(getMyPageMainAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 사용 가능한 쿠폰 리스트 SAGA
 */
function* getCouponListSaga() {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(mypageAPI.getCouponList);

    yield put(getCouponListAsync.success(result.data));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(getCouponListAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 쿠폰 발급 SAGA
 */
function* generateCouponSaga(action: ReturnType<typeof generateCouponAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(mypageAPI.generateCoupon, action.payload);

    if (result.status === STATUS_CODE_COMMON.SUCCESS) {
      // braze - 쿠폰 발급 시
      brazeAttribute.addCouponList(result.data.couponId + "");
      gtm.receivedCoupon({
        id: result.data.couponId,
        name: result.data.couponName,
      });
    }

    yield put(generateCouponAsync.success(result));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(generateCouponAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 쿠폰 적용가능 상품 리스트 SAGA
 */
function* getProductCouponListSaga(action: ReturnType<typeof getProductCouponAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(mypageAPI.getProductCouponList, action.payload);
    yield put(getProductCouponAsync.success(result));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(getProductCouponAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 쿠폰 적용가능 브랜드 리스트 SAGA
 */
function* getBrandCouponListSaga(action: ReturnType<typeof getBrandCouponAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(mypageAPI.getBrandCouponList, action.payload);
    yield put(getBrandCouponAsync.success(result));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(getBrandCouponAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 쿠폰 적용가능 상품 좋아요 클릭 SAGA
 */
function* setLikeProductCouponSaga(action: ReturnType<typeof setLikeProductCouponAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(baseAPI.likeProduct, action.payload);
    const { userAuthState }: AuthStateType = yield select((state: RootStateType) => state.auth);

    // braze - 상품 좋아요
    const { product } = action.payload;
    result.data.code === LIKE_STATUS.LIKE
      ? marketingService(eventType.likeProduct, {
          userId: userAuthState.data?.userId,
          productId: product.productId,
          productName: product.productName,
        })
      : marketingService(eventType.unLikeProduct, {
          userId: userAuthState.data?.userId,
          productId: product.productId,
          productName: product.productName,
        });
    setProductWishList(result.data.code, product);

    result.data.productId = action.payload.productId;
    yield put(setLikeProductCouponAsync.success(result));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(setLikeProductCouponAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 포인트 내역 SAGA
 */
function* getPointInfoSaga(action: ReturnType<typeof getPointListAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(mypageAPI.getPointList, action.payload);

    yield put(getPointListAsync.success(result.data));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(getPointListAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 상품 문의 내역 SAGA
 */
function* getQnaProductListSaga(action: ReturnType<typeof getQnaProductListAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(mypageAPI.getProductQnaList, action.payload);

    yield put(getQnaProductListAsync.success(result.data));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(getQnaProductListAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 상품 문의 작성 요청 SAGA
 */
function* insertProductQnaSaga(action: ReturnType<typeof insertProductQna.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(mypageAPI.insertProductQna, action.payload);

    yield put(
      insertProductQna.success({
        status: result.status,
      }),
    );
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(insertProductQna.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 상품 문의 수정 SAGA
 */
function* updateQnaProductSaga(action: ReturnType<typeof updateQnaProductAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(mypageAPI.updateProductQna, action.payload);

    yield put(updateQnaProductAsync.success({ ...action.payload, ...result }));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(updateQnaProductAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 상품 문의 삭제 SAGA
 */
function* deleteQnaProductSaga(action: ReturnType<typeof deleteQnaProductAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(mypageAPI.deleteProductQna, action.payload);

    yield put(deleteQnaProductAsync.success({ ...action.payload, ...result }));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(deleteQnaProductAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 주문 정보 내역 SAGA
 */
function* getOrderListSaga() {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(mypageAPI.getOrderList);

    yield put(getOrderListAsync.success(result.data.orderList));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(getOrderListAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 주문 상세 내역 SAGA
 */
function* getOrderDetailSaga(action: ReturnType<typeof getOrderDetailAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(mypageAPI.getOrderDetail, action.payload);

    yield put(getOrderDetailAsync.success(result));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(getOrderDetailAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 주문 취소 내역 SAGA
 */
function* getOrderCancelSaga(action: ReturnType<typeof getOrderCancelAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(mypageAPI.getOrderCancel, action.payload);

    yield put(getOrderCancelAsync.success(result));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(getOrderCancelAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 주문 반품 내역 SAGA
 */
function* getOrderReturnSaga(action: ReturnType<typeof getOrderReturnAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(mypageAPI.getOrderReturn, action.payload);

    yield put(getOrderReturnAsync.success(result));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(getOrderReturnAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 주문 교환 내역 SAGA
 */
function* getOrderExchangeSaga(action: ReturnType<typeof getOrderExchangeAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(mypageAPI.getOrderExchange, action.payload);

    yield put(getOrderExchangeAsync.success(result));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(getOrderExchangeAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 배송 추적 내역 SAGA
 */
function* getOrderDeliveryTrackingSaga(action: ReturnType<typeof getOrderDeliveryTrackingAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(mypageAPI.getOrderDeliveryTracking, action.payload);

    yield put(getOrderDeliveryTrackingAsync.success(result));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(getOrderDeliveryTrackingAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 주문 상품 배송지 내역 SAGA
 */
function* getOrderItemShippingInfoSaga(action: ReturnType<typeof getOrderItemShippingInfoAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(mypageAPI.getOrderItemShipping, action.payload);

    yield put(getOrderItemShippingInfoAsync.success(result.data));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(getOrderItemShippingInfoAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 주문 상품 배송지 수정 SAGA
 */
function* updateOrderItemShippingInfoSaga(action: ReturnType<typeof updateOrderItemShippingInfoAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(mypageAPI.updateOrderItemShipping, action.payload);

    yield put(updateOrderItemShippingInfoAsync.success(result.status));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(updateOrderItemShippingInfoAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 주문 확정 SAGA
 */
function* confirmPurchaseSaga(action: ReturnType<typeof confirmPurchaseAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(mypageAPI.confirmPurchase, action.payload);

    yield put(confirmPurchaseAsync.success(result.status));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(confirmPurchaseAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 배송 지연 사유 SAGA
 */
function* shippingDelayReasonSaga(action: ReturnType<typeof shippingDelayReasonAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(mypageAPI.shippingDelay, action.payload);

    yield put(shippingDelayReasonAsync.success(result));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(shippingDelayReasonAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 주문 취소 SAGA
 */
function* submitOrderCancelSaga(action: ReturnType<typeof submitOrderCancelAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(mypageAPI.submitOrderCancel, action.payload);

    yield put(submitOrderCancelAsync.success(result));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(submitOrderCancelAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 주문 취소 내역 SAGA
 */
function* resultOrderCancelSaga(action: ReturnType<typeof resultOrderCancelAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(mypageAPI.resultOrderCancel, action.payload);
    yield put(resultOrderCancelAsync.success(result));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(resultOrderCancelAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 주문 취소 철회 SAGA
 */
function* withdrawOrderCancelSaga(action: ReturnType<typeof withdrawOrderCancelAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(mypageAPI.withdrawOrderCancel, action.payload);
    result.data = { orderItemId: action.payload.orderItemId, ...result.data };
    yield put(withdrawOrderCancelAsync.success(result));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(withdrawOrderCancelAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 주문 반품 요청 SAGA
 */
function* submitOrderReturnSaga(action: ReturnType<typeof submitOrderReturnAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(mypageAPI.submitOrderReturn, action.payload);
    yield put(submitOrderReturnAsync.success(result));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(submitOrderReturnAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 주문 반품 내역 SAGA
 */
function* resultOrderReturnSaga(action: ReturnType<typeof resultOrderReturnAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(mypageAPI.resultOrderReturn, action.payload);
    result.data = { orderItemId: action.payload.orderItemId, ...result.data };
    yield put(resultOrderReturnAsync.success(result));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(resultOrderReturnAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 주문 반품 철회 SAGA
 */
function* withdrawOrderReturnSaga(action: ReturnType<typeof withdrawOrderReturnAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(mypageAPI.withdrawOrderReturn, action.payload);
    result.data = { orderItemId: action.payload.orderItemId, ...result.data };
    yield put(withdrawOrderReturnAsync.success(result));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(withdrawOrderReturnAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 주문 교환 요청 SAGA
 */
function* submitOrderExchangeSaga(action: ReturnType<typeof submitOrderExchangeAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(mypageAPI.submitOrderExchange, action.payload);
    yield put(submitOrderExchangeAsync.success(result));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(submitOrderExchangeAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 주문 교환 내역 SAGA
 */
function* resultOrderExchangeSaga(action: ReturnType<typeof resultOrderExchangeAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(mypageAPI.resultOrderExchange, action.payload);
    result.data = { orderItemId: action.payload.orderItemId, ...result.data };
    yield put(resultOrderExchangeAsync.success(result));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(resultOrderExchangeAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 주문 교환 철회 SAGA
 */
function* withdrawOrderExchangeSaga(action: ReturnType<typeof withdrawOrderExchangeAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(mypageAPI.withdrawOrderExchange, action.payload);
    result.data = { orderItemId: action.payload.orderItemId, ...result.data };
    yield put(withdrawOrderExchangeAsync.success(result));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(withdrawOrderExchangeAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 가상계좌 환불 정보 조회 SAGA
 */
function* selectOrderReturnVbankSaga(action: ReturnType<typeof selectOrderReturnVbankAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(mypageAPI.selectOrderReturnVbank, action.payload);
    yield put(selectOrderReturnVbankAsync.success(result));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(selectOrderReturnVbankAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 가상계좌 환불 정보 수정 SAGA
 */
function* updateOrderReturnVbankSaga(action: ReturnType<typeof updateOrderReturnVbankAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(mypageAPI.updateOrderReturnVbank, action.payload);
    if (result.status === STATUS_CODE_COMMON.SUCCESS) {
      const payInformList = [action.payload.bankHolder, `(${action.payload.bankName}/${action.payload.bankNumber})`];
      yield put(updateOrderReturnVbankAsync.success({ ...result, payInformList }));
      return;
    }

    yield put(updateOrderReturnVbankAsync.failure(result));
  } catch (e) {
    console.error(e);
  }
}

/**
 * 이메일 비밀번호 재확인 SAGA
 */
function* checkEmailMemberSaga(action: ReturnType<typeof checkEmailMemberAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(mypageAPI.checkEmailMember, action.payload);
    yield put(checkEmailMemberAsync.success(result));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(checkEmailMemberAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * sns 회원 정보 불러오기 SAGA
 */
function* getSnsMemberSaga(action: ReturnType<typeof getSnsMemberAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(mypageAPI.getSnsMember, action.payload);

    yield put(getSnsMemberAsync.success(result));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(getSnsMemberAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 이메일 비밀번호 변경 SAGA
 */
function* updatePasswordEmailMemberSaga(action: ReturnType<typeof updatePasswordEmailMemberAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(mypageAPI.updatePasswordEmailMember, action.payload);

    yield put(updatePasswordEmailMemberAsync.success(result.status));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(updatePasswordEmailMemberAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 회원 정보 변경 SAGA
 */
function* updateMemberInfoSaga(action: ReturnType<typeof updateMemberInfoAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(mypageAPI.updateMemberInfo, action.payload);

    result.data = action.payload;

    // 브레이즈 - 이메일 수신 동의 여부 셋팅
    brazeAttribute.setEmailNotificationSubscriptionType(!!action.payload.emailReceive);
    // 브레이즈 - firstName 설정
    brazeAttribute.setFirstName(result.data.name || "");
    // 에러 상태 초기화
    yield put(memberErrorReset());
    yield put(updateMemberInfoAsync.success(result));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(updateMemberInfoAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 회원 정보 탈퇴 SAGA
 */
function* removeMemberInfoSaga() {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(mypageAPI.removeMemberInfo);
    yield put(removeMemberInfoAsync.success(result.status));

    if (result.status === STATUS_CODE_COMMON.SUCCESS) {
      // 회원정보가 유효하지 않을 경우에는 강제로그 아웃 처리
      yield put(LogOutAsync.request({}));
    }
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(removeMemberInfoAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

export function* mypageSaga() {
  // 메인화면
  yield takeEvery(GET_MY_PAGE_MAIN.REQUEST, getMyPageMainSaga);

  // 사용 가능한 쿠폰 리스트
  yield takeEvery(GET_COUPON_LIST.REQUEST, getCouponListSaga);
  yield throttle(500, GENERATE_COUPON.REQUEST, generateCouponSaga);
  yield takeEvery(GET_PRODUCT_COUPON.REQUEST, getProductCouponListSaga);
  yield takeEvery(GET_BRAND_COUPON.REQUEST, getBrandCouponListSaga);
  yield throttle(100, SET_LIKE_PRODUCT_COUPON.REQUEST, setLikeProductCouponSaga);

  // 포인트 내역
  yield takeEvery(GET_POINT_LIST.REQUEST, getPointInfoSaga);

  // 주문 정보
  yield takeEvery(GET_ORDER_LIST.REQUEST, getOrderListSaga);
  yield takeEvery(GET_ORDER_DETAIL.REQUEST, getOrderDetailSaga);
  yield takeEvery(GET_ORDER_CANCEL.REQUEST, getOrderCancelSaga);
  yield takeEvery(GET_ORDER_RETURN.REQUEST, getOrderReturnSaga);
  yield takeEvery(GET_ORDER_EXCHANGE.REQUEST, getOrderExchangeSaga);
  yield takeEvery(GET_ORDER_DELIVERY_TRACKING.REQUEST, getOrderDeliveryTrackingSaga);
  yield throttle(100, GET_ORDER_ITEM_SHIPPING_INFO.REQUEST, getOrderItemShippingInfoSaga);
  yield throttle(100, UPDATE_ORDER_ITEM_SHIPPING_INFO.REQUEST, updateOrderItemShippingInfoSaga);
  yield throttle(100, CONFIRM_PURCHASE.REQUEST, confirmPurchaseSaga);

  // 배송 지연 사유
  yield throttle(100, SHIPPING_DELAY_REASON.REQUEST, shippingDelayReasonSaga);

  // 주문 취소
  yield throttle(100, SUBMIT_ORDER_CANCEL.REQUEST, submitOrderCancelSaga);
  yield throttle(100, RESULT_ORDER_CANCEL.REQUEST, resultOrderCancelSaga);
  yield throttle(100, WITHDRAW_ORDER_CANCEL.REQUEST, withdrawOrderCancelSaga);

  // 주문 반품
  yield throttle(100, SUBMIT_ORDER_RETURN.REQUEST, submitOrderReturnSaga);
  yield throttle(100, RESULT_ORDER_RETURN.REQUEST, resultOrderReturnSaga);
  yield throttle(100, WITHDRAW_ORDER_RETURN.REQUEST, withdrawOrderReturnSaga);

  // 주문 교환
  yield throttle(100, SUBMIT_ORDER_EXCHANGE.REQUEST, submitOrderExchangeSaga);
  yield throttle(100, RESULT_ORDER_EXCHANGE.REQUEST, resultOrderExchangeSaga);
  yield throttle(100, WITHDRAW_ORDER_EXCHANGE.REQUEST, withdrawOrderExchangeSaga);

  // 가상 계좌 환불 정보
  yield throttle(100, SELECT_ORDER_RETURN_VBANK.REQUEST, selectOrderReturnVbankSaga);
  yield throttle(100, UPDATE_ORDER_RETURN_VBANK.REQUEST, updateOrderReturnVbankSaga);

  // 상품 문의 내역
  yield takeEvery(GET_QNA_PRODUCT_LIST.REQUEST, getQnaProductListSaga);
  yield throttle(500, INSERT_PRODUCT_QNA.REQUEST, insertProductQnaSaga);
  yield throttle(500, UPDATE_QNA_PRODUCT.REQUEST, updateQnaProductSaga);
  yield throttle(500, DELETE_QNA_PRODUCT.REQUEST, deleteQnaProductSaga);

  // 회원 정보 수정
  yield throttle(500, CHECK_EMAIL_MEMBER.REQUEST, checkEmailMemberSaga);
  yield takeEvery(GET_SNS_MEMBER.REQUEST, getSnsMemberSaga);
  yield throttle(500, UPDATE_PASSWORD_EMAIL_MEMBER.REQUEST, updatePasswordEmailMemberSaga);
  yield throttle(500, UPDATE_MEMBER_INFO.REQUEST, updateMemberInfoSaga);
  yield throttle(500, REMOVE_MEMBER_INFO.REQUEST, removeMemberInfoSaga);
}
