import styled from "@styles/themedComponents";

export const ListStyle = styled.div`
  .list-pagination {
    text-align: center;
    font-weight: 500;
    font-family: ${(props) => props.theme.fonts.barlow};

    li {
      display: inline-block;
      cursor: pointer;

      a:focus {
        outline: none;
      }

      &.previous,
      &.next {
        width: 16px;
        height: 16px;
        background-size: contain;
        vertical-align: middle;

        a {
          width: 16px;
          height: 16px;
        }

        &.disabled {
          visibility: hidden;
        }
      }

      &.previous {
        margin-right: 20px;
      }

      &.next {
        margin-left: 0;
      }

      &.previous,
      &.next {
        img {
          width: 16px;
          height: 16px;
        }
      }

      &.list-page-number {
        height: 17px;
        font-size: 14px;
        font-weight: 500;
        color: ${(props) => props.theme.color.gray_01};
        margin-right: 20px;

        &.selected {
          font-weight: 600;
          color: ${(props) => props.theme.color.amondz_black};
          border-bottom: 1px solid ${(props) => props.theme.color.amondz_black};
        }
      }

      &.break {
        margin-right: 20px;
        color: ${(props) => props.theme.color.gray_01};
      }
    }
  }

  .infinite-scroll-btn {
    text-align: center;
    border-radius: 2px;
    padding: 15px 0 16px;
    border: solid 1px ${(props) => props.theme.color.gray_05};

    span {
      font-size: 13px;
      font-weight: 500;
      letter-spacing: -0.3px;
    }
  }

  .infinite-scroll-loading {
    text-align: center;
  }
`;
