import { PropsWithChildren, useState } from "react";
import { SWRConfig } from "swr";
import { QueryClient, QueryCache, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { SWRConfigOptions } from "@swr/options";
import { useGlobalErrorHandler } from "@hooks/common/useGlobalErrorHandler";

interface ICustomSWRConfigProps {
  fallback: any;
}
const CustomSWRConfig = (props: PropsWithChildren<ICustomSWRConfigProps>) => {
  const { children, fallback } = props;
  const errorHandler = useGlobalErrorHandler();
  /**
   * 공식 문서에서는 queryClient인스턴스 생성을 컴포넌트 외부에서 하도록 권장합니다
   * 하지만 전역 onError에 hook에서 리턴받은 함수를 등록해야하는 특수한 상황의 경우 App컴포넌트 내부에서 인스턴스를 생성해야합니다
   * 이럴 경우 queryClient의 안정성을 보장해야하며 useState의 경우 클로저로 상태를 저장하기 때문에 안정성이 보장됩니다.
   * 또한 useState의 인자로 값이 아닌 함수를 전달하는 것은 공식문서(https://react.dev/reference/react/useState#avoiding-recreating-the-initial-state)에서 나와있듯
   * 매번 초기값을 만드는 함수를 실행하지 않기 위함입니다.
   *
   * + 위와 같은 이유가 아니더라도 SSR을 구현하기 위해서는 해당 방법으로 queryClient 생성해야합니다
   * 참고 : https://tanstack.com/query/v4/docs/react/guides/ssr#using-hydration
   * */
  const [queryClient] = useState(
    () =>
      new QueryClient({
        queryCache: new QueryCache({
          onError: errorHandler,
        }),
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            keepPreviousData: true,
            retry: 0,
          },
        },
      }),
  );

  return (
    <SWRConfig
      value={{
        ...SWRConfigOptions,
        //swr실행전에 fallback에 해당 key를 가진 데이터를 먼저 조회
        //만약 fallback에 객체가 아닌 undefined가 들어가면 undefined에서 key를 조회하게 되어 참조에러 발생
        fallback: fallback || {},
        onError: (error) => errorHandler(error),
      }}
    >
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        {children}
      </QueryClientProvider>
    </SWRConfig>
  );
};

export default CustomSWRConfig;
