import * as React from "react";
import { FC, ReactNode } from "react";

import Img from "@components/common/Img";
import Row from "@components/common/Row";
import TodayDeliveryBadge from "@components/icon/TodayDeliveryBadge";
import { numberWithCommas } from "@lib/utility/common";
import { PRODUCT_STATUS } from "@constants/enum/productEnum";
import {
  HorizontalCardStyle,
  CardImageAreaStyle,
  CardInfoAreaStyle,
  CardButtonListAreaStyle,
  BrandNameStyle,
  ProductNameStyle,
  PriceStyle,
  OptionAndMessageStyle,
  TodayBadgeStyle,
  CountStyle,
  DateStyle,
} from "./style";

export type ProductStatusType = {
  status: PRODUCT_STATUS.NOW_ON_SALE | PRODUCT_STATUS.SOLD_OUT;
};

interface IHorizontalCardProps {
  className?: string;
  imgUrl: string;
  buttonList?: ReactNode;
  children: ReactNode;
}

function HorizontalCard({ className, imgUrl, buttonList, children }: IHorizontalCardProps) {
  return (
    <HorizontalCardStyle className={className}>
      <CardImageAreaStyle>
        <Img src={imgUrl} />
      </CardImageAreaStyle>
      <CardInfoAreaStyle>{children}</CardInfoAreaStyle>
      <CardButtonListAreaStyle>{buttonList}</CardButtonListAreaStyle>
    </HorizontalCardStyle>
  );
}

interface INameProps {
  className?: string;
  name: string;
}
const BrandName: FC<INameProps> = ({ className, name }) => (
  <BrandNameStyle className={className}>{name}</BrandNameStyle>
);

interface IProductNameProps extends INameProps {
  isEllipsis?: boolean;
}
const ProductName: FC<IProductNameProps> = ({ className, name, isEllipsis }) => (
  <ProductNameStyle className={className} isEllipsis={isEllipsis}>
    {name}
  </ProductNameStyle>
);

interface IPriceProps {
  className?: string;
  price: number;
  count?: number;
}
const Price: FC<IPriceProps> = ({ className, price, count }) => {
  return (
    <>
      <PriceStyle className={className}>{numberWithCommas(price, 0)}</PriceStyle>
      {count !== undefined && <CountStyle>{count}</CountStyle>}
    </>
  );
};

interface ITextProps {
  className?: string;
  text: string | null;
}
const Option: FC<ITextProps> = ({ className, text }) => {
  return <OptionAndMessageStyle className={className}>{text ? text : "옵션 : 없음"}</OptionAndMessageStyle>;
};

const Message: FC<ITextProps> = ({ className, text }) => {
  return <OptionAndMessageStyle className={className}>{text}</OptionAndMessageStyle>;
};

interface IDateProps {
  className?: string;
  date: string;
}
const Date: FC<IDateProps> = ({ className, date }) => {
  return <DateStyle className={className}>{date}</DateStyle>;
};

interface ITodayBadgeProps extends ProductStatusType {}
const TodayBadge: FC<ITodayBadgeProps> = ({ status }) => {
  return (
    <TodayBadgeStyle>
      <TodayDeliveryBadge width={48} height={12} isActive={status !== PRODUCT_STATUS.SOLD_OUT} />
    </TodayBadgeStyle>
  );
};

HorizontalCard.BrandName = BrandName;
HorizontalCard.ProductName = ProductName;
HorizontalCard.Price = Price;
HorizontalCard.Option = Option;
HorizontalCard.Message = Message;
HorizontalCard.Date = Date;
HorizontalCard.TodayBadge = TodayBadge;
HorizontalCard.Row = Row;

export default HorizontalCard;
