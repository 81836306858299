export const menuListMock = [
  {
    id: 8,
    linkType: 2000,
    title: "SALE",
    linkUrl: "",
    imgUrl: "https://cdn.amondz.com/home/menu/1/resize/icon/HMAI_439_50.jpeg?v=1703127794785",
  },
  {
    id: 11,
    linkType: 2000,
    title: "아몬즈 단독",
    linkUrl: "",
    imgUrl: "https://cdn.amondz.com/home/menu/2/resize/icon/HMAI_517_50.jpeg?v=1715243884711",
  },
  {
    id: 5,
    linkType: 2000,
    title: "14/18K",
    linkUrl: "",
    imgUrl: "https://cdn.amondz.com/home/menu/3/resize/icon/HMAI_490_50.jpeg?v=1709091889913",
  },
  {
    id: 6,
    linkType: 2000,
    title: "맨/젠더리스",
    linkUrl: "",
    imgUrl: "https://cdn.amondz.com/home/menu/4/resize/icon/HMAI_491_50.jpeg?v=1709091902439",
  },
  {
    id: 12,
    linkType: 2000,
    title: "럭셔리",
    linkUrl: "",
    imgUrl: "https://cdn.amondz.com/home/menu/5/resize/icon/HMAI_489_50.jpeg?v=1709091880157",
  },
  {
    id: 3300,
    linkType: 2,
    title: "금팔고 쇼핑",
    linkUrl: "",
    imgUrl: "https://cdn.amondz.com/home/menu/6/resize/icon/HMAI_493_50.jpeg?v=1709173811909",
  },
  {
    id: 3454,
    linkType: 2,
    title: "골드데이",
    linkUrl: "",
    imgUrl: "https://cdn.amondz.com/home/menu/7/resize/icon/HMAI_519_50.jpeg?v=1715314116073",
  },
  {
    id: 2385,
    linkType: 2,
    title: "혜택 모아보기",
    linkUrl: "",
    imgUrl: "https://cdn.amondz.com/home/menu/8/resize/icon/HMAI_482_50.jpeg?v=1707188559544",
  },
];
