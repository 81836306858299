import { isAndroid, isIOS, isBrowser } from "@amondz/detect-device";
import { DEVICE_OS_TYPE } from "@constants/enum/baseEnums";
import { DEVICE_TYPE } from "@constants/service/common/common";

/**
 * @returns {DEVICE_OS_TYPE} 디바이스 OS 타입
 */
export const detectDeviceOs = (): DEVICE_OS_TYPE => {
  // browser
  if (isBrowser) {
    return DEVICE_OS_TYPE.BROWSER;
  }

  // android
  if (isAndroid) {
    return DEVICE_OS_TYPE.ANDROID;
  }

  // ios
  if (isIOS) {
    return DEVICE_OS_TYPE.IOS;
  }

  return DEVICE_OS_TYPE.BROWSER;
};

export const getDeviceOption = (userAgent?: string): { deviceType: number; os: string } => {
  const targetUserAgent: string = userAgent || navigator?.userAgent;

  // os 찾기
  let os = "unknown";

  if (/android/.test(targetUserAgent.toLowerCase())) {
    os = "android";
  } else if (/ipad|iphone|ipod/.test(targetUserAgent.toLowerCase())) {
    os = "ios";
  }

  return {
    deviceType: DEVICE_TYPE.MOBILE,
    os,
  };
};

export const isInstagramApp = (): boolean => {
  /**
   * navigator.userAgent를 통해 사용자 브라우저 환경을 파악하는 것은 권장하지 않음
   * 1. navigator.userAgent는 변경될 수 있는 값이고, 환경에 따라 부정확한 값일 수 있음
   * 2. 개인정보이기 때문에 보안상 좋지 않음
   *
   * 최대한 navigator.userAgent가 필요없도록 기능을 설계하는 것을 권장
   * */
  return navigator ? navigator.userAgent.includes("Instagram") : false;
};
