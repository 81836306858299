import React, { FC } from "react";

import ScrollXList from "@components/common/ScrollXList";
import Product from "@components/common/Product";
import Img from "@components/common/Img";
import { searchBeforeRecommendedProductListDataType, searchProductListCommonDataType } from "@swr/types/searchTypes";
import { SliderWrapperStyle } from "../style";

interface ISearchRecommendedProductListProps {
  recommendProductList: searchProductListCommonDataType[] | searchBeforeRecommendedProductListDataType[];
}

const SearchRecommendedProductList: FC<ISearchRecommendedProductListProps> = (props) => {
  const { recommendProductList } = props;
  const { VerticalCard } = Product;

  return (
    <SliderWrapperStyle>
      <ScrollXList>
        {recommendProductList.map((product) => (
          <VerticalCard
            key={product.id}
            id={product.id}
            image={<Img src={product.imgUrl} width={300} height={300} layout="responsive" />}
            productName={product.productName}
            brandName={product.storeName}
          />
        ))}
      </ScrollXList>
    </SliderWrapperStyle>
  );
};

export default SearchRecommendedProductList;
