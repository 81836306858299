import { AxiosError, InternalAxiosRequestConfig, AxiosResponse } from "axios";
import { isNil } from "lodash";

import superFetchV2 from "@amondz/apis-v2";
import { getHeaderData } from "@services/fetchUtils";
import { errorHandlerV2 } from "@services/errorHandlers";
import { ErrorDataType } from "@store/modules/base";
import { getAmondzDeviceType } from "@lib/utility/cookies";
import { DEVICE_TYPE } from "@constants/service/common/common";

superFetchV2.interceptors.request.use((req: InternalAxiosRequestConfig) => {
  if (!req.headers) {
    return req;
  }

  const { userKey, uuid, pageView, deviceType, os, version } = getHeaderData(req);
  req.headers["Authorization"] = userKey;
  req.headers["platform-type"] = deviceType;
  req.headers["os"] = os;
  req.headers["api-version"] = version;
  if (typeof req.headers["page-view"] !== "number" && !isNil(pageView)) req.headers["page-view"] = pageView;

  // CSR 에서 UUID 설정
  if (typeof window !== "undefined") req.headers["uuid"] = uuid;
  else delete req.headers.prevUrlPath;

  return req;
});

superFetchV2.interceptors.response.use(
  (res: AxiosResponse) => {
    return res.data;
  },
  (error: AxiosError<ErrorDataType>) => {
    if (error?.response?.data) {
      // TODO: no-unsafe-optional-chaining 에러가 발생하고 있어서 전체적인 로직 검증이 필요합니다.
      // eslint-disable-next-line no-unsafe-optional-chaining
      const { errors } = error.response?.data;
      const deviceType = Number(getAmondzDeviceType(error.config?.headers?.Cookie as string));

      const isWebView = deviceType === DEVICE_TYPE.IOS || deviceType === DEVICE_TYPE.ANDROID;

      //ssr시에는 dispatch 불가
      if (typeof window !== "undefined" || !isWebView) {
        errors?.length > 0 && errorHandlerV2(errors[0]);
      }
    }

    return Promise.reject(error);
  },
);
