import * as React from "react";
import { FC, memo } from "react";

import { FromType, useProductDetailFromPage } from "@amondz/marketing-service";
import Product from "@components/common/Product";
import Img from "@components/common/Img/Img";
import { IMAGE_SIZE } from "@constants/service/common/imageSizeConstants";
import { ContentsStyle, HeaderStyle, SlideListStyle, ScrollXListStyle } from "./style";

// todo: 타입 통합 작업 시 수정 예정
export type SlideListProductInfo = {
  id: number;
  name: string;
  discountRate: number;
  salesPrice: number;
  status: number;
  imgUrl: string;
};

interface ISlideListProps {
  className?: string;
  title?: string;
  productList: SlideListProductInfo[];
}

/**
 * 스와이프 가능한 상품 리스트
 * @param props
 * @constructor
 */
const SlideList: FC<ISlideListProps> = (props) => {
  const { className, title, productList } = props;
  const { setFrom } = useProductDetailFromPage();

  const handleClick = (): void => {
    // 블럭스 추천 상품을 통해 상품 상세 페이지 접근 시 from 정보를 보내줘야함
    setFrom(FromType.PRODUCT_DETAIL_PAGE);
  };

  return (
    <SlideListStyle className={className}>
      {title ? (
        <HeaderStyle>
          <strong>{title}</strong>
        </HeaderStyle>
      ) : null}
      <ContentsStyle>
        <ScrollXListStyle>
          {productList.map((item) => (
            <Product.VerticalCard
              key={item.id}
              className="product-list-item"
              id={item.id}
              image={
                <Img
                  src={item.imgUrl!}
                  width={IMAGE_SIZE.LARGE.WIDTH}
                  height={IMAGE_SIZE.LARGE.HEIGHT}
                  layout="responsive"
                />
              }
              productName={item.name}
              status={item.status}
              salesPrice={item.salesPrice}
              discountRate={item.discountRate}
              onClick={handleClick}
            />
          ))}
        </ScrollXListStyle>
      </ContentsStyle>
    </SlideListStyle>
  );
};

export default memo(SlideList);
