import styled from "@styles/themedComponents";
import ScrollXList from "@components/common/ScrollXList/ScrollXList";

export const SlideListStyle = styled.div``;

export const ScrollXListStyle = styled(ScrollXList)`
  width: calc(100% + 30px);
  margin: 0 -15px;
`;

export const HeaderStyle = styled.div`
  margin-bottom: 15px;

  strong {
    font-size: 18px;
    line-height: 27px;
    font-weight: bold;
    letter-spacing: -0.5px;
  }
`;

export const ContentsStyle = styled.div``;
