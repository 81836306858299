import { HomeListStateType } from "@amondz/types";

export const homeListMock: HomeListStateType = {
  homeMenuList: [
    {
      id: 8,
      linkType: 2000,
      title: "SALE",
      linkUrl: "",
      imgUrl: "https://cdn.amondz.com/home/menu/1/resize/icon/HMAI_439_50.jpeg?v=1703127794785",
    },
    {
      id: 11,
      linkType: 2000,
      title: "아몬즈 단독",
      linkUrl: "",
      imgUrl: "https://cdn.amondz.com/home/menu/2/resize/icon/HMAI_521_50.jpeg?v=1715933314455",
    },
    {
      id: 5,
      linkType: 2000,
      title: "14/18K",
      linkUrl: "",
      imgUrl: "https://cdn.amondz.com/home/menu/3/resize/icon/HMAI_490_50.jpeg?v=1709091889913",
    },
    {
      id: 6,
      linkType: 2000,
      title: "맨/젠더리스",
      linkUrl: "",
      imgUrl: "https://cdn.amondz.com/home/menu/4/resize/icon/HMAI_491_50.jpeg?v=1709091902439",
    },
    {
      id: 12,
      linkType: 2000,
      title: "럭셔리",
      linkUrl: "",
      imgUrl: "https://cdn.amondz.com/home/menu/5/resize/icon/HMAI_489_50.jpeg?v=1709091880157",
    },
    {
      id: 3300,
      linkType: 2,
      title: "금팔고 쇼핑",
      linkUrl: "",
      imgUrl: "https://cdn.amondz.com/home/menu/6/resize/icon/HMAI_493_50.jpeg?v=1709173811909",
    },
    {
      id: 3454,
      linkType: 2,
      title: "골드데이",
      linkUrl: "",
      imgUrl: "https://cdn.amondz.com/home/menu/7/resize/icon/HMAI_519_50.jpeg?v=1715314116073",
    },
    {
      id: 2385,
      linkType: 2,
      title: "혜택 모아보기",
      linkUrl: "",
      imgUrl: "https://cdn.amondz.com/home/menu/8/resize/icon/HMAI_482_50.jpeg?v=1707188559544",
    },
  ],
  widgetList: [
    {
      id: 3452,
      widgetType: 2,
      layoutType: 2,
      mainTitle: "-",
      subTitle: "",
      itemList: [
        {
          id: 3474,
          mobileBannerImg: {
            width: 1125,
            height: 1500,
            imgUrl: "https://image.amondz.com/rectangle-images/9cf8a6bd-eb00-43f2-92f6-89e22a90513f.jpg",
          },
          pcBannerImg: {
            width: 5760,
            height: 1500,
            imgUrl: "https://image.amondz.com/rectangle-images/4c294d82-6469-4305-94e0-965f7f184745.jpg",
          },
        },
        {
          id: 3469,
          mobileBannerImg: {
            width: 1125,
            height: 1500,
            imgUrl: "https://image.amondz.com/rectangle-images/f9ff9a95-755f-483c-9bf5-6e905ff8d6bd.jpg",
          },
          pcBannerImg: {
            width: 5760,
            height: 1500,
            imgUrl: "https://image.amondz.com/rectangle-images/a3f05c3d-e550-43e2-9eed-296fde58d92e.jpg",
          },
        },
      ],
    },
    {
      id: 6900,
      widgetType: 3,
      layoutType: 100,
      mainTitle: "아몬즈가 추천하는 베스트 브랜드",
      subTitle: "단독 혜택을 놓치지 마세요!",
      itemList: [
        {
          id: 3441,
          linkType: 2,
          linkUrl: "",
          title: "",
          contents: "",
          mobileBannerImg: {
            width: 495,
            height: 642,
            imgUrl: "https://image.amondz.com/rectangle-images/bb9f779b-1862-4537-bbca-8cb65047b027.jpg",
          },
          pcBannerImg: {
            width: 1422,
            height: 1287,
            imgUrl: "https://image.amondz.com/rectangle-images/525a52d6-c87e-49d0-acd6-6ba561290aeb.jpg",
          },
        },
        {
          id: 3442,
          linkType: 2,
          linkUrl: "",
          title: "",
          contents: "",
          mobileBannerImg: {
            width: 495,
            height: 642,
            imgUrl: "https://image.amondz.com/rectangle-images/aa2dd741-b40d-4841-9335-5f8328c07cf8.jpg",
          },
          pcBannerImg: {
            width: 1422,
            height: 1287,
            imgUrl: "https://image.amondz.com/rectangle-images/a7de8723-2268-4459-b4b7-7c73969d1b6e.jpg",
          },
        },
      ],
    },
    {
      id: 4723,
      widgetType: 0,
      eventId: null,
      layoutType: 0,
      isProductRecommend: true,
      mainTitle: "당신만을 위한 맞춤 추천",
      subTitle: null,
      buttonText: null,
      exposureItemCount: 20,
      itemList: [
        {
          id: 37895,
          name: "14k 미니 심플 하트 목걸이",
          storeId: 1230,
          storeName: "프리테",
          categoryId: 1,
          categoryName: "반지",
          isTodayDelivery: 1,
          salesPrice: 274350,
          imgUrl: "https://cdn.amondz.com/product/37895/resize/mainImg/PSI_1256442_50.jpeg?v=1711612165212",
          likeFlag: 0,
          status: 2,
          isGift: 1,
          originPrice: 295000,
          discountRate: 7,
          productCategoryList: [
            {
              id: 2,
              name: "목걸이",
            },
            {
              id: 70,
              name: "하트목걸이",
            },
            {
              id: 269,
              name: "14/18K",
            },
            {
              id: 299,
              name: "목걸이",
            },
            {
              id: 319,
              name: "오늘 출발",
            },
            {
              id: 320,
              name: "목걸이",
            },
            {
              id: 267,
              name: "베스트",
            },
            {
              id: 287,
              name: "목걸이",
            },
            {
              id: 403,
              name: "14/18K GOLD",
            },
            {
              id: 481,
              name: "베스트",
            },
          ],
        },
        {
          id: 7375,
          name: "Alice Heart Pearl Necklace",
          storeId: 437,
          storeName: "플로움",
          categoryId: 1,
          categoryName: "반지",
          isTodayDelivery: 1,
          salesPrice: 78000,
          imgUrl: "https://cdn.amondz.com/product/7375/resize/mainImg/PSI_70381_50.jpeg?v=1559613316521",
          likeFlag: 0,
          status: 2,
          isGift: 1,
          productCategoryList: [
            {
              id: 2,
              name: "목걸이",
            },
            {
              id: 70,
              name: "하트목걸이",
            },
            {
              id: 85,
              name: "펜던트목걸이",
            },
            {
              id: 319,
              name: "오늘 출발",
            },
            {
              id: 320,
              name: "목걸이",
            },
            {
              id: 86,
              name: "서브펜던트",
            },
            {
              id: 59,
              name: "원석목걸이",
            },
            {
              id: 267,
              name: "베스트",
            },
            {
              id: 287,
              name: "목걸이",
            },
            {
              id: 648,
              name: "목걸이&펜던트",
            },
          ],
        },
        {
          id: 26845,
          name: "실버 4발 프롱 실체인 위아몬즈 목걸이",
          storeId: 930,
          storeName: "위아몬즈",
          isTodayDelivery: 1,
          categoryId: 1,
          categoryName: "반지",
          salesPrice: 44910,
          imgUrl: "https://cdn.amondz.com/product/26845/resize/mainImg/PSI_887982_50.jpeg?v=1676515170708",
          likeFlag: 0,
          status: 2,
          isGift: 1,
          originPrice: 49900,
          discountRate: 10,
          productCategoryList: [
            {
              id: 2,
              name: "목걸이",
            },
            {
              id: 319,
              name: "오늘 출발",
            },
            {
              id: 320,
              name: "목걸이",
            },
            {
              id: 59,
              name: "원석목걸이",
            },
            {
              id: 85,
              name: "펜던트목걸이",
            },
            {
              id: 267,
              name: "베스트",
            },
            {
              id: 648,
              name: "목걸이&펜던트",
            },
          ],
        },
        {
          id: 30568,
          name: "[아몬즈 단독] your necklace 이니셜 목걸이",
          storeId: 954,
          storeName: "딥브로우",
          categoryId: 1,
          categoryName: "반지",
          isTodayDelivery: 1,
          salesPrice: 57800,
          imgUrl: "https://cdn.amondz.com/product/30568/resize/mainImg/PSI_299192_50.jpeg?v=1604296486786",
          likeFlag: 0,
          status: 2,
          isGift: 1,
          originPrice: 68000,
          discountRate: 15,
          productCategoryList: [
            {
              id: 2,
              name: "목걸이",
            },
            {
              id: 66,
              name: "이니셜목걸이",
            },
            {
              id: 70,
              name: "하트목걸이",
            },
            {
              id: 86,
              name: "서브펜던트",
            },
            {
              id: 266,
              name: "아몬즈 단독",
            },
            {
              id: 275,
              name: "목걸이",
            },
            {
              id: 319,
              name: "오늘 출발",
            },
            {
              id: 320,
              name: "목걸이",
            },
            {
              id: 267,
              name: "베스트",
            },
            {
              id: 287,
              name: "목걸이",
            },
            {
              id: 648,
              name: "목걸이&펜던트",
            },
            {
              id: 653,
              name: "하트 주얼리",
            },
            {
              id: 654,
              name: "목걸이&펜던트",
            },
          ],
        },
        {
          id: 77994,
          name: "[아몬즈 단독] 14k/18k 이터너티 반지(2size)",
          storeId: 439,
          storeName: "멕코이",
          categoryId: 1,
          categoryName: "반지",
          isTodayDelivery: 1,
          salesPrice: 171000,
          imgUrl: "https://cdn.amondz.com/product/77994/resize/mainImg/PSI_827010_50.jpeg?v=1669632750260",
          likeFlag: 0,
          status: 2,
          isGift: 1,
          originPrice: 180000,
          discountRate: 5,
          productCategoryList: [
            {
              id: 3,
              name: "반지",
            },
            {
              id: 269,
              name: "14/18K",
            },
            {
              id: 296,
              name: "반지",
            },
            {
              id: 397,
              name: "커플 주얼리",
            },
            {
              id: 399,
              name: "커플링",
            },
            {
              id: 266,
              name: "아몬즈 단독",
            },
            {
              id: 272,
              name: "반지",
            },
            {
              id: 110,
              name: "트위스트링",
            },
            {
              id: 480,
              name: "아몬즈 단독",
            },
            {
              id: 319,
              name: "오늘 출발",
            },
            {
              id: 323,
              name: "반지",
            },
            {
              id: 403,
              name: "14/18K GOLD",
            },
            {
              id: 481,
              name: "베스트",
            },
            {
              id: 267,
              name: "베스트",
            },
            {
              id: 284,
              name: "반지",
            },
            {
              id: 512,
              name: "골드데이!",
            },
            {
              id: 671,
              name: "무료 각인",
            },
            {
              id: 672,
              name: "각인 주얼리",
            },
            {
              id: 675,
              name: "14K",
            },
          ],
        },
        {
          id: 85213,
          name: "combi texture ring",
          storeId: 1052,
          storeName: "테나시트",
          categoryId: 1,
          categoryName: "반지",
          isTodayDelivery: 0,
          salesPrice: 69000,
          imgUrl: "https://cdn.amondz.com/product/85213/resize/mainImg/PSI_933186_50.jpeg?v=1681812772551",
          likeFlag: 0,
          status: 2,
          isGift: 1,
          productCategoryList: [
            {
              id: 3,
              name: "반지",
            },
            {
              id: 121,
              name: "더블링",
            },
          ],
        },
        {
          id: 81142,
          name: "[아몬즈 단독] petit angel heart necklace(Silver)",
          storeId: 954,
          storeName: "딥브로우",
          categoryId: 1,
          categoryName: "반지",
          isTodayDelivery: 0,
          salesPrice: 52200,
          imgUrl: "https://cdn.amondz.com/product/81142/resize/mainImg/PSI_871894_50.jpeg?v=1674090692011",
          likeFlag: 0,
          status: 2,
          isGift: 1,
          originPrice: 58000,
          discountRate: 10,
          productCategoryList: [
            {
              id: 2,
              name: "목걸이",
            },
            {
              id: 85,
              name: "펜던트목걸이",
            },
            {
              id: 86,
              name: "서브펜던트",
            },
            {
              id: 266,
              name: "아몬즈 단독",
            },
            {
              id: 275,
              name: "목걸이",
            },
            {
              id: 59,
              name: "원석목걸이",
            },
            {
              id: 70,
              name: "하트목걸이",
            },
            {
              id: 653,
              name: "하트 주얼리",
            },
            {
              id: 654,
              name: "목걸이&펜던트",
            },
          ],
        },
        {
          id: 77368,
          name: "실버 4mm 화이트 스톤 위아몬즈 목걸이",
          storeId: 930,
          storeName: "위아몬즈",
          categoryId: 1,
          categoryName: "반지",
          isTodayDelivery: 1,
          salesPrice: 85000,
          imgUrl: "https://cdn.amondz.com/product/77368/resize/mainImg/PSI_852093_50.jpeg?v=1671592685416",
          likeFlag: 0,
          status: 2,
          isGift: 1,
          productCategoryList: [
            {
              id: 2,
              name: "목걸이",
            },
            {
              id: 85,
              name: "펜던트목걸이",
            },
            {
              id: 319,
              name: "오늘 출발",
            },
            {
              id: 320,
              name: "목걸이",
            },
          ],
        },
        {
          id: 17411,
          name: "골드바 목걸이 (14k ,18k)",
          storeId: 677,
          storeName: "보스크",
          categoryId: 1,
          categoryName: "반지",
          isTodayDelivery: 0,
          salesPrice: 225000,
          imgUrl: "https://cdn.amondz.com/product/17411/resize/mainImg/PSI_293709_50.jpeg?v=1603417646264",
          likeFlag: 0,
          status: 2,
          isGift: 1,
          originPrice: 450000,
          discountRate: 50,
          productCategoryList: [
            {
              id: 2,
              name: "목걸이",
            },
            {
              id: 269,
              name: "14/18K",
            },
            {
              id: 299,
              name: "목걸이",
            },
            {
              id: 85,
              name: "펜던트목걸이",
            },
            {
              id: 481,
              name: "베스트",
            },
          ],
        },
        {
          id: 94332,
          name: "[아몬즈 단독] moonie heart pearl necklace",
          storeId: 690,
          storeName: "앵브록스",
          categoryId: 1,
          categoryName: "반지",
          isTodayDelivery: 0,
          salesPrice: 48600,
          imgUrl: "https://cdn.amondz.com/product/94332/resize/mainImg/PSI_1039798_50.jpeg?v=1692945732456",
          likeFlag: 0,
          status: 2,
          isGift: 1,
          originPrice: 54000,
          discountRate: 10,
          productCategoryList: [
            {
              id: 2,
              name: "목걸이",
            },
            {
              id: 57,
              name: "진주목걸이",
            },
            {
              id: 70,
              name: "하트목걸이",
            },
            {
              id: 85,
              name: "펜던트목걸이",
            },
            {
              id: 266,
              name: "아몬즈 단독",
            },
            {
              id: 275,
              name: "목걸이",
            },
            {
              id: 616,
              name: "진주",
            },
            {
              id: 620,
              name: "#서브펜던트",
            },
            {
              id: 621,
              name: "#하트",
            },
            {
              id: 267,
              name: "베스트",
            },
            {
              id: 648,
              name: "목걸이&펜던트",
            },
            {
              id: 653,
              name: "하트 주얼리",
            },
            {
              id: 654,
              name: "목걸이&펜던트",
            },
          ],
        },
        {
          id: 78140,
          name: "14k 랑브 물방울 네크리스 (2 color)",
          storeId: 1935,
          storeName: "유어오브",
          categoryId: 1,
          categoryName: "반지",
          isTodayDelivery: 1,
          salesPrice: 185000,
          imgUrl: "https://cdn.amondz.com/product/78140/resize/mainImg/PSI_828992_50.jpeg?v=1669878625498",
          likeFlag: 0,
          status: 2,
          isGift: 1,
          originPrice: 205560,
          discountRate: 10,
          productCategoryList: [
            {
              id: 2,
              name: "목걸이",
            },
            {
              id: 85,
              name: "펜던트목걸이",
            },
            {
              id: 269,
              name: "14/18K",
            },
            {
              id: 299,
              name: "목걸이",
            },
            {
              id: 319,
              name: "오늘 출발",
            },
            {
              id: 320,
              name: "목걸이",
            },
            {
              id: 403,
              name: "14/18K GOLD",
            },
            {
              id: 481,
              name: "베스트",
            },
            {
              id: 267,
              name: "베스트",
            },
            {
              id: 648,
              name: "목걸이&펜던트",
            },
          ],
        },
        {
          id: 52289,
          name: "14k 러브 키스토리 목걸이",
          storeId: 1616,
          storeName: "도로시",
          categoryId: 1,
          categoryName: "반지",
          isTodayDelivery: 1,
          salesPrice: 196620,
          imgUrl: "https://cdn.amondz.com/product/52289/resize/mainImg/PSI_984834_50.jpeg?v=1687491481592",
          likeFlag: 0,
          status: 2,
          isGift: 1,
          originPrice: 339000,
          discountRate: 42,
          productCategoryList: [
            {
              id: 2,
              name: "목걸이",
            },
            {
              id: 85,
              name: "펜던트목걸이",
            },
            {
              id: 70,
              name: "하트목걸이",
            },
            {
              id: 269,
              name: "14/18K",
            },
            {
              id: 299,
              name: "목걸이",
            },
            {
              id: 319,
              name: "오늘 출발",
            },
            {
              id: 320,
              name: "목걸이",
            },
            {
              id: 403,
              name: "14/18K GOLD",
            },
            {
              id: 481,
              name: "베스트",
            },
            {
              id: 512,
              name: "골드데이!",
            },
            {
              id: 670,
              name: "30% 이상 특가",
            },
            {
              id: 675,
              name: "14K",
            },
          ],
        },
        {
          id: 99358,
          name: "[아몬즈 단독] Draping ring",
          storeId: 1624,
          storeName: "엘엔엠디",
          categoryId: 1,
          categoryName: "반지",
          isTodayDelivery: 0,
          salesPrice: 65000,
          imgUrl: "https://cdn.amondz.com/product/99358/resize/mainImg/PSI_1106595_50.jpeg?v=1698865257197",
          likeFlag: 1,
          status: 2,
          isGift: 1,
          productCategoryList: [
            {
              id: 3,
              name: "반지",
            },
            {
              id: 98,
              name: "멜팅링",
            },
            {
              id: 397,
              name: "커플 주얼리",
            },
            {
              id: 399,
              name: "커플링",
            },
            {
              id: 104,
              name: "커플링",
            },
            {
              id: 267,
              name: "베스트",
            },
            {
              id: 284,
              name: "반지",
            },
            {
              id: 266,
              name: "아몬즈 단독",
            },
            {
              id: 272,
              name: "반지",
            },
          ],
        },
        {
          id: 85126,
          name: "실버 스네이크 체인 미니 링 위아몬즈 목걸이",
          storeId: 930,
          storeName: "위아몬즈",
          categoryId: 1,
          categoryName: "반지",
          isTodayDelivery: 0,
          salesPrice: 67900,
          imgUrl: "https://cdn.amondz.com/product/85126/resize/mainImg/PSI_1167116_50.jpeg?v=1702970010621",
          likeFlag: 0,
          status: 2,
          isGift: 1,
          productCategoryList: [
            {
              id: 2,
              name: "목걸이",
            },
            {
              id: 85,
              name: "펜던트목걸이",
            },
            {
              id: 319,
              name: "오늘 출발",
            },
            {
              id: 320,
              name: "목걸이",
            },
          ],
        },
        {
          id: 61608,
          name: "[신상백서][아몬즈 단독] HEART CLOVER PENDANT",
          storeId: 1588,
          storeName: "어나더셀",
          categoryId: 1,
          categoryName: "반지",
          isTodayDelivery: 1,
          salesPrice: 71100,
          imgUrl: "https://cdn.amondz.com/product/61608/resize/mainImg/PSI_911501_50.jpeg?v=1679376692254",
          likeFlag: 0,
          status: 2,
          isGift: 1,
          originPrice: 79000,
          discountRate: 10,
          productCategoryList: [
            {
              id: 2,
              name: "목걸이",
            },
            {
              id: 85,
              name: "펜던트목걸이",
            },
            {
              id: 56,
              name: "토글목걸이",
            },
            {
              id: 267,
              name: "베스트",
            },
            {
              id: 287,
              name: "목걸이",
            },
            {
              id: 319,
              name: "오늘 출발",
            },
            {
              id: 320,
              name: "목걸이",
            },
            {
              id: 266,
              name: "아몬즈 단독",
            },
            {
              id: 275,
              name: "목걸이",
            },
            {
              id: 616,
              name: "진주",
            },
            {
              id: 622,
              name: "#플라워",
            },
            {
              id: 648,
              name: "목걸이&펜던트",
            },
            {
              id: 653,
              name: "하트 주얼리",
            },
            {
              id: 654,
              name: "목걸이&펜던트",
            },
          ],
        },
        {
          id: 44356,
          name: "라이크어플라워 미니 챠밍 실버 목걸이",
          storeId: 753,
          storeName: "마마카사르",
          categoryId: 1,
          categoryName: "반지",
          isTodayDelivery: 1,
          salesPrice: 72800,
          imgUrl: "https://cdn.amondz.com/product/44356/resize/mainImg/PSI_576310_50.jpeg?v=1643020548848",
          likeFlag: 0,
          status: 2,
          isGift: 1,
          originPrice: 104000,
          discountRate: 30,
          productCategoryList: [
            {
              id: 2,
              name: "목걸이",
            },
            {
              id: 85,
              name: "펜던트목걸이",
            },
            {
              id: 319,
              name: "오늘 출발",
            },
            {
              id: 320,
              name: "목걸이",
            },
            {
              id: 267,
              name: "베스트",
            },
            {
              id: 287,
              name: "목걸이",
            },
            {
              id: 648,
              name: "목걸이&펜던트",
            },
          ],
        },
        {
          id: 20317,
          name: "LAYERED CROSS NECKLACE",
          storeId: 702,
          storeName: "마호가니",
          categoryId: 1,
          categoryName: "반지",
          isTodayDelivery: 0,
          salesPrice: 110000,
          imgUrl: "https://cdn.amondz.com/product/20317/resize/mainImg/PSI_177283_50.jpeg?v=1588829957232",
          likeFlag: 0,
          status: 2,
          isGift: 1,
          productCategoryList: [
            {
              id: 2,
              name: "목걸이",
            },
            {
              id: 85,
              name: "펜던트목걸이",
            },
            {
              id: 397,
              name: "커플 주얼리",
            },
            {
              id: 398,
              name: "목걸이",
            },
            {
              id: 319,
              name: "오늘 출발",
            },
            {
              id: 320,
              name: "목걸이",
            },
          ],
        },
        {
          id: 67376,
          name: "실버 블루쿼츠 힐링스톤 위아몬즈 목걸이",
          storeId: 930,
          storeName: "위아몬즈",
          categoryId: 1,
          categoryName: "반지",
          isTodayDelivery: 1,
          salesPrice: 85410,
          imgUrl: "https://cdn.amondz.com/product/67376/resize/mainImg/PSI_701736_50.jpeg?v=1655344638054",
          likeFlag: 0,
          status: 2,
          isGift: 1,
          originPrice: 94900,
          discountRate: 10,
          productCategoryList: [
            {
              id: 2,
              name: "목걸이",
            },
            {
              id: 85,
              name: "펜던트목걸이",
            },
            {
              id: 59,
              name: "원석목걸이",
            },
            {
              id: 319,
              name: "오늘 출발",
            },
            {
              id: 320,
              name: "목걸이",
            },
          ],
        },
        {
          id: 40526,
          name: "14K 스몰 러프DIA 위아몬즈 목걸이",
          storeId: 930,
          storeName: "위아몬즈",
          categoryId: 1,
          categoryName: "반지",
          isTodayDelivery: 1,
          salesPrice: 207000,
          imgUrl: "https://cdn.amondz.com/product/40526/resize/mainImg/PSI_791594_50.jpeg?v=1666143598320",
          likeFlag: 0,
          status: 2,
          isGift: 1,
          originPrice: 230000,
          discountRate: 10,
          productCategoryList: [
            {
              id: 2,
              name: "목걸이",
            },
            {
              id: 269,
              name: "14/18K",
            },
            {
              id: 299,
              name: "목걸이",
            },
            {
              id: 319,
              name: "오늘 출발",
            },
            {
              id: 320,
              name: "목걸이",
            },
            {
              id: 59,
              name: "원석목걸이",
            },
            {
              id: 85,
              name: "펜던트목걸이",
            },
            {
              id: 403,
              name: "14/18K GOLD",
            },
            {
              id: 267,
              name: "베스트",
            },
            {
              id: 287,
              name: "목걸이",
            },
            {
              id: 607,
              name: "다이아몬드",
            },
            {
              id: 608,
              name: "천연",
            },
            {
              id: 512,
              name: "골드데이!",
            },
            {
              id: 675,
              name: "14K",
            },
          ],
        },
      ],
    },
    {
      id: 6927,
      widgetType: 0,
      eventId: 3454,
      layoutType: 300,
      isProductRecommend: true,
      mainTitle: "1018 GOLD DAYS",
      subTitle: "최대 62% 할인 + 15% 쿠폰",
      buttonText: "기획전 보러 가기",
      exposureItemCount: 10,
      itemList: [
        {
          id: 83246,
          name: "14k 18k 볼륨 두줄 반지",
          storeId: 2236,
          storeName: "금은다해",
          isTodayDelivery: 0,
          originPrice: 530000,
          discountRate: 36,
          salesPrice: 339200,
          imgUrl: "https://cdn.amondz.com/product/83246/resize/mainImg/PSI_895678_50.jpeg?v=1677754415572",
          likeFlag: 0,
          status: 2,
          categoryId: 3,
          categoryName: "반지",
          productCategoryList: [
            {
              id: 3,
              name: "반지",
            },
            {
              id: 121,
              name: "더블링",
            },
            {
              id: 269,
              name: "14/18K",
            },
            {
              id: 296,
              name: "반지",
            },
          ],
        },
        {
          id: 93818,
          name: "14K 18K 해피 스마일 반지",
          storeId: 2236,
          storeName: "금은다해",
          isTodayDelivery: 0,
          originPrice: 270000,
          discountRate: 38,
          salesPrice: 167400,
          imgUrl: "https://cdn.amondz.com/product/93818/resize/mainImg/PSI_1032440_50.jpeg?v=1692340743269",
          likeFlag: 0,
          status: 2,
          categoryId: 3,
          categoryName: "반지",
          productCategoryList: [
            {
              id: 3,
              name: "반지",
            },
            {
              id: 106,
              name: "실반지",
            },
            {
              id: 110,
              name: "트위스트링",
            },
            {
              id: 269,
              name: "14/18K",
            },
            {
              id: 296,
              name: "반지",
            },
          ],
        },
        {
          id: 29717,
          name: "소프트 베이직 골드 목걸이 (14k)",
          storeId: 677,
          storeName: "보스크",
          isTodayDelivery: 0,
          originPrice: 210000,
          discountRate: 45,
          salesPrice: 115500,
          imgUrl: "https://cdn.amondz.com/product/29717/resize/mainImg/PSI_852802_50.jpeg?v=1671615198542",
          likeFlag: 0,
          status: 2,
          categoryId: 2,
          categoryName: "목걸이",
          productCategoryList: [
            {
              id: 2,
              name: "목걸이",
            },
            {
              id: 269,
              name: "14/18K",
            },
            {
              id: 299,
              name: "목걸이",
            },
            {
              id: 62,
              name: "체인목걸이",
            },
            {
              id: 481,
              name: "베스트",
            },
          ],
        },
        {
          id: 75652,
          name: "14K 러프 트리플 콤비 반지",
          storeId: 1605,
          storeName: "a14",
          isTodayDelivery: 0,
          originPrice: 386000,
          discountRate: 25,
          salesPrice: 289500,
          imgUrl: "https://cdn.amondz.com/product/75652/resize/mainImg/PSI_819282_50.jpeg?v=1668752339177",
          likeFlag: 0,
          status: 2,
          categoryId: 3,
          categoryName: "반지",
          productCategoryList: [
            {
              id: 3,
              name: "반지",
            },
            {
              id: 91,
              name: "원석반지",
            },
            {
              id: 269,
              name: "14/18K",
            },
            {
              id: 296,
              name: "반지",
            },
            {
              id: 319,
              name: "오늘 출발",
            },
            {
              id: 323,
              name: "반지",
            },
            {
              id: 403,
              name: "14/18K GOLD",
            },
            {
              id: 512,
              name: "골드데이!",
            },
            {
              id: 675,
              name: "14K",
            },
          ],
        },
        {
          id: 99311,
          name: "24K 순금 프로포즈 반지 선물 편지 골드바 1g 1.875g 3.75g",
          storeId: 1597,
          storeName: "뽀르띠",
          isTodayDelivery: 0,
          originPrice: 177600,
          discountRate: 3,
          salesPrice: 172270,
          imgUrl: "https://cdn.amondz.com/product/99311/resize/mainImg/PSI_1106060_50.jpeg?v=1698825284460",
          likeFlag: 0,
          status: 2,
          categoryId: 10,
          categoryName: "기타소품",
          productCategoryList: [
            {
              id: 10,
              name: "기타소품",
            },
            {
              id: 379,
              name: "24K",
            },
            {
              id: 384,
              name: "골드바",
            },
            {
              id: 512,
              name: "골드데이!",
            },
            {
              id: 672,
              name: "각인 주얼리",
            },
          ],
        },
        {
          id: 80614,
          name: "14k 데일리 쓰리 큐빅 반지",
          storeId: 2236,
          storeName: "금은다해",
          isTodayDelivery: 0,
          originPrice: 250000,
          discountRate: 41,
          salesPrice: 147500,
          imgUrl: "https://cdn.amondz.com/product/80614/resize/mainImg/PSI_859819_50.jpeg?v=1672396549741",
          likeFlag: 0,
          status: 2,
          categoryId: 3,
          categoryName: "반지",
          productCategoryList: [
            {
              id: 3,
              name: "반지",
            },
            {
              id: 106,
              name: "실반지",
            },
            {
              id: 269,
              name: "14/18K",
            },
            {
              id: 296,
              name: "반지",
            },
            {
              id: 91,
              name: "원석반지",
            },
          ],
        },
        {
          id: 91973,
          name: "14k 클래시크 노아 목걸이",
          storeId: 2117,
          storeName: "드네브",
          isTodayDelivery: 0,
          originPrice: 510000,
          discountRate: 34,
          salesPrice: 336600,
          imgUrl: "https://cdn.amondz.com/product/91973/resize/mainImg/PSI_1007534_50.jpeg?v=1689682036416",
          likeFlag: 0,
          status: 2,
          categoryId: 2,
          categoryName: "목걸이",
          productCategoryList: [
            {
              id: 2,
              name: "목걸이",
            },
            {
              id: 85,
              name: "펜던트목걸이",
            },
            {
              id: 269,
              name: "14/18K",
            },
            {
              id: 299,
              name: "목걸이",
            },
            {
              id: 512,
              name: "골드데이!",
            },
            {
              id: 675,
              name: "14K",
            },
          ],
        },
        {
          id: 81166,
          name: "[아몬즈 단독] 14K 클래식 스몰 원터치 귀걸이",
          storeId: 1605,
          storeName: "a14",
          isTodayDelivery: 1,
          originPrice: 300000,
          discountRate: 25,
          salesPrice: 225000,
          imgUrl: "https://cdn.amondz.com/product/81166/resize/mainImg/PSI_915670_50.jpeg?v=1679874574537",
          likeFlag: 0,
          status: 2,
          categoryId: 1,
          categoryName: "귀걸이",
          productCategoryList: [
            {
              id: 1,
              name: "귀걸이",
            },
            {
              id: 19,
              name: "원터치귀걸이",
            },
            {
              id: 269,
              name: "14/18K",
            },
            {
              id: 298,
              name: "귀걸이",
            },
            {
              id: 319,
              name: "오늘 출발",
            },
            {
              id: 321,
              name: "귀걸이",
            },
            {
              id: 266,
              name: "아몬즈 단독",
            },
            {
              id: 274,
              name: "귀걸이",
            },
            {
              id: 480,
              name: "아몬즈 단독",
            },
            {
              id: 403,
              name: "14/18K GOLD",
            },
            {
              id: 512,
              name: "골드데이!",
            },
            {
              id: 675,
              name: "14K",
            },
          ],
        },
        {
          id: 97025,
          name: "14k 리본 귀걸이_ver.B",
          storeId: 1044,
          storeName: "에르테",
          isTodayDelivery: 0,
          originPrice: 310000,
          discountRate: 3,
          salesPrice: 300700,
          imgUrl: "https://cdn.amondz.com/product/97025/resize/mainImg/PSI_1075511_50.jpeg?v=1696480424160",
          likeFlag: 0,
          status: 2,
          categoryId: 1,
          categoryName: "귀걸이",
          productCategoryList: [
            {
              id: 1,
              name: "귀걸이",
            },
            {
              id: 18,
              name: "스터드귀걸이",
            },
            {
              id: 269,
              name: "14/18K",
            },
            {
              id: 298,
              name: "귀걸이",
            },
          ],
        },
        {
          id: 86734,
          name: "14K 엠보싱 슬림 하트 반지",
          storeId: 1605,
          storeName: "a14",
          isTodayDelivery: 0,
          originPrice: 304000,
          discountRate: 25,
          salesPrice: 228000,
          imgUrl: "https://cdn.amondz.com/product/86734/resize/mainImg/PSI_977842_50.jpeg?v=1686880114616",
          likeFlag: 0,
          status: 2,
          categoryId: 3,
          categoryName: "반지",
          productCategoryList: [
            {
              id: 3,
              name: "반지",
            },
            {
              id: 91,
              name: "원석반지",
            },
            {
              id: 269,
              name: "14/18K",
            },
            {
              id: 296,
              name: "반지",
            },
            {
              id: 319,
              name: "오늘 출발",
            },
            {
              id: 323,
              name: "반지",
            },
            {
              id: 403,
              name: "14/18K GOLD",
            },
            {
              id: 653,
              name: "하트 주얼리",
            },
            {
              id: 655,
              name: "반지",
            },
            {
              id: 658,
              name: "골드",
            },
            {
              id: 512,
              name: "골드데이!",
            },
            {
              id: 675,
              name: "14K",
            },
          ],
        },
      ],
      topImage: {
        width: 1035,
        height: 840,
        url: "https://image.amondz.com/rectangle-images/9ab62556-84c6-4302-ae8a-14db9b2a018d.jpg",
      },
    },
    {
      id: 6698,
      widgetType: 3,
      layoutType: 1,
      mainTitle: null,
      subTitle: null,
      itemList: [
        {
          id: 3360,
          linkType: 2,
          linkUrl: null,
          title: null,
          contents: null,
          mobileBannerImg: {
            width: 3750,
            height: 710,
            imgUrl: "https://cdn.amondz.com/widget/6698/item/banner/4139/resize/WIBAI_11687_50.jpeg?v=1710744972990",
          },
          pcBannerImg: {
            width: 2892,
            height: 366,
            imgUrl: "https://cdn.amondz.com/widget/6698/item/banner/4139/resize/WIBAI_11696_50.jpeg?v=1710813805563",
          },
        },
      ],
    },
    {
      id: 6914,
      widgetType: 0,
      eventId: null,
      layoutType: 0,
      isProductRecommend: true,
      mainTitle: "성년의날 장미꽃 대신 플라워 주얼리",
      subTitle: "",
      buttonText: "VIEW MORE",
      exposureItemCount: 4,
      itemList: [
        {
          id: 85565,
          name: "진주 꽃 귀걸이 EC1645",
          storeId: 1721,
          storeName: "타티아나",
          isTodayDelivery: 0,
          salesPrice: 35000,
          imgUrl: "https://cdn.amondz.com/product/85565/resize/mainImg/PSI_927446_50.jpeg?v=1681113495607",
          likeFlag: 0,
          status: 2,
          categoryId: 1,
          categoryName: "귀걸이",
          productCategoryList: [
            {
              id: 1,
              name: "귀걸이",
            },
            {
              id: 14,
              name: "진주귀걸이",
            },
            {
              id: 18,
              name: "스터드귀걸이",
            },
          ],
        },
        {
          id: 70108,
          name: "Baby flower",
          storeId: 1320,
          storeName: "플루이",
          isTodayDelivery: 0,
          salesPrice: 28000,
          imgUrl: "https://cdn.amondz.com/product/70108/resize/mainImg/PSI_872273_50.jpeg?v=1674111488531",
          likeFlag: 0,
          status: 2,
          categoryId: 3,
          categoryName: "반지",
          productCategoryList: [
            {
              id: 3,
              name: "반지",
            },
          ],
        },
        {
          id: 67681,
          name: "14k 4프롱 2mm 다이아몬드 귀걸이",
          storeId: 1673,
          storeName: "소뉴",
          isTodayDelivery: 0,
          originPrice: 283000,
          discountRate: 10,
          salesPrice: 254700,
          imgUrl: "https://cdn.amondz.com/product/67681/resize/mainImg/PSI_705374_50.jpeg?v=1655949941345",
          likeFlag: 0,
          status: 2,
          categoryId: 1,
          categoryName: "귀걸이",
          productCategoryList: [
            {
              id: 1,
              name: "귀걸이",
            },
            {
              id: 269,
              name: "14/18K",
            },
            {
              id: 298,
              name: "귀걸이",
            },
            {
              id: 13,
              name: "원석귀걸이",
            },
            {
              id: 607,
              name: "다이아몬드",
            },
            {
              id: 609,
              name: "랩그로운",
            },
            {
              id: 512,
              name: "골드데이!",
            },
            {
              id: 673,
              name: "천연 다이아",
            },
            {
              id: 676,
              name: "18K",
            },
            {
              id: 675,
              name: "14K",
            },
          ],
        },
        {
          id: 55023,
          name: "[신상백서] DANDELION PENDANT",
          storeId: 1588,
          storeName: "어나더셀",
          isTodayDelivery: 0,
          originPrice: 87000,
          discountRate: 10,
          salesPrice: 78300,
          imgUrl: "https://cdn.amondz.com/product/55023/resize/mainImg/PSI_563392_50.jpeg?v=1641284384973",
          likeFlag: 0,
          status: 2,
          categoryId: 2,
          categoryName: "목걸이",
          productCategoryList: [
            {
              id: 2,
              name: "목걸이",
            },
            {
              id: 85,
              name: "펜던트목걸이",
            },
          ],
        },
      ],
    },
    {
      id: 6919,
      widgetType: 0,
      eventId: null,
      layoutType: 0,
      isProductRecommend: true,
      mainTitle: "하객룩 주얼리 고민된다면",
      subTitle: "하객룩을 완성하는 주얼리 추천",
      buttonText: "VIEW MORE",
      exposureItemCount: 4,
      itemList: [
        {
          id: 66678,
          name: "[이서,노정의 착용]bebe heart toggle pearl necklace",
          storeId: 734,
          storeName: "헤이",
          isTodayDelivery: 0,
          originPrice: 38000,
          discountRate: 5,
          salesPrice: 36100,
          imgUrl: "https://cdn.amondz.com/product/66678/resize/mainImg/PSI_696488_50.jpeg?v=1655104819528",
          likeFlag: 0,
          status: 2,
          categoryId: 2,
          categoryName: "목걸이",
          productCategoryList: [
            {
              id: 2,
              name: "목걸이",
            },
            {
              id: 57,
              name: "진주목걸이",
            },
            {
              id: 70,
              name: "하트목걸이",
            },
            {
              id: 56,
              name: "토글목걸이",
            },
            {
              id: 616,
              name: "진주",
            },
            {
              id: 621,
              name: "#하트",
            },
            {
              id: 653,
              name: "하트 주얼리",
            },
            {
              id: 654,
              name: "목걸이&펜던트",
            },
          ],
        },
        {
          id: 20390,
          name: "Chubby heart necklace (2colors)",
          storeId: 448,
          storeName: "마뚜아",
          isTodayDelivery: 0,
          salesPrice: 34000,
          imgUrl: "https://cdn.amondz.com/product/20390/resize/mainImg/PSI_177887_50.jpeg?v=1588933027459",
          likeFlag: 0,
          status: 2,
          categoryId: 2,
          categoryName: "목걸이",
          productCategoryList: [
            {
              id: 2,
              name: "목걸이",
            },
            {
              id: 62,
              name: "체인목걸이",
            },
            {
              id: 70,
              name: "하트목걸이",
            },
          ],
        },
        {
          id: 27691,
          name: "lorher tag Ring (골드)",
          storeId: 967,
          storeName: "로르허",
          isTodayDelivery: 1,
          salesPrice: 47000,
          imgUrl: "https://cdn.amondz.com/product/27691/resize/mainImg/PSI_264773_50.jpeg?v=1599448718479",
          likeFlag: 0,
          status: 2,
          categoryId: 3,
          categoryName: "반지",
          productCategoryList: [
            {
              id: 3,
              name: "반지",
            },
            {
              id: 123,
              name: "드롭링",
            },
            {
              id: 319,
              name: "오늘 출발",
            },
            {
              id: 323,
              name: "반지",
            },
          ],
        },
        {
          id: 74458,
          name: "윈터 라이트 이어링 [실버/골드]",
          storeId: 765,
          storeName: "돌",
          isTodayDelivery: 0,
          salesPrice: 39000,
          imgUrl: "https://cdn.amondz.com/product/74458/resize/mainImg/PSI_786632_50.jpeg?v=1665551578017",
          likeFlag: 0,
          status: 2,
          categoryId: 1,
          categoryName: "귀걸이",
          productCategoryList: [
            {
              id: 1,
              name: "귀걸이",
            },
            {
              id: 18,
              name: "스터드귀걸이",
            },
          ],
        },
      ],
    },
    {
      id: 2000,
      widgetType: 3,
      layoutType: 2,
      mainTitle: "주얼리 키워드 #",
      itemList: [
        {
          id: 2869,
          linkType: 2,
          linkUrl: null,
          title: null,
          contents: null,
          mobileBannerImg: {
            width: 936,
            height: 684,
            imgUrl: "https://cdn.amondz.com/widget/2000/item/banner/1382/resize/WIBAI_10156_50.jpeg?v=1687420214448",
          },
          pcBannerImg: {
            width: 1299,
            height: 1008,
            imgUrl: "https://cdn.amondz.com/widget/2000/item/banner/1382/resize/WIBAI_10157_50.jpeg?v=1687420214791",
          },
        },
        {
          id: 2832,
          linkType: 2,
          linkUrl: null,
          title: null,
          contents: null,
          mobileBannerImg: {
            width: 936,
            height: 684,
            imgUrl: "https://cdn.amondz.com/widget/2000/item/banner/1383/resize/WIBAI_10028_50.jpeg?v=1685585139298",
          },
          pcBannerImg: {
            width: 1299,
            height: 1008,
            imgUrl: "https://cdn.amondz.com/widget/2000/item/banner/1383/resize/WIBAI_10029_50.jpeg?v=1685585139596",
          },
        },
        {
          id: 2823,
          linkType: 2,
          linkUrl: null,
          title: null,
          contents: null,
          mobileBannerImg: {
            width: 936,
            height: 684,
            imgUrl: "https://cdn.amondz.com/widget/2000/item/banner/1669/resize/WIBAI_9998_50.jpeg?v=1684979375336",
          },
          pcBannerImg: {
            width: 1299,
            height: 1008,
            imgUrl: "https://cdn.amondz.com/widget/2000/item/banner/1669/resize/WIBAI_9999_50.jpeg?v=1684979375815",
          },
        },
        {
          id: 2807,
          linkType: 2,
          linkUrl: null,
          title: null,
          contents: null,
          mobileBannerImg: {
            width: 936,
            height: 684,
            imgUrl: "https://cdn.amondz.com/widget/2000/item/banner/1385/resize/WIBAI_9986_50.jpeg?v=1684372177394",
          },
          pcBannerImg: {
            width: 1299,
            height: 1008,
            imgUrl: "https://cdn.amondz.com/widget/2000/item/banner/1385/resize/WIBAI_9987_50.jpeg?v=1684372177858",
          },
        },
        {
          id: 2793,
          linkType: 2,
          linkUrl: null,
          title: null,
          contents: null,
          mobileBannerImg: {
            width: 936,
            height: 684,
            imgUrl: "https://cdn.amondz.com/widget/2000/item/banner/1566/resize/WIBAI_9940_50.jpeg?v=1683769533435",
          },
          pcBannerImg: {
            width: 1299,
            height: 1008,
            imgUrl: "https://cdn.amondz.com/widget/2000/item/banner/1566/resize/WIBAI_9941_50.jpeg?v=1683769533782",
          },
        },
        {
          id: 2782,
          linkType: 2,
          linkUrl: null,
          title: null,
          contents: null,
          mobileBannerImg: {
            width: 936,
            height: 684,
            imgUrl: "https://cdn.amondz.com/widget/2000/item/banner/1495/resize/WIBAI_9922_50.jpeg?v=1683166071969",
          },
          pcBannerImg: {
            width: 1299,
            height: 1008,
            imgUrl: "https://cdn.amondz.com/widget/2000/item/banner/1495/resize/WIBAI_9923_50.jpeg?v=1683166072446",
          },
        },
        {
          id: 2753,
          linkType: 2,
          linkUrl: null,
          title: null,
          contents: null,
          mobileBannerImg: {
            width: 936,
            height: 684,
            imgUrl: "https://cdn.amondz.com/widget/2000/item/banner/1943/resize/WIBAI_9782_50.jpeg?v=1682564827334",
          },
          pcBannerImg: {
            width: 1299,
            height: 1008,
            imgUrl: "https://cdn.amondz.com/widget/2000/item/banner/1943/resize/WIBAI_9783_50.jpeg?v=1682564827819",
          },
        },
        {
          id: 2737,
          linkType: 2,
          linkUrl: null,
          title: null,
          contents: null,
          mobileBannerImg: {
            width: 936,
            height: 684,
            imgUrl: "https://cdn.amondz.com/widget/2000/item/banner/3297/resize/WIBAI_9742_50.jpeg?v=1681959640963",
          },
          pcBannerImg: {
            width: 1299,
            height: 1008,
            imgUrl: "https://cdn.amondz.com/widget/2000/item/banner/3297/resize/WIBAI_9743_50.jpeg?v=1681959641291",
          },
        },
        {
          id: 2721,
          linkType: 2,
          linkUrl: null,
          title: null,
          contents: null,
          mobileBannerImg: {
            width: 936,
            height: 684,
            imgUrl: "https://cdn.amondz.com/widget/2000/item/banner/1380/resize/WIBAI_9678_50.jpeg?v=1681354318810",
          },
          pcBannerImg: {
            width: 1299,
            height: 1008,
            imgUrl: "https://cdn.amondz.com/widget/2000/item/banner/1380/resize/WIBAI_9679_50.jpeg?v=1681354319206",
          },
        },
        {
          id: 2705,
          linkType: 2,
          linkUrl: null,
          title: null,
          contents: null,
          mobileBannerImg: {
            width: 936,
            height: 684,
            imgUrl: "https://cdn.amondz.com/widget/2000/item/banner/1381/resize/WIBAI_9616_50.jpeg?v=1680744777634",
          },
          pcBannerImg: {
            width: 1299,
            height: 1008,
            imgUrl: "https://cdn.amondz.com/widget/2000/item/banner/1381/resize/WIBAI_9617_50.jpeg?v=1680744777953",
          },
        },
      ],
    },
    {
      id: 2000,
      widgetType: 3,
      layoutType: 2,
      mainTitle: "주얼리 키워드 #",
      subTitle: "주얼리 탐구 생활",
      itemList: [
        {
          id: 2869,
          linkType: 2,
          linkUrl: null,
          title: null,
          contents: null,
          mobileBannerImg: {
            width: 936,
            height: 684,
            imgUrl: "https://cdn.amondz.com/widget/2000/item/banner/1382/resize/WIBAI_10156_50.jpeg?v=1687420214448",
          },
          pcBannerImg: {
            width: 1299,
            height: 1008,
            imgUrl: "https://cdn.amondz.com/widget/2000/item/banner/1382/resize/WIBAI_10157_50.jpeg?v=1687420214791",
          },
        },
        {
          id: 2832,
          linkType: 2,
          linkUrl: null,
          title: null,
          contents: null,
          mobileBannerImg: {
            width: 936,
            height: 684,
            imgUrl: "https://cdn.amondz.com/widget/2000/item/banner/1383/resize/WIBAI_10028_50.jpeg?v=1685585139298",
          },
          pcBannerImg: {
            width: 1299,
            height: 1008,
            imgUrl: "https://cdn.amondz.com/widget/2000/item/banner/1383/resize/WIBAI_10029_50.jpeg?v=1685585139596",
          },
        },
        {
          id: 2823,
          linkType: 2,
          linkUrl: null,
          title: null,
          contents: null,
          mobileBannerImg: {
            width: 936,
            height: 684,
            imgUrl: "https://cdn.amondz.com/widget/2000/item/banner/1669/resize/WIBAI_9998_50.jpeg?v=1684979375336",
          },
          pcBannerImg: {
            width: 1299,
            height: 1008,
            imgUrl: "https://cdn.amondz.com/widget/2000/item/banner/1669/resize/WIBAI_9999_50.jpeg?v=1684979375815",
          },
        },
        {
          id: 2807,
          linkType: 2,
          linkUrl: null,
          title: null,
          contents: null,
          mobileBannerImg: {
            width: 936,
            height: 684,
            imgUrl: "https://cdn.amondz.com/widget/2000/item/banner/1385/resize/WIBAI_9986_50.jpeg?v=1684372177394",
          },
          pcBannerImg: {
            width: 1299,
            height: 1008,
            imgUrl: "https://cdn.amondz.com/widget/2000/item/banner/1385/resize/WIBAI_9987_50.jpeg?v=1684372177858",
          },
        },
        {
          id: 2793,
          linkType: 2,
          linkUrl: null,
          title: null,
          contents: null,
          mobileBannerImg: {
            width: 936,
            height: 684,
            imgUrl: "https://cdn.amondz.com/widget/2000/item/banner/1566/resize/WIBAI_9940_50.jpeg?v=1683769533435",
          },
          pcBannerImg: {
            width: 1299,
            height: 1008,
            imgUrl: "https://cdn.amondz.com/widget/2000/item/banner/1566/resize/WIBAI_9941_50.jpeg?v=1683769533782",
          },
        },
        {
          id: 2782,
          linkType: 2,
          linkUrl: null,
          title: null,
          contents: null,
          mobileBannerImg: {
            width: 936,
            height: 684,
            imgUrl: "https://cdn.amondz.com/widget/2000/item/banner/1495/resize/WIBAI_9922_50.jpeg?v=1683166071969",
          },
          pcBannerImg: {
            width: 1299,
            height: 1008,
            imgUrl: "https://cdn.amondz.com/widget/2000/item/banner/1495/resize/WIBAI_9923_50.jpeg?v=1683166072446",
          },
        },
        {
          id: 2753,
          linkType: 2,
          linkUrl: null,
          title: null,
          contents: null,
          mobileBannerImg: {
            width: 936,
            height: 684,
            imgUrl: "https://cdn.amondz.com/widget/2000/item/banner/1943/resize/WIBAI_9782_50.jpeg?v=1682564827334",
          },
          pcBannerImg: {
            width: 1299,
            height: 1008,
            imgUrl: "https://cdn.amondz.com/widget/2000/item/banner/1943/resize/WIBAI_9783_50.jpeg?v=1682564827819",
          },
        },
        {
          id: 2737,
          linkType: 2,
          linkUrl: null,
          title: null,
          contents: null,
          mobileBannerImg: {
            width: 936,
            height: 684,
            imgUrl: "https://cdn.amondz.com/widget/2000/item/banner/3297/resize/WIBAI_9742_50.jpeg?v=1681959640963",
          },
          pcBannerImg: {
            width: 1299,
            height: 1008,
            imgUrl: "https://cdn.amondz.com/widget/2000/item/banner/3297/resize/WIBAI_9743_50.jpeg?v=1681959641291",
          },
        },
        {
          id: 2721,
          linkType: 2,
          linkUrl: null,
          title: null,
          contents: null,
          mobileBannerImg: {
            width: 936,
            height: 684,
            imgUrl: "https://cdn.amondz.com/widget/2000/item/banner/1380/resize/WIBAI_9678_50.jpeg?v=1681354318810",
          },
          pcBannerImg: {
            width: 1299,
            height: 1008,
            imgUrl: "https://cdn.amondz.com/widget/2000/item/banner/1380/resize/WIBAI_9679_50.jpeg?v=1681354319206",
          },
        },
        {
          id: 2705,
          linkType: 2,
          linkUrl: null,
          title: null,
          contents: null,
          mobileBannerImg: {
            width: 936,
            height: 684,
            imgUrl: "https://cdn.amondz.com/widget/2000/item/banner/1381/resize/WIBAI_9616_50.jpeg?v=1680744777634",
          },
          pcBannerImg: {
            width: 1299,
            height: 1008,
            imgUrl: "https://cdn.amondz.com/widget/2000/item/banner/1381/resize/WIBAI_9617_50.jpeg?v=1680744777953",
          },
        },
      ],
    },
  ],
};
