import React, { FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Router, { useRouter } from "next/router";
import { isNil } from "lodash";

import { setLastSearchKeyword, setSearchKeyword } from "@store/modules/search";
import { RootStateType } from "@store/modules";
import {
  eventSearchCount,
  integrationSearchCount,
  productSearchCount,
  reviewSearchCount,
  searchModalStateType,
  searchModalVisible,
  setRecentSearchKeywordList,
  storeSearchCount,
} from "@store/modules/search";
import { RECENT_SEARCH_WORD_MAX_LENGTH } from "@constants/service/search/search";
import { SEARCH_RESULT_PAGE_URL_PATH } from "@constants/url/internalUrlConstants";
import { SearchWordButtonListStyle, SearchWordButtonListWrapperStyle, SearchWordButtonStyle } from "./style";

interface ISearchWordButtonListProps {
  className?: string;
  wordList: string[];
  // 가상연산자가 필요한지(before:)
  needContent?: boolean;
}

const SearchWordButtonList: FC<ISearchWordButtonListProps> = (props) => {
  const { className, wordList, needContent = false } = props;
  const { lastSearchKeyword, recentSearchKeywordList } = useSelector<RootStateType, searchModalStateType>(
    (state: RootStateType) => state.search.searchModalState,
  );

  const router = useRouter();
  const dispatch = useDispatch();

  const onClickSearchWordButton = useCallback(
    (word: string) => () => {
      // 서로 다른 검색어를 쳤을 경우에만 최근 검색어 로직 수행 및 검색 결과 수 초기화
      if (word !== lastSearchKeyword) {
        dispatch(integrationSearchCount({ count: 0 }));
        dispatch(productSearchCount({ count: 0 }));
        dispatch(storeSearchCount({ count: 0 }));
        dispatch(reviewSearchCount({ count: 0 }));
        dispatch(eventSearchCount({ count: 0 }));

        // 재정의한 최근 검색어 리스트
        let returnSearchKeywordList: string[] = [];
        // 중복 단어 인 경우 삭제하고 리스트 맨 앞에 삽입
        if (recentSearchKeywordList.includes(word)) {
          const filterList = recentSearchKeywordList.filter((keyword) => keyword !== word);
          returnSearchKeywordList = [word, ...filterList];
          dispatch(setRecentSearchKeywordList({ recentSearchKeywordList: [word, ...filterList] }));
        } else {
          // 최근 검색어의 길이가 최대 길이(10)을 초과할 경우 제일 오래된 기록을 검색 기록을 제거
          if (recentSearchKeywordList.length >= RECENT_SEARCH_WORD_MAX_LENGTH) {
            returnSearchKeywordList = [word, ...recentSearchKeywordList.slice(0, RECENT_SEARCH_WORD_MAX_LENGTH - 1)];
            dispatch(
              setRecentSearchKeywordList({
                recentSearchKeywordList: [word, ...recentSearchKeywordList.slice(0, RECENT_SEARCH_WORD_MAX_LENGTH - 1)],
              }),
            );
          } else {
            returnSearchKeywordList = [word, ...recentSearchKeywordList];
            dispatch(
              setRecentSearchKeywordList({
                recentSearchKeywordList: [word, ...recentSearchKeywordList],
              }),
            );
          }
        }

        if (!isNil(localStorage)) {
          localStorage.setItem("searchHistory", JSON.stringify(returnSearchKeywordList));
        }
      }

      // 검색 페이지 내부에서는 history stack 쌓지 않음
      if (router.pathname.includes(SEARCH_RESULT_PAGE_URL_PATH)) {
        Router.replace(`${SEARCH_RESULT_PAGE_URL_PATH}?keyword=${encodeURIComponent(word)}`, undefined);
      } else {
        Router.push(`${SEARCH_RESULT_PAGE_URL_PATH}?keyword=${encodeURIComponent(word)}`, undefined);
      }
      dispatch(setSearchKeyword({ searchKeyword: word }));
      dispatch(setLastSearchKeyword({ lastSearchKeyword: word }));
      dispatch(searchModalVisible({ isSearchModalVisible: false }));
    },
    [recentSearchKeywordList],
  );

  return (
    <SearchWordButtonListWrapperStyle className={className}>
      {wordList.map((word) => (
        <SearchWordButtonListStyle key={word} needContent={needContent}>
          <SearchWordButtonStyle onClick={onClickSearchWordButton(word)}>{word}</SearchWordButtonStyle>
        </SearchWordButtonListStyle>
      ))}
    </SearchWordButtonListWrapperStyle>
  );
};

export default SearchWordButtonList;
