import { rest } from "msw";
import {
  imageReferralsMock,
  likeProductsMock,
  likeStoresMock,
  orderCancelResultMock,
  orderCancelSelectMock,
  orderCancelSubmitMock,
  orderDetailMock,
  orderListMock,
  orderReturnResultMock,
  orderReturnSubmitMock,
  recentOrderMock,
  userSummaryMock,
} from "@services/msw/mocks/mypage";

export const mypageHandlers = [
  rest.get(process.env.NEXT_PUBLIC_API_URL_V2 + "/members-summary", userSummaryMock),
  rest.get(process.env.NEXT_PUBLIC_API_URL_V2 + "/like-stores", likeStoresMock),
  rest.get(process.env.NEXT_PUBLIC_API_URL_V2 + "/like-products", likeProductsMock),
  rest.get(process.env.NEXT_PUBLIC_API_URL_V2 + "/image-referrals", imageReferralsMock),
  rest.get(process.env.NEXT_PUBLIC_API_URL_V2 + "/orders", recentOrderMock),
  rest.post(process.env.NEXT_PUBLIC_API_URL + "/mypage/order/list/web/v2", orderListMock),
  rest.post(process.env.NEXT_PUBLIC_API_URL + "/mypage/order/detail/web/v3", orderDetailMock),
  rest.post(process.env.NEXT_PUBLIC_API_URL + "/mypage/order/cancel/select/web/v2", orderCancelSelectMock),
  rest.post(process.env.NEXT_PUBLIC_API_URL + "/mypage/order/cancel/submit/web/v2", orderCancelSubmitMock),
  rest.post(process.env.NEXT_PUBLIC_API_URL + "/mypage/order/cancel/result/web/v2", orderCancelResultMock),
  rest.post(process.env.NEXT_PUBLIC_API_URL + "/mypage/order/return/submit/web/v2", orderReturnSubmitMock),
  rest.post(process.env.NEXT_PUBLIC_API_URL + "/mypage/order/return/result/web/v2", orderReturnResultMock),
];
