import * as React from "react";
import { FC, memo } from "react";

import { IBaseIconProps } from "@components/icon/type";
import Theme from "@styles/theme";

interface ILikeButtonProps extends IBaseIconProps {
  status: boolean;
}

const LikeOnButton: FC<IBaseIconProps> = memo(({ width, height, fill = Theme.color.amondz_purple }) => (
  <svg
    role="img"
    aria-label="좋아요 활성화"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.87 4.005a5.019 5.019 0 0 0-3.703 1.904L12 6.131l-.012-.018A5.024 5.024 0 0 0 7.895 4C5.078 4 2.8 6.37 2.8 9.286c0 1.196.384 2.331 1.081 3.257l.123.157.129.151c1.105 1.344 3.405 3.466 6.903 6.37a1.682 1.682 0 0 0 2.174-.02l1.011-.879c3.143-2.749 5.075-4.628 5.82-5.67l.073-.104c.162-.211.313-.446.447-.696l.127-.254a.755.755 0 0 0 .055-.124 5.36 5.36 0 0 0 .457-2.188C21.2 6.37 18.922 4 16.105 4l-.235.005z"
      fill={fill}
      fillRule="nonzero"
    />
  </svg>
));

const LikeOffButton: FC<IBaseIconProps> = memo(({ width, height, fill = Theme.color.amondz_black }) => (
  <svg
    role="img"
    aria-label="좋아요 비활성화"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.824 4.005a5.068 5.068 0 0 0-3.753 1.937L12 6.036l-.07-.094A5.066 5.066 0 0 0 7.94 4C5.097 4 2.8 6.386 2.8 9.32a5.42 5.42 0 0 0 1.09 3.275l.124.158.382.449c1.175 1.338 3.37 3.338 6.587 6.004.663.55 1.626.54 2.279-.022l1.002-.869c3.007-2.626 4.895-4.449 5.691-5.505l.154-.215c.16-.208.312-.444.447-.695l.128-.256a.802.802 0 0 0 .059-.133c.294-.65.457-1.41.457-2.192C21.2 6.386 18.903 4 16.06 4l-.236.005zM16.06 5c2.282 0 4.14 1.93 4.14 4.32 0 .549-.098 1.082-.286 1.58l-.119.287-.006.013a4.521 4.521 0 0 1-.355.623l-.136.188c-.68 1.004-2.804 3.055-6.35 6.123l-.34.293a.765.765 0 0 1-.987.009l-1.058-.884c-2.714-2.287-4.564-4.01-5.541-5.155l-.118-.14A4.42 4.42 0 0 1 3.8 9.32C3.8 6.93 5.658 5 7.94 5c1.526 0 2.897.845 3.619 2.196l.441.826.441-.826C13.163 5.845 14.534 5 16.06 5z"
      fill={fill}
      fillRule="evenodd"
    />
  </svg>
));

const LikeButton: FC<ILikeButtonProps> = ({ width, height, status, fill }) => {
  return (
    <>
      {status ? (
        <LikeOnButton width={width} height={height} fill={fill} />
      ) : (
        <LikeOffButton width={width} height={height} fill={fill} />
      )}
    </>
  );
};

export default memo(LikeButton);
