/**
 *
 * 유저 웹 URL PATH
 *
 */
// 상용 유저 웹 URL PATH
export const PRODUCTION_USER_WEB_HOST_URL_LIST = [
  "https://amondz.com",
  "https://www.amondz.com",
  "https://m.amondz.com",
];

// 테스트 유저 웹 URL PATH
export const TEST_USER_WEB_HOST_URL_LIST = [
  "https://qaweb.qa-amondz.com/",
  "https://qam.qa-amondz.com/",
  "http://qaweb.qa-amondz.com/",
  "http://qam.qa-amondz.com/",
  "https://devweb.dev-amondz.com/",
  "https://devm.dev-amondz.com/",
  "http://devweb.dev-amondz.com/",
  "http://devm.dev-amondz.com/",
];

/**
 *
 * 외부 URL PATH
 *
 */
// 비주얼 회사 사이트 URL
export const BEJEWEL_COMPANY_SITE_URL = "http://company.bejewel.co.kr";

// 비주얼 회사 사업자 정보 확인 URL
export const BEJEWEL_BUSINESS_LICENSE_INFORMATION_URL =
  "http://www.ftc.go.kr/bizCommPop.do?wrkr_no=4768800525&apv_perm_no=";

// 아몬즈 매거진 사이트 URL
export const AMONDZ_MAGAZINE_SITE_URL = "https://magazine.amondz.com/";

// one 서비스 URL
export const ONE_SERVICE_URL = "https://one.amondz.com";

// 아몬즈의 서비스 이용약관 URL
export const AMONDZ_SERVICE_TERMS_CONDITIONS_URL =
  "https://amondz.notion.site/2022-10-25-a13d276cf10744528cb5454715566a35";

// 아몬즈의 서비스 필수 개인정보 처리방침 노션 URL
export const AMONDZ_SERVICE_ESSENTIAL_PERSONAL_INFORMATION_COLLECTION_URL =
  "https://amondz.notion.site/c07ca2d7092d4eb888c5808d2d813c3b";

// 아몬즈의 서비스 개인정보 처리방침 노션 URL
export const AMONDZ_SERVICE_PERSONAL_INFORMATION_COLLECTION_URL =
  "https://amondz.notion.site/12413292d53a48d0aaa7f560c8f422e4";

// 아몬즈 채널톡 URL
export const AMONDZ_CHANNEL_TALK_URL = "http://amondz.channel.io";

// byamondz.jp URL
export const BYAMONDZ_JAPAN_URL = "https://byamondz.jp/";

// byamondz.us URL
export const BYAMONDZ_US_URL = "https://www.byamondz.com/";

export const AMONDZ_MAGAZINE_URL = "https://magazine.amondz.com/";

// 인스타 아몬즈 채널 URL
export const INSTAGRAM_AMONDZ_CHANNEL_URL = "https://www.instagram.com/amondz_official";

// 유투브 아몬즈 채널 URL
export const YOUTUBE_AMONDZ_CHANNEL_URL = "https://www.youtube.com/channel/UCaHRBZoab2sCoPQesW-GcWA";

// 페이스북 아몬즈 채널 URL
export const FACEBOOK_AMONDZ_CHANNEL_URL = "https://www.facebook.com/amondzofficial";

// 카카오 아몬즈 채널 URL
export const KAKAO_AMONDZ_CHANNEL_URL = "https://pf.kakao.com/_wSxlxfC";

// 다음 주소록 검색 API URL
export const DAUM_ADDRESS_BOOK_SEARCH_API_URL = "https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js";

// IAMPORT 결제 API URL
export const IAMPORT_PAYMENT_API_URL = "https://cdn.iamport.kr/v1/iamport.js";

// 아몬즈 OG 태그 로고 이미지  URL PATH
export const AMONDZ_LOGO_OG_TAG_URL = "https://cdn.amondz.com/common/images/amondz_logo.jpg";

/**
 * 트래킹 링크
 *
 * 웹에서 사용중인 channel 을 파악하기 위해 접두사로 web 을 붙여 사용
 */
// amondz 앱 ABCbook 기획전으로 이동하는 헬로바전용 딥링크 URL
export const AMONDZ_HELLO_BAR_DEEP_LINK_URL = "https://abr.ge/@amondz/web_hello?&deeplink_url=amondz://event/2385";

// amondz 앱 다운로드 딥링크 URL
export const AMONDZ_APP_DOWNLOAD_DEEP_LINK_URL = "https://abr.ge/@amondz/web_download?&deeplink_url=amondz://home";

// amondz referral 페이지로 이동하는 딥링크 URL
export const AMONDZ_REFERRAL_DEEP_LINK_URL = "https://abr.ge/@amondz/web_referral?&deeplink_url=amondz://referral";
