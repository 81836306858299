export const STATUS_CODE_API = {
  /**
   *
   * 이메일 로그인
   *
   * */
  MEMBER_EMAIL_LOGIN: {
    CODE_106010000: 106010000, // 이메일 로그인 - 유효하지 않은 이메일
    CODE_106010100: 106010100, // 이메일 로그인 - 유효하지 않은 비밀번호
  },

  /**
   *
   * 계정 찾기 SMS 인증 번호 요청
   *
   * */
  SMS_AUTH_ACCOUNT_REQUEST: {
    CODE_114010000: 114010000, // [투버튼 팝업] 계정 찾기 SMS 인증 번호 요청 - 유효하지 회원정보
    CODE_114010100: 114010100, // [토스트 메시지] 계정 찾기 SMS 인증 번호 요청 - 증복된 인증번호
    CODE_114010200: 114010200, // [토스트 메시지] 계정 찾기 SMS 인증 번호 요청 - 토큰 생성 실패
    CODE_114010300: 114010300, // [토스트 메시지] 계정 찾기 SMS 인증 번호 요청 - 인증번호 생성 실패
    CODE_114010400: 114010400, // [토스트 메시지] 계정 찾기 SMS 인증 번호 요청 - 인증번호 SMS 발송 실패
  },

  /**
   *
   * 계정 찾기 SMS 코드 인증 요청
   *
   * */
  SMS_AUTH_ACCOUNT_CONFIRM: {
    CODE_114000000: 114000000, // [원버튼 팝업] 계정 찾기 SMS 코드 인증 요청 - 유효하지 회원정보
    CODE_114000100: 114000100, // [인라인 텍스트] 계정 찾기 SMS 코드 인증 요청 - 유효하지 않은 SMS 코드
    CODE_114000200: 114000200, // [원버튼 팝업] 계정 찾기 SMS 코드 인증 요청 - 이미 SNS 로 가입된 계정
  },

  /**
   *
   * 비밀번호 찾기 SMS 인증 번호 요청
   *
   * */
  SMS_AUTH_PASSWORD_REQUEST: {
    CODE_114050000: 114050000, // [투버튼 팝업] 비밀번호 찾기 SMS 인증 번호 요청 - 유효하지 회원정보
    CODE_114050050: 114050050, // [투버튼 팝업] 비밀번호 찾기 SMS 인증 번호 요청 - 유효하지 않은 이메일
    CODE_114050100: 114050100, // [토스트 메시지] 비밀번호 찾기 SMS 인증 번호 요청 - 증복된 인증번호
    CODE_114050200: 114050200, // [토스트 메시지] 비밀번호 찾기 SMS 인증 번호 요청 - 토큰 생성 실패
    CODE_114050300: 114050300, // [토스트 메시지] 비밀번호 찾기 SMS 인증 번호 요청 - 인증번호 생성 실패
    CODE_114050400: 114050400, // [토스트 메시지] 비밀번호 찾기 SMS 인증 번호 요청 - 인증번호 SMS 발송 실패
  },

  /**
   *
   * 비밀번호 찾기 SMS 코드 인증 요청
   *
   * */
  SMS_AUTH_PASSWORD_CONFIRM: {
    CODE_114040000: 114040000, // [투버튼 팝업] 비밀번호 찾기 SMS 코드 인증 요청 - 유효하지 않은 회원정보
    CODE_114040050: 114040050, // [원버튼 팝업] 비밀번호 찾기 SMS 코드 인증 요청 - 유효하지 않은 이메일
    CODE_114040100: 114040100, // [인라인 텍스트] 비밀번호 찾기 SMS 코드 인증 요청 - 유효하지 않은 SMS 코드
  },

  /**
   *
   * 비밀번호 변경 요청
   *
   * */
  MEMBER_PASSWORD_UPDATE: {
    CODE_106020000: 106020000, // [원버튼 팝업] 비밀번호 변경 요청 - 비밀번호 재설정 가능 시간 초과
    CODE_106020100: 106020100, // [원버튼 팝업] 비밀번호 변경 요청 - 비밀번호 유효성 체크 실패
    CODE_106020200: 106020200, // [원버튼 팝업] 비밀번호 변경 요청 - 비밀번호 암호화 실패
    CODE_106020300: 106020300, // [원버튼 팝업] 비밀번호 변경 요청 - 비밀번호 변경 실패
  },

  /**
   *
   * 회원가입 SMS 인증 번호 요청
   *
   * */
  SMS_AUTH_JOIN_REQUEST: {
    CODE_114030000: 114030000, // [원버튼 팝업] 회원가입 SMS 인증 번호 요청 - 하루 요청 가능 횟수 초과
    CODE_114030100: 114030100, // [원버튼 팝업] 회원가입 SMS 인증 번호 요청 - 이미 가입된 핸드폰 번호
    CODE_114030200: 114030200, // [토스트 메시지] 회원가입 SMS 인증 번호 요청 - 증복된 인증번호
    CODE_114030300: 114030300, // [토스트 메시지] 회원가입 SMS 인증 번호 요청 - 토큰 생성 실패
    CODE_114030400: 114030400, // [토스트 메시지] 회원가입 SMS 인증 번호 요청 - 인증번호 생성 실패
    CODE_114030500: 114030500, // [토스트 메시지] 회원가입 SMS 인증 번호 요청 - 인증번호 SMS 발송 실패
  },

  /**
   *
   * 회원가입 SMS 코드 인증 요청
   *
   * */
  SMS_AUTH_JOIN_CONFIRM: {
    CODE_114020000: 114020000, // [원버튼 팝업] 회원가입 SMS 코드 인증 요청 - 이미 가입된 핸드폰 번호
    CODE_114020100: 114020100, // [인라인 텍스트] 회원가입 SMS 코드 인증 요청 - 유효하지 않은 SMS 코드
  },

  /**
   *
   * 이메일 회원가입 요청
   *
   * */
  MEMBER_EMAIL_JOIN: {
    CODE_106000000: 106000000, // [원버튼 팝업] 이메일 회원가입 요청 - 유효하지 않은 휴대폰 번호
    CODE_106000001: 106000001, // [원버튼 팝업] 이메일 회원가입 요청 - 유효하지 않은 이메일
    CODE_106000002: 106000002, // [원버튼 팝업] 이메일 회원가입 요청 - 유효하지 않은 비밀번호
    CODE_106000050: 106000050, // [원버튼 팝업] 이메일 회원가입 요청 - 이미 가입된 이메일
    CODE_106000060: 106000060, // [원버튼 팝업] 이메일 회원가입 요청 - 이미 가입된 핸드폰 번호
    CODE_106000200: 106000200, // [토스트 메시지] 이메일 회원가입 요청 - 비밀번호 암호화 실패
    CODE_106000300: 106000300, // [토스트 메시지] 이메일 회원가입 요청 - 회원정보 저장 중 오류 발생
  },

  /**
   *
   * 홈 - 서브페이지 첫 접근 요청
   *
   */
  HOME_SUB_LIST_FIRST: {
    CODE_115010000: 115010000,
  },

  /**
   *
   * 기획전 상세 첫 접근 요청
   *
   * */
  EVENT_DETAIL_FIRST: {
    CODE_104000000: 104000000, // 기획전 상세 첫 접근 요청 - 유효하지 않은 이벤트 정보
  },

  /**
   *
   * 기획전 상세 첫 접근 요청
   *
   * */
  WIDGET_DETAIL_FIRST: {
    CODE_115000000: 115000000, // 위젯 상세 첫 접근 요청 - 유효하지 않은 위젯 정보
  },

  /**
   *
   * 상품 리스트 첫 접근 요청
   *
   * */
  PRODUCT_LIST_FIRST: {
    INVALID_CATEGORY_INFO: 110020000, // 상품 리스트 첫 접근 요청 - 유효하지 않은 카테고리 정보
  },

  /**
   *
   * 상품 리스트 더보기 요청
   *
   * */
  PRODUCT_LIST_MORE: {
    INVALID_CATEGORY_INFO: 110030000, // 상품 리스트 더보기 요청 - 유효하지 않은 카테고리 정보
  },

  /**
   *
   * 상품 상세 첫 접근 요청
   *
   * */
  PRODUCT_DETAIL_FIRST: {
    CODE_110000000: 110000000, // 상품 상세 첫 접근 요청 - 유효하지 않은 상품 상세
  },

  /**
   *
   * 상품 문의하기 작성, 수정, 삭제 요청
   *
   * */
  PRODUCT_QNA: {
    // [토스트 메시지] 상품 신규 질문 제출 - 일시적인 오류가 발생했습니다. 다시 시도해주세요.
    CODE_110040000: 110040000,
    // [토스트 메시지] 상품 문의 수정 - 문의사항 수정 도중 오류가 발생했습니다. (toast)
    CODE_107210000: 107210000,
    // [토스트 메시지] 상품 문의 삭제 - 문의사항 삭제 도중 오류가 발생했습니다. (toast)
    CODE_107200000: 107200000,
  },

  /**
   *
   * 상품 상세 첫 접근 요청
   *
   * */
  PRODUCT_ORDER_RETURN_EXCHANGE_DELIVERY_INFO: {
    CODE_110010000: 110010000, // 유효하지 않은 반품/교환 관련 정보
  },

  /**
   *
   * 결제 준비 요청
   *
   * */
  ORDER_PREPARATION: {
    // [원버튼 팝업] DB 에 등록된 결제수단이 존재하지 않습니다.
    CODE_108030000: 108030000,
    // [원버튼 팝업] 모든 결제수단 사용이 불가능한 주문건입니다.
    CODE_108030100: 108030100,
  },

  /**
   *
   * 결제 진행 요청
   *
   * */
  ORDER_PAY: {
    // [원버튼 팝업(인라인 텍스트)] 주문자 필수 정보가 입력되지 않았습니다.
    CODE_108000000: 108020000,
    // [인라인 텍스트] 배송지 필수 정보가 입력되지 않았습니다.
    CODE_108020001: 108020001,
    // [원버튼 팝업] 주문서의 필수정보가 입력되지 않았습니다.
    CODE_108020002: 108020002,
    // [원버튼 팝업] 결제 금액은 최소 2000원 이상이 되야 합니다.
    CODE_108020003: 108020003,
    // [원버튼 팝업] 결제수단 정보가 유효하지 않습니다.
    CODE_108020004: 108020004,
    // [인라인 텍스트] 가상계좌 결제일경우 환불계좌 정보를 필수로 입력해야 합니다.
    CODE_108020005: 108020005,
    // [원버튼 팝업] 존재하지 않는 계좌 정보이거나 지원하지 않는 은행의 계좌번호 입니다. (inline)
    CODE_108020006: 108020006,
    // [원버튼 팝업] 구매자 정보가 존재하지 않습니다.
    CODE_108020100: 108020100,
    // [인라인 텍스트] 사용가능한 포인트 금액을 초과 했습니다.
    CODE_108020007: 108020007,
    // [원버튼 팝업] 주문 목록 정보가 존재하지 않습니다.
    CODE_108020008: 108020008,
    // [원버튼 팝업] 옵션 id 혹은 옵션 가격의 정보가 누락되었습니다.
    CODE_108020009: 108020009,
    // [원버튼 팝업] 쿠폰이 중복으로 사용되었습니다.
    CODE_108020020: 108020020,
    // [원버튼 팝업] 제품/옵션 가격 정보를 불러올수 없습니다.
    CODE_108020200: 108020200,
    // [원버튼 팝업] 판매 중지된 브랜드 상품은 주문 할 수 없습니다.
    CODE_108020208: 108020208,
    // [원버튼 팝업] 판매중 상태가 아닌 제품은 주문 할 수 없습니다.
    CODE_108020206: 108020206,
    // [원버튼 팝업] 주문 할 수 없는 옵션의 상품이 포함되어 있습니다.
    CODE_108020207: 108020207,
    // [원버튼 팝업] 주문서에 작성된 제품금액 정보가 올바르지 않습니다.
    CODE_108020202: 108020202,
    // [원버튼 팝업] 주문서에 작성된 제품별 합계 금액과 실제 금액이 일치하지 않습니다. 다시 시도해 주세요
    CODE_108020204: 108020204,
    // [원버튼 팝업] 주문서에 작성된 제품금액과 실제판매 금액이 일치하지 않습니다. 다시 시도해 주세요
    CODE_108020203: 108020203,
    // [원버튼 팝업] 주문서에 작성된 제품옵션이 실제 판매 옵션과 일치하지 않습니다. 다시 시도해 주세요
    CODE_108020205: 108020205,
    // [원버튼 팝업] 주문서에 작성된 제품번호가 올바르지 않습니다.
    CODE_108020201: 108020201,
    // [원버튼 팝업] 제품 총 금액이 주문서와 일치하지 않습니다.
    CODE_108020260: 108020260,
    // [원버튼 팝업] 선택된 쿠폰중 사용 불가능한 쿠폰이 존재합니다.
    CODE_108020300: 108020300,
    // [원버튼 팝업] 쿠폰의 최소 구매 가능 금액보다 제품 금액이 낮은 경우가 존재 합니다.
    CODE_108020301: 108020301,
    // [원버튼 팝업] 쿠폰 할인 금액이 유효하지 않은 경우가 존재 합니다.
    CODE_108020302: 108020302,
    // [원버튼 팝업] 쿠폰의 최대 할인 혜택 금액을 초과는 경우가 존재 합니다.
    CODE_108020303: 108020303,
    // [원버튼 팝업] 쿠폰의 총 할인 혜택 금액이 주문서와 일치하지 않습니다.
    CODE_108020304: 108020304,
    // [원버튼 팝업] 사용 불가능한 쿠폰이 포함되어 있습니다.
    CODE_108020305: 108020305,
    // [원버튼 팝업] 해당 상품에 사용할수 없는 종류의 쿠폰입니다.
    CODE_108020306: 108020306,
    // [원버튼 팝업] 결제 금액이 주문서와 일치하지 않습니다.
    CODE_108020400: 108020400,
    // [토스트 메시지] 환불계좌 정보를 저장하는데 실패 했습니다.
    CODE_108020500: 108020500,
    // [토스트 메시지] 환불계좌 정보 id 를 유저 정보에 저장하는데 실패하였습니다.
    CODE_108020501: 108020501,
    // [원버튼 팝업] DB 에 주문서를 저장하던 도중 오류가 발생했습니다.
    CODE_108020600: 108020620,
    // [원버튼 팝업] 제품 1개당 결제 금액은 최소 2000원 이상이 되야 합니다.
    CODE_108020620: 108020620,
    // [원버튼 팝업] DB 에 주문서 상세정보를 저장하던 도중 오류가 발생했습니다.
    CODE_108020610: 108020610,
    // [원버튼 팝업] 주문하려는 상품관리 타입 정보가 일치하지 않습니다.
    CODE_108020611: 108020611,
    // [원버튼 팝업] DB 에 주문서 상품관리 타입 정보를 저장하던 도중 오류가 발생했습니다.
    CODE_108020612: 108020612,
  },

  /**
   *
   * 결제 완료 요청
   *
   * */
  PAYMENT_COMPLETE: {
    // PG 사 결제 실패
    CODE_109000000: 109000000,
    // PG 사 api 호출 후 결제 검증 실패
    CODE_109000100: 109000100,
    // 결제 정보 유효하지 않음
    CODE_109000200: 109000200,
  },

  /**
   *
   * 이메일 회원 정보 수정
   *
   * */
  CHECK_EMAIL_MEMBER: {
    // [인라인 텍스트] 이메일 회원 정보 수정 - 유효하지 않은 이메일
    CODE_107010000: 107010000,
    // [인라인 텍스트] 이메일 회원 정보 수정 - 유효하지 않은 비밀번호
    CODE_107010100: 107010100,
  },
  SELECT_SNS_USER_PROFILE: {
    // [원버튼 팝업] 회원정보가 존재하지 않습니다.
    CODE_107040000: 107040000,
  },
  UPDATE_PASSWORD: {
    // [인라인 텍스트] 새 비밀번호가 일치하지 않습니다.
    CODE_107330000: 107330000,
    // [인라인 텍스트] 영문/숫자를 포함한 6~15자를 입력해 주세요.
    CODE_107330100: 107330100,
    // [원버튼 팝업] 회원정보가 존재하지 않습니다.
    CODE_107330200: 107330200,
    // [인라인 텍스트] 기존 비밀번호와 일치하지 않습니다.
    CODE_107330300: 107330300,
    // [원버튼 팝업] 새로운 비밀번호를 암호화 하는데 실패 했습니다. 다시 시도해주세요.
    CODE_107330400: 107330400,
    // [원버튼 팝업] 비밀번호를 DB 에 저장하던 도중 오류가 발생했습니다.
    CODE_107330500: 107330500,
  },
  UPDATE_EMAIL_USER_PROFILE: {
    // [원버튼 팝업] 변경된 회원정보를 DB 에 저장하던 도중 오류가 발생했습니다.
    CODE_107320000: 107320000,
  },
  USER_WITH_DRAWAL: {
    // [토스트 메시지] 회원 탈퇴에 실패 했습니다.
    CODE_107030000: 107030000,
  },

  /**
   *
   *  마이페이지 - 쿠폰 발급
   *
   * */
  GENERATE_COUPON: {
    // [인라인 텍스트] 쿠폰 발급 요청 - 유효하지 않은 쿠폰입니다. 쿠폰코드를 다시 확인해주세요.
    CODE_103010000: 103010000,
    // [인라인 텍스트] 쿠폰 발급 요청 - 발행이 종료된 쿠폰입니다..
    CODE_103010010: 103010010,
    // [인라인 텍스트] 쿠폰 발급 요청 - 이미 발급 받은 쿠폰입니다.
    CODE_103010100: 103010100,
  },

  /**
   *
   * 주문 취소 접근
   *
   * */
  SELECT_ORDER_CANCEL: {
    // 페이지 접근 - 404 페이지 노출
    CODE_107060000: 107060000,
  },
  /**
   *
   * 주문 취소 요청
   *
   * */
  SUBMIT_ORDER_CANCEL: {
    // [원 버튼 팝업] 결제 정보가 존재하지 않습니다. (버튼: 확인 - 마이페이지로 메인으로 이동)
    CODE_107070000: 107070000,
    // [원 버튼 팝업] 주문 취소 가능한 상태가 아니거나 이미 취소된 상태 입니다. (버튼: 확인 - 마이페이지로 메인으로 이동)
    CODE_107070001: 107070001,
    // [원 버튼 팝업] 환불 계좌정보를 입력해 주세요. (버튼: 확인 - 환불 계좌 정보 영역으로 스크롤 이동)
    CODE_107070002: 107070002,
    // [원 버튼 팝업] 존재하지 않는 계좌 정보이거나 지원하지 않는 은행의 계좌번호 입니다. (버튼: 확인 - 환불 계좌 정보 영역으로 스크롤 이동)
    CODE_107070003: 107070003,
    // [원 버튼 팝업] 결제 취소에 실패했습니다. 다시 시도해주세요. (버튼: 확인 - 원 버튼 팝업 닫기)
    CODE_107070100: 107070100,
    // [원 버튼 팝업] 결제 취소 정보가 올바르지 않습니다. (버튼: 확인 - 새로고침)
    CODE_107070101: 107070101,
    // [원 버튼 팝업] 결제 취소 정보가 올바르지 않습니다. (버튼: 확인 - 새로고침)
    CODE_107070102: 107070102,
    // [원 버튼 팝업] 계좌에 금액이 송금되어 입금확인중인 상태이기 때문에 취소가 불가능합니다. 은행에서 입금확인이 완료되면 잠시 후 다시 시도해주세요. (버튼: 확인 - 새로고침)
    CODE_107070110: 107070110,
    // [토스트 메시지] 결제 취소 도중 오류가 발생했습니다.
    CODE_107070150: 107070150,
    // [토스트 메시지] 결제 취소 도중 오류가 발생했습니다.
    CODE_107070200: 107070200,
    // [토스트 메시지] 결제 취소 도중 오류가 발생했습니다.
    CODE_107070210: 107070210,
    // [토스트 메시지] 결제 취소 도중 오류가 발생했습니다.
    CODE_107070220: 107070220,
    // [토스트 메시지] 결제 취소 도중 오류가 발생했습니다.
    CODE_107070300: 107070300,
    // [토스트 메시지] 결제 취소 도중 오류가 발생했습니다.
    CODE_107070301: 107070301,
    // [원 버튼 팝업] 주문 정보가 존재하지 않습니다. (버튼: 확인 - 마이페이지로 메인으로 이동)
    CODE_107070400: 107070400,
    // [토스트 메시지] 결제 취소 도중 오류가 발생했습니다.
    CODE_107070401: 107070401,
    // [토스트 메시지] 결제 취소 도중 오류가 발생했습니다.
    CODE_107070402: 107070402,
    // [원 버튼 팝업] 부분 취소 요청이 불가한 주문입니다.
    CODE_107070403: 107070403,
    // [원 버튼 팝업] 취소 정보가 존재하지 않습니다. (버튼: 확인 - 마이페이지로 메인으로 이동)
    CODE_107250000: 107250000,
  },
  /**
   *
   * 주문 취소 요청
   *
   * */
  RESULT_ORDER_CANCEL: {
    // 404 페이지 노출
    CODE_107250000: 107250000,
    // 404 페이지 노출
    CODE_107250100: 107250100,
  },
  /**
   *
   * 주문 취소 요청 철회
   *
   * */
  WITHDRAW_ORDER_CANCEL: {
    // [원 버튼 팝업] 취소 철회 가능한 상태가 아니거나 이미 철회 된 상태 입니다. (버튼: 확인 - 원 버튼 팝업 닫기)
    CODE_107260000: 107260000,
    // [토스트 메시지] 취소 철회 도중 오류가 발생했습니다.
    CODE_107260100: 107260100,
    // [토스트 메시지] 취소 철회 도중 오류가 발생했습니다.
    CODE_107260200: 107260200,
    // [원 버튼 팝업] 반품 철회 가능한 상태가 아니거나 이미 철회 된 상태 입니다. (버튼: 확인 - 원 버튼 팝업 닫기)
    CODE_107180000: 107180000,
    // [토스트 메시지] 반품 철회 도중 오류가 발생했습니다.
    CODE_107180100: 107180100,
    // [토스트 메시지] 반품 철회 도중 오류가 발생했습니다.
    CODE_107180200: 107180200,
  },

  /**
   *
   * 주문 반품 접근
   *
   * */
  SELECT_ORDER_RETURN: {
    // 페이지 접근 - 404 페이지 노출
    CODE_107140000: 107140000,
  },
  /**
   *
   * 주문 반품 요청
   *
   * */
  SUBMIT_ORDER_RETURN: {
    // [원 버튼 팝업] 주문 정보가 존재하지 않습니다. (버튼: 확인 - 마이페이지로 메인으로 이동)
    CODE_107150000: 107150000,
    // [원 버튼 팝업] 첨부 이미지는 최대 3장까지 업로드 가능합니다. (버튼: 확인 - 원 버튼 팝업 닫기)
    CODE_107150001: 107150001,
    // [원 버튼 팝업] 반품 가능한 상태가 아니거나 이미 반품처리 된 상태 입니다. (버튼: 확인 - 마이페이지로 메인으로 이동)
    CODE_107150050: 107150050,
    // [원 버튼 팝업] 환불 계좌정보를 입력해 주세요. (버튼: 확인 - 환불 계좌 정보 영역으로 스크롤 이동)
    CODE_107150080: 107150080,
    // [원 버튼 팝업] 존재하지 않는 계좌 정보이거나 지원하지 않는 은행의 계좌번호 입니다. (버튼: 확인 - 환불 계좌 정보 영역으로 스크롤 이동)
    CODE_107150081: 107150081,
    // [토스트 메시지] 반품 신청 도중 오류가 발생했습니다.
    CODE_107150100: 107150100,
    // [토스트 메시지] 반품 신청 도중 오류가 발생했습니다.
    CODE_107150200: 107150200,
    // [토스트 메시지] 반품 신청 도중 오류가 발생했습니다.
    CODE_107150300: 107150300,
    // [토스트 메시지] 반품 신청 도중 오류가 발생했습니다.
    CODE_107150400: 107150400,
  },
  /**
   *
   * 주문 반품 내역 확인
   *
   * */
  RESULT_ORDER_RETURN: {
    // [원 버튼 팝업] 반품 정보가 존재하지 않습니다. (버튼: 확인 - 마이페이지로 메인으로 이동)
    CODE_107130000: 107130000,
    // [원 버튼 팝업] 반품 정보를 조회 할수 없는 상태 입니다. (버튼: 확인 - 마이페이지로 메인으로 이동)
    CODE_107130100: 107130100,
  },
  /**
   *
   * 주문 반품 요청 철회
   *
   * */
  WITHDRAW_ORDER_RETURN: {
    // [원 버튼 팝업] 반품 철회 가능한 상태가 아니거나 이미 철회 된 상태 입니다. (버튼: 확인 - 원 버튼 팝업 닫기)
    CODE_107180000: 107180000,
    // [토스트 메시지] 반품 철회 도중 오류가 발생했습니다.
    CODE_107180100: 107180100,
    // [토스트 메시지] 반품 철회 도중 오류가 발생했습니다.
    CODE_107180200: 107180200,
  },

  /**
   *
   * 주문 교환 접근
   *
   * */
  SELECT_ORDER_EXCHANGE: {
    // 페이지 접근 - 404 페이지 노출
    CODE_107240000: 107240000,
  },
  /**
   *
   * 주문 교환 요청
   *
   * */
  SUBMIT_ORDER_EXCHANGE: {
    // [원 버튼 팝업] 주문 정보가 존재하지 않습니다. (버튼: 확인 - 마이페이지로 메인으로 이동)
    CODE_107110000: 107110000,
    // [원 버튼 팝업] 첨부 이미지는 최대 3장까지 업로드 가능합니다. (버튼: 확인 - 원 버튼 팝업 닫기)
    CODE_107110001: 107110001,
    // [원 버튼 팝업] 교환 가능한 상태가 아니거나 이미 교환처리 된 상태 입니다. (버튼: 확인 - 마이페이지로 메인으로 이동)
    CODE_107110050: 107110050,
    // [토스트 메시지] 교환 신청 도중 오류가 발생했습니다.
    CODE_107110100: 107110100,
    // [토스트 메시지] 교환 신청 도중 오류가 발생했습니다.
    CODE_107110200: 107110200,
    // [토스트 메시지] 교환 신청 도중 오류가 발생했습니다.
    CODE_107110400: 107110400,
  },
  /**
   *
   * 주문 교환 내역 확인
   *
   * */
  RESULT_ORDER_EXCHANGE: {
    // [원 버튼 팝업] 교환 정보가 존재하지 않습니다. (버튼: 확인 - 마이페이지로 메인으로 이동)
    CODE_107100000: 107100000,
    // [원 버튼 팝업] 교환 정보를 조회 할수 없는 상태 입니다. (버튼: 확인 - 마이페이지로 메인으로 이동)
    CODE_107100100: 107100100,
  },
  /**
   *
   * 주문 교환 요청 철회
   *
   * */
  WITHDRAW_ORDER_EXCHANGE: {
    // [원 버튼 팝업] 교환 철회 가능한 상태가 아니거나 이미 철회 된 상태 입니다. (버튼: 확인 - 원 버튼 팝업 닫기)
    CODE_107190000: 107190000,
    // [토스트 메시지] 교환 철회 도중 오류가 발생했습니다.
    CODE_107190100: 107190100,
    // [토스트 메시지] 교환 철회 도중 오류가 발생했습니다.
    CODE_107190200: 107190200,
  },

  /**
   *
   * 가상계좌 환불 정보 조회
   *
   */
  SELECT_ORDER_VBANK: {
    // [원 버튼 팝업] 환불 계좌 정보가 존재하지 않습니다. (버튼: 확인 - 환불정보 변경 모달 열지 않기)
    CODE_107150400: 107150400,
    // [원 버튼 팝업] 은행 계좌 정보가 존재하지 않습니다. (버튼: 확인 - 환불정보 변경 모달 열지 않기)
    CODE_107160100: 107160100,
  },
  /**
   *
   * 가상계좌 환불 정보 수정
   *
   */
  UPDATE_ORDER_VBANK: {
    // [원 버튼 팝업] 환불 계좌 정보 수정이 불가능한 상태입니다. (버튼: 확인 - 환불정보 변경 모달 닫기)
    CODE_107170000: 107170000,
    // [원 버튼 팝업] 서버에서 내려주는 메시지 노출 (버튼: 확인 - 환불정보 변경 모달 닫기)
    CODE_107170050: 107170050,
    // [토스트 메시지] 환불 계좌 수정 도중 오류가 발생했습니다.
    CODE_107170100: 107170100,
  },

  /**
   *
   * 쿠폰 적용 가능 상품 리스트 요청
   *
   * */
  PRODUCT_COUPON: {
    CODE_107340100: 107340100, // 쿠폰 적용 가능 상품 리스트 요청 - 리다이렉트
  },

  /**
   *
   * 쿠폰 적용 가능 브랜드 리스트 요청
   *
   * */
  BRAND_COUPON: {
    CODE_107350100: 107350100, // 쿠폰 적용 가능한 브랜드가 존재하지 않을 경우(쿠폰 적용 가능한 브랜드목록 화면에 접근했는데 couponId 에 연결된 브랜드정보가 없을경우)
  },

  /**
   *
   *  프로필 정보 요청
   *
   * */
  USER_PROFILE: {
    // 프로필 정보 요청 - 유효하지 않은 user key
    INVALID_USER_KEY: 106040000,
  },

  /**
   *
   * 상품 후기 상세 첫 접근 요청
   *
   * */
  PRODUCT_REVIEW_DETAIL_FIRST: {
    CODE_117000000: 117000000, // 상품 후기 상세 첫 접근 요청 - 유효하지 않은 상품 상세
  },

  // 후기 작성 요청
  INSERT_PRODUCT_REVIEW: {
    CODE_110040000: 110040000, // 후기 작성 요청 - 일시적인 오류가 발생했습니다. 다시 시도해주세요.
    CODE_107230100: 107230100, // 후기 작성 요청 - 해당 상품의 리뷰를 작성할 수 없습니다.
    CODE_110050000: 110050000, // 후기 작성 체크 요청 - 후기 작성 가능한 구매 확정건이 존재하지 않습니다.
  },

  /**
   *
   * 선물 상품 상세 요청
   *
   */
  GIFT_DETAIL: {
    // 상품 상세 요청 - giftToken 이 정상복호화 되지 않은 경우(엉뚱한 giftToken을 입력했을 경우)
    CODE_400: 400,
    // 상품 상세 요청 - 선물 주문건이 존재하지 않거나 선물이 취소 된 주문건입니다. (복호화한 선물 토큰 정보에 부합하는 주문건이 존재하지 않는 경우 or 모든주문건이 취소완료 또는 취소환불완료상태인경우)
    CODE_118000000: 118000000,
    // 상품 상세 요청 - 배송지 입력 기한이 만료된 선물하기 주문건입니다.
    CODE_118000100: 118000100,
  },

  /**
   *
   * 선물 상품 배송지 입력 요청
   *
   */
  GIFT_UPDATE: {
    // 선물 상품 배송지 입력 요청 - 선물 수령지 입력이 불가능한 상태거나 존재하지 않는 주문건입니다.
    // 복호화 한 선물 토큰 정보에 부합하는 주문건이 존재하지 않거나 선물이 수락 대기 상태가 아닌 경우
    CODE_118010000: 118010000,
  },

  /**
   *
   * 선물 상품 배송 추적 요청
   *
   */
  GIFT_DELIVERY_TRACKING: {
    // 선물 상품 배송 추적 요청 - 선물 배송 추적 할 수 있는 주문 정보가 존재하지 않습니다.
    // 복호화한 선물 토큰 정보에 부합하는 주문건이 존재하지 않는 경우
    CODE_118020000: 118020000,
    // 선물 상품 배송지 입력 요청 - 선물 배송 추적 정보가 존재하지 않습니다.
    // 주문건은 존재하는데 배송 정보가 없는 경우 (주문서가 배송추적을 할수없는 상태)
    CODE_118020100: 118020100,
  },

  /**
   *
   * 선물 상품 거절 요청
   *
   */
  GIFT_REJECT: {
    // 선물을 거절할수 없는 상태입니다.(복호화한 선물 토큰 정보에 부합하는 주문건이 존재하지 않거나 선물이 수락 대기 상태가 아닌경우)
    CODE_118030000: 118030000,
    // 결제 취소될 선물 주문 아이템이 존재하지 않습니다.(결제취소 할수 있는 주문 아이템이 없는 경우)
    CODE_118030100: 118030100,
  },

  /**
   *
   * 브랜드 상세 첫 접근 요청
   *
   */
  BRAND_DETAIL_FIRST: {
    // 해당 번호에 대한 브랜드 상세 정보가 존재하지 않는 경우
    CODE_101000000: 101000000,
  },
};
