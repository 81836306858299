import ButtonCover from "@components/common/cover/ButtonCover";
import Icon from "@components/common/Icon";
import styled from "@styles/themedComponents";

interface ISearchInputClearButtonStyleProps {
  isSearchKeywordExisted: boolean;
}

interface ISearchModalStyleProps extends ISearchInputClearButtonStyleProps {
  isRecentSearchKeywordListExisted: boolean;
  isAutoCompleteDataExisted?: boolean;
}

export const SearchModalStyle = styled.section<ISearchModalStyleProps>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: ${({ isAutoCompleteDataExisted, isSearchKeywordExisted }) =>
    isAutoCompleteDataExisted || !isSearchKeywordExisted ? "auto" : "73px"};
  min-height: 100vh;
  z-index: ${({ theme }) => theme.zIndex.modalBox};
  font-family: ${(props) => props.theme.fonts.notoSansCJKkr};
  background-color: ${({ theme }) => theme.color.amondz_white};
  padding: ${({ isSearchKeywordExisted }) => (isSearchKeywordExisted ? "16px 15px 0" : "16px 15px 40px")};
  border-radius: 10px;
  box-shadow: 0 2px 11px 0 rgba(197, 197, 197, 0.5);
  overflow-x: hidden;

  .recommended-search-list-wrapper {
    margin-top: 30px;
    padding-bottom: 20px;

    .recommended-search-list {
      padding-top: 0;
      margin-top: 0;
    }
  }

  .recent-search-word-list-wrapper {
    margin-top: 30px;
    padding-bottom: 20px;

    & > header {
      margin-bottom: 10px;
    }
  }

  .recommended-product-list-wrapper {
    margin-top: 30px;
  }
`;

export const SearchFormStyle = styled.form`
  position: relative;
`;

export const HiddenInputStyle = styled.input`
  position: absolute;
  top: -100px;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  border: 0;
`;

export const SearchInputSectionStyle = styled.div<ISearchInputClearButtonStyleProps>`
  margin-bottom: ${({ isSearchKeywordExisted }) => (isSearchKeywordExisted ? "20px" : "30px")};

  form {
    width: calc(100% - 44px);

    input {
      font-size: 16px !important;
      font-weight: bold;
      padding: 8px 43px 8px 28px;
      height: 40px;
      z-index: 1;

      &::placeholder {
        font-size: 16px;
        font-weight: normal;
        letter-spacing: -0.5px;
      }
    }
  }
`;

export const SearchInputClearButtonStyle = styled(Icon)<ISearchInputClearButtonStyleProps>`
  display: ${({ isSearchKeywordExisted }) => (isSearchKeywordExisted ? "block" : "none")};
  position: absolute;
  width: 24px;
  height: 24px;
  top: 25px;
  right: 63px;
  cursor: pointer;
`;

export const SearchModalCloseButtonStyle = styled(ButtonCover)`
  position: absolute;
  top: 28px;
  right: 16px;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: -0.5px;
  color: ${({ theme }) => theme.color.amondz_black};
`;

export const SearchInputSubmitButton = styled(ButtonCover)`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 7px;
  left: 0;
  width: 24px;
  height: 24px;
`;

export const SearchIconStyle = styled(Icon)`
  width: 14px;
  height: 14px;
`;

export const RecentSearchListClearButton = styled(ButtonCover)`
  position: absolute;
  top: 0;
  right: 0;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.67;
  letter-spacing: -0.3px;
  color: ${(props) => props.theme.color.gray_01};
`;
