import { REVIEW_TITLE_TEXT } from "@constants/service/review/text";
import { MAY_PAGE_MENU, REFERRAL_IMG_TYPE } from "../../enum/mypageEnums";
import {
  MY_PAGE_MANAGE_URL_PATH,
  MY_PAGE_QNA_PRODUCT_URL_PATH,
  MY_PAGE_FAQ_URL_PATH,
} from "../../url/internalUrlConstants";
import {
  FRIEND_GRADE_MEMBER_BENEFIT,
  LOVER_GRADE_MEMBER_BENEFIT,
  MYPAGE_ORDER_RETURN_EXCHANGE_MODAL_EXCHANGE_BTN_TEXT,
  MYPAGE_ORDER_RETURN_EXCHANGE_MODAL_RETURN_BTN_TEXT,
  MYPAGE_ORDER_RETURN_EXCHANGE_MODAL_TODAY_DELIVERY_EXCHANGE_BTN_TEXT,
  VIP_GRADE_MEMBER_BENEFIT_01,
  VIP_GRADE_MEMBER_BENEFIT_02,
} from "./text";

// 마이페이지 메뉴
export const MYPAGE_MENU_TEXT = {
  [MAY_PAGE_MENU.BENEFIT]: "혜택정보",
  [MAY_PAGE_MENU.COUPON]: "쿠폰",
  [MAY_PAGE_MENU.POINT]: "포인트",
  [MAY_PAGE_MENU.MY_ACTIVITY]: "내 활동",
  [MAY_PAGE_MENU.ORDER_INFO]: "주문내역",
  [MAY_PAGE_MENU.REVIEW]: REVIEW_TITLE_TEXT,
  [MAY_PAGE_MENU.QNA_LIST]: "브랜드 문의내역",
  [MAY_PAGE_MENU.CS]: "고객센터",
  [MAY_PAGE_MENU.FAQ]: "자주묻는 질문",
  [MAY_PAGE_MENU.QNA]: "아몬즈에 문의하기",
  [MAY_PAGE_MENU.SETTING]: "설정",
  [MAY_PAGE_MENU.USER_MODIFY]: "회원정보수정",
  [MAY_PAGE_MENU.MEMBERSHIP]: "회원등급상세",
  // [MAY_PAGE_MENU.NOTIFICATION]: "알림설정",
};

export const MYPAGE_MENU_LIST = [
  {
    id: MAY_PAGE_MENU.CS,
    name: MYPAGE_MENU_TEXT[MAY_PAGE_MENU.CS],
    sub: [
      {
        id: MAY_PAGE_MENU.FAQ,
        name: MYPAGE_MENU_TEXT[MAY_PAGE_MENU.FAQ],
        href: MY_PAGE_FAQ_URL_PATH,
      },
      {
        id: MAY_PAGE_MENU.QNA,
        name: MYPAGE_MENU_TEXT[MAY_PAGE_MENU.QNA],
        // src: MY_PAGE_QNA_URL_PATH,
      },
      {
        id: MAY_PAGE_MENU.QNA_LIST,
        name: MYPAGE_MENU_TEXT[MAY_PAGE_MENU.QNA_LIST],
        src: MY_PAGE_QNA_PRODUCT_URL_PATH,
      },
    ],
  },
  {
    id: MAY_PAGE_MENU.SETTING,
    name: MYPAGE_MENU_TEXT[MAY_PAGE_MENU.SETTING],
    sub: [
      {
        id: MAY_PAGE_MENU.USER_MODIFY,
        name: MYPAGE_MENU_TEXT[MAY_PAGE_MENU.USER_MODIFY],
        src: MY_PAGE_MANAGE_URL_PATH,
      },
      // {
      // 	id: MAY_PAGE_MENU.NOTIFICATION,
      // 	name: MYPAGE_MENU_TEXT[MAY_PAGE_MENU.NOTIFICATION],
      // },
    ],
  },
];

// 레퍼럴 이미지 타입 리스트
export const REFERRAL_IMG_TYPE_LIST = [
  REFERRAL_IMG_TYPE.APP,
  REFERRAL_IMG_TYPE.DESKTOP,
  REFERRAL_IMG_TYPE.TABLET,
  REFERRAL_IMG_TYPE.MOBILE,
];

// 회원 이메일 최대 글자수
export const MAXIMUM_NUMBER_OF_USER_EMAIL = 80;

// 마이페이지 메뉴 줄바꿈 포인트 최대 값
export const MAXIMUM_VALUE_OF_POINT = 1000000;

// 쿠폰 코드 최대 글자수
export const MAXIMUM_NUMBER_OF_COUPON_CODE = 20;

export const COUPON_AVAILABLE_TYPE = ["all", "product", "brand"];

/**
 * 0: 주문서 작성
 * 1: 결제 실패
 * 2: 입금 대기
 * 3: 결제 완료
 * 4: 상품준비
 * 5: 발송 지연
 * 6: 배송 중
 * 7: 배송 완료
 * 8: 구매 확정
 * 9: 취소
 * 10: 반품
 * 11: 교환
 */
export const ORDER_STATUS = {
  SUBMIT: 0,
  PAYMENT_FAIL: 1,
  PENDING_DEPOSIT: 2,
  PAYMENT_COMPLETE: 3,
  DELIVERY_PREPARATION: 4,
  SHIPPING_DELAY: 5,
  SHIPPING: 6,
  DELIVERY_COMPLETE: 7,
  PURCHASE_CONFIRMATION: 8,
  CANCELLATION: 9,
  RETURN: 10,
  EXCHANGE: 11,
} as const;
export type ORDER_STATUS_TYPE = typeof ORDER_STATUS[keyof typeof ORDER_STATUS];
export const ORDER_STATUS_TEXT = {
  [ORDER_STATUS.SUBMIT]: "주문서 작성",
  [ORDER_STATUS.PAYMENT_FAIL]: "결제 실패",
  [ORDER_STATUS.PENDING_DEPOSIT]: "입금대기",
  [ORDER_STATUS.PAYMENT_COMPLETE]: "결제완료",
  [ORDER_STATUS.DELIVERY_PREPARATION]: "상품준비",
  [ORDER_STATUS.SHIPPING_DELAY]: "배송지연",
  [ORDER_STATUS.SHIPPING]: "배송중",
  [ORDER_STATUS.DELIVERY_COMPLETE]: "배송완료",
  [ORDER_STATUS.PURCHASE_CONFIRMATION]: "구매확정",
  [ORDER_STATUS.CANCELLATION]: "취소",
  [ORDER_STATUS.RETURN]: "반품",
  [ORDER_STATUS.EXCHANGE]: "교환",
};

/**
 * 0: 취소 요청
 * 1: 취소 요청 철회
 * 2: 취소 거부
 * 3: 취소 완료
 * 4: 환불 완료
 */
export const ORDER_CANCEL_STATUS = {
  REQUEST: 0,
  WITHDRAW: 1,
  REJECT: 2,
  CANCEL_DONE: 3,
  REFUND_DONE: 4,
} as const;
export type ORDER_CANCEL_STATUS_TYPE = typeof ORDER_CANCEL_STATUS[keyof typeof ORDER_CANCEL_STATUS];
export const ORDER_CANCEL_STATUS_TEXT = {
  [ORDER_CANCEL_STATUS.REQUEST]: "취소요청",
  [ORDER_CANCEL_STATUS.WITHDRAW]: "취소요청철회",
  [ORDER_CANCEL_STATUS.REJECT]: "취소거부",
  [ORDER_CANCEL_STATUS.CANCEL_DONE]: "취소완료",
  [ORDER_CANCEL_STATUS.REFUND_DONE]: "취소완료",
};

/**
 * 0: 반품 요청
 * 1: 반품 요청 철회
 * 3: 반품수거완료
 * 4: 반품처리지연
 * 5: 반품거부
 * 6: 반품승인
 * 7: 반품완료
 */
export const ORDER_RETURN_STATUS = {
  REQUEST: 0,
  WITHDRAW: 1,
  PICK_UP: 3,
  DELAY: 4,
  REJECT: 5,
  CONFIRM: 6,
  DONE: 7,
} as const;
export type ORDER_RETURN_STATUS_TYPE = typeof ORDER_RETURN_STATUS[keyof typeof ORDER_RETURN_STATUS];
export const ORDER_RETURN_STATUS_TEXT = {
  [ORDER_RETURN_STATUS.REQUEST]: "반품요청",
  [ORDER_RETURN_STATUS.WITHDRAW]: "반품요청철회",
  [ORDER_RETURN_STATUS.PICK_UP]: "반품수거완료",
  [ORDER_RETURN_STATUS.DELAY]: "반품처리지연",
  [ORDER_RETURN_STATUS.REJECT]: "반품거부",
  [ORDER_RETURN_STATUS.CONFIRM]: "반품승인",
  [ORDER_RETURN_STATUS.DONE]: "반품완료",
};

/**
 * 0: 교환 요청
 * 1: 교환 요청 철회
 * 3: 교환수거완료
 * 4: 교환처리지연
 * 5: 교환거부
 * 6: 교환품 배송중
 * 7: 교환완료
 */
export const ORDER_EXCHANGE_STATUS = {
  REQUEST: 0,
  WITHDRAW: 1,
  PICK_UP: 3,
  DELAY: 4,
  REJECT: 5,
  DELIVERY_SHIPPING: 6,
  DONE: 7,
} as const;
export type ORDER_EXCHANGE_STATUS_TYPE = typeof ORDER_EXCHANGE_STATUS[keyof typeof ORDER_EXCHANGE_STATUS];
export const ORDER_EXCHANGE_STATUS_TEXT = {
  [ORDER_EXCHANGE_STATUS.REQUEST]: "교환요청",
  [ORDER_EXCHANGE_STATUS.WITHDRAW]: "교환요청철회",
  [ORDER_EXCHANGE_STATUS.PICK_UP]: "교환수거완료",
  [ORDER_EXCHANGE_STATUS.DELAY]: "교환처리지연",
  [ORDER_EXCHANGE_STATUS.REJECT]: "교환거부",
  [ORDER_EXCHANGE_STATUS.DELIVERY_SHIPPING]: "재배송중",
  [ORDER_EXCHANGE_STATUS.DONE]: "교환완료",
};

/**
 * 0: 구매 확정
 * 1: 후가 작성
 * 2. 후기 보기
 */
export const ORDER_INFO_BUTTON = {
  CONFIRM: 0,
  WRITE: 1,
  READ: 2,
};
export type ORDER_INFO_BUTTON_TYPE = typeof ORDER_INFO_BUTTON[keyof typeof ORDER_INFO_BUTTON];

export const DELIVERY_TRACKING = {
  DELIVERY: 0,
  DIRECT: 1,
  VISIT: 2,
  QUICK: 3,
  ETC: 4,
} as const;
export type DELIVERY_TRACKING_TYPE = typeof DELIVERY_TRACKING[keyof typeof DELIVERY_TRACKING];
export const DELIVERY_TRACKING_TEXT = {
  [DELIVERY_TRACKING.DELIVERY]: "택배",
  [DELIVERY_TRACKING.DIRECT]: "직접전달",
  [DELIVERY_TRACKING.VISIT]: "방문수령",
  [DELIVERY_TRACKING.QUICK]: "퀵서비스",
  [DELIVERY_TRACKING.ETC]: "기타방법",
};

// 교환 및 반품 요청 모달 타입
export const REQUEST_RETURN_EXCHANGE_MODAL = {
  EXCHANGE: 0,
  RETURN: 1,
} as const;
export type REQUEST_RETURN_EXCHANGE_MODAL_TYPE =
  typeof REQUEST_RETURN_EXCHANGE_MODAL[keyof typeof REQUEST_RETURN_EXCHANGE_MODAL];
export const REQUEST_RETURN_EXCHANGE_MODAL_TYPE_LIST = [
  {
    id: REQUEST_RETURN_EXCHANGE_MODAL.EXCHANGE,
    name: MYPAGE_ORDER_RETURN_EXCHANGE_MODAL_EXCHANGE_BTN_TEXT,
  },
  {
    id: REQUEST_RETURN_EXCHANGE_MODAL.RETURN,
    name: MYPAGE_ORDER_RETURN_EXCHANGE_MODAL_RETURN_BTN_TEXT,
  },
];
export const REQUEST_RETURN_EXCHANGE_TODAY_DELIVERY_MODAL_TYPE_LIST = [
  {
    id: REQUEST_RETURN_EXCHANGE_MODAL.EXCHANGE,
    name: MYPAGE_ORDER_RETURN_EXCHANGE_MODAL_TODAY_DELIVERY_EXCHANGE_BTN_TEXT,
  },
  {
    id: REQUEST_RETURN_EXCHANGE_MODAL.RETURN,
    name: MYPAGE_ORDER_RETURN_EXCHANGE_MODAL_RETURN_BTN_TEXT,
  },
];

export const ORDER_RETURN_EXCHANGE_RESPONSIBILITY = {
  BUYER: 0, // 구매자 책임
  SELLER: 1, // 판매자 책임
};
export type ORDER_RETURN_EXCHANGE_RESPONSIBILITY_TYPE =
  typeof ORDER_RETURN_EXCHANGE_RESPONSIBILITY[keyof typeof ORDER_RETURN_EXCHANGE_RESPONSIBILITY];

// 주문서 타입
export const ORDER_TYPE = {
  GENERAL: 0,
  GIFT: 1,
} as const;

export const GIFT_STATUS = {
  PENDING: 0,
  ACCEPT: 1,
  REJECT: 2,
} as const;
export type GIFT_STATUS_TYPE = typeof GIFT_STATUS[keyof typeof GIFT_STATUS];
export const GIFT_STATUS_TEXT = {
  [GIFT_STATUS.PENDING]: "선물 수락 대기",
  [GIFT_STATUS.ACCEPT]: "선물 수락",
  [GIFT_STATUS.REJECT]: "선물 거절",
};

export const ORDER_ITEM_REVIEW_STATUS = {
  WRITE: 0,
  READ: 1,
} as const;

export const MYPAGE_MAIN_NOTI_STATUS = {
  PURCHASE_CONFIRM: 0,
  WRITE_REVIEW: 1,
} as const;

// 상품 구매후기
export const REVIEW_LIST_TYPE = {
  WRITE: 0,
  HISTORY: 1,
  LIKE: 2,
} as const;
// export type REVIEW_LIST_TYPE_TYPE = typeof REVIEW_LIST_TYPE[keyof typeof REVIEW_LIST_TYPE];;

//이미지 레퍼럴 클라이언트 타입
export const IMAGE_REFERRAL_CLIENT_TYPE = {
  APP: 0,
  PC: 1,
  TABLET: 2,
  MOBILE: 3,
} as const;

//이미지 레퍼럴 타입
export const IMAGE_REFERRAL_TYPE = {
  MAIN: 0,
  DETAIL: 1,
} as const;

//좋아요한 브랜드 한 페이지당 최대 갯수(초과시 페이지네이션)
export const LIKE_STORES_DEFAULT_PAGE_SIZE = 10;

//좋아요한 상품 한 페이지당 최대 갯수(초과시 페이지네이션)
export const LIKE_PRODUCTS_DEFAULT_PAGE_SIZE = 60;

//후기 작성 포인트
export const REVIEW_POINT = 1000;

//등급 타입
export const MEMBER_GRADE_TYPE = {
  FRIEND: 0,
  LOVER: 1,
  VIP: 2,
} as const;

//등급명
export const MEMBER_GRADE_TYPE_NAME = {
  [MEMBER_GRADE_TYPE.FRIEND]: "FRIEND",
  [MEMBER_GRADE_TYPE.LOVER]: "LOVER",
  [MEMBER_GRADE_TYPE.VIP]: "VIP",
} as const;

//등급 기준 및 혜택
export const MEMBER_GRADE_BENEFIT = {
  [MEMBER_GRADE_TYPE_NAME[MEMBER_GRADE_TYPE.FRIEND]]: [FRIEND_GRADE_MEMBER_BENEFIT],
  [MEMBER_GRADE_TYPE_NAME[MEMBER_GRADE_TYPE.LOVER]]: [LOVER_GRADE_MEMBER_BENEFIT],
  [MEMBER_GRADE_TYPE_NAME[MEMBER_GRADE_TYPE.VIP]]: [VIP_GRADE_MEMBER_BENEFIT_01, VIP_GRADE_MEMBER_BENEFIT_02],
};
