import styled from "@styles/themedComponents";

interface ISearchModalInfoBaseSectionStyleProps {
  sectionBorderBottom: boolean;
}

interface ISearchModalInfoBaseSectionTitleStyleProps {
  titleMarginBottomValue: number;
  mobileTitleMarginBottomValue: number;
}

export const SearchModalInfoBaseSectionStyle = styled.article<ISearchModalInfoBaseSectionStyleProps>`
  position: relative;
  padding-bottom: ${({ sectionBorderBottom }) => (sectionBorderBottom ? "30px" : 0)};
  border-bottom: ${({ theme, sectionBorderBottom }) =>
    sectionBorderBottom ? `1px solid ${theme.color.gray_07}` : "none"};
`;

export const SearchModalInfoBaseSectionTitleStyle = styled.header<ISearchModalInfoBaseSectionTitleStyleProps>`
  margin-bottom: ${({ mobileTitleMarginBottomValue }) => mobileTitleMarginBottomValue}px;
  height: 27px;
  font-size: 18px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: -0.5px;
`;
