import { ReactNode } from "react";
import { differenceInHours, differenceInDays, differenceInMinutes, format } from "date-fns";
import { isNaN, isNil, isNumber, replace, repeat } from "lodash";

import { UNIT_TYPE } from "@constants/enum/baseEnums";
import { setAmondzUuid } from "@lib/utility/uuid";

/**
 * 천 단위마다 "," 붙이기
 *
 * @param {number | string} number 변환하고자 하는 number 값
 * @param {any} defaultValue number 값이 0 | null | undefined 인 경우 노출되는 값
 *
 * @returns {string} 문자로 변환된 숫자
 */
export const numberWithCommas = (number?: number | string | null, defaultValue?: any): string => {
  if (!number) {
    return !isNil(defaultValue) ? defaultValue : "-";
  }
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

/**
 * 나누고자하는 첫번째 문자에 대해서만 split
 *
 * @param {string} target 나누려는 기준 문자
 * @param {string} value 나누려는 값
 *
 * @returns {Array} 나눠진 값을 배열로
 * @example
 * splitOnFirstTarget('=','aaa=bbb=cc') //['aaa','bbb=cc']
 */
export const splitOnFirstTarget = (target: string, value: string) => {
  const targetIndex = value.indexOf(target);
  return [value.slice(0, targetIndex), value.slice(targetIndex + 1)];
};

/**
 * 초를 시간으로 변경하기
 *
 * @param {number} seconds 변환 할 초
 * @param {string} formatString 초를 시간으로 변환할 때 설정할 시간 포맷, mm:ss 값이 기본 값
 *
 * @returns {string} 초를 시간단위로 환산된 값
 */
export const setSecondsToTime = (seconds: number, formatString: string = "mm:ss"): string => {
  return format(new Date(0, 0, 0, 0, 0, seconds), formatString);
};

/**
 * 단위 설정
 *
 * @param {number|string} amount 단위를 설정 할 number 또는 string 값
 * @param {UNIT_TYPE | ReactNode} [unit] 단위, "원" 이 기본 값
 *
 * @returns {string} 단위가 설정된 값
 */
export const setUnit = (amount: number | string, unit: UNIT_TYPE | ReactNode = UNIT_TYPE.WON): string => {
  if (isNil(amount)) {
    return "";
  }
  return `${amount}${unit}`;
};

/**
 * 전화번호 포맷으로 변경하기
 *
 * @param {string} [phoneNumber] 변환 할 전화번호
 *
 * @returns {string} "-"이 포함된 전화번화
 */
export const setPhoneNumberFormat = (phoneNumber?: string): string => {
  if (!phoneNumber) {
    return "-";
  }

  if (phoneNumber.length === 11) {
    // 11 자리
    return phoneNumber.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
  } else if (phoneNumber.indexOf("050") === 0) {
    // 안심번호
    if (phoneNumber.length === 11) {
      return phoneNumber.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
    }
    return phoneNumber.replace(/(\d{4})(\d{4})(\d{4})/, "$1-$2-$3");
  }
  return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
};

/**
 * 숫자 체크
 *
 * @param {any} number 숫자인지 체크할 데이터
 *
 * @returns {boolean} 숫자 여부
 */
export const isNumeric = (number: any): boolean => {
  return number && isNumber(number) && !isNaN(number);
};

/**
 * 문자열 변경하기
 *
 * @param {string} source 변경 할 원본
 * @param {string} target 변경 할 문자열
 * @param {string} alter 대체 할 문자열
 */
export const replaceAll = (source: string, target: string, alter: string): string => {
  return replace(source, new RegExp(target, "g"), alter);
};

/**
 * UUID 와 Cookie 옵션 만들기
 *
 * @returns {string} uuid
 * @returns {string} date 쿠키 유효 기간
 */
export const setUuidOption = (): { uuid: string; date: Date } => {
  const uuid = setAmondzUuid();

  // 쿠키 유효기간 10년
  const date = new Date();
  date.setTime(date.getTime() + 10 * 365 * 24 * 60 * 60 * 1000);

  return {
    uuid,
    date,
  };
};

/**
 * "-" 제거하기
 *
 * @param {string} source "-" 를 제거할 원본
 * @returns {string} "-" 가 제거된 문자열
 */
export const setClearDash = (source: string): string => {
  return replaceAll(source, "-", "");
};

/**
 * "-" 추가
 *
 * @param {string} source "-" 를 추가할 원본
 * @returns {string} "-" 가 추가된 문자열
 */
export const setDash = (source: string): string => {
  const num = new Array(3);
  num[0] = source.slice(0, 3);
  num[1] = source.slice(3, source.length - 4);
  num[2] = source.slice(-4);
  return `${num[0]}-${num[1]}-${num[2]}`;
};

/**
 * 특정 element 으로 이동
 *
 * @param {HTMLElement} element 이동할 element
 * @param {"start" | "center" | "end" | "nearest"} block 설정
 * @param {"smooth" | "auto"} behavior 설정
 */
export const scrollIntoElement = (
  element: HTMLElement | null,
  block?: "start" | "center" | "end" | "nearest",
  behavior?: "smooth" | "auto",
): void => {
  if (element) {
    /**
     * https://developer.mozilla.org/ko/docs/Web/API/Element/scrollIntoView
     * behavior: 전환에니메이션을 정의 "auto", "smooth"중 하나 선택. 기본값은 "auto"
     * block: 수직 정렬을 정의 "start", "center", "end", "nearest"중 하나 선택. 기본값은 "start"
     *
     * safari 에서는 behavior: "smooth" 을 지원하지 않고 있음
     */
    element.scrollIntoView({
      behavior: behavior || "auto",
      block: block || "center",
    });
  }
};

/**
 * 문자열 앞, 뒤 공백 제거하기
 *
 * @param {string} target 앞, 뒤 공백 제거할 원본
 * @returns {string} 앞, 뒤 공백이 제거된 문자열
 */
export const setTrim = (target: string): string => {
  return target.trim();
};

/**
 * CSV 를 array 로 변환
 *
 * @param {string} targetCSV 변경할 CSV
 * @returns {number[]} number element 로 구성된 array
 */
export const setCSVtoArray = (targetCSV?: string): string[] => {
  if (!targetCSV) {
    return [];
  }
  return targetCSV.split(",");
};

/**
 * array 를 CSV 로 변환
 *
 * @param {any[]} targetArray 변경할 array
 * @returns {string} 변경된 CSV
 */
export const setArrayToCSV = (targetArray?: string[] | number[]): string => {
  if (!targetArray) {
    return "";
  }
  return targetArray.join(",");
};

/**
 * object 를 query string 으로 변환
 * @param url query string 을 붙일 url
 * @param data query string 으로 변환 할 data
 */
export const setObjectToQuerystring = (url: string, data: object) => {
  return (
    url +
    Object.entries(data).reduce((a, c, i) => {
      return `${a}${i === 0 ? "?" : "&"}${c[0]}=${typeof c[1] !== "boolean" ? c[1] : Number(c[1])}`;
    }, "")
  );
};

/**
 * query string 을 object 로 변환
 * @param key 변환할 key 값
 * @deprecated : key를 object로 분해하여 쓰지 마시오, key는 cache와 관련있는 key고 object는 object 이므로 명확히 경계를 구분해야합니다, 추가로 이 메소드는 string boolean과 real boolean을 구분할줄 모릅니다
 */
export const setQuerystringToObject = (key: string): [string, any] => {
  //검색어에 ? 가 들어가는 경우가 있기때문에 처음에 등장하는 ? 를 기준으로 분리
  const [url, param] = splitOnFirstTarget("?", key);

  const data = param.split("&").reduce((a, c) => {
    const [key, value] = splitOnFirstTarget("=", c);
    return {
      ...a,
      [key]: value,
    };
  }, {});

  return [url, data];
};

/**
 * @param target 바꾸려는 문자열
 * @param start 바꾸려는 문자열의 시작 인덱스
 * @param length 바꾸려는 문자열의 종료 인덱스
 * @param alter 바꾸고 싶은 문자열
 * @returns { string } 바뀐 문자열
 */
export const setAlterString = (target: string, start: number, length: number, alter: string): string => {
  // 바꾸려는 문자열의 길이가 바꾸고 싶은 문자열보다 더 길 경우 종료 인덱스 이후의 바뀌지 않은 문자열도 붙여서 반환
  if (start + length < target.length) {
    return target.slice(0, start) + repeat(alter, length) + target.slice(start + length);
  } else {
    // 바꾸려는 문자열의 길이가 바꾸고 싶은 문자열과 동일하거나 짧을 경우 바꾸고 싶은 문자열을 적용하여 반환
    return target.slice(0, start) + repeat(alter, length);
  }
};

/**
 * 배송지 입력까지 남은 시간(24시간 이상 남았을 시 일수, 1시간 초과 ~ 24시간 이내로 남았을 시 시간, 1시간 이내로 남았을 시 분을 반환)
 * @param currentDatetime 현재 Datetime(YYYY-MM-DD HH:MM:SS)
 * @param expireDatetime 만료 Datetime(YYYY-MM-DD HH:MM:SS)
 * @returns { string } 남은 일, 시간, 분(조건에 따라 한 종류의 값만 반환)
 */
export const getLeftDateTime = (currentDatetime: Date, expireDatetime: Date): string => {
  // 남은 일수
  const leftDay = differenceInDays(expireDatetime, currentDatetime);

  // 남은 시간
  const leftHour = differenceInHours(expireDatetime, currentDatetime);

  // 남은 분
  const leftMinute = differenceInMinutes(expireDatetime, currentDatetime);

  // 1일 이상 남았으면 남은 일수 반환
  if (leftDay > 0) return leftDay.toString() + "일";
  // 1일 미만 1시간 이상 남았으면 남은 시간 반환
  else if (leftHour > 0) return leftHour.toString() + "시간";
  // 1일 미만 1시간 미만 남았으면 남은 분 반환(배송지 입력 기한이 지난 경우에는 0분 반환)
  else return leftMinute < 0 ? "0분" : leftMinute.toString() + "분";
};

/***
 * 전체 텍스트 중 특정 텍스트만 Bold 처리하는 함수
 * @param content 전체 텍스트
 * @param keyword Bold 처리하고 싶은 텍스트
 * @returns { string } 키워드가 전체 텍스트에 존재할 경우, 전체 텍스트 중 키워드가 Bold 처리된 텍스트 반환, 이외의 경우에는 원래 텍스트 반환
 */
export const setKeywordBolding = (content: string, keyword: string) => {
  if (content.includes(keyword)) {
    return replaceAll(content, keyword, keyword.bold());
  }
  return content;
};

/***
 * 숫자를 받아, 한국 화폐 단위를 적용하는 함수
 * @param price 최대 할인가격, 최소 천원단위까지만 가능한 숫자를 전달받음.
 * @returns { string } @만원, @만@천원 으로 변환된 문자열
 */

export const convertToKoreanUnits = (price: number): string => {
  if (!isNumeric(price)) return '0원';

  const tenThousands = Math.floor(price / 10000);
  const thousands = Math.floor((price % 10000) / 1000);

  let formattedNumber = "";

  if (tenThousands > 0) {
    formattedNumber += `${tenThousands}만 `;
  }

  if (thousands > 0) {
    formattedNumber += `${thousands}천`;
  }

  return setUnit(formattedNumber.trim(), '원');
};
